import io from "socket.io-client";
import { serverUrl } from "../../helpers/utility";

export const socket = new io(serverUrl);

// Add a connect listener
socket.on("connect", () => {
  // Connected to server
  console.log("Socket connected.");
});

export let connected = false;
