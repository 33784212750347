import React from 'react';
import Modal from 'react-bootstrap/Modal';
import i18n from 'i18next';
import Button from 'react-bootstrap/Button';
import * as PropTypes from 'prop-types';

function GenericModal({
  show,
  size,
  hideModal,
  submitAction,
  title,
  submitLabel,
  submitClass,
  submitDisabled = false,
  submitTitle,
  children,
}) {
  return (
    <Modal
      size={size || 'md'}
      centered={true}
      show={show}
      onHide={() => {
        hideModal();
      }}
    >
      <form
        onSubmit={(event) => {
          if (event) {
            event.preventDefault();
          }

          if (submitAction) {
            submitAction();
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollable-modal">{children}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-dark"
            onClick={() => {
              hideModal();
            }}
            className={`${submitAction ? 'm-r--10' : ''}`}
          >
            {i18n.t('generic_modal__close')}
          </Button>

          {submitAction ? (
            <Button
              variant={submitClass || 'success'}
              type="submit"
              disabled={submitDisabled}
              title={submitTitle}
            >
              {submitLabel || i18n.t('generic_modal__ok')}
            </Button>
          ) : null}
        </Modal.Footer>
      </form>
    </Modal>
  );
}

GenericModal.propTypes = {
  show: PropTypes.bool.isRequired,
  size: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  submitAction: PropTypes.func,
  title: PropTypes.string.isRequired,
  submitLabel: PropTypes.string,
  submitClass: PropTypes.string,
  submitDisabled: PropTypes.bool,
};

export default GenericModal;
