import React from 'react';
import { withRouter } from 'react-router';
import './audit.scss';
import connect from 'react-redux/es/connect/connect';
import { withTranslation } from 'react-i18next';
import compose from 'ramda/src/compose';
import { forEach } from 'underscore';
import i18n from 'i18next';
import AuditSection from '../../components/audit-section/audit-section';
import AutoFixModal from '../../components/autofix-modal/autofix-modal';
import AutoFix from '../../components/auto-fix/auto-fix';
import RequestHelp from '../../components/request-help/request-help';
import { AuditSummary } from '../../components/audit-summary/audit-summary';
import ErrorBoundaryFallback from '../../components/error-boundary-fallback/error-boundary-fallback';
import WidgetContainer from '../../components/widgetContainer/widgetContainer';
import Toast from '../../components/toast/toast';
import EmptyStateContainer from '../../components/EmptyStateContainer/EmptyStateContainer';
import {
  EASY,
  MEDIUM,
  HARD,
  CATEGORY_SEO,
  CATEGORY_PERFORMANCE,
  CATEGORY_SECURITY,
  CATEGORY_MOBILE,
  SEO_ID,
  PERFORMANCE_ID,
  SECURITY_ID,
  MOBILE_ID,
} from '../../helpers/constants';
import { setAuditFailMsg } from '../../actions/notifications';

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    userType: state.authentication.userType,
    loginFailure: state.authentication.loginFailure,
    organisation: state.organisation.organisation,
    googleConnected: state.authentication.googleConnected,
    googleAnalyticsAccounts: state.authentication.googleAnalyticsAccounts,
    googleWebmasterAccounts: state.authentication.googleWebmasterAccounts,
    locale: state.locale.userLocaleModel,
    basicSiteList: state.sites.basicSiteList,
    site: state.sites.selectedSiteData,
    audit: state.sites.selectedSiteAuditData,
    notifications: state.notifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideAuditFailMsg: () => {
      dispatch(setAuditFailMsg(false));
    },
  };
};

class Audit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      issues: null,
      showAutoFixModal: false,
      showRequestHelpModal: false,
      seoTabActive: true,
      performanceTabActive: false,
      securityTabActive: false,
      mobileTabActive: false,
    };

    // difficulty is a score between 1 (easy) to 3 (difficult)
    this.metricInformation = {
      absent_doctype: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_doctype__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_doctype__fail_msg'),
        failName: i18n.t('audit_doctype__fail_name'),
        findIssue: null,
        findPages: (page) => page.have_doctype === false,
        issueFound: null,
        passMessage: i18n.t('audit_doctype__pass_msg'),
        passName: i18n.t('audit_doctype__pass_name'),
      },
      absent_encoding_meta_tag: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_meta__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_meta__fail_msg'),
        failName: i18n.t('audit_meta__fail_name'),
        findIssue: null,
        findPages: (page) => page.have_enc_meta_tag === false,
        issueFound: null,
        passMessage: i18n.t('audit_meta__pass_msg'),
        passName: i18n.t('audit_meta__pass_name'),
      },
      absent_h1_tags: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_h1__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_h1__fail_msg'),
        failName: i18n.t('audit_h1__fail_name'),
        findIssue: null,
        findPages: (page) => page.h1_count === 0,
        issueFound: null,
        passMessage: i18n.t('audit_h1__pass_msg'),
        passName: i18n.t('audit_h1__pass_name'),
      },
      compression_disabled: {
        canAutoFix: false,
        category: [CATEGORY_PERFORMANCE],
        description: i18n.t('audit_compression__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_compression__fail_msg'),
        failName: i18n.t('audit_compression__fail_name'),
        findIssue: null,
        findPages: null,
        issueFound: null,
        passMessage: i18n.t('audit_compression__pass_msg'),
        passName: i18n.t('audit_compression__pass_name'),
      },
      content_invalid_rate: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_content_rate__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_content_rate__fail_msg'),
        failName: i18n.t('audit_content_rate__fail_name'),
        findIssue: null,
        findPages: (page) => page.plain_text_rate < 0.1,
        issueFound: null,
        passMessage: i18n.t('audit_content_rate__pass_msg'),
        passName: i18n.t('audit_content_rate__pass_name'),
      },
      content_invalid_size: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_content_size__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_content_size__fail_msg'),
        failName: i18n.t('audit_content_size__fail_name'),
        findIssue: null,
        findPages: (page) => page.plain_text_size < 1024,
        issueFound: null,
        passMessage: i18n.t('audit_content_size__pass_msg'),
        passName: i18n.t('audit_content_size__pass_name'),
      },
      content_readability_bad: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_content_read__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_content_read__fail_msg'),
        failName: i18n.t('audit_content_read__fail_name'),
        findIssue: null,
        findPages: (page) => page.content_readability_flesh_kincaid < 15,
        issueFound: null,
        passMessage: i18n.t('audit_content_read__pass_msg'),
        passName: i18n.t('audit_content_read__pass_name'),
      },
      deprecated_html_tags: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_html_old__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_html_old__fail_msg'),
        failName: i18n.t('audit_html_old__fail_name'),
        findIssue: (page) => page.deprecated_html_tags,
        findPages: (page) => page.have_deprecated_tags,
        issueFound: i18n.t('audit_html_old__issue'),
        passMessage: i18n.t('audit_html_old__pass_msg'),
        passName: i18n.t('audit_html_old__pass_name'),
      },
      duplicate_meta_descriptions: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_meta_descr__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_meta_descr__fail_msg'),
        failName: i18n.t('audit_meta_descr__fail_name'),
        findIssue: null,
        findPages: (page) => page.have_meta_description_duplicates,
        passMessage: i18n.t('audit_meta_descr__pass_msg'),
        passName: i18n.t('audit_meta_descr__pass_name'),
      },
      duplicate_meta_tags: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_meta_tags__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_meta_tags__fail_msg'),
        failName: i18n.t('audit_meta_tags__fail_name'),
        findIssue: (page) => page.duplicate_meta_tags,
        findPages: (page) =>
          page.duplicate_meta_tags && page.duplicate_meta_tags.length,
        issueFound: i18n.t('audit_meta_tags__issue'),
        passMessage: i18n.t('audit_meta_tags__pass_msg'),
        passName: i18n.t('audit_meta_tags__pass_name'),
      },
      duplicate_titles: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_dup_titles__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_dup_titles__fail_msg'),
        failName: i18n.t('audit_dup_titles__fail_name'),
        findIssue: (page) => page.title,
        findPages: (page) => page.have_title_duplicates,
        issueFound: i18n.t('audit_dup_titles__issue'),
        passMessage: i18n.t('audit_dup_titles__pass_msg'),
        passName: i18n.t('audit_dup_titles__pass_name'),
      },
      favicon_invalid: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_favicon__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_favicon__fail_msg'),
        failName: i18n.t('audit_favicon__fail_name'),
        findIssue: null,
        findPages: null,
        issueFound: null,
        passMessage: i18n.t('audit_favicon__pass_msg'),
        passName: i18n.t('audit_favicon__pass_name'),
      },
      have_robots: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_robot__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_robot__fail_msg'),
        failName: i18n.t('audit_robot__fail_name'),
        findIssue: null,
        findPages: null,
        issueFound: null,
        passMessage: i18n.t('audit_robot__pass_msg'),
        passName: i18n.t('audit_robot__pass_name'),
      },
      have_sitemap: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_sitemap__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_sitemap__fail_msg'),
        failName: i18n.t('audit_sitemap__fail_name'),
        findIssue: null,
        findPages: null,
        issueFound: null,
        passMessage: i18n.t('audit_sitemap__pass_msg'),
        passName: i18n.t('audit_sitemap__pass_name'),
      },
      images_invalid_alt: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_no_alt__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_no_alt__fail_msg'),
        failName: i18n.t('audit_no_alt__fail_name'),
        findIssue: null,
        findPages: (pages) => pages.images_invalid_alt > 0,
        issueFound: null,
        passMessage: i18n.t('audit_no_alt__pass_msg'),
        passName: i18n.t('audit_no_alt__pass_name'),
      },
      links_broken: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_links__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_links__fail_msg'),
        failName: i18n.t('audit_links__fail_name'),
        findIssue: null,
        findPages: (pages) => pages.links_broken > 0,
        issueFound: null,
        passMessage: i18n.t('audit_links__pass_msg'),
        passName: i18n.t('audit_links__pass_name'),
      },
      meta_description_empty: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_empty_meta_descr__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_empty_meta_descr__fail_msg'),
        failName: i18n.t('audit_empty_meta_descr__fail_name'),
        findIssue: null,
        findPages: (page) => page.meta_description_length === 0,
        issueFound: null,
        passMessage: i18n.t('audit_empty_meta_descr__pass_msg'),
        passName: i18n.t('audit_empty_meta_descr__pass_name'),
      },
      meta_description_inappropriate: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_wrong_meta_descr__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_wrong_meta_descr__fail_msg'),
        failName: i18n.t('audit_wrong_meta_descr__fail_name'),
        findIssue: null,
        findPages: (page) => page.meta_description_consistency < 0.2,
        issueFound: null,
        passMessage: i18n.t('audit_wrong_meta_descr__pass_msg'),
        passName: i18n.t('audit_wrong_meta_descr__pass_name'),
      },
      pages_invalid_size: {
        canAutoFix: false,
        category: [CATEGORY_PERFORMANCE],
        description: i18n.t('audit_page_size__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_page_size__fail_msg'),
        failName: i18n.t('audit_page_size__fail_name'),
        findIssue: null,
        findPages: (page) => page.page_size < 1024 || page.page_size > 256000,
        issueFound: null,
        passMessage: i18n.t('audit_page_size__pass_msg'),
        passName: i18n.t('audit_page_size__pass_name'),
      },
      pages_with_flash: {
        canAutoFix: false,
        category: [CATEGORY_SEO, CATEGORY_MOBILE],
        description: i18n.t('audit_flash__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_flash__fail_msg'),
        failName: i18n.t('audit_flash__fail_name'),
        findIssue: null,
        findPages: (page) => page.have_flash,
        issueFound: null,
        passMessage: i18n.t('audit_flash__pass_msg'),
        passName: i18n.t('audit_flash__pass_name'),
      },
      pages_with_frame: {
        canAutoFix: false,
        category: [CATEGORY_PERFORMANCE, CATEGORY_MOBILE],
        description: i18n.t('audit_iframe__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_iframe__fail_msg'),
        failName: i18n.t('audit_iframe__fail_name'),
        findIssue: null,
        findPages: (page) => page.have_frame,
        issueFound: null,
        passMessage: i18n.t('audit_iframe__pass_msg'),
        passName: i18n.t('audit_iframe__pass_name'),
      },
      pages_with_lorem_ipsum: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_lorem__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_lorem__fail_msg'),
        failName: i18n.t('audit_lorem__fail_name'),
        findIssue: null,
        findPages: (page) => page.have_lorem_ipsum,
        issueFound: null,
        passMessage: i18n.t('audit_lorem__pass_msg'),
        passName: i18n.t('audit_lorem__pass_name'),
      },
      response_code_4xx: {
        canAutoFix: false,
        category: [CATEGORY_PERFORMANCE],
        description: i18n.t('audit_400__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_400__fail_msg'),
        failName: i18n.t('audit_400__fail_name'),
        findIssue: null,
        findPages: (page) => page.response_code.toString()[0] === '4',
        issueFound: null,
        passMessage: i18n.t('audit_400__pass_msg'),
        passName: i18n.t('audit_400__pass_name'),
      },
      response_code_5xx: {
        canAutoFix: false,
        category: [CATEGORY_PERFORMANCE],
        description: i18n.t('audit_500__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_500__fail_msg'),
        failName: i18n.t('audit_500__fail_name'),
        findIssue: null,
        findPages: (page) => page.response_code.toString()[0] === '5',
        issueFound: null,
        passMessage: i18n.t('audit_500__pass_msg'),
        passName: i18n.t('audit_500__pass_name'),
      },
      seo_non_friendly_url: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_seo_url__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_seo_url__fail_msg'),
        failName: i18n.t('audit_seo_url__fail_name'),
        findIssue: null,
        findPages: (page) => page.seo_friendly_url === false,
        issueFound: null,
        passMessage: i18n.t('audit_seo_url__pass_msg'),
        passName: i18n.t('audit_seo_url__pass_name'),
      },
      ssl: {
        canAutoFix: false,
        category: [CATEGORY_SECURITY],
        description: i18n.t('audit_ssl__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_ssl__fail_msg'),
        failName: i18n.t('audit_ssl__fail_name'),
        findIssue: null,
        findPages: (page) => page.ssl === false,
        issueFound: null,
        passMessage: i18n.t('audit_ssl__pass_msg'),
        passName: i18n.t('audit_ssl__pass_name'),
      },
      ssl_certificate_valid: {
        canAutoFix: false,
        category: [CATEGORY_SECURITY],
        description: i18n.t('audit_ssl_valid__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_ssl_valid__fail_msg'),
        failName: i18n.t('audit_ssl_valid__fail_name'),
        findIssues: null,
        findPages: (page) => page.ssl_certificate_valid === false,
        issueFound: null,
        passName: i18n.t('audit_ssl_valid__pass_name'),
        passMessage: i18n.t('audit_ssl_valid__pass_msg'),
      },
      test_server_signature: {
        canAutoFix: false,
        category: [CATEGORY_PERFORMANCE],
        description: i18n.t('audit_test_server__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_test_server__fail_msg'),
        failName: i18n.t('audit_test_server__fail_name'),
        findIssue: null,
        findPages: null,
        issueFound: null,
        passMessage: i18n.t('audit_test_server__pass_msg'),
        passName: i18n.t('audit_test_server__pass_name'),
      },
      time_load_high: {
        canAutoFix: false,
        category: [CATEGORY_PERFORMANCE],
        description: i18n.t('audit_load_high__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_load_high__fail_msg'),
        failName: i18n.t('audit_load_high__fail_name'),
        findIssue: null,
        findPages: null,
        issueFound: null,
        passMessage: i18n.t('audit_load_high__pass_msg'),
        passName: i18n.t('audit_load_high__pass_name'),
      },
      title_duplicate_tag: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_dup_title__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_dup_title__fail_msg'),
        failName: i18n.t('audit_dup_title__fail_name'),
        findIssue: null,
        findPages: (page) => page.title_duplicate_tag,
        issueFound: null,
        passMessage: i18n.t('audit_dup_title__pass_msg'),
        passName: i18n.t('audit_dup_title__pass_name'),
      },
      title_empty: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_empty_title__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_empty_title__fail_msg'),
        failName: i18n.t('audit_empty_title__fail_name'),
        findIssue: null,
        findPages: (page) => page.title_length === 0,
        issueFound: null,
        passMessage: i18n.t('audit_empty_title__pass_msg'),
        passName: i18n.t('audit_empty_title__pass_name'),
      },
      title_inappropriate: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_wrong_title__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_wrong_title__fail_msg'),
        failName: i18n.t('audit_wrong_title__fail_name'),
        findIssue: (page) => page.title,
        findPages: (page) => page.title_consistency < 0.3,
        issueFound: i18n.t('audit_wrong_title__issue'),
        passMessage: i18n.t('audit_wrong_title__pass_msg'),
        passName: i18n.t('audit_wrong_title__pass_name'),
      },
      title_long: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_long_title__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_long_title__fail_msg'),
        failName: i18n.t('audit_long_title__fail_name'),
        findIssue: (page) => page.title,
        findPages: (page) => page.title_length > 65,
        issueFound: i18n.t('audit_long_title__issue'),
        passMessage: i18n.t('audit_long_title__pass_msg'),
        passName: i18n.t('audit_long_title__pass_name'),
      },
      title_short: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_short_title__descr'),

        difficulty: EASY,
        failMessage: i18n.t('audit_short_title__fail_msg'),
        failName: i18n.t('audit_short_title__fail_name'),
        findIssue: (page) => page.title,
        findPages: (page) => page.title_length < 30,
        issueFound: i18n.t('audit_short_title__issue'),
        passMessage: i18n.t('audit_short_title__pass_msg'),
        passName: i18n.t('audit_short_title__pass_name'),
      },
    };

    this.toggleIssueVisibility = this.toggleIssueVisibility.bind(this);
    this.togglePagesVisibility = this.togglePagesVisibility.bind(this);
    this.onAutoFixClick = this.onAutoFixClick.bind(this);
    this.onRequestHelpClick = this.onRequestHelpClick.bind(this);
    this.setTabActive = this.setTabActive.bind(this);
  }

  renderDifficulty({ difficultyScore = 1 }) {
    let label = i18n.t('audit_label__easy');

    if (difficultyScore === EASY) {
      label = i18n.t('audit_label__easy');
    }

    if (difficultyScore === MEDIUM) {
      label = i18n.t('audit_label__medium');
    }

    if (difficultyScore === HARD) {
      label = i18n.t('audit_label__difficult');
    }

    return (
      <div className={`difficulty-score difficulty-${label.toLowerCase()}`}>
        <span>{label}</span>
      </div>
    );
  }

  renderAuditError(extendedCrawlStatus) {
    let auditFailText;
    switch (extendedCrawlStatus) {
      case 'site_unreachable':
        auditFailText = i18n.t('audit_failed__site_unreachable');
        break;
      case 'invalid_page_status_code':
        auditFailText = i18n.t('audit_failed__invalid_page_status_code');
        break;
      case 'forbidden_meta_tag':
        auditFailText = i18n.t('audit_failed__forbidden_meta_tag');
        break;
      case 'forbidden_robots':
        auditFailText = i18n.t('audit_failed__forbidden_robots');
        break;
      case 'forbidden_http_header':
        auditFailText = i18n.t('audit_failed__forbidden_http_header');
        break;
      case 'too_many_redirects':
        auditFailText = i18n.t('audit_failed__too_many_redirects');
        break;
      case 'unknown':
        auditFailText = i18n.t('audit_failed__unknown');
        break;
      default:
        auditFailText = '';
    }
    return auditFailText;
  }

  identifyAuditIssues() {
    if (this.props.audit && this.props.audit.summary) {
      const issues = [];

      forEach(this.props.audit.summary, (issueValue, key) => {
        if (
          this.metricInformation[key] &&
          this.metricInformation[key].passName &&
          this.metricInformation[key].description &&
          // Check that issueValue is not null, which can occur when an audit crawl fails.
          issueValue
        ) {
          issues.push({
            ...this.metricInformation[key],
            key,
            show: false,
            showPages: false,
            // Cast to boolean so UI doesn't fail on non-migrated data
            // (casting can be removed once fully migrated)
            passed: !!issueValue.passed,
          });
        }
      });

      this.setState({ issues });
    }
  }

  componentDidMount() {
    // Set the active tab from the location hash
    this.props.location.hash && this.setTabActive(this.props.location.hash);
    if (this.props.audit && this.props.audit._id) {
      // Audit data available
      if (this.props.audit.summary) {
        this.identifyAuditIssues();
      }
    }
  }

  componentDidUpdate(prevProps) {
    // |If hash changes, set the active tab from the location hash
    if (prevProps.location.hash !== this.props.location.hash) {
      this.setTabActive(this.props.location.hash);
    }

    if (
      (typeof prevProps.audit === 'undefined' &&
        this.props.audit &&
        this.props.audit._id) ||
      (this.props.audit &&
        prevProps.audit &&
        this.props.audit._id !== prevProps.audit._id)
    ) {
      // Audit data available
      if (this.props.audit.summary) {
        this.identifyAuditIssues();
      }
    }
  }

  changeLanguage(lng) {
    this.props.i18n.changeLanguage(lng);
  }

  toggleIssueVisibility({ issueName }) {
    const issues = this.state.issues.map((issue) =>
      issue.passName === issueName
        ? {
            ...issue,
            show: !issue.show,
          }
        : issue
    );

    this.setState({
      issues,
    });
  }

  togglePagesVisibility({ issueName }) {
    const issues = this.state.issues.map((issue) =>
      issue.passName === issueName
        ? {
            ...issue,
            showPages: !issue.showPages,
          }
        : issue
    );

    this.setState({
      issues,
    });
  }

  onAutoFixClick(issue) {
    this.setState({
      showAutoFixModal: true,
      currentIssue: issue,
    });
  }

  onRequestHelpClick(issue) {
    this.setState({
      showRequestHelpModal: true,
      currentIssue: issue,
    });
  }

  setTabActive(string) {
    let tabString =
      string.substring(0, 1) === '#'
        ? string.substring(1, string.length)
        : string;
    tabString = tabString.split('_')[0];
    // Return early if incorrect string is passed from hash router
    if (!['seo', 'performance', 'security', 'mobile'].includes(tabString)) {
      return;
    }
    const activeTabState = tabString + 'TabActive';
    const newTabState = {
      seoTabActive: false,
      performanceTabActive: false,
      securityTabActive: false,
      mobileTabActive: false,
    };
    newTabState[activeTabState] = true;
    this.setState(newTabState);
  }

  render() {
    const issuesLoaded = this.state.issues && this.state.issues.length > 0;
    const issues = issuesLoaded && [...this.state.issues];

    return (
      <div id="audit">
        <div className="standard-page-layout">
          {this.props.user &&
          this.props.audit &&
          this.props.site &&
          this.props.audit ? (
            <div className="m-b--15">
              <div className="col-12 page-padding">
                {this.props.site.audit.didLastAuditFail &&
                  this.props.notifications.auditFailMsg && (
                    <Toast
                      type="error"
                      closeToastCallback={() => this.props.hideAuditFailMsg()}
                      title={i18n.t('audit_failed__base_message')}
                      subtitle={this.renderAuditError(
                        this.props.site.audit.auditFailReason
                      )}
                    />
                  )}
                {this.props.site.audit.didLastAuditFail &&
                !this.props.site.audit.lastSuccessfulAuditId ? (
                  <EmptyStateContainer
                    message={i18n.t('empty_state_container__when_ready')}
                  />
                ) : (
                  <WidgetContainer>
                    <ErrorBoundaryFallback text="Something went wrong while trying to load your audit summary.">
                      <AuditSummary
                        audit={this.props.audit}
                        site={this.props.site}
                      />
                    </ErrorBoundaryFallback>
                  </WidgetContainer>
                )}

                <div className="col-12 col-lg-6 offset-lg-3 mb-5">
                  <h1 className="title">{i18n.t('audit_title')}</h1>
                  <p className="description">{i18n.t('audit_continuous')}</p>
                </div>

                {this.props.site.audit &&
                this.props.site.audit.didLastAuditFail &&
                !this.props.site.audit.lastSuccessfulAuditId ? (
                  <EmptyStateContainer
                    message={i18n.t('empty_state_container__when_ready')}
                  />
                ) : (
                  <>
                    <div className="tab-menu">
                      <ul>
                        <li>
                          <button
                            className={
                              this.state.seoTabActive ? 'btn active' : 'btn'
                            }
                            onClick={() => this.setTabActive('seo')}
                          >
                            {i18n.t('audit_tab_menu__seo')}
                          </button>
                        </li>
                        <li>
                          <button
                            className={
                              this.state.performanceTabActive
                                ? 'btn active'
                                : 'btn'
                            }
                            onClick={(e) => this.setTabActive('performance')}
                          >
                            {i18n.t('audit_tab_menu__performance')}
                          </button>
                        </li>
                        <li>
                          <button
                            className={
                              this.state.securityTabActive
                                ? 'btn active'
                                : 'btn'
                            }
                            onClick={(e) => this.setTabActive('security')}
                          >
                            {i18n.t('audit_tab_menu__security')}
                          </button>
                        </li>
                        <li>
                          <button
                            className={
                              this.state.mobileTabActive ? 'btn active' : 'btn'
                            }
                            onClick={(e) => this.setTabActive('mobile')}
                          >
                            {i18n.t('audit_tab_menu__mobile')}
                          </button>
                        </li>
                      </ul>
                    </div>
                    {issuesLoaded && (
                      <>
                        {this.state.seoTabActive && (
                          <AuditSection
                            {...{
                              title: i18n.t('audit_summary__seo'),
                              shortTitle: i18n.t('audit_seo__short'),
                              id: SEO_ID,
                              description: i18n.t('audit_seo__description'),
                              category: CATEGORY_SEO,
                              issues: issues.filter((x) =>
                                x.category.includes(CATEGORY_SEO)
                              ),
                              renderDifficulty: this.renderDifficulty,
                              toggleIssueVisibility: this.toggleIssueVisibility,
                              togglePagesVisibility: this.togglePagesVisibility,
                              onAutoFixClick: this.onAutoFixClick,
                              onRequestHelpClick: this.onRequestHelpClick,
                              site: this.props.site,
                              audit: this.props.audit,
                              pagesTotal: this.props.audit.summary.pages_total,
                              score: 'seoScore',
                            }}
                          />
                        )}

                        {this.state.performanceTabActive && (
                          <AuditSection
                            {...{
                              title: i18n.t('audit_performance'),
                              id: PERFORMANCE_ID,
                              description: i18n.t('audit_performance__descr'),
                              category: CATEGORY_PERFORMANCE,
                              issues: issues.filter((x) =>
                                x.category.includes(CATEGORY_PERFORMANCE)
                              ),
                              renderDifficulty: this.renderDifficulty,
                              toggleIssueVisibility: this.toggleIssueVisibility,
                              togglePagesVisibility: this.togglePagesVisibility,
                              onAutoFixClick: this.onAutoFixClick,
                              onRequestHelpClick: this.onRequestHelpClick,
                              site: this.props.site,
                              audit: this.props.audit,
                              score: 'performanceScore',
                            }}
                          />
                        )}

                        {this.state.securityTabActive && (
                          <AuditSection
                            {...{
                              title: i18n.t('audit_security'),
                              id: SECURITY_ID,
                              description: i18n.t('audit_security__descr'),
                              category: CATEGORY_SECURITY,
                              issues: issues.filter((x) =>
                                x.category.includes(CATEGORY_SECURITY)
                              ),
                              renderDifficulty: this.renderDifficulty,
                              toggleIssueVisibility: this.toggleIssueVisibility,
                              togglePagesVisibility: this.togglePagesVisibility,
                              onAutoFixClick: this.onAutoFixClick,
                              onRequestHelpClick: this.onRequestHelpClick,
                              site: this.props.site,
                              audit: this.props.audit,
                              score: 'securityScore',
                            }}
                          />
                        )}

                        {this.state.mobileTabActive && (
                          <AuditSection
                            {...{
                              title: i18n.t('audit_mobile'),
                              id: MOBILE_ID,
                              description: i18n.t('audit_mobile__descr'),
                              category: CATEGORY_MOBILE,
                              issues: issues.filter((x) =>
                                x.category.includes(CATEGORY_MOBILE)
                              ),
                              renderDifficulty: this.renderDifficulty,
                              toggleIssueVisibility: this.toggleIssueVisibility,
                              togglePagesVisibility: this.togglePagesVisibility,
                              onAutoFixClick: this.onAutoFixClick,
                              onRequestHelpClick: this.onRequestHelpClick,
                              site: this.props.site,
                              audit: this.props.audit,
                              score: 'mobileScore',
                            }}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          ) : null}

          <AutoFixModal
            show={this.state.showAutoFixModal}
            hideModal={() => {
              this.setState({
                showAutoFixModal: false,
                currentIssue: null,
              });
            }}
            title={i18n.t('audit_fix_issue')}
            size="lg"
            beta={
              this.props.audit && this.props.audit.detectedCMS === 'WordPress'
            }
          >
            <div className="auto-fix-modal">
              <AutoFix
                CMS={this.props.audit && this.props.audit.detectedCMS}
                currentIssue={this.state.currentIssue}
                site={this.props.site}
                user={this.props.user}
                hideModal={() => {
                  this.setState({
                    showAutoFixModal: false,
                    currentIssue: null,
                  });
                }}
              />
            </div>
          </AutoFixModal>
          <RequestHelp
            show={this.state.showRequestHelpModal}
            hideModal={() => {
              this.setState({
                showRequestHelpModal: false,
                currentIssue: null,
              });
            }}
            currentIssue={this.state.currentIssue}
            audit={this.props.audit}
            user={this.props.user}
          />
        </div>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Audit);
