import './toast.scss';

const Toast = ({ type, closeToastCallback, title, subtitle }) => {
  const renderClassNames = (type) => {
    switch (type) {
      case 'error':
        return 'toast toast-danger';
      default:
        return 'toast toast-info';
    }
  };

  const renderIcon = (type) => {
    switch (type) {
      case 'error':
        return <i className="fal fa-exclamation-triangle"></i>;
      default:
        return <i className="fal fa-info-circle"></i>;
    }
  };

  return (
    <div className={renderClassNames(type)}>
      <div className="toast-details">
        <div className="toast-icon">{renderIcon(type)}</div>
        <div className="toast-text">
          <span className="title">{title}</span>
          <span>{subtitle}</span>
        </div>
      </div>
      <div className="toast-close" onClick={closeToastCallback}>
        <i className="fal fa-times" />
      </div>
    </div>
  );
};

export default Toast;
