import React from 'react';
import * as PropTypes from 'prop-types';
import i18n from 'i18next';
import ReactSpeedometer from 'react-d3-speedometer';
import AuditPassed from '../audit-passed/audit-passed';

import './audit-speed-chart.scss';

import theme from '../../assets/css/theme.module.scss';

const getPassed = (value, limit) => value <= limit;
const getResult = (value, limit) =>
  getPassed(value, limit)
    ? i18n.t('audit_section__yes')
    : i18n.t('audit_section__no');

const AuditSpeedChart = ({ value, units, timeLimit, bounds }) => {
  return (
    <div className="audit-speed-chart">
      <AuditPassed
        result={getResult(value, bounds[0])}
        passed={getPassed(value, bounds[0])}
      />
      <ReactSpeedometer
        value={Math.min(value, timeLimit)}
        segmentColors={[theme.soCoSecondary, '#f59e4d', theme.soCoTertiary]}
        maxValue={0}
        minValue={timeLimit}
        customSegmentStops={[timeLimit, bounds[1], bounds[0], 0]}
        needleColor="#5f5f5f"
        currentValueText={`${value}${units}`}
        width={240}
        height={240}
        valueTextFontSize="20px"
      />
    </div>
  );
};

AuditSpeedChart.propTypes = {
  value: PropTypes.number.isRequired,
};

export default AuditSpeedChart;
