import React from 'react';
import i18n from 'i18next';
import RequestUpdate from '../request-update/request-update';

const Divider = ({ text }) => (
  <p className="go-divider">
    <span className="go-divider__line"></span>
    <span className="go-divider__text">{text}</span>
    <span className="go-divider__line"></span>
  </p>
);

const UpdateWP = ({ domain, user, hideModal }) => (
  <div>
    <h4 className="update-wp__header">{i18n.t('wp_auto__update_header')}</h4>
    <p>
      <strong>{i18n.t('wp_auto__update_paragraph_1')}</strong>
    </p>
    <p>
      {i18n.t('wp_auto__update_paragraph_2')}{' '}
      <a
        href="https://wordpress.org/support/article/updating-wordpress/"
        target="_blank"
        rel="noreferrer"
      >
        {i18n.t('wp_auto__update_paragraph_2a')}
      </a>{' '}
      {i18n.t('wp_auto__update_paragraph_2b')}
    </p>
    <Divider text="OR" />
    <RequestUpdate domain={domain} user={user} hideModal={hideModal} />
  </div>
);

export default UpdateWP;
