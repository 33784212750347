import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import i18n from 'i18next';
import {
  MOBILE_ID,
  PERFORMANCE_ID,
  SECURITY_ID,
  SEO_ID,
} from '../../helpers/constants';
import GRBarChart from '../gr-bar-chart/gr-bar-chart';
import IpadScreenshot from '../ipad-screenshot/ipad-screenshot';
import ipadTransparent from '../../assets/images/ipadTransparent.png';
import './audit-summary.scss';
import GRRadialChart from '../gr-radial-chart/gr-radial-chart';
import NoScreenshot from '../no-screenshot/no-screenshot';

const AuditSummary = ({
  audit: {
    scores: {
      overallScore,
      performanceScore,
      securityScore,
      seoScore,
      mobileScore,
    } = {},
    screenshotUrl,
  },
  site,
}) => {
  return (
    <div id="audit-summary" className="dashboard-box">
      <div className="row row-no-margin align-items-center gr-section dashboard-box-content audit-summary-content dashboard-box-content-no-min-height">
        <div className="col-12 col-md-6 col-lg-4">
          {overallScore ? (
            <Link to="/audit">
              <GRRadialChart
                value={overallScore}
                titlePrefix="Overall"
                infoIcon={
                  <div>
                    <p>{i18n.t('audit_summary__overall_score')}</p>
                    <p>
                      <span className="bold">
                        {i18n.t('audit_summary__seo')}
                      </span>
                      &nbsp;= {i18n.t('audit_summary__seo_2')}
                      <br />
                      <span className="bold">
                        {i18n.t('audit_summary__perf')}
                      </span>
                      &nbsp;= {i18n.t('audit_summary__perf_2')}
                      <br />
                      <span className="bold">
                        {i18n.t('audit_summary__security')}
                      </span>
                      &nbsp;= {i18n.t('audit_summary__security_2')}
                      <br />
                      <span className="bold">
                        {i18n.t('audit_summary__mobile')}
                      </span>
                      &nbsp;= {i18n.t('audit_summary__mobile_2')}
                      <br />
                    </p>
                  </div>
                }
              />
            </Link>
          ) : (
            <p className="audit-scoring-pending">
              {i18n.t('audit_summary__pending')}
            </p>
          )}
        </div>

        <div className="col-12 col-md-6 col-lg-3 text-left audit-group-container">
          {seoScore && (
            <>
              <div className="audit-bar-chart-container">
                <HashLink
                  to={`/audit#${SEO_ID}`}
                  className="audit-bar-chart-title"
                >
                  {i18n.t('audit_summary__seo_bis')}
                  <span className="audit-bar-chart-score audit-bar-chart-score-seo">
                    {seoScore}%
                  </span>
                  <GRBarChart chartId="seoScoreChart" value={seoScore} />
                </HashLink>
              </div>
            </>
          )}

          {performanceScore && (
            <>
              <div className="audit-bar-chart-container">
                <HashLink
                  to={`/audit#${PERFORMANCE_ID}`}
                  className="audit-bar-chart-title"
                >
                  {i18n.t('audit_summary__perf')}
                  <span className="audit-bar-chart-score">
                    {performanceScore}%
                  </span>
                  <GRBarChart
                    chartId="performanceScoreChart"
                    value={performanceScore}
                  />
                </HashLink>
              </div>
            </>
          )}

          {securityScore && (
            <>
              <div className="audit-bar-chart-container">
                <HashLink
                  to={`/audit#${SECURITY_ID}`}
                  className="audit-bar-chart-title"
                >
                  {i18n.t('audit_summary__security')}
                  <span className="audit-bar-chart-score">
                    {securityScore}%
                  </span>
                  <GRBarChart
                    chartId="securityScoreChart"
                    value={securityScore}
                  />
                </HashLink>
              </div>
            </>
          )}

          {mobileScore && (
            <>
              <div className="audit-bar-chart-container">
                <HashLink
                  to={`/audit#${MOBILE_ID}`}
                  className="audit-bar-chart-title"
                >
                  {i18n.t('audit_summary__mobile')}
                  <span className="audit-bar-chart-score">{mobileScore}%</span>
                  <GRBarChart chartId="mobileScoreChart" value={mobileScore} />
                </HashLink>
              </div>
            </>
          )}
        </div>

        <div className="col-12 col-lg-5">
          <div id="siteSummaryScreenshot" className="text-center">
            {screenshotUrl && screenshotUrl !== null ? (
              <IpadScreenshot
                canvasId={'primaryScreenshot'}
                screenshotSrc={screenshotUrl}
                displayWidth={415}
              />
            ) : (
              <div style={{ position: 'relative' }}>
                <img
                  src={ipadTransparent}
                  alt="iPad"
                  style={{
                    width: '385px',
                    maxWidth: '100%',
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: '290px',
                  }}
                >
                  <NoScreenshot text={i18n.t('screenshot__not_available')} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { AuditSummary };
