import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import RegisterAgencyForm from './register-agency-form/register-agency-form';
import './register-agency.scss';
import { getParameterByName, serverUrl } from '../../helpers/utility';
import axios from 'axios';
import LoadingCircle from '../../components/loading-circle/loading-circle';

class RegisterAgency extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      registerErrorMessage: null,
      registerSuccess: false,
      timerFinished: false,
      secondsRemaining: 30,
      redirectInfo: null,
    };

    this.attemptRegisterAgency = this.attemptRegisterAgency.bind(this);
  }

  attemptRegisterAgency(event, data) {
    if (event) {
      event.preventDefault();
    }

    // Set source based on URL parameter, if one exists
    const source = getParameterByName('source');
    if (source && data) {
      console.log('---- Set sign-up source to: ' + source);
      data.source = source;
    } else {
      // Fallback to session information as source
      if (sessionStorage && sessionStorage.getItem('source') && data) {
        data.source = sessionStorage.getItem('source');
      }
    }

    // Validate terms
    if (data.agreeToTerms !== true) {
      this.setState({
        registerErrorMessage: i18n.t('register_agency__terms_error'),
      });

      return false;
    }

    axios
      .post(`${serverUrl}/organisation/register`, data)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.success &&
          response.data.data
        ) {
          const redirectInfo = response.data.data;

          this.setState({
            registerSuccess: true,
            internalSubdomain: data.internalSubdomain,
            redirectInfo,
          });

          let countdown = setInterval(() => {
            const currentSecondsRemaining = this.state.secondsRemaining;

            if (currentSecondsRemaining > 0) {
              this.setState({
                secondsRemaining: currentSecondsRemaining - 1,
              });
            } else {
              clearInterval(countdown);
            }
          }, 1000);

          setTimeout(() => {
            this.setState({
              timerFinished: true,
            });
          }, 30000);

          setTimeout(() => {
            window.href = `https://${
              redirectInfo.customAppURL[0]
            }/#/login?username=${encodeURIComponent(
              redirectInfo.username
            )}&password=${encodeURIComponent(redirectInfo.password)}`;
          }, 45000);
        } else {
          this.setState({
            registerErrorMessage: i18n.t('register_agency__generic_error'),
          });
        }
      })
      .catch(function (error) {
        // handle error
        this.setState({
          registerErrorMessage: error.message,
        });
      });
  }

  componentDidMount() {
    // Persist any source information to session, useful in case we navigate away from register page
    const source = getParameterByName('source');
    // Do not set if null etc.
    if (source) {
      sessionStorage.setItem('source', source);
    }
  }

  componentDidUpdate() {
    // Must be done on update not in the constructor.
    if (this.props.user) {
      console.log(
        '---- REDIRECT: You are logged in, redirecting from register to homepage.'
      );
      window.location.href = '#/';
    }
  }

  render() {
    return (
      <div
        className={`container-fluid ${
          this.state.registerSuccess === true ? 'complete' : ''
        }`}
        id="register-agency"
      >
        {this.state.registerSuccess !== true ? (
          <div className="d-flex flex-wrap align-items-center full-height">
            <div className="col-12 text-center col-lg-6 col-xl-5">
              <div className="small-form-container">
                {this.props.user ? (
                  <div>
                    <h1>{i18n.t('register')}</h1>

                    <h3>{i18n.t('register__registered')}</h3>
                  </div>
                ) : (
                  <RegisterAgencyForm
                    attemptRegisterAgency={this.attemptRegisterAgency}
                    registerErrorMessage={this.state.registerErrorMessage}
                  />
                )}
              </div>
            </div>

            <div
              className="d-none d-lg-flex col-lg-6 col-xl-7"
              id="register-bg"
            >
              <div className="m-b--30">
                <h1 id="loginMarketingTitle">
                  {i18n.t('register_agency__audit_lead')}
                </h1>

                <div className="">
                  <div className="register-feature">
                    <div className="feature-icon">
                      <span className="fal fa-infinity" />
                    </div>
                    <div className="feature-title">
                      {i18n.t('register_agency__unlimited_audits')}
                    </div>
                  </div>

                  <div className="register-feature">
                    <div className="feature-icon">
                      <span className="fal fa-file-chart-line" />
                    </div>
                    <div className="feature-title">
                      {i18n.t('register_agency__reporting')}
                    </div>
                  </div>

                  <div className="register-feature">
                    <div className="feature-icon">
                      <span className="fal fa-paint-brush" />
                    </div>
                    <div className="feature-title">
                      {i18n.t('register_agency__branding')}
                    </div>
                  </div>

                  <div className="register-feature">
                    <div className="feature-icon">
                      <span className="fal fa-envelope-open-dollar" />
                    </div>
                    <div className="feature-title">
                      {i18n.t('register_agency__sales_proposals')}
                    </div>
                  </div>

                  <div className="register-feature">
                    <div className="feature-icon">
                      <span className="fal fa-analytics" />
                    </div>
                    <div className="feature-title">
                      {i18n.t('register_agency__no_cost_upfront')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {this.state.registerSuccess === true ? (
          <div className="main-content-container">
            <div className="col-12 text-center col-sm-10 offset-sm-1 col-xl-6 offset-xl-0">
              <div className="partner-confirmation m-t--30">
                <h1 className="primary-title">{i18n.t('welcome')}</h1>

                <div className="row">
                  <div className="col-12">
                    <div>
                      {this.state.timerFinished === true ? (
                        <Fragment>
                          <h2 className="m-t--30 m-b--10">
                            {i18n.t('register__account_created')}
                          </h2>

                          <p className="col-12 m-t--20">
                            {i18n.t('register_agency__try_again_later')}
                          </p>

                          <br />

                          {this.state.redirectInfo ? (
                            <a
                              href={`https://${
                                this.state.redirectInfo.customAppURL[0]
                              }/#/login?username=${encodeURIComponent(
                                this.state.redirectInfo.username
                              )}&password=${encodeURIComponent(
                                this.state.redirectInfo.password
                              )}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="btn btn-success btn-lg">
                                {`https://${this.state.redirectInfo.customAppURL[0]}`}
                              </div>
                            </a>
                          ) : null}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <h2 className="m-t--30 m-b--10">
                            {i18n.t('register__creating_account')}
                          </h2>

                          <p className="m-b--45">
                            {i18n.t('register__please_wait_1')}{' '}
                            {this.state.secondsRemaining}{' '}
                            {i18n.t('register__please_wait_2')}
                          </p>
                          <br />

                          <LoadingCircle />
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

RegisterAgency.propTypes = {
  user: PropTypes.object,
};

export default RegisterAgency;
