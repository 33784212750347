import { connect } from "react-redux";
import RegisterAgency from "./register-agency";

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    registerError: state.register.error,
    registerErrorMessage: state.register.errorMessage,
  };
};

const RegisterAgencyContainer = connect(mapStateToProps)(RegisterAgency);

export default RegisterAgencyContainer;
