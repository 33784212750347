import React from 'react';
import i18n from 'i18next';
import './no-results-content.scss';
import * as PropTypes from 'prop-types';

class NoResultsContent extends React.Component {
  render() {
    return (
      <div className="no-results">
        <h4>{this.props.title || i18n.t('no_results')}</h4>

        <p>{this.props.description || i18n.t('no_results__not_discovered')}</p>
      </div>
    );
  }
}

NoResultsContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default NoResultsContent;
