import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import classnames from 'classnames';
import { serverUrl } from '../../helpers/utility';

import AddSiteDescription from './add-site-description';
import LoadingCircle from '../../components/loading-circle/loading-circle';

const Status = ({ websiteOK, error }) => {
  if (error) {
    switch (error) {
      case 'ValidationError':
        return (
          <div className="add-site__status add-site__status--error">
            This URL is invalid
          </div>
        );
      default:
        return (
          <div className="add-site__status add-site__status--error">
            Something went wrong, please try again later
          </div>
        );
    }
  } else {
    switch (websiteOK) {
      case true:
        return (
          <div className="add-site__status add-site__status--online">
            Your website is online
          </div>
        );
      case false:
        return (
          <div className="add-site__status add-site__status--offline">
            Your website is offline
          </div>
        );
      default:
        return (
          <div className="add-site__status">
            Check the availability of your site
          </div>
        );
    }
  }
};

class AddSiteForm extends Component {
  constructor() {
    super();

    this.state = {
      domain: '',
      websiteOK: null,
      statusCode: null,
      auditInProgress: false,
      loading: false,
      error: null,
    };
  }

  checkSiteStatus = async (url) => {
    const payload = {
      domain: url,
    };

    try {
      const response = await axios.post(
        `${serverUrl}/site/uptime/check`,
        payload
      );

      const { websiteOK, statusCode } = response.data;

      this.setState({
        websiteOK,
        statusCode,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    if (!this.state.websiteOK) {
      this.checkSiteStatus(this.state.domain);
    } else {
      const response = await axios.post(`${serverUrl}/site`, {
        domain: this.state.domain,
      });
      this.setState({ loading: true });

      const { success, error } = response.data;
      if (success) {
        // redirect
        // show success screen announcing email to come
        // lock access to /add-site
        setTimeout(() => {
          this.setState({ auditInProgress: true, loading: false });
        }, 5000);
      } else {
        this.setState({
          loading: false,
          error: typeof error === 'undefined' ? true : error,
        });
        console.log(`ERR: the site could not be added`);
      }
    }
  };

  handleReset = (e) => {
    e.preventDefault();

    this.setState({
      domain: '',
      websiteOK: null,
      statusCode: null,
    });
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      websiteOK: null,
      statusCode: null,
      error: null,
    });
  };

  render() {
    const { domain, websiteOK, loading, auditInProgress, error } = this.state;

    const inputClasses = classnames({
      'form-control': true,
      'is-invalid': error === 'ValidationError',
    });

    // check if site is online
    // if not display warning message instead of submitting form
    // on second press submit anyway
    if (loading) {
      return (
        <div className="small-form-container">
          <div className="add-site__status add-site__status--loading">
            <LoadingCircle />
            We are preparing your audit, this may take a few minutes...
          </div>
        </div>
      );
    }
    if (auditInProgress) {
      return (
        <div className="small-form-container">
          <div className="add-site__status add-site__status--loading">
            <p>You can now close this window. </p>
            <p>
              We will send you an email once we have collected and prepared the
              data!
            </p>
            <p className="text-small">
              the average waiting time is around 10min
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <AddSiteDescription />
          <div className="small-form-container">
            <form
              className="add-site__form"
              onSubmit={this.handleSubmit}
              autoComplete="off"
            >
              <div className="formInput">
                <label>Site URL</label>
                <input
                  name="domain"
                  placeholder="ex: https://mysite.com"
                  className={inputClasses}
                  type="text"
                  value={domain}
                  maxLength="100"
                  onChange={this.handleInputChange}
                  autoFocus
                  required
                />
              </div>
              <div className="formFooter">
                <Button variant="outline-dark" onClick={this.handleReset}>
                  Reset
                </Button>
                <Button variant="success" type="submit">
                  {!websiteOK ? 'Check Status' : 'Add Site'}
                </Button>
              </div>
              <Status websiteOK={websiteOK} error={error} />
            </form>
          </div>
        </>
      );
    }
  }
}

export default AddSiteForm;
