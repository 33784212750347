import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      agreeToTerms: false,
    };
  }

  handleInputChange = (event) => {
    if (event.target && event.target.name) {
      const update = {};
      update[event.target.name] = event.target.value;
      this.setState(update);
    }
  };

  handleCheckboxChange = (event) => {
    if (event.target && event.target.name) {
      const update = {};
      update[event.target.name] = event.target.checked;
      this.setState(update);
    }
  };

  render() {
    return (
      <Fragment>
        {this.props.organisationLogoDark ? (
          <div className="login-logo-container">
            <img
              src={this.props.organisationLogoDark}
              alt="Logo"
              className="login-logo"
            />
          </div>
        ) : null}

        <h1 className="h2 m-b--30">
          {this.props.t('register_form__create_free_account')}
        </h1>

        <form
          onSubmit={(event) => {
            this.props.attemptRegister(event, this.state);
          }}
          autoComplete="off"
        >
          <div>
            <div className="formInput">
              <div className="text-left">
                {this.props.t('register_form__name')}
              </div>
              <input
                autoFocus={true}
                type="text"
                className="form-control"
                value={this.state.name}
                autoComplete="name"
                name="name"
                required
                minLength="3"
                onChange={this.handleInputChange}
              />
            </div>

            <div className="formInput">
              <div className="text-left">{this.props.t('login__email')}</div>
              <input
                type="email"
                className="form-control"
                value={this.state.email}
                name="email"
                autoComplete="email"
                required
                onChange={this.handleInputChange}
              />
            </div>

            <div className="formInput">
              <div className="text-left">
                {this.props.t('account__password')}
              </div>
              <input
                type="password"
                className="form-control"
                value={this.state.password}
                name="password"
                required
                minLength="5"
                onChange={this.handleInputChange}
              />
            </div>

            <div className="formInput text-left">
              <label className={`checkbox-container`}>
                {this.props.t('register_form__terms_1')}{' '}
                <a
                  className="underline"
                  href={this.props.termsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {this.props.t('register_form__terms_2')}
                </a>
                <input
                  type="checkbox"
                  value={this.state.agreeToTerms}
                  autoComplete="agreeToTerms"
                  name="agreeToTerms"
                  onChange={this.handleCheckboxChange}
                />
                <span className="checkmark" />
              </label>
            </div>
          </div>

          {this.props.registerError ? (
            <div className="error m-t--10 m-b--20">
              {this.props.registerErrorMessage}
            </div>
          ) : null}

          <input
            type="submit"
            value="Create Account"
            className="btn btn-success wide"
          />

          <div className="loginOtherOptions">
            <div>
              <a href="#/login">
                <i>{this.props.t('register_form__already_have_account')}</i>
              </a>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

RegisterForm.propTypes = {
  attemptRegister: PropTypes.func.isRequired,
  registerError: PropTypes.bool.isRequired,
  registerErrorMessage: PropTypes.string,
  termsUrl: PropTypes.string,
  organisationLogoDark: PropTypes.string,
};

export default withTranslation()(RegisterForm);
