import React from 'react';
import * as PropTypes from 'prop-types';
import './gr-bar-chart.scss';
import { handleInputChange } from '../../helpers/utility';
import Chart from 'react-apexcharts';

import theme from '../../assets/css/theme.module.scss';

class GRBarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleInputChange = handleInputChange.bind(this);
  }

  getColor(score) {
    if (score > 80) {
      return theme.soCoTertiary;
    } else {
      if (score > 50) {
        return '#f59e4d';
      } else {
        return theme.soCoSecondary;
      }
    }
  }

  render() {
    return (
      <Chart
        options={{
          chart: {
            height: 8,
            type: 'bar',
            stacked: true,
            sparkline: {
              enabled: true,
            },
          },
          states: {
            normal: {
              filter: {
                type: 'none',
                value: 0,
              },
            },
            hover: {
              filter: {
                type: 'none',
                value: 0,
              },
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '70%',
              colors: {
                backgroundBarColors: [this.props.backgroundColor || '#fff'],
                backgroundBarOpacity: 0.2,
                backgroundBarRadius: 5,
              },
              startingShape: 'rounded',
              endingShape: 'rounded',
            },
          },
          title: {
            enabled: false,
          },
          subtitle: {
            enabled: false,
          },
          tooltip: {
            enabled: false,
          },
          yaxis: {
            max: 100,
          },
          fill: {
            colors: this.getColor(this.props.value),
            opacity: 1,
            type: 'solid',
          },
        }}
        series={[
          {
            name: 'Metric',
            data: [this.props.value],
          },
        ]}
        type="bar"
        height={12}
        className={'chart-radius m-b--10'}
      />
    );
  }
}

GRBarChart.propTypes = {
  value: PropTypes.number.isRequired,
};

export default GRBarChart;
