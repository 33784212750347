const initialState = {
  auditFailMsg: false,
};
const notifications = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_AUDIT_FAIL_MSG':
      return {
        ...state,
        auditFailMsg: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default notifications;
