import React from 'react';
import i18n from 'i18next';
import Modal from 'react-bootstrap/Modal';
import * as PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { format } from 'date-fns';

class BacklinksModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backlinks: [],
    };
  }

  componentDidMount() {
    if (this.props.backlinks.length > 0) {
      this.setState({
        backlinks: this.props.backlinks,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (typeof prevProps.backlinks === 'undefined' &&
        this.props.backlinks &&
        this.props.backlinks.length > 0) ||
      (prevProps.backlinks &&
        prevProps.backlinks.length === 0 &&
        this.props.backlinks &&
        this.props.backlinks.length > 0)
    ) {
      this.setState({
        backlinks: this.props.backlinks,
      });
    }
  }

  render() {
    return (
      <Modal
        size="lg"
        centered={true}
        show={this.props.show}
        onHide={() => {
          this.props.hideModal();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{i18n.t('backlink_modal__backlinks')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollable-modal">
          <div>
            <div className="row table-row">
              <div className="col-7 col-lg-6 font-weight-bold">
                {i18n.t('backlink_modal__website')}
              </div>
              <div className="col-5 col-lg-2 font-weight-bold">
                {i18n.t('backlink_modal__trust_flow')}
              </div>
              <div className="d-none d-lg-block col-lg-2 font-weight-bold">
                {i18n.t('backlink_modal__citation_flow')}
              </div>
              <div className="d-none d-lg-block col-lg-2 font-weight-bold">
                {i18n.t('backlink_modal__detected')}
              </div>
            </div>

            {this.state.backlinks.map((backlink) => {
              if (backlink && backlink.DateLost === '') {
                return (
                  <div
                    className="row table-row"
                    key={`referrer-${backlink.SourceURL}`}
                  >
                    <div
                      className="col-7 col-lg-6 text-truncate"
                      title={`${backlink.SourceURL}. ${i18n.t(
                        'backlink_modal__detected_on'
                      )} ${format(
                        new Date(backlink.FirstIndexedDate),
                        'dd/mm/yy'
                      )}`}
                    >
                      {backlink.SourceURL}
                    </div>
                    <div className="col-5 col-lg-2">
                      {backlink.SourceTrustFlow}
                    </div>
                    <div className="d-none d-lg-block col-lg-2">
                      {backlink.SourceCitationFlow}
                    </div>
                    <div className="d-none d-lg-block col-lg-2">
                      {format(new Date(backlink.FirstIndexedDate), 'dd/mm/yy')}
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-dark"
            onClick={() => {
              this.props.hideModal();
            }}
          >
            {i18n.t('backlink_modal__close')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

BacklinksModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  backlinks: PropTypes.array.isRequired,
};

export default BacklinksModal;
