import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import RegisterForm from './register-form/register-form';
import './register.scss';
import { getParameterByName } from '../../helpers/utility';
import { withTranslation } from 'react-i18next';

class Register extends React.Component {
  componentDidMount() {
    // Persist any source information to session, useful in case we navigate away from register page
    const source = getParameterByName('source');

    // Do not set if null etc.
    if (source) {
      sessionStorage.setItem('source', source);
    }
  }

  componentDidUpdate() {
    // Must be done on update not in the constructor.
    if (this.props.user) {
      console.log(
        '---- REDIRECT: You are logged in, redirecting from register to homepage.'
      );
      window.location.href = '#/';
    }
  }

  render() {
    return (
      <div className="container-fluid" id="register">
        <div className="d-flex flex-wrap align-desktop full-height">
          <div className="col-12 text-center col-lg-6 col-xl-5">
            <div className="small-form-container">
              {this.props.user ? (
                <Fragment>
                  <h1 className="h2">{this.props.t('register')}</h1>

                  <h3>{this.props.t('register__registered')}</h3>
                </Fragment>
              ) : (
                <RegisterForm
                  attemptRegister={(event, data) => {
                    if (
                      this.props.organisation &&
                      this.props.organisation._id
                    ) {
                      data.organisationId = this.props.organisation._id.toString();
                    }

                    this.props.attemptRegister(event, data);
                  }}
                  registerError={this.props.registerError}
                  registerErrorMessage={this.props.registerErrorMessage}
                  termsUrl={
                    this.props.organisation
                      ? this.props.organisation.termsUrl
                      : null
                  }
                  organisationLogoDark={
                    this.props.organisation &&
                    this.props.organisation.organisationLogoDark &&
                    this.props.organisation.organisationLogoDark.Url
                      ? this.props.organisation.organisationLogoDark.Url
                      : null
                  }
                />
              )}
            </div>
          </div>
          <div className="d-none d-lg-flex col-lg-6 col-xl-7" id="register-bg">
            <div className="m-b--30">
              <h1 id="loginMarketingTitle">
                {this.props.t('register__online_visibility')}
              </h1>

              <div className="m-t--45">
                <div className="register-feature">
                  <div className="feature-icon">
                    <span className="fal fa-engine-warning" />
                  </div>
                  <div className="feature-title">
                    {this.props.t('register__detect_issues')}
                  </div>
                </div>

                <div className="register-feature">
                  <div className="feature-icon">
                    <span className="fal fa-search" />
                  </div>
                  <div className="feature-title">
                    {this.props.t('register__keyword_research')}
                  </div>
                </div>

                <div className="register-feature">
                  <div className="feature-icon">
                    <span className="fal fa-tasks" />
                  </div>
                  <div className="feature-title">
                    {this.props.t('register__issue_monitoring')}
                  </div>
                </div>

                <div className="register-feature">
                  <div className="feature-icon">
                    <span className="fal fa-analytics" />
                  </div>
                  <div className="feature-title">
                    {this.props.t('register__insights')}
                  </div>
                </div>

                <div className="register-feature">
                  <div className="feature-icon">
                    <span className="fal fa-plus-circle" />
                  </div>
                  <div className="feature-title">
                    {this.props.t('register__much_more')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  user: PropTypes.object,
};

export default withTranslation()(Register);
