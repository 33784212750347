import React from 'react';
import './info-icon.scss';
import * as PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function InfoIcon(props) {
  const placement = props.placement || 'bottom-start';

  return (
    <OverlayTrigger
      key={props.id}
      placement={placement}
      overlay={
        <Tooltip id={`tooltip-${props.id}`}>
          {props.text || 'Lorem ipsum dolor sit amet'}
        </Tooltip>
      }
    >
      <span className={`info-icon ${props.invertColor ? 'invert-color' : ''}`}>
        <i className="fal fa-info-circle" />
      </span>
    </OverlayTrigger>
  );
}

InfoIcon.propTypes = {
  id: PropTypes.string.isRequired,
  placement: PropTypes.string,
  text: PropTypes.any.isRequired,
  invertColor: PropTypes.bool,
};

export default InfoIcon;
