import React, { Component, Fragment } from 'react';
import i18n from 'i18next';
import axios from 'axios';
import './wp-automation.scss';
import logo from '../../assets/images/wp-logo.png';
import { serverUrl } from '../../helpers/utility';

import WPloader from './wp-loader';
import SuccessMessage from './success-message';
import UpdateWP from './update-wp';

import classnames from 'classnames';

const HeaderLogo = () => (
  <img className="wp-automation__logo" src={logo} alt="wordpress logo" />
);

const HeaderText = () => (
  <Fragment>
    <p>
      {i18n.t('wp_auto__header_1')}{' '}
      <strong>{i18n.t('wp_auto__header_2')}</strong>. <br />
      {i18n.t('wp_auto__header_3')}
    </p>
    <p>
      {i18n.t('wp_auto__header_4')}{' '}
      <strong>{i18n.t('wp_auto__header_5')}</strong>{' '}
      {i18n.t('wp_auto__header_6')}{' '}
      <strong>{i18n.t('wp_auto__header_7')}</strong>
      {i18n.t('wp_auto__header_8')}
    </p>
  </Fragment>
);

const Form = ({
  show,
  handleSubmit,
  URL,
  setURL,
  username,
  setUsername,
  userPassword,
  setUserPassword,
  error,
  CredentialsClasses,
  URLclasses,
}) =>
  show && (
    <form onSubmit={handleSubmit}>
      <div className="formInput">
        <label>{i18n.t('wp_auto__form_1')}</label>
        <span className="wp-automation__description">
          {i18n.t('wp_auto__form_2')}
        </span>
        <input
          autoFocus={true}
          className={URLclasses}
          type="text"
          value={URL}
          name="URL"
          onChange={setURL}
          required={true}
        />
        <div className="invalid-feedback">{i18n.t(error.message)}</div>
      </div>
      <div className="formInput">
        <label>{i18n.t('wp_auto__form_3')}</label>
        <input
          autoFocus={true}
          className={CredentialsClasses}
          type="text"
          value={username}
          name="username"
          onChange={setUsername}
          required={true}
        />
      </div>
      <div className="formInput">
        <label>{i18n.t('wp_auto__form_4')}</label>
        <input
          className={CredentialsClasses}
          type="password"
          value={userPassword}
          name="password"
          onChange={setUserPassword}
          required={true}
        />
        <div className="invalid-feedback">{i18n.t(error.message)}</div>
      </div>
      {error.name === 'RequestError' && (
        <p className="wp-automation__error-generic">{i18n.t(error.message)}</p>
      )}
      <input
        className="btn btn-success"
        type="submit"
        value="Submit"
        readOnly={true}
      />
    </form>
  );

const isWPupdate = (errorName) => {
  switch (errorName) {
    case 'PageNotFoundError':
    case 'LoginFormNotFoundError':
    case 'LoginError':
      return false;
    case 'FileUploadError':
      return true;
    default:
      return true;
  }
};

class WPautomation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      URL: `${this.props.domain}/wp-admin`,
      username: '',
      userPassword: '',
      error: {
        name: '',
        message: '',
      },
      showWPupdate: false,
      isLoading: false,
      data: [],
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      error: {
        name: '',
        message: '',
      },
      showWPupdate: false,
      isLoading: true,
    });

    const payload = {
      url: this.state.URL,
      credentialsModel: {
        username: {
          selector: '#user_login',
          value: this.state.username,
        },
        password: {
          selector: '#user_pass',
          value: this.state.userPassword,
        },
        submit: {
          selector: '#wp-submit',
        },
      },
    };

    axios
      .post(`${serverUrl}/wp/automation`, payload)
      .then((response) => {
        if (response && response.data && response.data.success) {
          this.setState({
            data: response.data.data.response.reportingData,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          if (
            err.response.data.error &&
            err.response.data.error.name &&
            err.response.data.error.message
          ) {
            this.setState({
              error: err.response.data.error,
              showWPupdate: isWPupdate(err.response.data.error.name),
            });
          }
        } else if (err.request) {
          // try again later
          this.setState({
            error: {
              name: 'RequestError',
              message: 'wp_auto__request_error',
            },
          });
        }
        this.setState({ isLoading: false });
      });
  };

  handleUsernameChange = (e) => {
    this.setState({ username: e.target.value });
  };

  handleUserPasswordChange = (e) => {
    this.setState({ userPassword: e.target.value });
  };

  handleURLChange = (e) => {
    this.setState({ URL: e.target.value });
  };

  render() {
    const loadingMessages = [
      i18n.t('wp_auto__loading_msg_1'),
      i18n.t('wp_auto__loading_msg_2'),
      i18n.t('wp_auto__loading_msg_3'),
    ];

    const {
      URL,
      username,
      userPassword,
      error,
      data,
      isLoading,
      showWPupdate,
    } = this.state;

    const URLclasses = classnames({
      'form-control': true,
      'is-invalid':
        error.name === 'PageNotFoundError' ||
        error.name === 'LoginFormNotFoundError',
    });

    const CredentialsClasses = classnames({
      'form-control': true,
      'is-invalid':
        error.name === 'LoginError' || error.name === 'FileUploadError',
    });

    if (data.length > 0) {
      return (
        <div className="wp-automation">
          <HeaderLogo />
          <SuccessMessage data={data} />
        </div>
      );
    }

    if (showWPupdate) {
      return (
        <div className="wp-automation">
          <HeaderLogo />
          <UpdateWP
            domain={this.props.domain}
            user={this.props.user}
            hideModal={this.props.hideModal}
          />
        </div>
      );
    }

    return (
      <div className="wp-automation">
        <HeaderLogo />
        <Fragment>
          <HeaderText />
          <WPloader show={isLoading} messages={loadingMessages} />
          <Form
            show={!isLoading}
            handleSubmit={this.handleSubmit}
            URL={URL}
            setURL={this.handleURLChange}
            username={username}
            setUsername={this.handleUsernameChange}
            userPassword={userPassword}
            setUserPassword={this.handleUserPasswordChange}
            error={error}
            CredentialsClasses={CredentialsClasses}
            URLclasses={URLclasses}
          />
        </Fragment>
      </div>
    );
  }
}

export default WPautomation;
