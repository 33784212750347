import React from 'react';
import axios from 'axios';
import connect from 'react-redux/es/connect/connect';
import compose from 'ramda/src/compose';
import { withTranslation } from 'react-i18next';

import './stay-informed-alerts.scss';
import { serverUrl } from '../../helpers/utility';
import InfoIcon from '../../components/info-icon/info-icon';
import LoadingCircle from '../../components/loading-circle/loading-circle';
import downtimeSrc from '../../assets/images/summary/review.svg';
import securitySrc from '../../assets/images/summary/privacy.svg';
import competitorSrc from '../../assets/images/summary/message.svg';
import crawlingSrc from '../../assets/images/summary/alert.svg';

const mapStateToProps = (state) => {
  return {
    site: state.sites.selectedSiteData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export class StayInformedAlerts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      downtimeAlertsEnabled: false,
      securityAlertsEnabled: false,
      competitorAlertsEnabled: false,
      crawlingAlertsEnabled: false,
    };

    this.handleMonitoringCheckboxChange =
      this.handleMonitoringCheckboxChange.bind(this);
    this.setAlertsState = this.setAlertsState.bind(this);
  }

  handleMonitoringCheckboxChange(event) {
    if (event.target && event.target.name) {
      const update = {};
      update[event.target.name] = event.target.checked;
      this.setState(update, () => {
        this.updateSite({
          siteModel: {
            monitoringAlerts: {
              downtimeAlertsEnabled: this.state.downtimeAlertsEnabled,
              securityAlertsEnabled: this.state.securityAlertsEnabled,
              competitorAlertsEnabled: this.state.competitorAlertsEnabled,
              crawlingAlertsEnabled: this.state.crawlingAlertsEnabled,
            },
          },
        });
      });
    }
  }

  async updateSite({ siteModel }) {
    if (this.props.site && this.props.site._id && siteModel) {
      try {
        const response = await axios.put(
          `${serverUrl}/site/id/${this.props.site._id}`,
          siteModel
        );

        if (response && response.data && response.data.success) {
          console.log('Site updated.');
        } else {
          console.error('Error updating site!');
        }
      } catch (err) {
        console.error('Error updating site!');
      }
    } else {
      console.error('Error updating site! Invalid params');
    }
  }

  setAlertsState() {
    if (this.props.site && this.props.site.monitoringAlerts) {
      this.setState({
        downtimeAlertsEnabled:
          this.props.site.monitoringAlerts.downtimeAlertsEnabled,
        securityAlertsEnabled:
          this.props.site.monitoringAlerts.securityAlertsEnabled,
        competitorAlertsEnabled:
          this.props.site.monitoringAlerts.competitorAlertsEnabled,
        crawlingAlertsEnabled:
          this.props.site.monitoringAlerts.crawlingAlertsEnabled,
      });
    }
  }

  componentDidMount() {
    if (
      this.props.site &&
      this.props.site._id &&
      this.props.site.monitoringAlerts
    ) {
      this.setAlertsState();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (typeof prevProps.site === 'undefined' &&
        this.props.site &&
        this.props.site._id) ||
      (this.props.site &&
        prevProps.site &&
        this.props.site._id !== prevProps.site._id)
    ) {
      this.setAlertsState();
    }
  }

  render() {
    return (
      <div id="stay-informed-alerts">
        {this.props.site && this.props.site.monitoringAlerts ? (
          <div id="stayInformedAlertsHeader" className="standard-page-layout">
            <div className="text-center m-b--15">
              <div className="col-12 page-padding">
                <div className="row gr-section">
                  <div className="col-12">
                    <h2 className="site-alerts">
                      <span>{this.props.t('alerts__stay_informed')} </span>
                      <span className="site-alerts-subheader">
                        {this.props.t('alerts')}
                      </span>
                      <span className="m-l--5">
                        <InfoIcon
                          id={'alertsInfo'}
                          text={
                            <div>
                              <p>{this.props.t('alerts__info')}</p>
                            </div>
                          }
                        />
                      </span>
                    </h2>

                    <div className="flex flex-wrap">
                      <div className="monitoring-switch">
                        <div className="monitoring-switch-controls">
                          <div className="formInput float-right">
                            <label className="switch square">
                              <input
                                type="checkbox"
                                checked={!!this.state.downtimeAlertsEnabled}
                                autoComplete="downtimeAlertsEnabled"
                                name="downtimeAlertsEnabled"
                                onChange={this.handleMonitoringCheckboxChange}
                              />
                              <span className="slider square"></span>
                            </label>
                          </div>
                        </div>

                        <div className="monitoring-switch-image">
                          <img src={downtimeSrc} className="img" alt="" />
                        </div>

                        <div className="monitoring-switch-info">
                          <h4 className="title">
                            {this.props.t('alerts__downtime')}
                          </h4>

                          <p className="text">
                            {this.props.t('alerts__downtime_info')}
                          </p>
                        </div>
                      </div>

                      <div className="monitoring-switch">
                        <div className="monitoring-switch-controls">
                          <div className="formInput float-right">
                            <label className="switch square">
                              <input
                                type="checkbox"
                                checked={!!this.state.securityAlertsEnabled}
                                autoComplete="securityAlertsEnabled"
                                name="securityAlertsEnabled"
                                onChange={this.handleMonitoringCheckboxChange}
                              />
                              <span className="slider square"></span>
                            </label>
                          </div>
                        </div>

                        <div className="monitoring-switch-image">
                          <img src={securitySrc} className="img" alt="" />
                        </div>

                        <div className="monitoring-switch-info">
                          <h4 className="title">
                            {this.props.t('alerts__security')}
                          </h4>

                          <p className="text">
                            {this.props.t('alerts__security_info')}
                          </p>
                        </div>
                      </div>

                      <div className="monitoring-switch">
                        <div className="monitoring-switch-controls">
                          <div className="formInput float-right">
                            <label className="switch square">
                              <input
                                type="checkbox"
                                checked={!!this.state.competitorAlertsEnabled}
                                autoComplete="competitorAlertsEnabled"
                                name="competitorAlertsEnabled"
                                onChange={this.handleMonitoringCheckboxChange}
                              />
                              <span className="slider square"></span>
                            </label>
                          </div>
                        </div>

                        <div className="monitoring-switch-image">
                          <img src={competitorSrc} className="img" alt="" />
                        </div>

                        <div className="monitoring-switch-info">
                          <h4 className="title">
                            {this.props.t('alerts__competitor')}
                          </h4>

                          <p className="text">
                            {this.props.t('alerts__competitor_info')}
                          </p>
                        </div>
                      </div>

                      <div className="monitoring-switch">
                        <div className="monitoring-switch-controls">
                          <div className="formInput float-right">
                            <label className="switch square">
                              <input
                                type="checkbox"
                                checked={!!this.state.crawlingAlertsEnabled}
                                autoComplete="crawlingAlertsEnabled"
                                name="crawlingAlertsEnabled"
                                onChange={this.handleMonitoringCheckboxChange}
                              />
                              <span className="slider square"></span>
                            </label>
                          </div>
                        </div>

                        <div className="monitoring-switch-image">
                          <img src={crawlingSrc} className="img" alt="" />
                        </div>

                        <div className="monitoring-switch-info">
                          <h4 className="title">
                            {this.props.t('alerts__crawling')}
                          </h4>

                          <p className="text">
                            {this.props.t('alerts__crawling_info')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="large-loading-box">
            <LoadingCircle />
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(StayInformedAlerts);
