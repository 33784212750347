import React from "react";
import { Route, Redirect } from "react-router-dom";

//https://gist.github.com/abohannon/cca2dd998edf9dc2c2165f538eece4b2
const PrivateRoute = ({ component: Component, authed, ...rest }) => {
  const getComponentToRender = (props) => {
    let actualComponent = <div loading="true"></div>;

    // Check for true (default is null)
    if (authed === true) {
      actualComponent = <Component {...props} />;
    }

    // Check for false (default is null)
    if (authed === false) {
      actualComponent = <Redirect to="/login" />;
    }

    //console.log("Rendering: " + authed);
    return actualComponent;
  };

  return <Route {...rest} render={getComponentToRender} />;
};

export default PrivateRoute;
