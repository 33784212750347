import React, { Component } from 'react';
import { HashRouter, Route } from 'react-router-dom';
import SiteSummary from './pages/site-summary/site-summary';
import SiteUnavailable from './pages/site-unavailable/site-unavailable';
import Audit from './pages/audit/audit';
import StayInformedAlerts from './pages/alerts/stay-informed-alerts';
import Login from './pages/login/login-container';
import ForgottenPassword from './pages/forgot/forgot';
import Register from './pages/register/register-container';
import RegisterAgency from './pages/register-agency/register-agency-container';
import AdminSetup from './pages/admin-setup/admin-setup';
import AdminUsers from './pages/admin-users/admin-users';
import AdminClients from './pages/admin-clients/admin-clients';
import AdminImport from './pages/admin-import/admin-import';
import GoRankLogs from './pages/gorank-logs/gorank-logs';
import ThemeGenerator from './pages/theme-generator/theme-generator';
import AddSite from './pages/add-site/add-site';
import UserAccount from './pages/user-account/user-account-container';
import PrivateRoute from './components/hoc/private-route/private-route';
import connect from 'react-redux/es/connect/connect';
import axios from 'axios';
import { loginSuccess, loginUnauthenticated, setLocale } from './actions';
import {
  getOrganisation,
  getParameterByName,
  getUserLocale,
  serverUrl,
  userHasLoggedInActions,
} from './helpers/utility';

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  userType: state.authentication.userType,
  goRankTeam: state.authentication.goRankTeam,
  loggedIn: state.authentication.loggedIn,
  googleConnected: state.authentication.googleConnected,
});

function mapDispatchToProps(dispatch) {
  // when arguments match, you can pass configuration object, which will
  // wrap your actions creators with dispatch automatically.
  return {
    checkLoginStatus: () => {
      console.log('Attempting token log in');
      const bearerToken = getParameterByName('bearerToken');
      if (bearerToken) {
        axios
          .post(`${serverUrl}/sso/login`, {
            bearerToken: bearerToken,
          })
          .then((response) => {
            if (response.data.success) {
              dispatch(loginSuccess(response.data.data.user));
              console.log('Token log in success');
              userHasLoggedInActions({
                dispatch,
                user: response.data.data.user,
              });
            } else {
              dispatch(loginUnauthenticated(response.data.data));
            }
          })
          .catch(function (error) {
            console.log('Authentication error.');
          });
      } else {
        // Check if user is logged in
        axios
          .get(`${serverUrl}/user`)
          .then((response) => {
            if (response && response.data && response.data.success === true) {
              dispatch(loginSuccess(response.data.user));
              const user = response.data.user;

              // Post user login helper function (List of sites, Google accounts)
              userHasLoggedInActions({ dispatch, user });
            } else {
              console.log('User is not logged in');
              dispatch(loginUnauthenticated(response.data.data));
            }
          })
          .catch(function (error) {
            console.log('Authentication error.');
          });
      }
    },
    getOrganisation: () => {
      getOrganisation({ dispatch });
    },
    setLocale: (userLocaleModel) => {
      dispatch(setLocale(userLocaleModel));
    },
  };
}

class AppRouter extends Component {
  componentDidMount() {
    // Fade the app into visibility
    // Timeout must match sidebar.js
    setTimeout(() => {
      const innerContentEl = document.querySelectorAll('#root #inner-content');

      if (innerContentEl && innerContentEl.length > 0) {
        innerContentEl[0].style.opacity = 1;
      }
    }, 350);

    // Request initial auth/org info
    this.props.checkLoginStatus();
    this.props.getOrganisation();

    if (sessionStorage && sessionStorage.getItem('redirect')) {
      console.log('----- Redirecting you based on session data');
      window.location.href = decodeURIComponent(
        sessionStorage.getItem('redirect')
      );
      sessionStorage.removeItem('redirect');
    }

    const userLocaleModel = getUserLocale();
    if (userLocaleModel) {
      this.props.setLocale(userLocaleModel);
    }
  }

  componentDidUpdate() {}

  render() {
    return (
      <HashRouter>
        {this.props.children}
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/register-agency" component={RegisterAgency} />
        <Route path="/forgot-password" component={ForgottenPassword} />

        <PrivateRoute
          path="/"
          exact
          component={SiteSummary}
          authed={this.props.loggedIn}
        />
        <PrivateRoute
          path="/dashboard"
          component={SiteSummary}
          authed={this.props.loggedIn}
        />
        <PrivateRoute
          path="/audit"
          exact
          component={Audit}
          authed={this.props.loggedIn}
        />
        <PrivateRoute
          path="/alerts"
          exact
          component={StayInformedAlerts}
          authed={this.props.loggedIn}
        />
        <PrivateRoute
          path="/site-unavailable"
          exact
          component={SiteUnavailable}
          authed={this.props.loggedIn}
        />

        {/* Admin */}
        <PrivateRoute
          path="/admin"
          component={AdminClients}
          authed={
            this.props.loggedIn &&
            this.props.user &&
            this.props.user.currentPermissions &&
            this.props.user.currentPermissions.isOrganisationAdmin
          }
        />
        <PrivateRoute
          path="/admin-users"
          component={AdminUsers}
          authed={
            this.props.loggedIn &&
            this.props.user &&
            this.props.user.currentPermissions &&
            this.props.user.currentPermissions.isOrganisationAdmin
          }
        />
        <PrivateRoute
          path="/admin-setup"
          component={AdminSetup}
          authed={
            this.props.loggedIn &&
            this.props.user &&
            this.props.user.currentPermissions &&
            this.props.user.currentPermissions.isOrganisationAdmin
          }
        />
        <PrivateRoute
          path="/admin-clients"
          component={AdminClients}
          authed={
            this.props.loggedIn &&
            this.props.user &&
            this.props.user.currentPermissions &&
            this.props.user.currentPermissions.isOrganisationAdmin
          }
        />
        <PrivateRoute
          path="/admin-import"
          component={AdminImport}
          authed={
            this.props.loggedIn &&
            this.props.user &&
            this.props.user.currentPermissions &&
            this.props.user.currentPermissions.isOrganisationAdmin
          }
        />
        <PrivateRoute
          path="/theme"
          component={ThemeGenerator}
          authed={
            this.props.loggedIn &&
            this.props.user &&
            this.props.user.currentPermissions &&
            this.props.user.currentPermissions.isOrganisationAdmin
          }
        />

        {/* Application Admin */}
        <PrivateRoute
          path="/logs"
          component={GoRankLogs}
          authed={this.props.goRankTeam}
        />

        {/* Authenticated Users */}
        <PrivateRoute
          path="/site-summary"
          component={SiteSummary}
          authed={this.props.loggedIn}
        />
        <PrivateRoute
          path="/account"
          component={UserAccount}
          authed={this.props.loggedIn}
        />

        {/* Self Sign Up */}
        <PrivateRoute
          path="/add-site"
          component={AddSite}
          authed={this.props.loggedIn}
        />
      </HashRouter>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
