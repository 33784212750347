export const loginSuccess = (user) => ({
  type: "LOGIN_SUCCESS",
  user,
});
export const loginFailure = () => ({
  type: "LOGIN_FAILED",
  user: null,
});
export const loginUnauthenticated = () => ({
  type: "NOT_LOGGED_IN",
  user: null,
});
export const userUpdate = (user) => ({
  type: "USER_UPDATE",
  user,
});
export const registerSuccess = (response) => ({
  type: "REGISTER_SUCCESS",
  message: response,
});
export const registerError = (response) => ({
  type: "REGISTER_ERROR",
  message: response && response.message ? response.message : null,
});
export const logout = () => ({
  type: "LOGOUT",
});
export const setOrganisation = (organisation) => ({
  type: "SET_ORGANISATION",
  organisation,
});
export const setTeam = (team) => ({
  type: "SET_TEAM_USERS",
  team,
});
export const googleConnected = () => ({
  type: "GOOGLE_CONNECTED",
});
export const googleDisconnected = () => ({
  type: "GOOGLE_DISCONNECTED",
});
export const googleAnalyticsAccounts = (accounts) => ({
  type: "GOOGLE_ANALYTICS_ACCOUNTS",
  accounts,
});
export const googleWebmasterAccounts = (accounts) => ({
  type: "GOOGLE_WEBMASTER_ACCOUNTS",
  accounts,
});
export const setSites = (basicSiteList) => ({
  type: "SET_SITES",
  basicSiteList,
});
export const setSitesLoading = (loadingSiteList) => ({
  type: "SET_LOADING_SITES",
  loadingSiteList,
});
export const setSelectedSiteData = (siteData) => ({
  type: "SET_SELECTED_SITE_DATA",
  siteData,
});
export const setSelectedSiteAuditData = (auditData) => ({
  type: "SET_SELECTED_SITE_AUDIT_DATA",
  auditData,
});
export const setLocale = (userLocaleModel) => ({
  type: "SET_LOCALE",
  userLocale: userLocaleModel.userLocale,
  userCurrency: userLocaleModel.userCurrency,
  userCurrencyCode: userLocaleModel.userCurrencyCode,
  ddMMYYYYLocale: userLocaleModel.ddMMYYYYLocale,
  ddMMYYLocale: userLocaleModel.ddMMYYLocale,
});
