import React from 'react';
import axios from 'axios';
import { serverUrl } from '../../helpers/utility';
import connect from 'react-redux/es/connect/connect';
import './admin-users.scss';
import PermissionsModal from '../../components/permissions-modal/PermissionsModal';
import { withTranslation } from 'react-i18next';
import compose from 'ramda/src/compose';
import GRTable from '../../components/gr-table/gr-table';

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    organisation: state.organisation.organisation,
  };
};

class AdminUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      selectedUser: null,
      editUserModal: false,
    };

    this.updateUserPermissions = this.updateUserPermissions.bind(this);
  }

  manageUser(user) {
    this.setState({
      editUserModal: true,
      selectedUser: user,
    });
  }

  updateUserPermissions(newRole) {
    let endpoint = `${serverUrl}/user/permissions`;

    if (this.state.selectedUser && newRole) {
      axios
        .put(endpoint, {
          targetUserId: this.state.selectedUser._id,
          role: newRole,
        })
        .then((response) => {
          if (response && response.data && response.data.success) {
            console.log('Permissions updated.');
            this.getAllUsers();
          }
        })
        .catch(function (error) {
          // handle error
          console.error('Error updating user permissions!');
        });
    } else {
      console.error('ERR: Bad params, cannot update user role');
    }
  }

  getAllUsers() {
    // Get recent audits for admin user
    axios
      .get(`${serverUrl}/user/all`)
      .then((response) => {
        if (response && response.data && response.data.success === true) {
          const users = response.data.data;

          if (users) {
            this.setState({
              users,
            });
          }
        }
      })
      .catch(function (err) {
        console.error(`ERR: Could not fetch users. ${err}`);
      });
  }

  componentDidMount() {
    this.getAllUsers();
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="main-content-container" id="admin">
          {this.state.users && this.state.users.length > 0 ? (
            <div className="row">
              <div className="col-12 m-t--20">
                <div className="m-b--10">
                  <a href={`/#admin`}>
                    <span className={`m-r--5`}>
                      <i className={`fal fa-chevron-left`} />
                    </span>
                    <span>{this.props.t('admin_users__back')}</span>
                  </a>
                </div>

                <GRTable
                  data={this.state.users}
                  rows={{}}
                  columns={[
                    {
                      label: this.props.t('admin_users__email'),
                      size: 'lg',
                      getValue: (item) => {
                        return item.email;
                      },
                    },
                    {
                      label: this.props.t('admin_users__name'),
                      size: 'lg',
                      getValue: (item) => {
                        return `${item.name} ${item.lastname || ''} ${
                          item.authenticationType &&
                          item.authenticationType === 'google' ? (
                            <span
                              className="badge badge-light m-l--10"
                              title={this.props.t('admin_users__auth_google')}
                            >
                              G
                            </span>
                          ) : (
                            ''
                          )
                        }`;
                      },
                    },
                    {
                      label: this.props.t('admin_users__logins'),
                      size: 'md',
                      getValue: (item) => {
                        return item.loginCount;
                      },
                    },
                    {
                      label: this.props.t('admin_users__status'),
                      size: 'md',
                      getValue: (item) => {
                        return item.currentPermissions
                          ? item.currentPermissions.roleName
                          : 'N/A';
                      },
                    },
                    {
                      label: this.props.t('admin_users__actions'),
                      size: 'md',
                      getValue: (item) => {
                        if (
                          item.currentPermissions &&
                          item.currentPermissions.isOrganisationOwner !== true
                        ) {
                          return (
                            <div
                              className="btn btn-outline-info"
                              onClick={() => {
                                this.manageUser(item);
                              }}
                            >
                              {this.props.t('admin_users__edit')}
                            </div>
                          );
                        } else {
                          return (
                            <div className="btn btn-outline-info disabled">
                              {this.props.t('admin_users__edit')}
                            </div>
                          );
                        }
                      },
                    },
                  ]}
                />
              </div>
            </div>
          ) : null}
        </div>

        {this.state.selectedUser ? (
          <PermissionsModal
            show={this.state.editUserModal}
            hideModal={() => {
              this.setState({
                editUserModal: false,
              });
            }}
            onSubmit={this.updateUserPermissions}
            user={this.state.selectedUser}
            organisationOwner={
              this.props.user && this.props.user.currentPermissions
                ? this.props.user.currentPermissions.isOrganisationOwner
                : false
            }
            applicationAdmin={
              this.props.user && this.props.user.currentPermissions
                ? this.props.user.currentPermissions.isApplicationAdmin
                : false
            }
          />
        ) : null}
      </div>
    );
  }
}

AdminUsers.propTypes = {};

export default compose(withTranslation(), connect(mapStateToProps))(AdminUsers);
