import React from 'react';
import { sortBy } from 'underscore';
import InfoIcon from '../info-icon/info-icon';
import i18n from 'i18next';

import './detailed-savings-table.scss';

const DetailedSavingsTable = ({ values, currencyModel, keywordsList }) => (
  <div className="table">
    <div className="tr th">
      <div className="td flex-2">
        {i18n.t('savings_table__keyword')}{' '}
        <InfoIcon
          id="keyword"
          placement="bottom"
          text={
            <p style={{ margin: 0 }}>
              {i18n.t('savings_table__keyword_explained')}
            </p>
          }
        />
      </div>
      <div className="td">{i18n.t('savings_table__position')}</div>
      <div className="td d-none d-sm-flex">
        {i18n.t('savings_table__cpc')}
        <InfoIcon
          id="cpc"
          placement="bottom"
          text={
            <p style={{ margin: 0 }}>
              {i18n.t('savings_table__cpc_explained')}
            </p>
          }
        />
      </div>
      <div className="td d-none d-md-flex">
        {i18n.t('savings_table__monthly_search')}
      </div>
      <div className="td">{i18n.t('savings_table__savings')}</div>
    </div>
    {sortBy(Object.keys(values), (keyword) => {
      return -1 * Number(values[keyword]);
    }).map((kwd, index) => {
      const keywordMatch = keywordsList.find(({ keyword }) => keyword === kwd);
      return (
        <div
          className="tr"
          key={`kwd-${kwd.replace(/\s+/g, '-').toLowerCase()}`}
        >
          <div className="td flex-2">
            {keywordMatch.meta && keywordMatch.meta.url ? (
              <a
                href={keywordMatch.meta.url}
                target="_blank"
                rel="noreferrer noopener"
                title={keywordMatch.meta.url}
              >
                {kwd.length > 32 ? `${kwd.slice(0, 32)}\u2026` : kwd}{' '}
                <i
                  className="fal fa-file"
                  style={{ marginLeft: '4px', fontSize: '12px' }}
                />
              </a>
            ) : kwd.length > 32 ? (
              `${kwd.slice(0, 32)}\u2026`
            ) : (
              kwd
            )}
          </div>
          <div className="td">
            {keywordMatch.position ? keywordMatch.position : 'n/a'}
          </div>
          <div className="td d-none d-sm-flex">
            {keywordMatch.cpc && currencyModel.symbol}
            {keywordMatch.cpc
              ? Number(keywordMatch.cpc / currencyModel.exchange_rate).toFixed(
                  2
                )
              : 'n/a'}
          </div>
          <div className="td d-none d-md-flex">
            {keywordMatch.search_volume
              ? Number(keywordMatch.search_volume).toFixed(0)
              : 'n/a'}
          </div>
          <div className="td">
            {currencyModel.symbol}
            {Number(values[kwd] / currencyModel.exchange_rate).toFixed(0)}
          </div>
        </div>
      );
    })}
  </div>
);

export default DetailedSavingsTable;
