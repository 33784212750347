import React from 'react';
import classnames from 'classnames';

import './panel.scss';

const Panel = ({ title = null, className, count = null, children }) => {
  const panelClasses = classnames({
    panel: true,
    [className]: typeof className !== 'undefined',
  });

  if (title) {
    return (
      <div className={panelClasses}>
        <h3 className="panel__title">
          {title} <small>{count && count > 0 ? ` ${count}` : ''}</small>
        </h3>
        <div className="panel__content">{children}</div>
      </div>
    );
  }
  return (
    <div className={panelClasses}>
      <div className="panel__content">{children}</div>
    </div>
  );
};

export default Panel;
