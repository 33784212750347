import React from 'react';
import classNames from 'classnames';

import './navigationMobile.scss';

const NavigationMobile = ({
  basicSiteList,
  changeLanguage,
  changeSelectedSite,
  getLangFromLocale,
  language,
  menuVisible,
  t,
  toggleMenu,
  user,
  siteId,
}) =>
  menuVisible && (
    <div id="mobileMenuOpenContainer">
      <div className="mobile-menu-controls">
        <span className="mobile-menu-close-icon" onClick={toggleMenu}>
          <i className="fal fa-times" />
        </span>
      </div>
      <div className="mobile-menu-links">
        <div className="mobile-menu-link">
          <span
            className={classNames('mobile-menu-link-text', {
              active: window.location.hash === '#/site-summary',
            })}
          >
            <a href="#/site-summary" target="_self" onClick={toggleMenu}>
              {t('header__dashboard')}
            </a>
          </span>
        </div>

        <div className="mobile-menu-link">
          <span
            className={classNames('mobile-menu-link-text', {
              active: window.location.hash === '#/audit',
            })}
          >
            <a href="#/audit" target="_self" onClick={toggleMenu}>
              {t('header__audit')}
            </a>
          </span>
        </div>

        <div className="mobile-menu-link">
          <span
            className={classNames('mobile-menu-link-text', {
              active: window.location.hash === '#/alerts',
            })}
          >
            <a href="#/alerts" target="_self" onClick={toggleMenu}>
              {t('header__alerts')}
            </a>
          </span>
        </div>

        {user &&
        user.currentPermissions &&
        (user.currentPermissions.isOrganisationAdmin ||
          user.currentPermissions.isOrganisationOwner) ? (
          <div className="mobile-menu-link">
            <span
              className={classNames('mobile-menu-link-text', {
                active: window.location.hash === '#/admin',
              })}
            >
              <a href="#/admin" target="_self" onClick={toggleMenu}>
                {t('header__admin')}
              </a>
            </span>
          </div>
        ) : null}

        {user && user.selfSignUp && (
          <div className="hideFromIframe mobile-menu-link">
            <span>
              <a href="#/account" target="_self" onClick={toggleMenu}>
                <i className="fal fa-user" />
              </a>
            </span>
          </div>
        )}

        <div className="m-t--20">
          {basicSiteList && basicSiteList.length > 1 ? (
            <div>
              <select
                className="form-control rounded-border custom"
                name="siteId"
                value={siteId || ''}
                onChange={changeSelectedSite}
              >
                {basicSiteList.map((site) => {
                  return (
                    <option key={site._id} value={site._id}>
                      {site.domainFriendly}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : null}

          <div className="m-t--15">
            <select
              className="form-control rounded-border custom"
              value={getLangFromLocale(language)}
              onChange={(event) => {
                changeLanguage(event.target.value);
              }}
            >
              <option value="nl">{t('dutch')}</option>
              <option value="en">{t('english')}</option>
              <option value="es">{t('espanol')}</option>
              <option value="fr" disabled>
                {t('french')}
              </option>
              <option value="de" disabled>
                {t('german')}
              </option>
              <option value="it" disabled>
                {t('italian')}
              </option>
              <option value="pt" disabled>
                {t('portuguese')}
              </option>
              <option value="tr">{t('turkish')}</option>
            </select>
          </div>
        </div>
      </div>
      <div className="mobile-menu-terms hideFromIframe">
        <div className="mobile-menu-term-text">{t('header__policy')}</div>
        <div className="mobile-menu-term-text">{t('header__terms')}</div>
      </div>
    </div>
  );

export default NavigationMobile;
