import React from 'react';

import './EmptyStateContainer.scss';

const EmptyStateContainer = ({ type, message }) => {
  const renderIcon = (type) => {
    switch (type) {
      case 'error':
        return <i class="fal fa-file-exclamation"></i>;
      default:
        return <i className="fal fa-file-chart-line"></i>;
    }
  };

  return (
    <div className="empty-state-container">
      <div className="icon-container">{renderIcon(type)}</div>
      <div>{message}</div>
    </div>
  );
};

export default EmptyStateContainer;
