import React, { useState } from 'react';
import i18n from 'i18next';
import { formatDistanceToNow } from 'date-fns';

import './render-issues.scss';

import { WPfixList } from '../../helpers/constants';
import IssueCTA from '../../components/issue-cta/issue-cta';

const autoFixOverride = (autoFixBoolean, issue, audit) => {
  if (!issue.passed) {
    if (autoFixBoolean) {
      return true;
    }

    if (
      !autoFixBoolean &&
      audit &&
      audit.detectedCMS === 'WordPress' &&
      WPfixList.includes(issue.key)
    ) {
      return true;
    }

    return autoFixBoolean;
  }
  return false;
};

// Returns an array of pages which contain an issue, found by the custom function defined by the metric information object
const findPages = (issue, pages) =>
  issue && issue.findPages && pages && pages.length
    ? pages.filter(issue.findPages)
    : [];

const RenderIssues = ({
  issues,
  toggleIssueVisibility,
  togglePagesVisibility,
  renderDifficulty,
  onAutoFixClick,
  onLearnFixClick,
  onRequestHelpClick,
  audit,
  showMoreVisible = true,
  maxIssuesToShow = 5,
}) => {
  const [showMore, setShowMore] = useState(false);
  const limit = showMore ? issues.length : maxIssuesToShow;
  const issuesToRender = issues.slice(0, limit);

  return (
    <>
      {issuesToRender.map((issue, issueIndex) => {
        const pagesWithIssue = findPages(issue, audit.pages);
        const pagesList = pagesWithIssue.map((page) => {
          const foundIssueFromPage = !!issue.findIssue
            ? issue.findIssue(page)
            : null;
          return (
            <tr key={`${issue.passName}-${page.address_full}`}>
              <td>
                <a
                  href={page.address_full}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {page.address_relative}
                </a>
              </td>
              {!!issue.findIssue && (
                <td>
                  {Array.isArray(foundIssueFromPage) ? (
                    <>
                      {foundIssueFromPage.length > 1 ? (
                        <ul>
                          {foundIssueFromPage.map((x) => (
                            <li>{x}</li>
                          ))}
                        </ul>
                      ) : (
                        <p>{foundIssueFromPage[0]}</p>
                      )}
                    </>
                  ) : (
                    <p>{foundIssueFromPage}</p>
                  )}
                </td>
              )}
            </tr>
          );
        });

        const issueMessage = issue.passed
          ? issue.passMessage
          : issue.failMessage;
        const regex = /\$\$issuecount/;
        const message = issueMessage.replace(regex, pagesWithIssue.length);

        return (
          <div key={`issue-${issueIndex}`} className={`issue-item`}>
            <div className="issue-item-inner">
              <div
                className={`issue-item-header`}
                onClick={() => {
                  toggleIssueVisibility({
                    issueName: issue.passName,
                  });
                }}
              >
                {issue.passed ? (
                  <div className={`issue-item-pass`}>
                    {i18n.t('audit_section__pass')}
                  </div>
                ) : (
                  <div className={`issue-item-fail`}>
                    {i18n.t('audit_section__improve')}
                  </div>
                )}

                <div className={`issue-item-header-title`}>
                  <span>{issue.passed ? issue.passName : issue.failName}</span>
                </div>

                <div className={`issue-item-header-toggle`}>
                  <span>
                    <i
                      className={`fal ${
                        issue.show === true
                          ? 'fa-chevron-up'
                          : 'fa-chevron-down'
                      }`}
                    />
                  </span>
                </div>
              </div>

              {issue.show === true ? (
                <div className="issue-item-info">
                  <p>{message}</p>
                  {!issue.passed && <p>{issue.description}</p>}
                  {!issue.passed && !!pagesWithIssue.length && (
                    <>
                      <p
                        className="audit-section-show-pages"
                        onClick={() => {
                          togglePagesVisibility({
                            issueName: issue.passName,
                          });
                        }}
                      >
                        <span className="audit-section-show-pages-chevron">
                          <i
                            className={`fal ${
                              issue.showPages === true
                                ? 'fa-chevron-up'
                                : 'fa-chevron-down'
                            }`}
                          />
                        </span>
                        {i18n.t('audit_section__show_pages_with_issue')}
                      </p>
                      {issue.showPages && (
                        <table className="issue-item-locator-table">
                          <thead>
                            <tr>
                              <th>{i18n.t('audit_section__page_url')}</th>
                              {!!issue.findIssue && !!issue.issueFound && (
                                <th>{issue.issueFound}</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>{pagesList}</tbody>
                        </table>
                      )}
                    </>
                  )}

                  <div className="issue-item-footer">
                    <div className="issue-item-details">
                      <p className="issue-item-last-scan">
                        {i18n.t('audit_section__last_scan')}:{' '}
                        {formatDistanceToNow(new Date(audit.created))}{' '}
                        {i18n.t('audit_section__ago')}
                      </p>
                      <div className={`issue-item-difficulty`}>
                        {renderDifficulty({
                          difficultyScore: issue.difficulty,
                        })}
                      </div>
                    </div>

                    <div className="issue-item-actions">
                      <IssueCTA
                        action={onAutoFixClick}
                        className="issue-item-action--auto-fix"
                        icon="fa-wrench"
                        issue={issue}
                        text="audit_section__fix"
                        show={autoFixOverride(issue.canAutoFix, issue, audit)}
                      />
                      <IssueCTA
                        action={onLearnFixClick}
                        className="issue-item-action--learn-to-fix"
                        icon="fa-book-open"
                        issue={issue}
                        text="audit_section__learn"
                        show={issue.learnToFix === true && !issue.passed}
                      />
                      <IssueCTA
                        action={onRequestHelpClick}
                        className="issue-item-action--request-help"
                        icon="fa-user"
                        issue={issue}
                        text="request_help__cta"
                        show={!issue.passed}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        );
      })}
      {showMoreVisible && issues.length > maxIssuesToShow && (
        <div className="show-more-button">
          <button
            className="btn btn-as-link show-more-button-button"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? i18n.t('show_less') : i18n.t('show_more')}
          </button>
        </div>
      )}
    </>
  );
};

export default RenderIssues;
