import React from 'react';
import format from 'date-fns/format';

import './subscription-invoices.scss';

const SubscriptionInvoices = ({ invoices }) => {
  if (invoices && invoices.length > 0) {
    return (
      <div className="subscription-invoices">
        <ul className="subscription-invoices__invoices">
          {invoices.map(({ date, url }) => (
            <li key={date}>
              <a href={url} target="_blank" rel="noreferrer">
                {format(new Date(date), 'PPPP')}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className="subscription-invoices subscription-invoices--empty">
      Your invoices will appear here
    </div>
  );
};

export default SubscriptionInvoices;
