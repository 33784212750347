import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import scriptLoader from 'react-async-script-loader';
import { serverUrl } from '../../helpers/utility';

import './subscription-plan.scss';

const STRIPE_PUBLIC_KEY =
  'pk_test_51J06G4EYzICgSso7OVgZ9saBL4JNbMmDQEIGppjF9qQXQaGSgmPpRJmqfOoCAiQrgynNA42jB6Hep2G78sda0MFZ0053IjfCkt';
const PRICE_ID = 'price_1J07S3EYzICgSso7KoVlhoX6';

const SubDuration = ({ end, cancel_at }) => {
  if (cancel_at) {
    return (
      <p className="user-account__sub-duration">
        Your subscription will end on {end}
      </p>
    );
  } else {
    return (
      <p className="user-account__sub-duration">
        Your subscription will auto renew on {end}
      </p>
    );
  }
};

const ManageInfo = ({ cancel_at }) => {
  if (cancel_at) {
    return (
      <p className="user-account__manage-info">
        you can renew your subscription <br /> at anytime
      </p>
    );
  } else {
    return (
      <p className="user-account__manage-info">
        you can cancel your subscription <br /> at anytime
      </p>
    );
  }
};

const SubscriptionPlan = ({
  userId,
  userEmail,
  customerId,
  isScriptLoaded,
  isScriptLoadSucceed,
  plan,
  trial,
}) => {
  const [stripe, setStripe] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isScriptLoaded && isScriptLoadSucceed) {
      setStripe(window.Stripe(STRIPE_PUBLIC_KEY));
    }
  }, [isScriptLoaded, isScriptLoadSucceed]);

  const createCheckoutSession = async (priceId) => {
    try {
      const response = await axios.post(
        `${serverUrl}/create-checkout-session`,
        {
          priceId,
          client_reference_id: userId,
          customer_email: userEmail,
        }
      );

      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.log('Error (upgrade PRO): ', error);
    }
  };

  const invokeCustomerPortal = async (customerId) => {
    try {
      const response = await axios.post(`${serverUrl}/customer-portal`, {
        id: customerId,
      });

      if (response.data) {
        return response.data;
      }
    } catch (err) {
      console.log(`Error (customer portal): `, err);
    }
  };

  const handleUpdateToPRO = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    createCheckoutSession(PRICE_ID)
      .then((data) => {
        stripe.redirectToCheckout({
          sessionId: data.sessionId,
        });
      })
      .catch(() => setIsLoading(false));
  };

  const handleManageSubscription = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    invokeCustomerPortal(customerId)
      .then((data) => {
        window.location.href = data.url;
      })
      .catch(() => setIsLoading(false));
  };

  if (trial && trial.active) {
    return (
      <div className="user-account__sub">
        <div className="user-account__sub-icon bg-gradient-success">TRIAL</div>
        <p className="user-account__sub-duration">
          Your TRIAL period will end <br /> {trial.duration}
        </p>
        <Button variant="primary" onClick={handleUpdateToPRO}>
          Upgrade to PRO
          {isLoading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </Button>
      </div>
    );
  }

  if (plan && !trial.active) {
    return (
      <div className="user-account__sub">
        <div className="user-account__sub-icon bg-gradient-primary">PRO</div>
        <SubDuration {...plan} />
        <ManageInfo cancel_at={plan.cancel_at} />
        <Button variant="warning" onClick={handleManageSubscription}>
          Manage Subscription
          {isLoading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </Button>
      </div>
    );
  }

  // handle issue with plan
  return null;
};

export default scriptLoader('https://js.stripe.com/v3/')(SubscriptionPlan);
