const sites = (state, action) => {
  switch (action.type) {
    case "SET_SITES":
      return {
        ...state,
        basicSiteList: action.basicSiteList,
      };
    case "SET_LOADING_SITES":
      return {
        ...state,
        loadingSiteList: action.loadingSiteList,
      };
    case "SET_SELECTED_SITE_DATA":
      return {
        ...state,
        selectedSiteData: action.siteData,
      };
    case "SET_SELECTED_SITE_AUDIT_DATA":
      return {
        ...state,
        selectedSiteAuditData: action.auditData,
      };
    default:
      return {
        ...state,
      };
  }
};

export default sites;
