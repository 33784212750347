import React from 'react';
import connect from 'react-redux/es/connect/connect';
import i18n from 'i18next';
import './admin-clients.scss';
import { serverUrl } from '../../helpers/utility';
import axios from 'axios';
import GRTable from '../../components/gr-table/gr-table';
import GRCard from '../../components/gr-card/gr-card';
import GenericModal from '../../components/generic-modal/GenericModal';
import { subDays, isBefore, format, parseISO } from 'date-fns';
import { forEach } from 'underscore';
import { setSelectedSiteAuditData, setSelectedSiteData } from '../../actions';

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    userType: state.authentication.userType,
    organisation: state.organisation.organisation,
    organisationTeam: state.organisation.team,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedSiteData: (site) => {
      dispatch(setSelectedSiteData(site));
    },
    setSelectedSiteAuditData: (auditData) => {
      dispatch(setSelectedSiteAuditData(auditData));
    },
  };
};

class AdminClients extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      totalClients: 0,
      trialSiteCount: 0,
      sites: null,
      siteToRemove: null,
      showRemovalModal: false,
    };

    this.getAuditData = this.getAuditData.bind(this);
    this.getSite = this.getSite.bind(this);
  }

  getAuditData() {
    if (this.auditId) {
      // Get an existing audit
      axios
        .get(`${serverUrl}/audit/id/${this.auditId}?backlinks=true&pages=true`)
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success === true &&
            response.data.data
          ) {
            const auditData = response.data.data;
            this.props.setSelectedSiteAuditData(auditData);
          } else {
            console.error(`ERR: Bad audit response`);
          }
        })
        .catch((err) => {
          console.error(`ERR: Could not fetch audit data: ${err}`);
        });
    }
  }

  getSite() {
    // Get an existing site
    axios
      .get(`${serverUrl}/site/id/${this.siteId}`)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.success === true &&
          response.data.data
        ) {
          const siteData = response.data.data;
          this.props.setSelectedSiteData(siteData);
        }
      })
      .catch((err) => {
        console.error(`ERR: Could not fetch site data: ${err}`);
        this.setState({
          loadingSite: false,
        });
      });
  }

  getTrialUsers() {
    if (this.state.sites) {
      let trialSiteCount = 0;
      let trialDuration = this.state.terms
        ? this.state.terms.trialDurationInDays
        : 30;
      const sites = [...this.state.sites];

      forEach(sites, (site) => {
        if (
          site &&
          site.created &&
          isBefore(subDays(new Date(), trialDuration), new Date(site.created))
        ) {
          trialSiteCount++;
          site.freeTrial = true;
        } else {
          site.freeTrial = false;
        }

        if (site && site.cancelled) {
          site.cancelled = true;
        } else {
          site.cancelled = false;
        }
      });

      this.setState({
        trialSiteCount,
        sites,
      });
    }
  }

  getOrganisationTerms() {
    // Get: terms
    axios.get(`${serverUrl}/organisation/commercialTerms`).then((response) => {
      if (response && response.data && response.data.success === true) {
        const data = response.data.data;

        if (data && data.terms) {
          this.setState({
            terms: data,
          });
        }
      }
    });
  }

  getSites() {
    // Get: All sites for an organisation
    axios
      .get(`${serverUrl}/site/all?fetchRelatedUser=true`)
      .then((response) => {
        if (response && response.data && response.data.success === true) {
          const data = response.data.data;

          this.setState(
            {
              sites: data,
            },
            () => {
              this.getTrialUsers();
            }
          );
        }
      });
  }

  componentDidMount() {
    this.getSites();
    this.getOrganisationTerms();
  }

  componentDidUpdate(prevProps) {}

  setRemoveModalVisibile({ site }) {
    this.setState(
      {
        siteToRemove: site,
      },
      () => {
        this.setState({
          showRemovalModal: true,
        });
      }
    );
  }

  getSiteStatusAsText(site) {
    if (site) {
      if (site.freeTrial) {
        return (
          <div className={`site-status-icon`} title={`admin_clients__trial`}>
            <i className={`fal fa-hourglass`} />
          </div>
        );
      }
      if (site.cancelled) {
        return (
          <div
            className={`site-status-icon red`}
            title={i18n.t('admin_clients__cancelled')}
          >
            <i className={`fal fa-times`} />
          </div>
        );
      }
      if (site.freeTrial !== true && site.cancelled !== true) {
        return (
          <div
            className={`site-status-icon green`}
            title={i18n.t('admin_clients__active_subscription')}
          >
            <i className={`fal fa-check`} />
          </div>
        );
      }
    } else {
      console.error(`ERR: Invalid site data when fetching status`);
    }
  }

  removeSiteById({ siteId }) {
    // Put: Remove a site by de-activating and adding a cancelled date
    axios
      .put(`${serverUrl}/site/id/${siteId}`, {
        active: false,
        cancelled: new Date(),
      })
      .then((response) => {
        if (response && response.data && response.data.success === true) {
          this.setState({
            showRemovalModal: false,
            siteToRemove: null,
          });

          this.getSites();
        } else {
          console.error(`ERR: Could not de-activate site`);
        }
      });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="main-content-container m-t--30" id="admin-clients">
          <div className="row">
            <div className="col-12 col-md-6 col-xl-3">
              <GRCard
                label={i18n.t('admin_clients__total_label')}
                title={i18n.t('admin_clients__total_title')}
                value={`${this.state.sites ? this.state.sites.length : 0}`}
                iconClass="fal fa-users"
                customOuterClass="bg-gradient-primary"
              />
            </div>

            <div className="col-12 col-md-6 col-xl-3">
              <GRCard
                label={i18n.t('admin_clients__paid_label')}
                title={i18n.t('admin_clients__paid_title')}
                value={`${
                  typeof this.state.sites !== 'undefined' &&
                  this.state.sites !== null &&
                  typeof this.state.trialSiteCount !== 'undefined'
                    ? this.state.sites.length - this.state.trialSiteCount
                    : 0
                }`}
                iconClass="fal fa-star"
                customOuterClass="bg-gradient-primary"
              />
            </div>

            <div className="col-12 col-md-6 col-xl-3">
              <GRCard
                label={i18n.t('admin_clients__trial_label')}
                title={i18n.t('admin_clients__trial_title')}
                value={`${this.state.trialSiteCount || 0}`}
                iconClass="fal fa-filter"
                customOuterClass="bg-gradient-primary"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 m-t--10">
              <hr />
            </div>

            <div className="col-12 m-t--20">
              <a href="#/admin-import" className={`m-r--10`}>
                <div className="btn btn-outline-dark">
                  {i18n.t('admin_clients__import')}
                </div>
              </a>

              <a href="#/admin-users" className={`m-r--10`}>
                <div className="btn btn-outline-dark">
                  {i18n.t('admin_clients__manage')}
                </div>
              </a>

              <a href="#/admin-setup" className={`m-r--10`}>
                <div className="btn btn-outline-dark">
                  {i18n.t('admin_clients__settings')}
                </div>
              </a>
            </div>
          </div>

          {this.state.sites && this.state.sites.length > 0 ? (
            <div className="row">
              <div className="col-12 m-t--20 m-b--45">
                <GRTable
                  data={this.state.sites}
                  rows={{}}
                  columns={[
                    {
                      label: i18n.t('admin_clients__website'),
                      size: 'lg',
                      getValue: (item) => {
                        return item.domainFriendly;
                      },
                    },
                    {
                      label: i18n.t('admin_clients__email'),
                      size: 'lg',
                      getValue: (item) => {
                        return item.relatedUser
                          ? item.relatedUser.email
                          : 'N/A';
                      },
                      getTitle: (item) => {
                        return item && item.name
                          ? `${item.name} ${item.lastname}. ID: ${item.userExternalId}`
                          : '';
                      },
                    },
                    {
                      label: i18n.t('admin_clients__status'),
                      size: 'md',
                      getValue: (item) => {
                        return this.getSiteStatusAsText(item);
                      },
                      getTitle: (item) => {
                        return `${i18n.t('admin_clients__added')}: ${format(
                          parseISO(item.created),
                          'dd/MM/yy'
                        )}. ${
                          item.cancelled
                            ? `${i18n.t('admin_clients__cancelled')}: ${format(
                                parseISO(item.cancelled),
                                'dd/MM/yy'
                              )}`
                            : i18n.t('admin_clients__active')
                        }`;
                      },
                    },
                    {
                      label: i18n.t('admin_clients__link'),
                      size: 'md',
                      getValue: (item) => {
                        return item._id &&
                          typeof item.auditId !== 'undefined' ? (
                          <div
                            onClick={async () => {
                              this.siteId = item._id;
                              this.auditId = item.auditId;
                              this.getSite();
                              this.getAuditData();
                              window.location.href = '#/site-summary';
                            }}
                          >
                            <div className="btn btn-sm btn-outline-dark">
                              {i18n.t('admin_clients__view')}
                            </div>
                          </div>
                        ) : (
                          <span>{i18n.t('admin_clients__processing')}</span>
                        );
                      },
                    },
                    {
                      label: i18n.t('admin_clients__actions'),
                      size: 'lg',
                      getValue: (item) => {
                        return (
                          <div>
                            <span
                              className="btn btn-sm btn-outline-danger m-r--10"
                              onClick={() => {
                                this.setRemoveModalVisibile({ site: item });
                              }}
                            >
                              {i18n.t('admin_clients__remove')}
                            </span>
                            <span
                              className="btn btn-sm btn-outline-dark"
                              title={i18n.t('admin_clients__demo_data')}
                              onClick={() => {
                                axios.get(
                                  `${serverUrl}/site/test/rankings/backfill/id/${item._id}`
                                );
                              }}
                            >
                              {i18n.t('admin_clients__data')}
                            </span>
                          </div>
                        );
                      },
                    },
                  ]}
                />
              </div>
            </div>
          ) : null}

          <GenericModal
            show={this.state.showRemovalModal}
            hideModal={() => {
              this.setState({
                showRemovalModal: false,
              });
            }}
            title={i18n.t('admin_clients__remove_confirm_title')}
            submitAction={() => {
              if (this.state.siteToRemove && this.state.siteToRemove._id) {
                this.removeSiteById({ siteId: this.state.siteToRemove._id });

                this.setState({
                  showRemovalModal: false,
                });
              } else {
                console.error(`ERR: Invalid site selection`);
              }
            }}
            submitLabel={i18n.t('admin_clients__remove')}
            submitClass="danger"
          >
            <div>
              <div className="alert alert-danger text-center m-b--15">
                {i18n.t('admin_clients__remove_confirm')} '
                {this.state.siteToRemove
                  ? this.state.siteToRemove.domainFriendly
                  : 'N/A'}
                '?
              </div>
            </div>
          </GenericModal>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminClients);
