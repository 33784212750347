const locale = (state, action) => {
  switch (action.type) {
    case "SET_LOCALE":
      return {
        ...state,
        userLocaleModel: {
          userLocale: action.userLocale,
          userCurrency: action.userCurrency,
          userCurrencyCode: action.userCurrencyCode,
          ddMMYYYYLocale: action.ddMMYYYYLocale,
          ddMMYYLocale: action.ddMMYYLocale,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default locale;
