import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import AddSiteForm from './add-site-form';

import './add-site.scss';

const AddSite = ({ basicSiteList, loadingSiteList }) => {
  useEffect(() => {
    if (!!loadingSiteList && loadingSiteList.length) {
      window.location.href = '#/site-unavailable';
    }

    if (!!basicSiteList && basicSiteList.length) {
      window.location.href = '#/';
    }
  }, [basicSiteList, loadingSiteList]);

  return (
    <div className="container-fluid add-site">
      <div className="d-flex flex-wrap">
        <div className="col-12">
          <AddSiteForm />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  basicSiteList: state.sites.basicSiteList,
  loadingSiteList: state.sites.loadingSiteList,
});

export default connect(mapStateToProps, null)(AddSite);
