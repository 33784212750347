import React from 'react';
import classnames from 'classnames';
import i18n from 'i18next';

const IssueCTA = ({ action, className, icon, issue, text, show = false }) => {
  const ctaClasses = classnames('issue-item-action', className);

  return (
    show && (
      <span className={ctaClasses} onClick={() => action(issue)}>
        <span className={`m-r--10`}>
          <i className={`fal ${icon}`} />
        </span>
        <span>{i18n.t(text)}</span>
      </span>
    )
  );
};

export default IssueCTA;
