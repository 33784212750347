import React from 'react';
import i18n from 'i18next';
import './site-summary.scss';
import connect from 'react-redux/es/connect/connect';
import LoadingCircle from '../../components/loading-circle/loading-circle';
import BacklinksModal from '../../components/backlinks-modal/backlinks-modal';
import { withTranslation } from 'react-i18next';
import { filter, forEach, sortBy } from 'underscore';
import { format, subMonths } from 'date-fns';
import compose from 'ramda/src/compose';
import faviconMissingSrc from '../../assets/images/Favicon-Missing@4X.png';
import baiduSrc from '../../assets/images/search-engines/baidu.png';
import googleSrc from '../../assets/images/search-engines/google.png';
import bingSrc from '../../assets/images/search-engines/bing.png';
import yahooSrc from '../../assets/images/search-engines/yahoo.png';
import savingSrc from '../../assets/images/summary/saving.svg';
import { ReactComponent as Tick } from '../../assets/images/tick.svg';
import { ReactComponent as Pending } from '../../assets/images/pending.svg';
import NoResultsContent from '../../components/no-results-content/no-results-content';
import BacklinksPreview from '../../components/backlinks-preview/backlinks-preview';
import InfoIcon from '../../components/info-icon/info-icon';
import WidgetContainer from '../../components/widgetContainer/widgetContainer';
import axios from 'axios';
import {
  GBP_USD_EXCHANGE_RATE,
  EUR_USD_EXCHANGE_RATE,
  USD_USD_EXCHANGE_RATE,
  AUD_USD_EXCHANGE_RATE,
  CAD_USD_EXCHANGE_RATE,
  getUserCurrency,
  handleInputChange,
  serverUrl,
  getTopIssues,
} from '../../helpers/utility';
import GenericModal from '../../components/generic-modal/GenericModal';
import { ImageFallback } from '../../components/image-fallback/image-fallback';
import { AuditSummary } from '../../components/audit-summary/audit-summary';
import Sitemap from '../../components/sitemap/sitemap';
import RankingSummary from '../../components/ranking-summary/ranking-summary';
import DetailedSavingsTable from '../../components/detailed-savings-table/detailed-savings-table';
import KeywordsTable from '../../components/keywords-table/keywords-table';
import UptimeHistory from '../../components/uptime-history/uptime-history';
import TopIssues from '../../components/top-issues/top-issues';
import RequestHelp from '../../components/request-help/request-help';
import AutoFixModal from '../../components/autofix-modal/autofix-modal';
import AutoFix from '../../components/auto-fix/auto-fix';
import ErrorBoundaryFallback from '../../components/error-boundary-fallback/error-boundary-fallback';
import { removeUrlTrailingSlash } from '../../helpers/utility';
import {
  EASY,
  MEDIUM,
  HARD,
  CATEGORY_SEO,
  CATEGORY_PERFORMANCE,
  CATEGORY_SECURITY,
  CATEGORY_MOBILE,
} from '../../helpers/constants';
import EmptyStateContainer from '../../components/EmptyStateContainer/EmptyStateContainer';

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    userType: state.authentication.userType,
    loginFailure: state.authentication.loginFailure,
    organisation: state.organisation.organisation,
    googleConnected: state.authentication.googleConnected,
    googleAnalyticsAccounts: state.authentication.googleAnalyticsAccounts,
    googleWebmasterAccounts: state.authentication.googleWebmasterAccounts,
    locale: state.locale.userLocaleModel,
    basicSiteList: state.sites.basicSiteList,
    site: state.sites.selectedSiteData,
    audit: state.sites.selectedSiteAuditData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

class SiteSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditKeywordsModal: false,
      showEditCompetitorsModal: false,
      showKeywordSavingsModal: false,
      showCompetitorsKeywordsModal: false,
      selectedKeywords: [],
      savedKeywords: {},
      competitor1: '',
      competitor2: '',
      competitor3: '',
      competitor4: '',
      competitor5: '',
      filteredCompetitors: null,
      noKeywordDataAvailable: true,
      currencyModel: {
        symbol: '£',
        currency: 'GBP',
        exchange_rate: GBP_USD_EXCHANGE_RATE,
      },
      issues: [],
      showRequestHelpModal: false,
      showAutoFixModal: false,
      currentIssue: null,
    };

    // difficulty is a score between 1 (easy) to 3 (difficult)
    this.metricInformation = {
      absent_doctype: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_doctype__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_doctype__fail_msg'),
        failName: i18n.t('audit_doctype__fail_name'),
        findIssue: null,
        findPages: (page) => page.have_doctype === false,
        issueFound: null,
        passMessage: i18n.t('audit_doctype__pass_msg'),
        passName: i18n.t('audit_doctype__pass_name'),
      },
      absent_encoding_meta_tag: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_meta__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_meta__fail_msg'),
        failName: i18n.t('audit_meta__fail_name'),
        findIssue: null,
        findPages: (page) => page.have_enc_meta_tag === false,
        issueFound: null,
        passMessage: i18n.t('audit_meta__pass_msg'),
        passName: i18n.t('audit_meta__pass_name'),
      },
      absent_h1_tags: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_h1__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_h1__fail_msg'),
        failName: i18n.t('audit_h1__fail_name'),
        findIssue: null,
        findPages: (page) => page.h1_count === 0,
        issueFound: null,
        passMessage: i18n.t('audit_h1__pass_msg'),
        passName: i18n.t('audit_h1__pass_name'),
      },
      compression_disabled: {
        canAutoFix: false,
        category: [CATEGORY_PERFORMANCE],
        description: i18n.t('audit_compression__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_compression__fail_msg'),
        failName: i18n.t('audit_compression__fail_name'),
        findIssue: null,
        findPages: null,
        issueFound: null,
        passMessage: i18n.t('audit_compression__pass_msg'),
        passName: i18n.t('audit_compression__pass_name'),
      },
      content_invalid_rate: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_content_rate__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_content_rate__fail_msg'),
        failName: i18n.t('audit_content_rate__fail_name'),
        findIssue: null,
        findPages: (page) => page.plain_text_rate < 0.1,
        issueFound: null,
        passMessage: i18n.t('audit_content_rate__pass_msg'),
        passName: i18n.t('audit_content_rate__pass_name'),
      },
      content_invalid_size: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_content_size__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_content_size__fail_msg'),
        failName: i18n.t('audit_content_size__fail_name'),
        findIssue: null,
        findPages: (page) => page.plain_text_size < 1024,
        issueFound: null,
        passMessage: i18n.t('audit_content_size__pass_msg'),
        passName: i18n.t('audit_content_size__pass_name'),
      },
      content_readability_bad: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_content_read__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_content_read__fail_msg'),
        failName: i18n.t('audit_content_read__fail_name'),
        findIssue: null,
        findPages: (page) => page.content_readability_flesh_kincaid < 15,
        issueFound: null,
        passMessage: i18n.t('audit_content_read__pass_msg'),
        passName: i18n.t('audit_content_read__pass_name'),
      },
      deprecated_html_tags: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_html_old__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_html_old__fail_msg'),
        failName: i18n.t('audit_html_old__fail_name'),
        findIssue: (page) => page.deprecated_html_tags,
        findPages: (page) => page.have_deprecated_tags,
        issueFound: i18n.t('audit_html_old__issue'),
        passMessage: i18n.t('audit_html_old__pass_msg'),
        passName: i18n.t('audit_html_old__pass_name'),
      },
      duplicate_meta_descriptions: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_meta_descr__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_meta_descr__fail_msg'),
        failName: i18n.t('audit_meta_descr__fail_name'),
        findIssue: null,
        findPages: (page) => page.have_meta_description_duplicates,
        passMessage: i18n.t('audit_meta_descr__pass_msg'),
        passName: i18n.t('audit_meta_descr__pass_name'),
      },
      duplicate_meta_tags: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_meta_tags__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_meta_tags__fail_msg'),
        failName: i18n.t('audit_meta_tags__fail_name'),
        findIssue: (page) => page.duplicate_meta_tags,
        findPages: (page) =>
          page.duplicate_meta_tags && page.duplicate_meta_tags.length,
        issueFound: i18n.t('audit_meta_tags__issue'),
        passMessage: i18n.t('audit_meta_tags__pass_msg'),
        passName: i18n.t('audit_meta_tags__pass_name'),
      },
      duplicate_titles: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_dup_titles__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_dup_titles__fail_msg'),
        failName: i18n.t('audit_dup_titles__fail_name'),
        findIssue: (page) => page.title,
        findPages: (page) => page.have_title_duplicates,
        issueFound: i18n.t('audit_dup_titles__issue'),
        passMessage: i18n.t('audit_dup_titles__pass_msg'),
        passName: i18n.t('audit_dup_titles__pass_name'),
      },
      favicon_invalid: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_favicon__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_favicon__fail_msg'),
        failName: i18n.t('audit_favicon__fail_name'),
        findIssue: null,
        findPages: null,
        issueFound: null,
        passMessage: i18n.t('audit_favicon__pass_msg'),
        passName: i18n.t('audit_favicon__pass_name'),
      },
      have_robots: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_robot__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_robot__fail_msg'),
        failName: i18n.t('audit_robot__fail_name'),
        findIssue: null,
        findPages: null,
        issueFound: null,
        passMessage: i18n.t('audit_robot__pass_msg'),
        passName: i18n.t('audit_robot__pass_name'),
      },
      have_sitemap: {
        canAutoFix: true,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_sitemap__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_sitemap__fail_msg'),
        failName: i18n.t('audit_sitemap__fail_name'),
        findIssue: null,
        findPages: null,
        issueFound: null,
        passMessage: i18n.t('audit_sitemap__pass_msg'),
        passName: i18n.t('audit_sitemap__pass_name'),
      },
      images_invalid_alt: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_no_alt__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_no_alt__fail_msg'),
        failName: i18n.t('audit_no_alt__fail_name'),
        findIssue: null,
        findPages: (pages) => pages.images_invalid_alt > 0,
        issueFound: null,
        passMessage: i18n.t('audit_no_alt__pass_msg'),
        passName: i18n.t('audit_no_alt__pass_name'),
      },
      links_broken: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_links__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_links__fail_msg'),
        failName: i18n.t('audit_links__fail_name'),
        findIssue: null,
        findPages: (pages) => pages.links_broken > 0,
        issueFound: null,
        passMessage: i18n.t('audit_links__pass_msg'),
        passName: i18n.t('audit_links__pass_name'),
      },
      meta_description_empty: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_empty_meta_descr__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_empty_meta_descr__fail_msg'),
        failName: i18n.t('audit_empty_meta_descr__fail_name'),
        findIssue: null,
        findPages: (page) => page.meta_description_length === 0,
        issueFound: null,
        passMessage: i18n.t('audit_empty_meta_descr__pass_msg'),
        passName: i18n.t('audit_empty_meta_descr__pass_name'),
      },
      meta_description_inappropriate: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_wrong_meta_descr__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_wrong_meta_descr__fail_msg'),
        failName: i18n.t('audit_wrong_meta_descr__fail_name'),
        findIssue: null,
        findPages: (page) => page.meta_description_consistency < 0.2,
        issueFound: null,
        passMessage: i18n.t('audit_wrong_meta_descr__pass_msg'),
        passName: i18n.t('audit_wrong_meta_descr__pass_name'),
      },
      pages_invalid_size: {
        canAutoFix: false,
        category: [CATEGORY_PERFORMANCE],
        description: i18n.t('audit_page_size__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_page_size__fail_msg'),
        failName: i18n.t('audit_page_size__fail_name'),
        findIssue: null,
        findPages: (page) => page.page_size < 1024 || page.page_size > 256000,
        issueFound: null,
        passMessage: i18n.t('audit_page_size__pass_msg'),
        passName: i18n.t('audit_page_size__pass_name'),
      },
      pages_with_flash: {
        canAutoFix: false,
        category: [CATEGORY_SEO, CATEGORY_MOBILE],
        description: i18n.t('audit_flash__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_flash__fail_msg'),
        failName: i18n.t('audit_flash__fail_name'),
        findIssue: null,
        findPages: (page) => page.have_flash,
        issueFound: null,
        passMessage: i18n.t('audit_flash__pass_msg'),
        passName: i18n.t('audit_flash__pass_name'),
      },
      pages_with_frame: {
        canAutoFix: false,
        category: [CATEGORY_PERFORMANCE, CATEGORY_MOBILE],
        description: i18n.t('audit_iframe__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_iframe__fail_msg'),
        failName: i18n.t('audit_iframe__fail_name'),
        findIssue: null,
        findPages: (page) => page.have_frame,
        issueFound: null,
        passMessage: i18n.t('audit_iframe__pass_msg'),
        passName: i18n.t('audit_iframe__pass_name'),
      },
      pages_with_lorem_ipsum: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_lorem__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_lorem__fail_msg'),
        failName: i18n.t('audit_lorem__fail_name'),
        findIssue: null,
        findPages: (page) => page.have_lorem_ipsum,
        issueFound: null,
        passMessage: i18n.t('audit_lorem__pass_msg'),
        passName: i18n.t('audit_lorem__pass_name'),
      },
      response_code_4xx: {
        canAutoFix: false,
        category: [CATEGORY_PERFORMANCE],
        description: i18n.t('audit_400__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_400__fail_msg'),
        failName: i18n.t('audit_400__fail_name'),
        findIssue: null,
        findPages: (page) => page.response_code.toString()[0] === '4',
        issueFound: null,
        passMessage: i18n.t('audit_400__pass_msg'),
        passName: i18n.t('audit_400__pass_name'),
      },
      response_code_5xx: {
        canAutoFix: false,
        category: [CATEGORY_PERFORMANCE],
        description: i18n.t('audit_500__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_500__fail_msg'),
        failName: i18n.t('audit_500__fail_name'),
        findIssue: null,
        findPages: (page) => page.response_code.toString()[0] === '5',
        issueFound: null,
        passMessage: i18n.t('audit_500__pass_msg'),
        passName: i18n.t('audit_500__pass_name'),
      },
      seo_non_friendly_url: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_seo_url__descr'),
        difficulty: MEDIUM,
        failMessage: i18n.t('audit_seo_url__fail_msg'),
        failName: i18n.t('audit_seo_url__fail_name'),
        findIssue: null,
        findPages: (page) => page.seo_friendly_url === false,
        issueFound: null,
        passMessage: i18n.t('audit_seo_url__pass_msg'),
        passName: i18n.t('audit_seo_url__pass_name'),
      },
      ssl: {
        canAutoFix: false,
        category: [CATEGORY_SECURITY],
        description: i18n.t('audit_ssl__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_ssl__fail_msg'),
        failName: i18n.t('audit_ssl__fail_name'),
        findIssue: null,
        findPages: (page) => page.ssl === false,
        issueFound: null,
        passMessage: i18n.t('audit_ssl__pass_msg'),
        passName: i18n.t('audit_ssl__pass_name'),
      },
      ssl_certificate_valid: {
        canAutoFix: false,
        category: [CATEGORY_SECURITY],
        description: i18n.t('audit_ssl_valid__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_ssl_valid__fail_msg'),
        failName: i18n.t('audit_ssl_valid__fail_name'),
        findIssues: null,
        findPages: (page) => page.ssl_certificate_valid === false,
        issueFound: null,
        passName: i18n.t('audit_ssl_valid__pass_name'),
        passMessage: i18n.t('audit_ssl_valid__pass_msg'),
      },
      test_server_signature: {
        canAutoFix: false,
        category: [CATEGORY_PERFORMANCE],
        description: i18n.t('audit_test_server__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_test_server__fail_msg'),
        failName: i18n.t('audit_test_server__fail_name'),
        findIssue: null,
        findPages: null,
        issueFound: null,
        passMessage: i18n.t('audit_test_server__pass_msg'),
        passName: i18n.t('audit_test_server__pass_name'),
      },
      time_load_high: {
        canAutoFix: false,
        category: [CATEGORY_PERFORMANCE, CATEGORY_MOBILE],
        description: i18n.t('audit_load_high__descr'),
        difficulty: HARD,
        failMessage: i18n.t('audit_load_high__fail_msg'),
        failName: i18n.t('audit_load_high__fail_name'),
        findIssue: null,
        findPages: null,
        issueFound: null,
        passMessage: i18n.t('audit_load_high__pass_msg'),
        passName: i18n.t('audit_load_high__pass_name'),
      },
      title_duplicate_tag: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_dup_title__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_dup_title__fail_msg'),
        failName: i18n.t('audit_dup_title__fail_name'),
        findIssue: null,
        findPages: (page) => page.title_duplicate_tag,
        issueFound: null,
        passMessage: i18n.t('audit_dup_title__pass_msg'),
        passName: i18n.t('audit_dup_title__pass_name'),
      },
      title_empty: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_empty_title__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_empty_title__fail_msg'),
        failName: i18n.t('audit_empty_title__fail_name'),
        findIssue: null,
        findPages: (page) => page.title_length === 0,
        issueFound: null,
        passMessage: i18n.t('audit_empty_title__pass_msg'),
        passName: i18n.t('audit_empty_title__pass_name'),
      },
      title_inappropriate: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_wrong_title__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_wrong_title__fail_msg'),
        failName: i18n.t('audit_wrong_title__fail_name'),
        findIssue: (page) => page.title,
        findPages: (page) => page.title_consistency < 0.3,
        issueFound: i18n.t('audit_wrong_title__issue'),
        passMessage: i18n.t('audit_wrong_title__pass_msg'),
        passName: i18n.t('audit_wrong_title__pass_name'),
      },
      title_long: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_long_title__descr'),
        difficulty: EASY,
        failMessage: i18n.t('audit_long_title__fail_msg'),
        failName: i18n.t('audit_long_title__fail_name'),
        findIssue: (page) => page.title,
        findPages: (page) => page.title_length > 65,
        issueFound: i18n.t('audit_long_title__issue'),
        passMessage: i18n.t('audit_long_title__pass_msg'),
        passName: i18n.t('audit_long_title__pass_name'),
      },
      title_short: {
        canAutoFix: false,
        category: [CATEGORY_SEO],
        description: i18n.t('audit_short_title__descr'),

        difficulty: EASY,
        failMessage: i18n.t('audit_short_title__fail_msg'),
        failName: i18n.t('audit_short_title__fail_name'),
        findIssue: (page) => page.title,
        findPages: (page) => page.title_length < 30,
        issueFound: i18n.t('audit_short_title__issue'),
        passMessage: i18n.t('audit_short_title__pass_msg'),
        passName: i18n.t('audit_short_title__pass_name'),
      },
    };

    this.handleInputChange = handleInputChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.toggleIssueVisibility = this.toggleIssueVisibility.bind(this);
    this.togglePagesVisibility = this.togglePagesVisibility.bind(this);
    this.onRequestHelpClick = this.onRequestHelpClick.bind(this);
    this.onAutoFixClick = this.onAutoFixClick.bind(this);
    this.handleEditKeywordsSubmit = this.handleEditKeywordsSubmit.bind(this);
    this.generateTopDFSRankingSummary =
      this.generateTopDFSRankingSummary.bind(this);
  }

  toggleIssueVisibility({ issueName }) {
    const issues = this.state.issues.map((issue) =>
      issue.passName === issueName
        ? {
            ...issue,
            show: !issue.show,
          }
        : issue
    );

    this.setState({
      issues,
    });
  }

  togglePagesVisibility({ issueName }) {
    const issues = this.state.issues.map((issue) =>
      issue.passName === issueName
        ? {
            ...issue,
            showPages: !issue.showPages,
          }
        : issue
    );

    this.setState({
      issues,
    });
  }

  getSelectedSite() {
    return sessionStorage && sessionStorage.getItem('selectedSite')
      ? removeUrlTrailingSlash(sessionStorage.getItem('selectedSite'))
      : '';
  }

  renderDifficulty({ difficultyScore = 1 }) {
    let label = i18n.t('audit_label__easy');

    if (difficultyScore === EASY) {
      label = i18n.t('audit_label__easy');
    }

    if (difficultyScore === MEDIUM) {
      label = i18n.t('audit_label__medium');
    }

    if (difficultyScore === HARD) {
      label = i18n.t('audit_label__difficult');
    }

    return (
      <div className={`difficulty-score difficulty-${label.toLowerCase()}`}>
        <span>{label}</span>
      </div>
    );
  }

  onRequestHelpClick(issue) {
    this.setState({
      showRequestHelpModal: true,
      currentIssue: issue,
    });
  }

  onAutoFixClick(issue) {
    this.setState({
      showAutoFixModal: true,
      currentIssue: issue,
    });
  }

  handleCurrencyChange = (value) => {
    const animationDelay = 500;
    const setStorageAndCalculateSavings = () => {
      localStorage.setItem('currency', value);
      this.calculateSavings(this.state, animationDelay);
    };
    switch (value) {
      case 'GBP':
        this.setState(
          {
            currencyModel: {
              symbol: '£',
              currency: value,
              exchange_rate: GBP_USD_EXCHANGE_RATE,
            },
          },
          setStorageAndCalculateSavings
        );
        break;
      case 'EUR':
        this.setState(
          {
            currencyModel: {
              symbol: '€',
              currency: value,
              exchange_rate: EUR_USD_EXCHANGE_RATE,
            },
          },
          setStorageAndCalculateSavings
        );
        break;
      case 'USD':
        this.setState(
          {
            currencyModel: {
              symbol: '$',
              currency: value,
              exchange_rate: USD_USD_EXCHANGE_RATE,
            },
          },
          setStorageAndCalculateSavings
        );
        break;
      case 'AUD':
        this.setState(
          {
            currencyModel: {
              symbol: '$',
              currency: value,
              exchange_rate: AUD_USD_EXCHANGE_RATE,
            },
          },
          setStorageAndCalculateSavings
        );
        break;
      case 'CAD':
        this.setState(
          {
            currencyModel: {
              symbol: '$',
              currency: value,
              exchange_rate: CAD_USD_EXCHANGE_RATE,
            },
          },
          setStorageAndCalculateSavings
        );
        break;
      default:
        this.setState(
          {
            currencyModel: {
              symbol: '£',
              currency: 'GBP',
              exchange_rate: GBP_USD_EXCHANGE_RATE,
            },
          },
          () => {
            localStorage.setItem('currency', 'GBP');
            this.calculateSavings(this.state, animationDelay);
          }
        );
        break;
    }
  };

  calculateSavings(state, delay = 2500) {
    const id = 'seoValue';

    if (document.getElementById(id)) {
      document.getElementById(id).innerHTML = `${state.currencyModel.symbol}0`;
    }

    if (this.animationInterval) {
      clearInterval(this.animationInterval);
    }

    if (this.delayAnimationTimer) {
      clearTimeout(this.delayAnimationTimer);
    }

    function animateValue(id, start, end, duration) {
      const range = end - start;
      let current = start;
      let difference = end - start;
      let increment = end > start ? 1 : -1;

      if (difference > 1000) {
        increment = 20;
      }

      if (difference > 5000) {
        increment = 100;
      }

      if (difference > 10000) {
        increment = 500;
      }

      const stepTime = Math.abs(Math.floor(duration / range));
      const obj = document.getElementById(id);
      let timerInterval;

      if (obj) {
        timerInterval = setInterval(function () {
          current += increment;
          obj.innerHTML = `${state.currencyModel.symbol}${current}`;
          if (current >= end) {
            clearInterval(timerInterval);
          }
        }, stepTime);
      }

      return timerInterval;
    }

    if (this.props.site && this.props.site.seoValue) {
      let seoValue = this.props.site.seoValue;

      // Format and convert to GBP (approx. exchange rate of 1.25)
      seoValue = Number(
        Number(seoValue / state.currencyModel.exchange_rate).toFixed(0)
      );

      if (seoValue > 0) {
        this.delayAnimationTimer = setTimeout(() => {
          this.animationInterval = animateValue('seoValue', 0, seoValue, 5000);

          // Reduce font size for large seoValue's
          if (seoValue >= 10000) {
            setTimeout(() => {
              const obj = document.getElementById('seoValue');
              if (obj) {
                obj.style.fontSize = '40px';
              }
            }, 2000);
          }
        }, delay);
      }
    } else {
      return 10;
    }
  }

  handleEditKeywordsSubmit = (e) => {
    // On saving the editKeywordsModal, savedKeywords in localStorage
    // is updated with the selectedKeywords and saved to local storage
    let savedKeywordsUpdated = this.state.savedKeywords;
    savedKeywordsUpdated[this.props.site._id] = [
      ...this.state.selectedKeywords,
    ];
    localStorage.setItem('savedKeywords', JSON.stringify(savedKeywordsUpdated));

    const { topDFSRankingSummary, historicDataAvailable } =
      this.generateTopDFSRankingSummary(this.state.selectedKeywords);

    this.setState({
      savedKeywords: savedKeywordsUpdated,
      showEditKeywordsModal: false,
      topDFSRankingSummary,
      historicDataAvailable,
    });
  };

  generateTopDFSRankingSummary = (keywords) => {
    let topDFSRankingSummary = {},
      historicDataAvailable = false;
    forEach(keywords, (keyword) => {
      if (
        this.props.site.dfsRankingSummary &&
        this.props.site.dfsRankingSummary[keyword]
      ) {
        topDFSRankingSummary[keyword] =
          this.props.site.dfsRankingSummary[keyword];
      } else {
        topDFSRankingSummary[keyword] = {};
      }
    });

    if (
      this.props.site &&
      this.props.site.dfsRankingSummary &&
      this.props.site.keywords &&
      Object.keys(topDFSRankingSummary).length > 0
    ) {
      forEach(topDFSRankingSummary, (rankedList, rankedKeyword) => {
        if (
          rankedKeyword &&
          rankedList &&
          Array.isArray(rankedList) &&
          rankedList.length >= 2
        ) {
          historicDataAvailable = true;
        }
      });
    }
    return {
      topDFSRankingSummary,
      historicDataAvailable,
    };
  };

  identifyAuditIssues() {
    if (this.props.audit && this.props.audit.summary) {
      const issues = getTopIssues(
        this.props.audit.summary,
        this.metricInformation
      );
      this.setState({
        issues,
      });
    }
  }

  processSiteData() {
    this.setState({ currencyModel: getUserCurrency() }, () =>
      this.calculateSavings(this.state)
    );

    let selectedKeywords = [],
      savedKeywords = [];

    if (this.props.site.keywords) {
      // remove keywords with no position
      const filteredKeywords = this.props.site.keywords.filter(
        ({ position }) => typeof position === 'number'
      );
      // sort by position
      selectedKeywords = sortBy(filteredKeywords, 'position')
        .slice(0, 3)
        .map(({ keyword }) => keyword);

      savedKeywords = localStorage.getItem('savedKeywords')
        ? JSON.parse(localStorage.getItem('savedKeywords'))
        : {};
      // If current site._id matches an _id in the keys of savedKeywords and
      // all savedKeywords are present in the filteredKeywords array,
      // selectedKeywords will be overwritten by savedKeywords[site._id]
      if (
        Object.keys(savedKeywords).includes(this.props.site._id) &&
        !!savedKeywords[this.props.site._id].length &&
        savedKeywords[this.props.site._id].every((savedKeyword) =>
          filteredKeywords.map(({ keyword }) => keyword).includes(savedKeyword)
        )
      ) {
        selectedKeywords = [...savedKeywords[this.props.site._id]];
      } else {
        // If there is no savedKeywords for this site, save the top 3 to localStorage
        savedKeywords[this.props.site._id] = [...selectedKeywords];
        localStorage.setItem('savedKeywords', JSON.stringify(savedKeywords));
      }
    }

    const { topDFSRankingSummary, historicDataAvailable } =
      this.generateTopDFSRankingSummary(selectedKeywords);

    // Must update even if no keywords
    this.setState({
      selectedKeywords,
      savedKeywords,
      topDFSRankingSummary,
      historicDataAvailable,
    });

    // new site also means new audit data
    this.identifyAuditIssues();

    // filteredCompetitors ignores large tech companies that frequently show up for search terms like facebook, google.
    const filteredCompetitors = filter(
      this.props.site.competitors,
      (competitor) => {
        if (
          competitor.domain &&
          competitor.domain.includes('facebook') !== true &&
          competitor.domain.includes('microsoft') !== true &&
          competitor.domain.includes('linkedin') !== true &&
          competitor.domain.includes('apple') !== true &&
          competitor.domain.includes('yell') !== true &&
          competitor.domain.includes('yell') !== true &&
          competitor.domain.includes('wikipedia') !== true &&
          competitor.domain.includes('amazon') !== true &&
          competitor.domain.includes('tripadvisor') !== true &&
          competitor.domain.includes('ebay') !== true &&
          competitor.domain.includes('baidu') !== true &&
          competitor.domain.includes('youtube') !== true &&
          competitor.domain.includes('twitter') !== true &&
          competitor.domain.includes('yahoo') !== true &&
          competitor.domain.includes('instagram') !== true &&
          competitor.domain.includes('pornhub') !== true &&
          competitor.domain.includes('xvideos') !== true &&
          competitor.domain.includes('google') !== true &&
          competitor.domain.includes('pinterest') !== true &&
          competitor.domain.includes('snapchat') !== true &&
          competitor.domain.includes('cisco') !== true &&
          competitor.domain.includes('reddit') !== true &&
          competitor.domain.includes('zendesk') !== true &&
          competitor.domain.includes('uber') !== true &&
          competitor.domain.includes('yelp') !== true &&
          competitor.domain.includes('just-eat') !== true &&
          competitor.domain.includes('takeaway') !== true &&
          competitor.domain.includes('glassdoor') !== true &&
          competitor.domain.includes('indeed') !== true &&
          competitor.domain.includes('samsung') !== true &&
          competitor.domain.includes('github') !== true
        ) {
          return true;
        } else {
          // Always include if the target site.. even if Google!
          if (
            competitor.domain &&
            competitor.domain.includes(this.props.site.domainFriendly)
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
    );

    const top5Competitors = filteredCompetitors.slice(0, 5);

    if (top5Competitors && top5Competitors.length > 4) {
      this.setState({
        competitor1: top5Competitors[0].domain,
        competitor2: top5Competitors[1].domain,
        competitor3: top5Competitors[2].domain,
        competitor4: top5Competitors[3].domain,
        competitor5: top5Competitors[4].domain,
        filteredCompetitors: top5Competitors,
      });
    } else {
      // Must still update competitors, or will be a previous sites value!
      this.setState({
        competitor1: '',
        competitor2: '',
        competitor3: '',
        competitor4: '',
        competitor5: '',
        filteredCompetitors: top5Competitors,
      });
    }

    if (this.props.site.keywords && this.props.site.keywords.length > 0) {
      this.setState({
        noKeywordDataAvailable: false,
      });
    }
  }

  componentDidMount() {
    if (this.props.site && this.props.site._id) {
      // Site data available
      this.processSiteData();
    }

    if (
      typeof this.props.site === 'undefined' &&
      typeof this.props.basicSiteList === 'object'
    ) {
      if (this.props.basicSiteList.length < 1) {
        this.props.user.selfSignUp
          ? (window.location.href = '#/add-site')
          : (window.location.href = '#/site-unavailable') &&
            console.log('---- REDIRECT: No sites to show');
      } else if (
        // If sessionStorage selectedSite is not in basicSiteList, redirect to SiteUnavailable
        this.getSelectedSite() &&
        !this.props.basicSiteList.find(
          ({ domain }) => domain === this.getSelectedSite()
        )
      ) {
        window.location.href = '#/site-unavailable';
      }
    }
  }

  componentDidUpdate(prevProps) {
    // Props now exists and didn't before... OR prop value has changed vs prevProp
    if (
      (typeof prevProps.site === 'undefined' &&
        this.props.site &&
        this.props.site._id) ||
      (this.props.site &&
        prevProps.site &&
        this.props.site._id !== prevProps.site._id)
    ) {
      // Site data available
      this.processSiteData();
    }

    if (
      typeof this.props.site === 'undefined' &&
      typeof this.props.basicSiteList === 'object'
    ) {
      if (this.props.basicSiteList.length < 1) {
        this.props.user.selfSignUp
          ? (window.location.href = '#/add-site')
          : (window.location.href = '#/site-unavailable') &&
            console.log('---- REDIRECT: No sites to show');
      } else if (
        // If sessionStorage selectedSite is not in basicSiteList, redirect to SiteUnavailable
        this.getSelectedSite() &&
        !this.props.basicSiteList.find(
          ({ domain }) => domain === this.getSelectedSite()
        )
      ) {
        window.location.href = '#/site-unavailable';
      }
    }
  }

  componentWillUnmount() {}

  changeLanguage(lng) {
    this.props.i18n.changeLanguage(lng);
  }

  exportLinksToCSV({ domainFriendly, dataJSON }) {
    const data = {
      data: dataJSON,
      fields: [
        {
          label: this.props.t('export_csv__url'),
          value: 'urlFrom',
          default: null,
        },
        {
          label: this.props.t('export_csv__page_title'),
          value: 'pageFromTitle',
          default: null,
        },
        {
          label: this.props.t('export_csv__target_url'),
          value: 'urlTo',
          default: null,
        },
        {
          label: this.props.t('export_csv__date_discovered'),
          value: 'firstIndexedDate',
          default: null,
        },
        {
          label: this.props.t('export_csv__link_type'),
          value: 'linkType',
          default: null,
        },
      ],
    };

    axios
      .post(`${serverUrl}/site/export/csv`, data)
      .then((response) => {
        if (response && response.data) {
          // Download ignored by browser/client without this workaround
          // https://stackoverflow.com/questions/42748667/trigger-csv-file-download-with-angular
          let csvContent = response.data.toString();
          csvContent = csvContent.replace(/ /g, '%20');
          const a = document.createElement('a');
          a.href = 'data:application/csv;charset=utf-8,' + csvContent;
          a.setAttribute('download', `backlinks_${domainFriendly}.csv`);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  render() {
    const issuesLoaded = this.state.issues && this.state.issues.length > 0;
    const issues = issuesLoaded && [...this.state.issues];

    return (
      <div id="site-summary">
        {this.props.site && this.props.audit ? (
          <div id="siteSummaryHeader" className="standard-page-layout">
            <div className="text-center m-b--15">
              <div className="col-12 page-padding">
                {this.props.site.audit &&
                this.props.site.audit.didLastAuditFail &&
                !this.props.site.audit.lastSuccessfulAuditId ? (
                  <EmptyStateContainer
                    message={i18n.t('empty_state_container__when_ready')}
                  />
                ) : (
                  <WidgetContainer>
                    <ErrorBoundaryFallback text="Something went wrong while trying to load your audit summary.">
                      <AuditSummary
                        audit={this.props.audit}
                        site={this.props.site}
                      />
                    </ErrorBoundaryFallback>
                  </WidgetContainer>
                )}

                <WidgetContainer>
                  <div className="dashboard-box">
                    <div className="dashboard-box-content dashboard-box-content-no-min-height">
                      <div className="col-12 overflow-hidden">
                        <div className="search-engine-label">
                          {this.props.t('search_engine__label')}:
                          <div className="m-l--5">
                            <InfoIcon
                              id={'searchInfo'}
                              placement={'right'}
                              text={
                                <div>
                                  <p>
                                    {this.props.t('search_engine__info_icon')}
                                  </p>
                                </div>
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="gr-section flex align-items-center overflow-x search-engine-container">
                        <Sitemap site={this.props.site} />
                        <div className="search-engine-logo-item">
                          <div className="search-engine-logo-item-container">
                            <div className="search-engine-logo-item-logo">
                              <img
                                src={googleSrc}
                                alt="Google Search Engine Logo"
                              />
                            </div>
                            <div
                              className={
                                this.props.site.indexedByGoogle
                                  ? 'search-engine-logo-item-indexed'
                                  : 'search-engine-logo-item-pending'
                              }
                            >
                              {this.props.site.indexedByGoogle ? (
                                <Tick />
                              ) : (
                                <Pending />
                              )}
                              {this.props.site.indexedByGoogle
                                ? this.props.t('search_engine__index_completed')
                                : this.props.t('search_engine__index_pending')}
                            </div>
                          </div>
                        </div>
                        <div className="search-engine-logo-item">
                          <div className="search-engine-logo-item-container">
                            <div className="search-engine-logo-item-logo">
                              <img
                                src={bingSrc}
                                alt="Bing Search Engine Logo"
                              />
                            </div>
                            <div
                              className={
                                this.props.site.indexedByBing
                                  ? 'search-engine-logo-item-indexed'
                                  : 'search-engine-logo-item-pending'
                              }
                            >
                              {this.props.site.indexedByBing ? (
                                <Tick />
                              ) : (
                                <Pending />
                              )}
                              {this.props.site.indexedByBing
                                ? this.props.t('search_engine__index_completed')
                                : this.props.t('search_engine__index_pending')}
                            </div>
                          </div>
                        </div>
                        <div className="search-engine-logo-item">
                          <div className="search-engine-logo-item-container">
                            <div className="search-engine-logo-item-logo">
                              <img
                                src={yahooSrc}
                                alt="Yahoo! Search Engine Logo"
                              />
                            </div>
                            <div
                              className={
                                this.props.site.indexedByBing
                                  ? 'search-engine-logo-item-indexed'
                                  : 'search-engine-logo-item-pending'
                              }
                            >
                              {this.props.site.indexedByBing ? (
                                <Tick />
                              ) : (
                                <Pending />
                              )}
                              {this.props.site.indexedByBing
                                ? this.props.t('search_engine__index_completed')
                                : this.props.t('search_engine__index_pending')}
                            </div>
                          </div>
                        </div>
                        <div className="search-engine-logo-item">
                          <div className="search-engine-logo-item-container">
                            <div className="search-engine-logo-item-logo">
                              <img
                                src={baiduSrc}
                                alt="Baidu Search Engine Logo"
                              />
                            </div>
                            <div
                              className={
                                this.props.site.indexedByGoogle
                                  ? 'search-engine-logo-item-indexed'
                                  : 'search-engine-logo-item-pending'
                              }
                            >
                              {this.props.site.indexedByGoogle ? (
                                <Tick />
                              ) : (
                                <Pending />
                              )}
                              {this.props.site.indexedByGoogle
                                ? this.props.t('search_engine__index_completed')
                                : this.props.t('search_engine__index_pending')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </WidgetContainer>
                <div className="row gr-section">
                  <div className="col-12 col-lg-6 dashboard-box">
                    <WidgetContainer>
                      <div className="dashboard-box-header dashboard-box-header--keywords justify-content-between">
                        <div className="dashboard-box-header__wrapper">
                          <span className="text-left">
                            {this.props.t('keywords__ranking_on')}

                            <span className="m-l--5">
                              <InfoIcon
                                id={'searchInfo'}
                                placement={'right'}
                                text={
                                  <div>
                                    <p>
                                      {this.props.t(
                                        'keywords__graph_after_7_days'
                                      )}
                                    </p>
                                  </div>
                                }
                              />
                            </span>
                          </span>
                        </div>
                        <div className="dashboard-box-header__wrapper">
                          <span
                            className="btn btn-as-link justify"
                            onClick={() => {
                              this.setState({
                                showEditKeywordsModal: true,
                              });
                            }}
                          >
                            {this.props.t('keywords__edit')}
                          </span>
                        </div>
                      </div>
                      <div className="dashboard-box-content">
                        <RankingSummary
                          keywords={this.props.site.keywords}
                          savedSiteKeywords={
                            this.state.savedKeywords &&
                            this.state.savedKeywords[this.props.site._id]
                          }
                          noKeywordDataAvailable={
                            this.state.noKeywordDataAvailable
                          }
                          historicDataAvailable={
                            this.state.historicDataAvailable
                          }
                          topDFSRankingSummary={this.state.topDFSRankingSummary}
                        />
                      </div>
                    </WidgetContainer>
                  </div>

                  <div className="col-12 col-lg-6 dashboard-box">
                    <WidgetContainer>
                      <div className="dashboard-box-header dashboard-box-header--backlinks justify-content-between">
                        <div className="dashboard-box-header__wrapper">
                          <span className="text-left">
                            {`${
                              this.props.audit &&
                              this.props.audit.backlinks &&
                              this.props.audit.backlinks.summary &&
                              this.props.audit.backlinks.summary.backlinks
                                ? // Truncate to millions (when above 1,000,000)
                                  this.props.audit.backlinks.summary.backlinks.toString()
                                    .length > 7
                                  ? `${this.props.audit.backlinks.summary.backlinks
                                      .toString()
                                      .substring(
                                        0,
                                        this.props.audit.backlinks.summary.backlinks.toString()
                                          .length - 6
                                      )}M`
                                  : // Truncate to thousands (when above 10,000)
                                  this.props.audit.backlinks.summary.backlinks.toString()
                                      .length > 5
                                  ? `${this.props.audit.backlinks.summary.backlinks
                                      .toString()
                                      .substring(
                                        0,
                                        this.props.audit.backlinks.summary.backlinks.toString()
                                          .length - 3
                                      )}K`
                                  : this.props.audit.backlinks.summary.backlinks.toLocaleString()
                                : 0
                            } ${this.props.t('backlinks')}`}{' '}
                            <span className="hide-mobile">
                              {`${this.props.t('backlinks__from')} ${
                                this.props.audit &&
                                this.props.audit.backlinks &&
                                this.props.audit.backlinks.refDomains &&
                                this.props.audit.backlinks.summary
                                  ? // Truncate to length of websites
                                    this.props.audit.backlinks.summary
                                      .refDomains >
                                    this.props.audit.backlinks.refDomains.length
                                    ? `${this.props.audit.backlinks.refDomains.length}+`
                                    : this.props.audit.backlinks.refDomains
                                        .length
                                  : 0
                              } ${this.props.t('backlinks__websites')}`}
                            </span>
                            <span className="m-l--5">
                              <InfoIcon
                                id={'backlinkInfo'}
                                text={
                                  <div>
                                    <p>{this.props.t('backlinks__info')}</p>
                                  </div>
                                }
                              />
                            </span>
                          </span>
                        </div>
                        <div className="dashboard-box-header__wrapper">
                          {this.props.audit &&
                          this.props.audit.backlinks &&
                          this.props.audit.backlinks.backlinks &&
                          this.props.site &&
                          this.props.site.domainFriendly ? (
                            <span className="text-right">
                              <span
                                className="btn btn-as-link justify"
                                onClick={() => {
                                  this.exportLinksToCSV({
                                    domainFriendly:
                                      this.props.site.domainFriendly,
                                    dataJSON:
                                      this.props.audit.backlinks.backlinks,
                                  });
                                }}
                                title={this.props.t('backlinks__export')}
                              >
                                {this.props.t('download')}
                              </span>
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="dashboard-box-content dashboard-box-content-thin">
                        {this.props.audit &&
                        this.props.audit.backlinks &&
                        this.props.audit.backlinks.backlinks &&
                        this.props.audit.backlinks.backlinks.length > 0 ? (
                          <BacklinksPreview
                            backlinks={this.props.audit.backlinks.backlinks}
                          />
                        ) : (
                          // use <NoResults>
                          <div className="m-t--45 m-b--45 text-center light no-results">
                            <p>{this.props.t('backlinks__not_found')}</p>
                          </div>
                        )}
                      </div>
                    </WidgetContainer>
                  </div>

                  <div className="col-12 col-lg-6 dashboard-box">
                    <WidgetContainer>
                      <div className="dashboard-box-header dashboard-box-header--competitors justify-content-between">
                        <div className="dashboard-box-header__wrapper">
                          <span className="text-left">
                            {this.props.t('competitors__comparison')}

                            <span className="m-l--5">
                              <InfoIcon
                                id={'competitorInfo'}
                                text={
                                  <div>
                                    <p>{this.props.t('competitors__info')}</p>
                                  </div>
                                }
                              />
                            </span>
                          </span>
                        </div>
                        <div className="dashboard-box-header__wrapper">
                          <span
                            className="btn btn-as-link justify"
                            onClick={() => {
                              this.setState({
                                showCompetitorsKeywordsModal: true,
                              });
                            }}
                          >
                            {this.props.t('show_more')}
                          </span>
                        </div>
                      </div>
                      <div className="dashboard-box-content competitor-table">
                        {this.state.filteredCompetitors &&
                        this.state.filteredCompetitors.length ? (
                          this.state.filteredCompetitors.map(
                            (competitor, competitorIndex) => {
                              return (
                                <div
                                  key={competitor.domain}
                                  className={`competitor-table-row ${
                                    competitor.domain ===
                                    this.props.site.domainFriendly
                                      ? 'bg-gradient-primary competitor-table-row-highlight'
                                      : ''
                                  }`}
                                >
                                  <div className="competitor-item-icon d-none d-md-block">
                                    {competitor &&
                                    competitor.iconSrc &&
                                    typeof competitor.iconSrc === 'string' &&
                                    competitor.iconSrc.includes('https://') ? (
                                      <ImageFallback
                                        src={competitor.iconSrc}
                                        alt={`${competitor.domain} icon`}
                                        className="competitor-item-icon-src"
                                        fallbackSrc={faviconMissingSrc}
                                      />
                                    ) : (
                                      <img
                                        src={faviconMissingSrc}
                                        alt={`${competitor.domain} icon`}
                                        className="competitor-item-icon-src"
                                      />
                                    )}
                                  </div>
                                  <div className="competitor-item-name">
                                    {competitor.domain}
                                  </div>
                                  <div className={`competitor-item-number`}>
                                    <div
                                      className={`competitor-item-number-content competitor-item-number-content-${
                                        competitorIndex + 1
                                      }`}
                                    >
                                      {competitorIndex + 1}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )
                        ) : this.props.site.keywords &&
                          this.props.site.keywords.length ? (
                          <NoResultsContent
                            title={this.props.t(
                              'competitors__no_results_title'
                            )}
                            description={this.props.t(
                              'competitors__no_results_description'
                            )}
                          />
                        ) : (
                          <NoResultsContent
                            title={this.props.t(
                              'competitors__no_results_title'
                            )}
                            description={this.props.t(
                              'competitors__no_results_description_keywords'
                            )}
                          />
                        )}
                      </div>
                    </WidgetContainer>
                  </div>

                  <div className="col-12 col-lg-6 dashboard-box">
                    <WidgetContainer>
                      <div className="dashboard-box-header dashboard-box-header--savings justify-content-between">
                        <div className="dashboard-box-header__wrapper dashboard-box-header__wrapper-savings">
                          <span className="text-left">
                            {this.props.t('savings__top')}

                            <span className="m-l--5">
                              <InfoIcon
                                id={'savingsInfo'}
                                text={
                                  <div>
                                    <div className={'bold m-b--15'}>
                                      {this.props.t('savings__info_1')}
                                    </div>
                                    <p>{this.props.t('savings__info_2')}</p>
                                  </div>
                                }
                              />
                            </span>
                          </span>
                        </div>
                        <div className="d-flex align-items-center dashboard-box-header__wrapper">
                          <select
                            className="form-control rounded-border custom currency-change"
                            value={this.state.currencyModel.currency}
                            onChange={(event) => {
                              this.handleCurrencyChange(event.target.value);
                            }}
                          >
                            <option value="GBP">GBP</option>
                            <option value="EUR">EUR</option>
                            <option value="USD">USD</option>
                            <option value="AUD">AUD</option>
                            <option value="CAD">CAD</option>
                          </select>
                          <span
                            className="btn btn-as-link justify"
                            onClick={() => {
                              this.setState({
                                showKeywordSavingsModal: true,
                              });
                            }}
                          >
                            {this.props.t('show_more')}
                          </span>
                        </div>
                      </div>
                      <div className="dashboard-box-content savings-table">
                        {this.props.site &&
                        this.props.site.seoValueByKeyword ? (
                          <div className={'savings-padding'}>
                            {sortBy(
                              Object.keys(this.props.site.seoValueByKeyword),
                              (keyword) => {
                                return (
                                  -1 *
                                  Number(
                                    this.props.site.seoValueByKeyword[keyword]
                                  )
                                );
                              }
                            )
                              .slice(0, 3)
                              .map((keyword, index) => {
                                return (
                                  <div
                                    key={`keyword-savings-${keyword}`}
                                    className={'savings-table-row'}
                                  >
                                    <div className={'savings-name'}>
                                      "{keyword}"
                                    </div>
                                    <div className={'savings-number'}>
                                      {this.state.currencyModel.symbol}
                                      {Number(
                                        this.props.site.seoValueByKeyword[
                                          keyword
                                        ] /
                                          this.state.currencyModel.exchange_rate
                                      ).toFixed(0)}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        ) : (
                          <img
                            src={savingSrc}
                            className="m-t--10 m-b--20"
                            alt="SEO Savings"
                          />
                        )}

                        <div className="savings-info">
                          <div className="row align-items-center">
                            <div className="col-6 savings-amount" id="seoValue">
                              {`${this.state.currencyModel.symbol}0`}
                            </div>
                            <div className="col-6 savings-amount-info">
                              <div className="savings-amount-green">
                                {this.props.t('savings__saved_in')}
                              </div>
                              <div>
                                {format(subMonths(new Date(), 1), 'MMMM yyyy')}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </WidgetContainer>
                  </div>
                </div>

                {issuesLoaded && (
                  <div className="row">
                    <TopIssues
                      issues={issues}
                      audit={this.props.audit}
                      toggleIssueVisibility={this.toggleIssueVisibility}
                      togglePagesVisibility={this.togglePagesVisibility}
                      renderDifficulty={this.renderDifficulty}
                      onRequestHelpClick={this.onRequestHelpClick}
                      onAutoFixClick={this.onAutoFixClick}
                    />
                  </div>
                )}

                <div className="row gr-section">
                  <UptimeHistory uptime={this.props.site.uptime} />
                </div>

                <div className="row m-t--30"></div>
              </div>
            </div>
          </div>
        ) : (
          <div className="large-loading-box">
            <LoadingCircle />
          </div>
        )}

        <BacklinksModal
          show={this.state.showBacklinksModal || false}
          hideModal={() => {
            this.setState({ showBacklinksModal: false });
          }}
          backlinks={
            this.props.audit &&
            this.props.audit.backlinks &&
            this.props.audit.backlinks.backlinks
              ? this.props.audit.backlinks.backlinks
              : []
          }
        />

        <GenericModal
          show={this.state.showKeywordSavingsModal}
          size={'lg'}
          hideModal={() => {
            this.setState({
              showKeywordSavingsModal: false,
            });
          }}
          title={this.props.t('savings__monthly')}
        >
          <div className="dashboard-box">
            <div className="dashboard-box-content savings-table">
              {this.props.site && this.props.site.seoValueByKeyword ? (
                <DetailedSavingsTable
                  values={this.props.site.seoValueByKeyword}
                  currencyModel={this.state.currencyModel}
                  keywordsList={this.props.site.keywords}
                />
              ) : null}
            </div>
          </div>
        </GenericModal>

        <GenericModal
          show={this.state.showCompetitorsKeywordsModal}
          size="md"
          hideModal={() => {
            this.setState({
              showCompetitorsKeywordsModal: false,
            });
          }}
          title={this.props.t('competitors_table__modal_title')}
        >
          <div className="dashboard-box">
            <div className="dashboard-box-content savings-table">
              {this.props.site && this.props.site.keywords ? (
                <KeywordsTable keywords={this.props.site.keywords} />
              ) : null}
            </div>
          </div>
        </GenericModal>

        <GenericModal
          show={this.state.showEditKeywordsModal}
          size={'md'}
          hideModal={() => {
            this.setState({
              showEditKeywordsModal: false,
            });
          }}
          title={this.props.t('keywords__change')}
          submitAction={this.handleEditKeywordsSubmit}
          submitLabel={this.props.t('label__save')}
          submitClass="success"
        >
          <div className={'edit-keywords-container'}>
            {this.state.selectedKeywords &&
              this.state.selectedKeywords.map((selectedKeyword, index) => (
                <div key={index} className={'m-b--10'}>
                  <select
                    className="form-control"
                    value={selectedKeyword}
                    name={`selectedKeyword${index}`}
                    onChange={(e) => {
                      let selectedKeywordsUpdated = this.state.selectedKeywords;
                      selectedKeywordsUpdated[index] = e.target.value;
                      this.setState({
                        selectedKeywords: [...selectedKeywordsUpdated],
                      });
                    }}
                  >
                    {this.props.site &&
                      this.props.site.keywords
                        .filter(
                          ({ keyword }) =>
                            selectedKeyword === keyword ||
                            !this.state.selectedKeywords.includes(keyword)
                        )
                        .map(({ keyword }, index) => (
                          <option key={index}>{keyword}</option>
                        ))}
                  </select>
                </div>
              ))}
          </div>
        </GenericModal>
        <AutoFixModal
          show={this.state.showAutoFixModal}
          hideModal={() => {
            this.setState({
              showAutoFixModal: false,
              currentIssue: null,
            });
          }}
          title={i18n.t('audit_fix_issue')}
          size="lg"
          beta={
            this.props.audit && this.props.audit.detectedCMS === 'WordPress'
          }
        >
          <div className="auto-fix-modal">
            <AutoFix
              CMS={this.props.audit && this.props.audit.detectedCMS}
              currentIssue={this.state.currentIssue}
              site={this.props.site}
            />
          </div>
        </AutoFixModal>
        <RequestHelp
          show={this.state.showRequestHelpModal}
          hideModal={() => {
            this.setState({
              showRequestHelpModal: false,
              currentIssue: null,
            });
          }}
          currentIssue={this.state.currentIssue}
          audit={this.props.audit}
          user={this.props.user}
        />
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(SiteSummary);
