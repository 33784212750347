import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getListOfSites } from '../../helpers/utility';
import i18n from 'i18next';
import './site-unavailable.scss';
import { connect } from 'react-redux';
import { findIndex } from 'underscore';
import LoadingHourglass from '../../components/loading-hourglass/loading-hourglass';
import { removeUrlTrailingSlash } from '../../helpers/utility';

const mapStateToProps = (state) => {
  return {
    basicSiteList: state.sites.basicSiteList,
    loadingSiteList: state.sites.loadingSiteList,
    selfSignUp: state.authentication.user.selfSignUp,
  };
};

const SiteUnavailable = ({ basicSiteList, loadingSiteList, selfSignUp }) => {
  const dispatch = useDispatch();

  const selectedSite =
    sessionStorage && sessionStorage.getItem('selectedSite')
      ? removeUrlTrailingSlash(sessionStorage.getItem('selectedSite'))
      : '';

  useEffect(() => {
    // Return early if basicSiteList is unpopulated
    if (!basicSiteList || (!!basicSiteList && !basicSiteList.length)) {
      return;
    }

    if (!selectedSite) {
      // Redirect to #/ if no selectedSite
      window.location.href = '#/';
    } else if (basicSiteList.find(({ domain }) => domain === selectedSite)) {
      // Redirect to #/ if selectedSite is in basicSiteList
      window.location.href = '#/';
    }
  }, [basicSiteList, selectedSite]);

  // Dispatches getListOfSites every 5 minutes to update the basicSiteList and loadingSiteList state
  useEffect(() => {
    const interval = setInterval(() => {
      getListOfSites({ dispatch });
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  const getSelectedSiteData = () => {
    if (!selectedSite) {
      return null;
    }

    const selectedSiteIndex = findIndex(
      loadingSiteList,
      (site) =>
        !!(
          typeof selectedSite === 'string' &&
          site &&
          typeof site.domain === 'string' &&
          (site.domain.toLowerCase() === selectedSite.toLowerCase() ||
            site.domainFriendly === selectedSite.toLowerCase())
        )
    );

    if (selectedSiteIndex > -1) {
      console.log(`----- Detected pre-selected site`);
      return loadingSiteList[selectedSiteIndex].domainFriendly;
    }

    return null;
  };

  const displayLoadingSites = () =>
    !!loadingSiteList &&
    !!loadingSiteList.length &&
    loadingSiteList.map((site) => (
      <p key={site.domainFriendly}>- {site.domainFriendly}</p>
    ));

  return (
    <div
      className={`standard-page-layout d-flex align-items-center ${
        selfSignUp ? 'height-100vh-minus-footer' : 'height-100vh'
      }`}
    >
      <div className="container-fluid" id="site-unavailable">
        <div className="row gr-section">
          <div className="col-12 standard-box">
            <div className="box">
              {!!getSelectedSiteData() && (
                <div className="text-center">
                  <div className="mb-4">
                    <LoadingHourglass />
                  </div>
                  <p>{i18n.t('site__audit_in_progress')}</p>
                  <p>- {getSelectedSiteData()}</p>
                </div>
              )}

              {!getSelectedSiteData() && (
                <div className="text-center">
                  <div className="mb-4">
                    <LoadingHourglass />
                  </div>
                  <p>{i18n.t('site__audit_in_progress')}</p>

                  {displayLoadingSites()}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, null)(SiteUnavailable);
