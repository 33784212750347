import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import i18n from 'i18next';

import './autofix-modal.scss';

const AutoFixModal = ({
  show,
  size,
  hideModal,
  title,
  children,
  beta = false,
}) => (
  <Modal
    size={size || 'md'}
    centered={true}
    show={show}
    onHide={() => {
      hideModal();
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>
        {title}{' '}
        {beta && <span className="beta-badge badge badge-info">Beta</span>}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="scrollable-modal">{children}</Modal.Body>
    <Modal.Footer>
      <Button
        variant="outline-dark"
        onClick={() => {
          hideModal();
        }}
      >
        {i18n.t('generic_modal__close')}
      </Button>
    </Modal.Footer>
  </Modal>
);

export default AutoFixModal;
