import React from 'react';
import i18n from 'i18next';

import '../detailed-savings-table/detailed-savings-table.scss';

const KeywordsTable = ({ keywords }) => (
  <div className="table">
    <div className="tr th">
      <div className="td">{`${i18n.t('competitors_table__table_header')} (${
        keywords.length
      })`}</div>
    </div>
    {keywords.map(({ keyword }) => (
      <div
        className="tr"
        key={`kwd-${keyword.replace(/\s+/g, '-').toLowerCase()}`}
      >
        <div className="td">{keyword}</div>
      </div>
    ))}
  </div>
);

export default KeywordsTable;
