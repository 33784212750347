import React from 'react';

const AddSiteDescription = () => {
  return (
    <div className="add-site__description">
      <h2>Welcome to GoRank!</h2>
      <p>Get started by typing the URL of your website</p>
    </div>
  );
};

export default AddSiteDescription;
