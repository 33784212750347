import React, { Component } from 'react';
import i18n from 'i18next';
import add from 'date-fns/add';
import formatDistance from 'date-fns/formatDistance';
import format from 'date-fns/format';

import Button from 'react-bootstrap/Button';
import Panel from '../../components/panel/panel';
import SubscriptionPlan from '../../components/subscription-plan/subscription-plan';
import SubscriptionInvoices from '../../components/subscription-invoices/subscription-invoices';

import './user-account.scss';

class UserAccount extends Component {
  constructor(props) {
    super(props);

    if (this.props.user) {
      delete this.props.user.password;
    }

    let userState = Object.assign(
      {
        name: '',
        lastname: '',
      },
      this.props.user
    );

    this.state = {
      user: userState,
      userUpdateSuccess: false,
      plan: {
        start: null,
        end: null,
        cancel_at: null,
      },
      trial: {
        active: false,
        duration: 0,
      },
    };
  }

  handleInputChange = (e) => {
    let userState = Object.assign({}, this.state.user);

    userState[e.target.name] = e.target.value;
    this.setState({ user: userState });
  };

  calculatePlanDuration = ({ created, freeTrial, stripe }, trial = 30) => {
    // TODO: refactor
    let trialPeriodEnd = null;
    let trialRemainingDays = 0;
    let planPeriodStart = null;
    let planPeriodEnd = null;

    if (freeTrial) {
      trialPeriodEnd = add(new Date(created), { days: trial });
      trialRemainingDays = formatDistance(
        new Date(trialPeriodEnd),
        new Date(),
        {
          addSuffix: true,
        }
      );
      this.setState({ trial: { active: true, duration: trialRemainingDays } });
    }

    if (stripe && stripe.period_start && stripe.period_end) {
      planPeriodStart = format(new Date(stripe.period_start * 1000), 'do LLLL');
      planPeriodEnd = format(new Date(stripe.period_end * 1000), 'do LLLL');

      this.setState({
        trial: { active: false, duration: 0 },
        plan: {
          start: planPeriodStart,
          end: planPeriodEnd,
          cancel_at: stripe.cancel_at ? stripe.cancel_at : null,
        },
      });
    }
  };

  componentDidMount() {
    this.calculatePlanDuration(this.props.user);
  }

  render() {
    const {
      user: { name, lastname },
      userUpdateSuccess,
      plan,
      trial,
    } = this.state;
    const { email, _id, stripe } = this.props.user;

    return (
      <div className="container-fluid user-account">
        <div className="user-account__container">
          <Panel title="plan" className="user-account__plan">
            <SubscriptionPlan
              plan={plan}
              customerId={stripe && stripe.customer}
              userId={_id}
              userEmail={email}
              trial={trial}
            />
          </Panel>

          <Panel title="personal details" className="user-account__details">
            <form
              className="user-account__form"
              onSubmit={(event) => {
                const reloadUserData = this.props.reloadUserData;
                const userUpdateModel = {
                  ...this.state.user,
                };

                this.props.updateAccount(event, userUpdateModel, (success) => {
                  if (success) {
                    this.setState({ userUpdateSuccess: true });
                    reloadUserData();

                    setTimeout(() => {
                      this.setState({ userUpdateSuccess: false });
                    }, 4000);
                  }
                });
              }}
            >
              <div className="formInput">
                <label>Firstname</label>
                <input
                  className="form-control"
                  onChange={this.handleInputChange}
                  name="name"
                  value={name}
                  required
                />
              </div>
              <div className="formInput">
                <label>Lastname</label>
                <input
                  className="form-control"
                  onChange={this.handleInputChange}
                  name="lastname"
                  value={lastname}
                  required
                />
              </div>
              <div className="formInput">
                <label>Email</label>
                <input className="form-control" value={email} disabled />
              </div>
              <div className="formFooter">
                <Button type="submit" variant="success" className="m-t--10">
                  {userUpdateSuccess
                    ? i18n.t('account__saved')
                    : i18n.t('account__update')}
                </Button>
              </div>
            </form>
          </Panel>
          <Panel
            title="invoices"
            count={stripe && stripe.invoices.length}
            className="user_account__invoices"
          >
            <SubscriptionInvoices invoices={stripe && stripe.invoices} />
          </Panel>
        </div>
      </div>
    );
  }
}

export default UserAccount;
