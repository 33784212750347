import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import { buildKeywordScores } from '../../helpers/utility';

import RankingChart from '../ranking-chart/ranking-chart';
import NoResultsContent from '../no-results-content/no-results-content';

const RankingSummary = ({
  t,
  keywords,
  noKeywordDataAvailable,
  topDFSRankingSummary,
  historicDataAvailable,
  savedSiteKeywords,
}) => {
  if (noKeywordDataAvailable === true) {
    return <NoResultsContent description={t('ranking_info__no_results')} />;
  }

  if (historicDataAvailable === true) {
    const keywordScores = buildKeywordScores(topDFSRankingSummary);

    return <RankingChart keywordScores={keywordScores} />;
  }

  const keywordsToDisplay = () => {
    if (!savedSiteKeywords) {
      return keywords && keywords.length > 0
        ? keywords
            .filter(({ position }) => typeof position === 'number')
            .slice(0, 3)
        : [];
    }
    // Retrieves the ranking data for the savedSiteKeywords from site.keywords
    let savedSiteKeywordData = [];
    savedSiteKeywords.forEach((savedKeyword) => {
      savedSiteKeywordData.push(
        keywords.find(
          ({ keyword, position }) =>
            keyword === savedKeyword && typeof position === 'number'
        )
      );
    });
    return savedSiteKeywordData;
  };

  return (
    <Fragment>
      <div className="latest-ranking-container">
        {keywordsToDisplay().map(({ keyword, position }) => (
          <div className={`latest-ranking-score`} key={`keyword-${keyword}`}>
            <div className={`latest-ranking-bubble`}>
              <div className="latest-ranking-bubble-position">{position}</div>
              <div className="latest-ranking-bubble-rank-label">
                {t('ranking_summary__rank')}
              </div>
            </div>
            <div className="latest-ranking-keyword-label">{keyword}</div>
          </div>
        ))}
      </div>
      <div className="latest-ranking-info">
        <div className="col-12">
          <hr />
        </div>
        <h4 className="m-t--15">{t('ranking_info__data_visualisation')}</h4>
        <p>
          {t('ranking_info__announce_graph')}.{' '}
          {t('ranking_info__announce_graph_2')}!
        </p>
      </div>
    </Fragment>
  );
};

export default withTranslation()(RankingSummary);
