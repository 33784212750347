import { connect } from 'react-redux';
import axios from 'axios';
import i18n from 'i18next';
import {
  registerSuccess,
  registerError,
  loginSuccess,
} from '../../actions/index';
import Register from './register';
import {
  getOrganisation,
  getParameterByName,
  serverUrl,
  userHasLoggedInActions,
} from '../../helpers/utility';

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    organisation: state.organisation.organisation,
    registerError: state.register.error,
    registerErrorMessage: state.register.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    attemptRegister: (event, data) => {
      if (event) {
        event.preventDefault();
      }

      // Set source based on URL parameter, if one exists
      const source = getParameterByName('source');
      if (source && data) {
        console.log('---- Set sign-up source to: ' + source);
        data.source = source;
      } else {
        // Fallback to session information as source
        if (sessionStorage && sessionStorage.getItem('source') && data) {
          data.source = sessionStorage.getItem('source');
        }
      }

      // This is used with GetRewardful.com to power affiliate programs
      // https://app.getrewardful.com/setup/code
      if (window.Rewardful && window.Rewardful.referral) {
        data.referral = window.Rewardful.referral;
      }

      // Validate terms
      if (data.agreeToTerms !== true) {
        dispatch(
          registerError({
            message: i18n.t('register__error'),
          })
        );
        return false;
      }

      if (data && typeof data.organisationId === 'undefined') {
        data.organisationId = this.props.organisation._id;
      }

      axios
        .post(`${serverUrl}/user/register`, data)
        .then((response) => {
          if (response && response.data && response.data.success) {
            dispatch(registerSuccess(response));
            dispatch(loginSuccess(response.data.data.user));
            const user = response.data.data.user;

            // Post user login helper function (Zendesk, Team info, Google accounts)
            userHasLoggedInActions({ dispatch, user });

            getOrganisation({ dispatch, cacheBust: true });

            setTimeout(() => {
              window.location.href = '#/';
            }, 500);
          } else {
            dispatch(registerError({ message: response.data.message }));
          }
        })
        .catch(function (error) {
          // handle error
          dispatch(registerError({ message: error.message }));
        });
    },
  };
};

const RegisterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);

export default RegisterContainer;
