import React from 'react';
import i18n from 'i18next';

const SpeedFix = (props) => {
  return (
    <div>
      <div>
        <div>
          <h4>{i18n.t('speed_fix__1')}</h4>

          <p>{i18n.t('speed_fix__2')}</p>

          <h4>{i18n.t('speed_fix__3')}</h4>

          <p>
            {i18n.t('speed_fix__4')}
            <br />
            {i18n.t('speed_fix__5')}
          </p>

          <h4>{i18n.t('speed_fix__6')}</h4>

          <p>
            {i18n.t('speed_fix__7')}
            <br />
            <br />
            {i18n.t('speed_fix__8')}
            <br />
            <br />
            {i18n.t('speed_fix__9')}
          </p>

          <h4>{i18n.t('speed_fix__10')}</h4>

          <p>{i18n.t('speed_fix__11')}</p>

          <ul>
            <li>{i18n.t('speed_fix__12')}</li>
            <li>{i18n.t('speed_fix__13')}</li>
            <li>{i18n.t('speed_fix__14')}</li>
          </ul>

          <h4>{i18n.t('speed_fix__15')}</h4>

          <p>
            {i18n.t('speed_fix__16')}
            <br />
            <br />
            {i18n.t('speed_fix__17')}
            <br />
            <br />
            {i18n.t('speed_fix__18')}
          </p>

          <h4>{i18n.t('speed_fix__19')}</h4>

          <p>
            {i18n.t('speed_fix__20')}
            <br />
            {i18n.t('speed_fix__21')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SpeedFix;
