import React, { Component } from 'react';
import axios from 'axios';
import i18n from 'i18next';
import { serverUrl } from '../../helpers/utility';
import { toast } from 'react-toastify';
import classnames from 'classnames';

import GenericModal from '../../components/generic-modal/GenericModal';

import './request-help.scss';

const Header = ({ currentIssue, pages }) => (
  <ul className="request-help__header">
    <li>
      <span>{i18n.t('request_help__header_li')}:</span>{' '}
      {currentIssue && currentIssue.failName}
    </li>
    <li>
      <span>{i18n.t('request_help__header_li_2')}:</span>{' '}
      {currentIssue && currentIssue.description}
    </li>
    <li>
      <span>{i18n.t('request_help__header_li_3')}:</span>{' '}
      {pages.length > 0 ? pages.length : 'n/a'}
    </li>
  </ul>
);

const Description = () => (
  <h4 className="request-help__description">
    {i18n.t('request_help__description_p')}
  </h4>
);

const Error = ({ error }) => {
  switch (error.name) {
    case 'Bad Request':
      return (
        <p className="request-help__error-generic">
          {i18n.t('request_help__error_bad_request')}
        </p>
      );
    case 'Unauthorized':
      return (
        <p className="request-help__error-generic">
          {i18n.t('request_help__error_unauthorized')}
        </p>
      );
    case 'RequestError':
      return (
        <p className="request-help__error-generic">
          {i18n.t('wp_auto__request_error')}
        </p>
      );
    default:
      return null;
  }
};

const Form = ({
  handleInputChange,
  name,
  subject,
  to,
  emailClasses,
  cc,
  comments,
  error,
}) => (
  <>
    <div className="formInput">
      <label>
        {i18n.t('request_help__form_subject')}{' '}
        <span className="input-required">*</span>
      </label>
      <input
        name="subject"
        className={emailClasses}
        placeholder={i18n.t('request_help__form_subject_placeholder')}
        type="text"
        autoFocus={true}
        value={subject}
        onChange={handleInputChange}
        maxLength="100"
        required={true}
      />
    </div>
    <div className="formInput">
      <label>
        {i18n.t('request_help__form_to')}{' '}
        <span className="input-required">*</span>
      </label>
      <input
        name="to"
        className={emailClasses}
        placeholder={i18n.t('request_help__form_to_placeholder')}
        type="email"
        value={to}
        onChange={handleInputChange}
        maxLength="100"
        required={true}
      />
    </div>
    <div className="formInput">
      <label>{i18n.t('request_help__form_cc')}</label>
      <input
        name="cc"
        className={emailClasses}
        placeholder={i18n.t('request_help__form_cc_placeholder')}
        type="email"
        value={cc}
        onChange={handleInputChange}
        maxLength="100"
      />
    </div>
    <div className="formInput">
      <label>
        {i18n.t('request_help__form_name')}{' '}
        <span className="input-required">*</span>
      </label>
      <input
        name="name"
        className={emailClasses}
        placeholder={i18n.t('request_help__form_name_placeholder')}
        type="text"
        value={name}
        onChange={handleInputChange}
        maxLength="100"
        required={true}
      />
    </div>
    <div className="formInput">
      <label>{i18n.t('request_help__form_comments')}</label>
      <textarea
        name="comments"
        onChange={handleInputChange}
        placeholder={i18n.t('request_help__form_comments_placeholder')}
        value={comments}
        maxLength="500"
        className="form-control"
      />
    </div>
    <Error error={error} />
    <span className="form-asterisk">
      <span className="input-required">*</span>
      {i18n.t('request_help__form_required')}
    </span>
  </>
);

const findPages = (issue, pages) =>
  issue && issue.findPages && pages && pages.length
    ? pages.filter(issue.findPages)
    : [];

class RequestHelp extends Component {
  constructor() {
    super();

    this.state = {
      name: '',
      subject: '',
      to: '',
      cc: '',
      comments: '',
      error: {
        name: '',
        message: '',
      },
      pages: [],
    };
  }

  componentDidMount() {
    this.setState({
      pages: findPages(
        this.props.currentIssue,
        this.props.audit && this.props.audit.pages
      ),
      name: this.props.user.name || '',
      cc: this.props.user.email,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentIssue !== prevProps.currentIssue) {
      this.setState({
        pages: findPages(
          this.props.currentIssue,
          this.props.audit && this.props.audit.pages
        ),
      });
    }
  }

  handleSubmit = (e) => {
    this.setState({
      error: {
        name: '',
        message: '',
      },
    });

    const payload = {
      user: {
        _id: this.props.user._id,
        currentOrganisationId: this.props.user.currentOrganisationId,
        name: this.state.name,
        email: this.state.cc.toLowerCase(),
        permissions: this.props.user.permissions,
      },
      email: {
        subject: this.state.subject,
        domain: this.props.audit.domain,
        recipient: this.state.to,
        issue: {
          name: this.props.currentIssue.failName,
          description: this.props.currentIssue.description,
          pages: this.state.pages,
        },
        comments: this.state.comments,
      },
    };

    axios
      .post(`${serverUrl}/email/issue`, payload)
      .then((response) => {
        if (response && response.data && response.data.success) {
          //  close modal and send success toast
          this.props.hideModal();
          toast.success(i18n.t('request_help__success_toast'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          if (err.response.data.error && err.response.data.message) {
            console.log(err.response.data);
            this.setState({
              error: {
                name: err.response.data.error,
                message: err.response.data.message,
              },
            });
          }
        } else if (err.request) {
          // axios generated error
          this.setState({
            error: {
              name: 'RequestError',
              message: 'wp_auto__request_error',
            },
          });
        }
      });
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { currentIssue } = this.props;
    const { name, subject, to, cc, comments, error, pages } = this.state;

    const emailClasses = classnames({
      'form-control': true,
    });

    return (
      <GenericModal
        title={i18n.t('request_help__cta')}
        size="lg"
        show={this.props.show}
        hideModal={this.props.hideModal}
        submitAction={this.handleSubmit}
        submitLabel={i18n.t('request_help__form_send')}
      >
        <div className="request-help">
          <Description />
          <Header currentIssue={currentIssue} pages={pages} />
          <Form
            handleInputChange={this.handleInputChange}
            handleToChange={this.handleToChange}
            handleCCchange={this.handleCCchange}
            handleCommentsChange={this.handleCommentsChange}
            name={name}
            subject={subject}
            to={to}
            cc={cc}
            comments={comments}
            emailClasses={emailClasses}
            error={error}
          />
        </div>
      </GenericModal>
    );
  }
}

export default RequestHelp;
