const resources = {
  en: {
    translation: {
      monthly_savings: 'Monthly estimated savings',
      welcome: 'Welcome',
      show_less: 'Show less',
      show_more: 'Show more',
      download: 'Download',
      ranking_graph__rank_unknown: "Rank movement couldn't be calculated",
      ranking_graph__last_update: 'Last update:',
      ranking_graph__go_back: 'Back',
      ranking_graph__calculation_error: "We couldn't calculate the rank of",
      ranking_graph__try_again_one_week: "Try again in a week's time",
      ranking_graph__open_graph: 'Open graph',
      ranking_graph__rank_no_movement: "Rank hasn't moved since last week",
      ranking_graph__down: 'Down',
      ranking_graph__up: 'Up',
      ranking_graph__positions_last_week: 'positions since last week',
      ranking_summary__not_ranking:
        'It does not look like you are ranking for this term yet.',
      ranking_summary__updating: 'Ranking information is still being updated.',
      ranking_summary__rank: 'Rank',
      ranking_info__data_visualisation: 'Data Visualisation',
      ranking_info__announce_graph:
        'Great news, once we have enough information recorded we will present your information in an interactive chart here',
      ranking_info__announce_graph_2: 'Watch this space',
      ranking_info__no_results:
        'You are not ranking for any popular keywords just yet',
      export_csv__url: 'URL',
      export_csv__page_title: 'Page Title',
      export_csv__trust_flow: 'Trust Flow',
      export_csv__citation_flow: 'Citation Flow',
      export_csv__target_url: 'Target URL',
      export_csv__date_discovered: 'Date Discovered',
      export_csv__date_removed: 'Date Removed',
      export_csv__link_type: 'Link Type',
      search_engine__label:
        "We're making sure all pages of your website are being found on",
      search_engine__info_icon:
        'Get found on all the major search engines, we take away the complexity of getting your website found everywhere online and have now listed you on the most popular search engines available.',
      search_engine__index_completed: 'Index completed',
      search_engine__index_pending: 'Index pending',
      keywords__edit: 'Edit',
      keywords__ranking_on:
        'Ranking position on search engines when searching on these keywords',
      keywords__graph_after_7_days:
        'We have found 3 popular keywords which we know send traffic to your website. After 7 days your data will be shown in a live graph. Please allow some time after adding new keywords for data to be updated.',
      keywords__change: 'Change your keywords',
      keywords__modal_info:
        'Your keywords have been updated recently, please come back to this page in 10 minutes to see your results.',
      sitemap_fix__1: 'We detected and added a total of',
      sitemap_fix__2: 'pages to your sitemap.',
      sitemap_fix__3: 'Download Sitemap',
      speed_fix__1: 'What Do I Do About My Website Loading Slowly?',
      speed_fix__2:
        'If one thing is true about your website, it’s that loading speed matters. When your site loads slowly you’ll not only provide a poor user experience, but you’ll also rank lower in the search engines.',
      speed_fix__3: '1. Enable Caching',
      speed_fix__4:
        'Whenever you visit a website, certain elements are stored in a cache, so the next time you visit the site it can easily access those parts and load much faster. With caching, instead of your browser having to download every single resource, it only has to download a few of them.',
      speed_fix__5:
        'By turning on caching you can considerably improve your site’s loading for return visitors. If you’re using a CMS like WordPress you can install a plugin like W3 Total Cache or W3 Super Cache, either of which will let you enable sitewide caching, or caching of certain site elements.',
      speed_fix__6: '2. Remove Resource-Hogging Plugins and Add-ons',
      speed_fix__7:
        'If your site is currently running too many plugins you could potentially be slowing down your website. You might require a handful of plugins for your site to function the way you like, but chances are there are some you can live without, especially if they’re resource hogs.',
      speed_fix__8:
        'The best way to do this is to get a baseline test of your loading speed via a tool like GTMetrix or Google Pagespeed Insights. Then, go down your plugin list and deactivate one plugin at a time. Then, run the speed test with the plugin deactivated.',
      speed_fix__9:
        'This might be time-consuming, but it will help you find the plugins that are harming your site’s loading speed the most. At that point, you can search for a less resource-heavy plugin or find another workaround.',
      speed_fix__10: '3. Optimize and Reduce the Size of Your Images',
      speed_fix__11:
        'If your site has tons of images that aren’t optimized, then this will negatively impact your site’s loading speed. By having oversized images you’ll be requiring the browser to load larger files. There are a few different ways you can optimize your images to load faster.',
      speed_fix__12:
        'Ensure that your images are not unnecessarily large. For example, if the width of your blog page is 900px, then make sure that’s how wide your images are.',
      speed_fix__13:
        'If you’re using a CMS like WordPress, you can install a plugin like WPSmush that will automatically reduce the filesize of the images.',
      speed_fix__14:
        'Before you upload images, first run them through a tool called Tiny PNG to reduce the file size of your image, without sacrificing the quality.',
      speed_fix__15: '4. Minimize Your Code',
      speed_fix__16:
        'Sometimes your website’s code can get a little bit messy. When this happens your site will take much longer to load. When you’re making a lot of customizations, using a CMS, or even using a website builder to create your site there will be unnecessary line breaks, spaces, and other elements that don’t need to be there.',
      speed_fix__17:
        'If you’re using WordPress, then a plugin like Better WordPress Minify will minimize your code. Or, if you’re using one of the caching plugins highlighted above, then there should also be a minify option.',
      speed_fix__18:
        'If you aren’t using a CMS, you can minify your code by using the Pagespeed Insights Chrome Extension. This extension will create a minimized version of your code, so you can see which version is faster.',
      speed_fix__19: '5. Use a CDN',
      speed_fix__20:
        'The loading speed of your site is affected by the proximity of the user to the server where your site’s files are stored. The farther away they are from this physical location, the slower your site will load. Using a CDN can solve this problem.',
      speed_fix__21:
        'A CDN distributes your site’s files across a network of global servers, that way your users can access your site via the server that’s closest to them.',
      sitemap: "We've added",
      sitemap__2: 'pages to your',
      sitemap__3: 'Sitemap',
      backlinks: 'backlinks',
      backlinks__from: 'from',
      backlinks__websites: 'websites',
      backlinks__info:
        'A backlink is simply a link from one website to another. Search engines like Google use backlink as a ranking signal because when one website links to another, it means they believe the content is noteworthy.',
      backlinks__export: 'Export the top 100 backlinks',
      backlinks__not_found: 'No Backlinks Found',
      competitors__comparison: 'Keyword Competitors',
      competitors__info:
        "Benchmark your business vs your competitors, we track your overall visibility for your targeted keywords, the leaderboard will update when you and your competitors increase or decrease in rankings. Click on 'Show more' to see the list of keywords that we use to find, and update your competitors.",
      competitors__no_results_title: 'No competitors found',
      competitors__no_results_description:
        "We haven't found any direct competitors.",
      competitors__no_results_description_keywords:
        "We haven't found any direct competitors, because your site does not have any associated keywords.",
      savings__top: 'The value of your organic traffic',
      savings__info_1: 'How are your estimated savings calculated?',
      savings__info_2:
        'Average position of keywords we found for your business multiplied by average cost per click on Google Ads and estimated traffic volume.',
      savings__saved_in: 'Saved in',
      savings__monthly: 'Monthly estimated savings',
      alerts__stay_informed: 'Stay Informed',
      alerts: 'Alerts',
      alerts__info:
        'These essential alerts will keep your website at the forefront of your mind, ensuring you stay relevant, found and secure online.',
      alerts__downtime: 'Downtime Alert',
      alerts__downtime_info:
        "Get notified when your website needs attention, don't lose business when your website is offline.",
      alerts__security: 'Security Alert',
      alerts__security_info:
        'Bots and hackers are everywhere, be made aware when your website is vulnerable to the bad guys.',
      alerts__competitor: 'Competitor Alert',
      alerts__competitor_info:
        'Knowledge is power; you will be the first to know when your competitors get ahead or fall behind.',
      alerts__crawling: 'Crawling Alert',
      alerts__crawling_info:
        'If your website cannot be crawled then you will lose all visibility to millions of people online, we will let you know in a blink of an eye.',
      label__save: 'Save',
      account__settings: 'Settings',
      account__firstname: 'First Name',
      account__lastname: 'Last Name',
      account__email: 'Email',
      account__password: 'Password',
      account__phone: 'Phone',
      account__saved: 'Saved',
      account__update: 'Update',
      admin_clients__total_label: 'Total clients',
      admin_clients__total_title:
        'Total number of client websites we are optimising',
      admin_clients__paid_label: 'Paid clients',
      admin_clients__paid_title:
        'Paid subscriptions that are not on a free trial',
      admin_clients__trial_label: 'Free trials',
      admin_clients__trial_title: 'Number of clients on a free trial',
      admin_clients__import: 'Import Clients',
      admin_clients__manage: 'Manage Users',
      admin_clients__settings: 'Settings',
      admin_clients__website: 'Website',
      admin_clients__email: 'Client Email',
      admin_clients__status: 'Status',
      admin_clients__added: 'Added',
      admin_clients__cancelled: 'Cancelled',
      admin_clients__active_subscription: 'Active Subscription',
      admin_clients__trial: 'Trial',
      admin_clients__active: 'Active',
      admin_clients__link: 'Link',
      admin_clients__view: 'View',
      admin_clients__processing: 'Processing',
      admin_clients__actions: 'Actions',
      admin_clients__remove: 'Remove',
      admin_clients__demo_data:
        'Load demo data for testing purposes. Backfill the historic rankings data for selected site.',
      admin_clients__data: 'Data',
      admin_clients__remove_confirm_title: 'Confirm Removal',
      admin_clients__remove_confirm: 'Are you sure you wish to remove the site',
      admin_import__back: 'Back',
      admin_import__sites: 'Import Sites',
      admin_setup__back: 'Back',
      admin_setup__settings: 'Settings',
      admin_setup__business_name: 'Business Name',
      admin_setup__domain: 'Domain',
      admin_setup__domain_description:
        'The domains where your version of the app is hosted. Max. 10 domains.',
      admin_setup__send_from: 'Send From Email Address',
      admin_setup__send_from_description:
        'Contact us to change this field, we need to validate your domain.',
      admin_setup__shared_secret: 'Shared secret',
      admin_setup__custom_css: 'Custom Stylesheet',
      admin_setup__advanced: 'Advanced',
      admin_setup__css_page: 'Custom CSS page to load.',
      admin_setup__webhook_endpoints: 'Webhook Subscriber Endpoints',
      admin_setup__webhooks_description:
        'Endpoints to receive webhook alerts. Accepts comma separated list.',
      admin_setup__saved: 'Saved!',
      admin_setup__update: 'Update',
      admin_setup__logo_dark: 'Logo (Dark)',
      admin_setup__logo_dark_description:
        'Dark version, for the login page and light backgrounds.',
      admin_setup__no_image: 'No image has been added yet',
      admin_setup__drag_and_drop:
        "Drag 'n' drop some files here, or click to select files",
      admin_setup__favicon: 'Favicon',
      admin_setup__not_allowed: 'Not allowed',
      admin_setup__no_permission:
        'Sorry, you do not have permission to view this page.',
      admin_setup__account_owner_only:
        'Only the account owner can view this particular page.',
      admin_stats__users: 'Users',
      admin_stats__page_scanned: 'Pages Scanned',
      admin_stats__pages: 'Pages',
      admin_stats__audits: 'Audits',
      admin_stats__order_count: 'Order Count',
      admin_stats__order: 'Order',
      admin_stats__order_value: 'Order Value (£)',
      admin_stats__keywords: 'Keywords',
      admin_stats__proposals: 'Proposals',
      admin_stats__proposals_sent: 'Proposals Sent',
      admin_stats__sent_proposals: 'Sent Proposals',
      admin_stats__proposals_completed: 'Proposals Completed',
      admin_stats__completed_proposals: 'Completed Proposals',
      admin_stats__proposal_count_30_days: 'Proposal Count (Last 30 days)',
      admin_stats__proposal_value_30_days: 'Proposal Value (Last 30 days)',
      admin_stats__count: 'Count',
      admin_stats__value: 'Value (£)',
      admin_stats__download_png: 'download PNG',
      admin_stats__download_svg: 'download SVG',
      admin_stats__download_pdf: 'download PDF',
      admin_users__back: 'Back',
      admin_users__email: 'Email',
      admin_users__name: 'Name',
      admin_users__auth_google: 'Authenticated with Google',
      admin_users__logins: 'Logins',
      admin_users__status: 'Status',
      admin_users__actions: 'Actions',
      admin_users__edit: 'Edit',
      add_domain: 'Add domain',
      add_domain__up_to_10: 'Add up to 10 domains',
      add_domain__remove_last: 'Remove last domain',
      add_domain__remove: 'Remove domain',
      how_are_your_savings_calculated:
        'How are your estimated savings calculated?',
      login__sign_in: 'Sign In',
      login__email: 'Email Address',
      login__forgot_pwd: 'Forgot Password?',
      login__error: 'Sorry, you entered an incorrect username or password.',
      login__failed: 'Login failed',
      register: 'Register',
      register__account_created: 'Account Created',
      register__creating_account: 'Creating Your Account...',
      register__please_wait_1: 'Please wait',
      register__please_wait_2: 'seconds.',
      register__registered: 'You have already registered.',
      register__error: 'You must accept our terms and conditions to register.',
      register__online_visibility: 'Boost your Online Visibility',
      register__detect_issues: 'Detect Website Issues',
      register__keyword_research: 'Keyword Research',
      register__issue_monitoring: 'Issue Monitoring',
      register__insights: 'Search Insights',
      register__much_more: 'Plus Much More',
      register_form__create_free_account: 'Create Your Free Account',
      register_form__name: 'Your Name',
      register_form__email: 'Email',
      register_form__password: 'Password',
      register_form__agency_name: 'Agency name',
      register_form__domain: 'Your domain',
      register_form__domain_error: 'Sorry that subdomain is not available.',
      register_form__domain_success: 'Perfect! That subdomain is available',
      register_form__terms_1: 'I agree to the',
      register_form__terms_2: 'Terms and conditions',
      register_form__already_have_account: 'Already have an account? Login now',
      register_form__partner_account: 'Create Partner Account',
      register_agency__terms_error:
        'You must accept our terms and conditions to register.',
      register_agency__generic_error: 'Sorry an error occurred',
      register_agency__audit_lead:
        'Fully-branded audit and lead generation app.',
      register_agency__unlimited_audits: 'Unlimited Audits',
      register_agency__reporting: 'Client Reporting',
      register_agency__branding: 'Custom Branding',
      register_agency__sales_proposals: 'Sales Proposals',
      register_agency__no_cost_upfront: 'No Upfront Cost',
      register_agency__try_again_later:
        "If the link doesn't immediately work, please try again in an hour.",
      register_agency__terms_1: 'I accept the',
      register_agency__terms_2: 'agency terms and conditions',
      site__audit_pending: 'Site Audit Pending',
      site__audits_pending: 'Site Audits Pending',
      site__site_not_found: 'Site Not Found',
      site__sites_not_found: 'No Sites Found',
      site__site_not_found_message:
        'We could not find your site. Please contact support.',
      site__sites_not_found_message:
        'We could not find any sites associated with your account. Please contact support.',
      theme__all_fields_required: 'Please complete all fields',
      theme: 'Theme',
      theme__back: 'Back',
      theme__customise: 'Customise your theme.',
      theme__sidebar_bg: 'Sidebar background colour',
      theme__hex: 'Hex colour code',
      theme__header_colour: 'Header Text Colour',
      theme__primary_colour: 'Primary Text Colour',
      theme__primary_button_colour: 'Primary Button Colour',
      theme__custom_font: 'Custom Font',
      theme__select: 'Select',
      theme__disclaimer_1: 'Note: It may take',
      theme__disclaimer_2: 'up to an hour',
      theme__disclaimer_3:
        'before the theme is visible to everyone due to browser caching.',
      theme__saved: 'Saved!',
      theme__save: 'Save',
      theme__preview: 'Preview',
      theme__title: 'Title',
      theme__ok: 'OK',
      audit_result__pages: 'Pages',
      audit_result__page: 'Page',
      audit_result__pages_impacted: 'Number of pages impacted',
      audit_result__easy: 'Easy',
      audit_result__easy_fix: 'Easy to fix',
      audit_result__medium: 'Medium',
      audit_result__medium_fix: 'Medium difficulty',
      audit_result__difficult: 'Difficult',
      audit_result__difficult_fix: 'Difficult to fix',
      audit_result__explanation: 'Explanation',
      audit_result__advice: 'Advice',
      audit_result__export_list: 'Export List',
      audit_section__yes: 'Yes',
      audit_section__no: 'No',
      audit_section__pass: 'Pass',
      audit_section__fail: 'Fail',
      audit_section__show_pages_with_issue: 'Show pages with issue',
      audit_section__page_url: 'Page URL',
      audit_section__last_scan: 'Last scan',
      audit_section__ago: 'ago',
      audit_section__fix: 'Auto fix',
      audit_section__learn: 'Learn to fix',
      audit_section__what_we_found: 'What have we found?',
      audit_section__average_speed: 'Average speed',
      audit_section__average_speed_description:
        'Search engines gives preferential treatment to websites that load quickly. The faster your site loads, the lower the bounce rate. If your site is fast, you have a better chance of ranking on search engines over slow sites that drive high bounce rates.',
      audit_section__is_site_fast: 'Is your site loading fast?',
      audit_section__try_again_later: 'No data yet, try again later.',
      audit_section__security: 'Security',
      audit_section__is_site_secure: 'Is your site secure?',
      audit_section__pages_analyzed: 'Pages analyzed',
      audit_section__pages_on_site: 'The pages on your site',
      audit_section__mobile_speed: 'Mobile speed',
      audit_section__mobile_loading_time:
        "Your site's loading time on a mobile device",
      audit_section__mobile_ready: 'Mobile ready?',
      audit_section__mobile_site_works:
        'Your site works correctly on mobile devices',
      audit_section__no_screenshot: 'Screenshot unavailable',
      audit_section__what_to_look_at: 'What do you need to look at?',
      audit_section__no_issues: 'No issues found.',
      audit_section__what_done_well: 'What have you done well?',
      audit_summary__overall_score:
        'Your overall score is an average of your four specific scores detailed below.',
      audit_summary__seo: 'Search Engine Optimization (SEO)',
      audit_summary__seo_2: 'SEO performance',
      audit_summary__seo_bis: 'Search Engine Optimization',
      audit_summary__perf: 'Performance',
      audit_summary__perf_2: 'Load speed',
      audit_summary__security: 'Security',
      audit_summary__security_2: 'Site security',
      audit_summary__mobile: 'Mobile friendly',
      audit_summary__mobile_2: 'How easy your site is to use on mobile',
      audit_summary__pending: 'Scoring pending. Please try again later.',
      audit_tab_menu__seo: 'SEO',
      audit_tab_menu__performance: 'Performance',
      audit_tab_menu__security: 'Security',
      audit_tab_menu__mobile: 'Mobile',
      backlink_modal__backlinks: 'Backlinks',
      backlink_modal__website: 'Website',
      backlink_modal__trust_flow: 'Trust Flow',
      backlink_modal__citation_flow: 'Citation Flow',
      backlink_modal__detected: 'Detected',
      backlink_modal__detected_on: 'Detected on',
      backlink_modal__close: 'Close',
      generic_modal__close: 'Close',
      generic_modal__ok: 'OK',
      google_button__sign_in: 'Sign in with Google',
      radial_chart__score: 'SCORE',
      header__error: 'Sorry an error occurred',
      header__dashboard: 'Dashboard',
      header__audit: 'Audit',
      header__alerts: 'Alerts',
      header__admin: 'Admin',
      header__policy: 'Policy',
      header__terms: 'Terms',
      import_modal__title: 'Import',
      import_modal__step_1: '1) Optionally download our template file',
      import_modal__step_2: '2) Upload the spreadsheet to import',
      import_modal__step_2_def:
        'We support the following file types: CSV, XLS and XLSX',
      import_modal__download_template: 'Download Template',
      import_modal__upload: 'Upload',
      import_modal__close: 'Close',
      import_modal__next: 'Next',
      import_wizard__template_error:
        'Sorry, the spreadsheet you uploaded is not valid. Please ensure all required columns exist or download our template.',
      import_wizard__import_clients: 'Import Clients',
      import_wizard__confirm: 'Confirm',
      no_results: 'No results available',
      no_results__not_discovered:
        'This could be because this website has not been discovered by Google.',
      permissions_modal__edit: 'Edit Permissions',
      permissions_modal__tip:
        'Tip: Users may need to logout to see any new permissions you assign.',
      permissions_modal__select: 'Select',
      permissions_modal__user: 'User',
      permissions_modal__admin: 'Admin',
      permissions_modal__acc_owner: 'Account Owner',
      permissions_modal__fulfilment: 'Application Fulfilment',
      permissions_modal__close: 'Close',
      permissions_modal__update: 'Update',
      rate_limit__too_many_req: 'Too Many Requests',
      rate_limit__try_again: 'Sorry, please try again later.',
      site_task__auto_plan: 'Automation Plan Active',
      site_task__monitoring:
        'We are monitoring your site for any changes to ensure the best user experience and load time.',
      site_task__fast_load: 'Lightning fast load times',
      site_task__seo: 'SEO and markup fixes',
      site_task__img_opti: 'Image optimisation',
      site_task__sitemap: 'Sitemap generation',
      site_task__server_config: 'Server configuration',
      site_task__seo_plan: 'SEO Plan Active',
      site_task__increased_vis:
        'Our team of experts are working to increase your online visibility. Current tasks:',
      site_task__completed: 'Task completed',
      site_task__in_progress: 'Task in progress for page:',
      site_task__view_all: 'View All',
      audit_doctype__descr:
        'Doc type is the first element in the source code of a web page and should be part of every HTML document. Without one your browser enters a rather strange mode. It looks like you don’t have a Doctype, and while you can fix this yourself, it’s a little tricky unless you know how to code. We suggest sending this to your developer to resolve.',
      audit_doctype__fail_msg:
        'Uh oh, your Doc type needs to be setup correctly',
      audit_doctype__fail_name: 'No Doc type',
      audit_doctype__pass_msg: 'Great work, your Doc type is setup correctly',
      audit_doctype__pass_name: 'Doc Type',
      audit_meta__descr:
        'Meta tags are snippets of text that describe a page’s content in the page’s source code. Although meta tags don’t appear on the page itself, they are little content descriptors that tell search engines like Google what a web page is about. Meta tags only exist in HTML, usually at the ‘head’ of the page, and so are only visible to search engines (and people who know where to look). You need to add meta tags.',
      audit_meta__fail_msg:
        'Your meta tags seem to be missing on $$issuecount page(s)',
      audit_meta__fail_name: 'No Meta Tags',
      audit_meta__pass_msg: 'Your meta tags are looking fine',
      audit_meta__pass_name: 'Meta Tags',
      audit_h1__descr:
        'No ‘H1’ tags (headings) were found on your page. However, these are an important element of any website as they structure content and text in a meaningful and hierarchical way. Headings significantly improve the readability of content for visitors of a site. This works similarly with search engines, as they give Google an overview of the page content and the weighting of individual subtopics. You should add H1s to your website by accessing your content management system and looking for the headers option.',
      audit_h1__fail_msg:
        'Uh oh, you seem to be missing H1 tags on $$issuecount pages(s)',
      audit_h1__fail_name: 'Missing Page Title (H1)',
      audit_h1__pass_msg:
        'All your H1 tags seem to be visible and correctly formatted',
      audit_h1__pass_name: 'Page Title (H1)',
      audit_compression__descr:
        "The smaller the files needed to load a web page, the faster the complete page can be loaded. Compressing HTML, CSS, and JavaScript files therefore helps to improve your page speed. The compression of such files is possible by shortening code, for example by removing comments, line breaks, unnecessary characters, and spaces. In addition, file sizes can be compressed for transfer on the server side using Gzip (a file format and a software application used for file compression and decompression). Compression such as 'Gzip' is either not enabled or configured for your site. You need to change this. However, as this task may be challenging or restricted on your CMS, we suggest you send this task to your developers.",
      audit_compression__fail_msg:
        'You seem to have compression disabled. It might be tricky to fix but it will be well worth it.',
      audit_compression__fail_name: 'Compression Disabled',
      audit_compression__pass_msg:
        'It looks like your compression is enabled, great stuff.',
      audit_compression__pass_name: 'Compression Enabled',
      audit_content_rate__descr:
        'Only a small amount of content exists compared to the HTML on the page. Like thin content, invalid content is content that has little or no value to the user. Google considers doorway pages, low-quality affiliate pages, or simply pages with very little or no content as such pages. Solve this by producing high-quality, relevant, and original content.',
      audit_content_rate__fail_msg:
        'Uh oh! You still need to add more content on $$issuecount page(s)',
      audit_content_rate__fail_name: 'Invalid Content',
      audit_content_rate__pass_msg: 'Good work! That content looks lovely',
      audit_content_rate__pass_name: 'No Invalid Content',
      audit_content_size__descr:
        'Thin content is content that has little or no value to the user. Google considers doorway pages, low-quality affiliate pages, or simply pages with very little or no content as thin content pages. But don’t fall into the trap of just producing loads of very similar content — non-original pages and pages with scraped and duplicate content are considered thin content pages too. On top of that, Google doesn’t like pages that are stuffed with keywords either and can now distinguish between valuable and low-quality content. Solve this by producing high-quality, relevant, and original content.',
      audit_content_size__fail_msg:
        "Uh oh! Looks like you've still got some thin content on $$issuecount page(s). Fixing it will improve your Google ranking and make your site more user friendly",
      audit_content_size__fail_name: 'Thin Content Found',
      audit_content_size__pass_msg:
        'Nice work! All of the pages on your site are showing unique, quality content',
      audit_content_size__pass_name: 'No Thin Content Found',
      audit_content_read__descr:
        'The content on your page may be difficult to read. There are plenty of algorithms that are designed to measure content readability (this is what robots and search engines use too). One of the most popular algorithms for calculating content readability is the Flesch-Kincaid readability score. Other algorithms include Gunning-Fog, Coleman-Liau, SMOG index, etc. You need to improve the readability of your content.',
      audit_content_read__fail_msg:
        'The content on $$issuecount page(s) is still hard to read. It can be tricky to fix but is definitely worthwhile if you want your users to engage with your site',
      audit_content_read__fail_name: 'Poor Readability',
      audit_content_read__pass_msg:
        'Great work! Your content is easy to read and likely to engage your users',
      audit_content_read__pass_name: 'Good Readability',
      audit_html_old__descr:
        'Your site contains HTML tags that are not supposed to be used anymore. Generally, ‘deprecated’ means ‘something disapproved’. In IT, deprecated elements are those elements that are allowed, but not recommended and are being replaced by newer ones. So, a ‘deprecated code’ means that it is not recommended to use as there is a better alternative in that software to use instead. Try to use an alternative.',
      audit_html_old__fail_msg:
        "Uh oh, looks like you've still got some deprecated HTML tags on $$issuecount page(s)",
      audit_html_old__fail_name: 'Deprecated HTML Tags',
      audit_html_old__issue: 'Deprecated HTML tags found',
      audit_html_old__pass_msg:
        'Good work! All of your HTML tags look shiny, new, and beautiful!',
      audit_html_old__pass_name: 'No Deprecated HTML Tags',
      audit_meta_descr__descr:
        "One of your pages contains several 'description' meta tags, which may lead Google to display the wrong one. You should only have one on each page. Neither meta descriptions nor meta keywords factor into Google's ranking algorithms for web search. Meta descriptions can, however, impact a page's CTR (click-through-rate) on Google which can positively impact a page's ability to rank. For that reason, among others, it's important to put some effort into meta descriptions.",
      audit_meta_descr__fail_msg:
        "Looks like you've still got multiple description tags on $$issuecount page(s)",
      audit_meta_descr__fail_name: 'Multiple Descriptions',
      audit_meta_descr__pass_msg:
        'Great work! All of your meta tags looks fine',
      audit_meta_descr__pass_name: 'One Description',
      audit_meta_tags__descr:
        "Some meta tags on your site have been duplicated, remove them so you only have one of each 'meta' tag type’.",
      audit_meta_tags__fail_msg:
        'Uh oh, looks like you still have some duplicate meta tags on $$issuecount page(s)',
      audit_meta_tags__fail_name: 'Duplicate Tags',
      audit_meta_tags__issue: 'Duplicate meta tags found',
      audit_meta_tags__pass_msg: 'Nice work! All your meta tags looks fine',
      audit_meta_tags__pass_name: 'No Duplicate meta tags found',
      audit_dup_titles__descr:
        "It looks like you have several <title> tags on your page, you should only use one. The title tag helps both users and search engines to quickly understand what content they can expect to find on your page. If there are multiple title tags on the page, it might mean search engines display the 'wrong' one, which in turn may lead to lower engagement or CTR from search results. It can also have a negative impact on SEO.",
      audit_dup_titles__fail_msg:
        "Uh oh! You've still got multiple title tags on $$issuecount page(s). Fix this soon.",
      audit_dup_titles__fail_name: 'Multiple Titles',
      audit_dup_titles__issue: 'Duplicate titles found',
      audit_dup_titles__pass_msg:
        'Great work! All of your title tags look fine',
      audit_dup_titles__pass_name: 'No duplicate titles found',
      audit_favicon__descr:
        "No 'favicon' could be found for your site. However, a favicon brings many indirect benefits for your pages' SEO. Having a favicon next to your website title on browser tabs, bookmarks, and history archives etc. helps a user save time as they can identify and browse your website without difficulties. If there is no favicon to your site, you may also lose out on the chance of being on a bookmarked list of a Chrome browser and hence indirectly lose out on a small but significant search ranking signal. You should get a favicon for your site.",
      audit_favicon__fail_msg:
        'Uh oh! Looks like your favicon is still missing',
      audit_favicon__fail_name: 'No Favicon On Your Website',
      audit_favicon__pass_msg: 'Great news, you have a favicon',
      audit_favicon__pass_name: 'Favicon On Your Website',
      audit_robot__descr:
        'We could not find a robots file for your site, but this is an important file for search engines. Robots.txt is a text file webmasters create to instruct web robots (typically search engine robots) how to crawl pages on their website. The robots.txt file is part of the robots exclusion protocol (REP), a group of web standards that regulate how robots crawl the web, access and index content, and serve that content up to users. Get a robots file for your site or talk to your developer.',
      audit_robot__fail_msg:
        'Uh oh! Looks like your robots file is still missing',
      audit_robot__fail_name: 'Missing Robots File',
      audit_robot__pass_msg: 'Your robots file has been added and looks fine',
      audit_robot__pass_name: 'Robots File',
      audit_sitemap__descr:
        'We could not find a sitemap. A sitemap is a blueprint of your website that helps search engines find, crawl, and index all of your website’s content. Sitemaps also tell search engines which pages on your site are the most important. Adding a sitemap is tricky, but will significantly improve your SEO ranking. Add a sitemap or talk to your developer.',
      audit_sitemap__fail_msg:
        'Looks like you are still missing a sitemap. Adding a sitemap is tricky, but having one will significantly improve your SEO ranking',
      audit_sitemap__fail_name: 'No Sitemap Found',
      audit_sitemap__pass_msg: 'Great work, you have a sitemap!',
      audit_sitemap__pass_name: 'Sitemap Found',
      audit_no_alt__descr:
        "Images on your site do not always include an 'alt' tag. Having alt tags is a best practice for web accessibility (helping visually impaired users to understand an on-page image), and is helpful in increasing your search engine visibility. Add alternative text to photos.",
      audit_no_alt__fail_msg:
        'Your images are still missing alt tags on $$issuecount page(s)',
      audit_no_alt__fail_name: 'Missing Alt Tags on your Images',
      audit_no_alt__pass_msg:
        "Great! All of your images have alt tags. You're an accessibility hero!",
      audit_no_alt__pass_name: 'Alt Tags on Your Images',
      audit_links__descr:
        'We noticed that some of the links on your site were broken. Search engines see links as a vote for a website’s quality. Links to your website and links within your website can affect where your website ranks in search results. Because of this, it’s best practice to either remove or update broken links.',
      audit_links__fail_msg:
        "Uh oh! Looks like you've still got some broken links on $$issuecount page(s)",
      audit_links__fail_name: 'Broken Links Pointing To Your Website',
      audit_links__pass_msg:
        'Nice work! All of your links are working beautifully',
      audit_links__pass_name: 'No Broken Links Pointing To Your Website',
      audit_empty_meta_descr__descr:
        'The description meta tag exists, but does not appear to have a value. The meta description is considered important as it helps users to quickly understand what content they can expect to find on the page when clicking through from the search engine results page. Well-written meta descriptions typically achieve a better click-through-rate. If the meta description is empty, this represents a missed optimization opportunity. Furthermore, this means that search engines will write the meta description for you, sometimes with pretty disastrous results. Create a well-written meta description that matches the content on your page.',
      audit_empty_meta_descr__fail_msg:
        'Your meta description seems to be missing on $$issuecount page(s)',
      audit_empty_meta_descr__fail_name: 'Blank META Descriptions Found',
      audit_empty_meta_descr__pass_msg: 'Your meta description looks fine',
      audit_empty_meta_descr__pass_name: 'No Blank META Descriptions Found',
      audit_wrong_meta_descr__descr:
        'It looks like the meta description tag might not match the actual content on your page. The meta description is considered important in helping users to quickly understand what content they can expect to find on the page when clicking through from the search engine results page. Well-written meta descriptions typically achieve a better click-through-rate. If the meta description is empty, this represents a missed optimization opportunity. Furthermore, this means that search engines will write the meta description for you, sometimes with pretty disastrous results. Create well-written meta descriptions that match the content on your page.',
      audit_wrong_meta_descr__fail_msg:
        'Sorry, you have an inappropriate meta description on $$issuecount page(s). Try including a short summary of the page content in the meta description',
      audit_wrong_meta_descr__fail_name: 'Inappropriate META Descriptions',
      audit_wrong_meta_descr__pass_msg:
        'Great work! The meta description matches the page content',
      audit_wrong_meta_descr__pass_name: 'No Inappropriate META Descriptions',
      audit_page_size__descr:
        'Broken HTML or unsupported content on your pages: If Googlebot can’t parse the content of the page it won’t be able to crawl them. Use Fetch as Google to see how Googlebot sees your page.',
      audit_page_size__fail_msg:
        "Uh oh, looks like you've still got invalid content sizes on $$issuecount page(s)",
      audit_page_size__fail_name: 'Pages On The Website Have Invalid Sizes',
      audit_page_size__pass_msg: 'Great work, your page sizes look fine',
      audit_page_size__pass_name: 'Pages On The Website Have Valid Sizes',
      audit_flash__descr:
        'Search engine spiders can’t crawl Flash to figure out what is in that area. The google webmasters support forum states the following, “In general, search engines are text bases. This means that in order to be crawled and indexed, your content needs to be in text format. This doesn’t mean that you can’t include rich media content such as Flash, Silverlight, or videos on your site; it just means that any content you embed in these files should also be available in text format or it won’t be accessible to search engines."',
      audit_flash__fail_msg:
        "Looks like you've still got some flash content on $$issuecount page(s)",
      audit_flash__fail_name: 'Looks Like You Are Still Using Flash',
      audit_flash__pass_msg:
        'Nice work, all of your media content is search engine friendly',
      audit_flash__pass_name: 'Looks Like You Are Not Using Flash',
      audit_iframe__descr:
        'Google has some ability to crawl content in iFrame on web pages if the iFrame is SEO link engaged. Google is known to acknowledge iFrame via links, but it is still better to develop your website without iFrame.',
      audit_iframe__fail_msg:
        "Uh oh, there's still iFrame showing on $$issuecount page(s)",
      audit_iframe__fail_name: 'iFrame Found Within Your Website',
      audit_iframe__pass_msg:
        'Nice work, no iFrame was found within your website',
      audit_iframe__pass_name: 'No Iframes Found within Your Website',
      audit_lorem__descr:
        'Developers use the text Lorem ipsum as a placeholder for the page layout. After setting up the template, all such text must be replaced with a unique and relevant website theme, otherwise search engines may find the page not relevant or duplicate. Replace it with some relevant content to improve your SEO score.',
      audit_lorem__fail_msg:
        "Looks like you've still got some placeholder text on $$issuecount page(s). Replace it with some relevant content to improve your SEO score",
      audit_lorem__fail_name: 'Lorem Ipsum Found',
      audit_lorem__pass_msg:
        'No placeholder text found on your homepage, nice work!',
      audit_lorem__pass_name: 'No Lorem Ipsum Found',
      audit_400__descr:
        'Errors with HTTP status code 4xx are basically client side errors. This may be due to a problem with authentication or representation format etc. So the problem is on the client end. Check the console for a full description of the error. This includes full status code e.g (400,401,etc). Every code refers to a different client-side problem. Understand the problem and apply fixes accordingly.',
      audit_400__fail_msg: 'Uh oh! A 4xx error is still showing',
      audit_400__fail_name: '4xx Errors Found On Your Homepage',
      audit_400__pass_msg: 'No 4xx errors found on your homepage, nice work!',
      audit_400__pass_name: 'No 4xx Errors Found On Your Homepage',
      audit_500__descr:
        "A 5xx server error is an error which occurs when the server doesn't fulfil a request. There are 10 types of this error. It's quite difficult to detect and fix each occurrence of these errors, but search engines don't like them, especially 500 and 503 errors.",
      audit_500__fail_msg: 'Uh oh! A 5xx error is still showing',
      audit_500__fail_name: '5xx Errors Found',
      audit_500__pass_msg: 'No 5xx errors found, nice work!',
      audit_500__pass_name: 'No 5xx Errors Found',
      audit_seo_url__descr:
        'SEO friendly URLs are URLs that are designed to meet the needs of users and searchers. Specifically, URLs optimized for SEO tend to be short and keyword-rich. Make sure your URL is concise and includes a relevant keyword.',
      audit_seo_url__fail_msg:
        'URLs on $$issuecount page(s) are still not SEO friendly. Make sure your URL is concise and includes a relevant keyword',
      audit_seo_url__fail_name: 'This Is Not An SEO Friendly URL',
      audit_seo_url__pass_msg: 'Good work! That URL looks great!',
      audit_seo_url__pass_name: 'This Is An SEO Friendly URL',
      audit_ssl__descr:
        "You can be sure that having an SSL certificate on your site is valuable. It shows visitors that your site is verified and that it's safe from hackers. It also improves your SEO rankings. Get an SSL certificate.",
      audit_ssl__fail_msg:
        'Uh oh! $$issuecount page(s) is still showing as a non-secure page',
      audit_ssl__fail_name: 'You Need An SSL Certificate',
      audit_ssl__pass_msg:
        'Looks good! You have an SSL certificate, so your users will know that your site is secure',
      audit_ssl__pass_name: 'You Have An SSL Certificate Installed',
      audit_ssl_valid__descr:
        "You can be sure that having an SSL certificate on your site is valuable. It shows visitors that your site is verified and that it's safe from hackers. It also improves your SEO rankings.",
      audit_ssl_valid__fail_msg:
        'Uh oh! $$issuecount page(s) is showing as a non-secure page',
      audit_ssl_valid__fail_name: 'SSL Certificate Invalid',
      audit_ssl_valid__pass_msg:
        'Looks good! Your users will know that your site is secure',
      audit_ssl_valid__pass_name: 'SSL Certificate Valid',
      audit_test_server__descr:
        'When a user requests a URL from their website, the server on which your website is hosted, the server will return a header server status code. Ideally the server should return a ‘200 OK’ status code to inform the browser that everything is okay with the page and the page exists in the requested location. You have server errors that need to be fixed.',
      audit_test_server__fail_msg:
        'Uh oh! Your site is still showing a server error',
      audit_test_server__fail_name: 'Server Errors Found',
      audit_test_server__pass_msg: 'Server error is fixed, well done!',
      audit_test_server__pass_name: 'No Server Errors Found',
      audit_load_high__descr:
        "A slow website speed hurts your rankings and users' experience. Google has indicated site speed (and as a result, page speed) is one of the signals used by its algorithm to rank pages. In addition, a slow page speed means that search engines can crawl fewer pages using their allocated crawl budget, and this could negatively affect your indexation. Page speed is also important to user experience. Pages with a longer load time tend to have HARDer bounce rates, users spend less time on the page, and conversions are negatively affected. Work on improving your website speed.",
      audit_load_high__fail_msg:
        'Uh oh! Your website is still loading too slowly',
      audit_load_high__fail_name: 'Your Website Speed Is Not Great',
      audit_load_high__pass_msg:
        'Great work! Your website is loading lightning fast!',
      audit_load_high__pass_name: 'Your Website Speed Is Great',
      audit_dup_title__descr:
        'It sometimes happens that you want the same page in two different places on your website. Imagine that you have a product that you offer to your business customers and also to your private customers. Then you might want to put this page into two different locations/URLs on your website. Example: www.example.com/business/myproduct.html (Page Title: "My Product")\\nwww.example.com/private/myproduct.html (Page Title: "My Product") Both pages have a right to exist but Google will not know which one is the more powerful one (the original), so it will more or less split the link juice (ranking power) for the two pages. In this case you should use the rel="canonical" tag. The rel="canonical" tag should be put into the duplicate page and should point to the original page. If the original from our examples above should be the page in the business folder, then the rel="canonical" tag needs to be found in the page in the private folder (the duplicate page) and should look like this: <link rel="canonical" href="www.example.com/business/myproduct.html" />',
      audit_dup_title__fail_msg:
        'Uh oh! $$issuecount page(s) still have duplicate title tags',
      audit_dup_title__fail_name: 'You Have Duplicate Title Tags',
      audit_dup_title__pass_msg:
        'This looks great. The title looks different enough for both pages to rank well',
      audit_dup_title__pass_name: 'You Do Not Have Duplicate Title Tags',
      audit_empty_title__descr:
        'The title tag is considered important to help both users and search engines to quickly understand what content they can expect to find on the page. The title tag is considered one of the most important on-page SEO factors, so if it is missing this represents an issue that may affect search engine rankings and click-through-rate from the search results. Try adding some to improve SEO performance.',
      audit_empty_title__fail_msg:
        "Uh oh, still looks like you're missing some tags on $$issuecount page(s). Try adding some to improve SEO performance",
      audit_empty_title__fail_name: 'Your Websites Title Tags Are Empty',
      audit_empty_title__pass_msg: 'Well done, those tags look good!',
      audit_empty_title__pass_name: 'Your Websites Title Tags Are Not Empty',
      audit_wrong_title__descr:
        "Make your titles more relevant and less spammy, do not just list keywords and ensure you are writing with the user in mind first. Make sure they're clear, concise, and convey the value of the content.",
      audit_wrong_title__fail_msg:
        "Looks like you still need to tweak $$issuecount page(s). Make sure they're clear, concise, and convey the value of the content",
      audit_wrong_title__fail_name:
        'Inappropriate Titles Found On Your Homepage',
      audit_wrong_title__issue: 'Inappropriate titles found',
      audit_wrong_title__pass_msg: 'Good work! Those titles are great',
      audit_wrong_title__pass_name:
        'No Inappropriate Titles Found On Your Homepage',
      audit_long_title__descr:
        'Google typically displays the first 50–60 characters of a title tag. If you keep your titles under 60 characters, our research suggests that you can expect about 90% of your titles to display properly. Your website titles are too long. Try something shorter.',
      audit_long_title__fail_msg:
        'Sorry, your website titles are still too long. Try something shorter',
      audit_long_title__fail_name: 'Your Website Has Titles Which Are Too Long',
      audit_long_title__issue: 'Long titles found',
      audit_long_title__pass_msg:
        'Nice! Your website titles are a great length.',
      audit_long_title__pass_name:
        'Your Website Does Not Have Titles Which Are Too Long',
      audit_short_title__descr:
        'Google typically displays the first 50–60 characters of a title tag. If you keep your titles under 60 characters, our research suggests that you can expect about 90% of your titles to display properly. Your website titles are too short.',
      audit_short_title__fail_msg:
        'Sorry, your website titles are still too short.',
      audit_short_title__fail_name:
        'Your Website Has Titles Which Are Too Short',
      audit_short_title__issue: 'Short titles found',
      audit_short_title__pass_msg:
        'Nice! Your website titles are a great length.',
      audit_short_title__pass_name:
        'Your Website Does Not Have Titles Which Are Too Short',
      audit_label__easy: 'Easy',
      audit_label__medium: 'Medium',
      audit_label__difficult: 'Difficult',
      audit_title: 'Audit your Site',
      audit_continuous:
        'We continuously audit your website and keep you up to date about what needs to be fixed and how to do this.',
      audit_seo__short: 'SEO',
      audit_seo__description:
        'SEO optimisation is important to ensure you can maximise ranking potential and drive traffic to your website from search engines. You should ensure your page fulfils common recommended improvements outlined here before moving onto more advanced SEO strategies.',
      audit_performance: 'Performance',
      audit_performance__descr:
        'Performance is important to ensure a good user experience, and reduced bounce rates (which can also indirectly affect your search engine rankings). Continue to monitor your performance over time to ensure there are no periodic fluctuations.',
      audit_security: 'Security',
      audit_security__descr:
        "Security is important to ensure your website protects user data, doesn't become compromised or experience downtime or data loss. We recommend ongoing patching and the continuous use of protective monitoring tools.",
      audit_mobile: 'Mobile',
      audit_mobile__descr:
        'It’s increasingly important for businesses to have a mobile-friendly website. Not only can it help customers reach you more easily when they’re on the go, but it will impact search engine optimization.',
      audit_fix_issue: 'Fix Issue',
      savings_table__keyword: 'Keyword',
      savings_table__keyword_explained:
        'If we have a URL for where the keyword is ranking on your domain, the keyword will be turned into a link',
      savings_table__position: 'Position',
      savings_table__cpc: 'CPC',
      savings_table__cpc_explained: 'Cost Per Click',
      savings_table__monthly_search: 'Monthly Searches',
      savings_table__savings: 'Savings',
      competitors_table__modal_title: 'Keywords used for competitor comparison',
      competitors_table__table_header: 'Keywords',
      screenshot__not_available: 'Awaiting screenshot',
      english: 'English',
      dutch: 'Dutch',
      french: 'French',
      german: 'German',
      espanol: 'Espanol',
      portuguese: 'Portuguese',
      italian: 'Italian',
      turkish: 'Turkish',
      wp_auto__header_1: 'Good news! It looks like you are using',
      wp_auto__header_2: 'WordPress',
      wp_auto__header_3:
        'Which means that we can attempt to fix a lot of issues for you automatically.',
      wp_auto__header_4:
        'But in order for us to do this, you will need to enter your',
      wp_auto__header_5: 'username',
      wp_auto__header_6: 'and',
      wp_auto__header_7: 'password',
      wp_auto__header_8:
        ', and make sure that the URL for the admin section is correct.',
      wp_auto__form_1: 'URL',
      wp_auto__form_2:
        'We are assuming that the admin section of your site is the default set by wordpress, if not please edit it.',
      wp_auto__form_3: 'Username',
      wp_auto__form_4: 'Password',
      wp_auto__form_installed: 'installed',
      wp_auto__form_success_header:
        'Plugins were successfully installed and setup!',
      wp_auto__form_success_msg:
        'You should start seeing improvements with your next audit.',
      wp_auto__form_success_breakdown:
        'Here is a breakdown of what has been installed and configured on your site:',
      wp_auto__request_error:
        'The server could not be reached, try again later.',
      wp_auto__page_not_found: 'The URL provided could not be found',
      wp_auto__login_not_found:
        'The login form could not be found, please check the URL',
      wp_auto__login_error: 'You have entered an invalid username or password',
      wp_auto__file_upload_error:
        'Plugins could not be uploaded, the user needs to be an admin for this to work',
      wp_auto__loading_msg_1:
        'Attempting to find login form and use credentials...',
      wp_auto__loading_msg_2: 'Uploading plugins onto your wordpress site...',
      wp_auto__loading_msg_3:
        'Installing and configuring plugins, this may take a few minutes...',
      wp_auto__update_header:
        "It looks like you're not running the latest version of WordPress",
      wp_auto__update_paragraph_1:
        'This could be the reason why the autofix is not working correctly.',
      wp_auto__update_paragraph_2:
        'Updating WordPress is easy and recommended as it reduces the amount of bugs and will make your website safer. Click',
      wp_auto__update_paragraph_2a: 'here',
      wp_auto__update_paragraph_2b:
        'to find a guide on how to update to the latest version.',
      uptime__header_title: 'Uptime History',
      uptime__header_status: 'Current Status',
      uptime__table_date: 'Date',
      uptime__table_time: 'Time (UTC)',
      uptime__table_status: 'Status',
      uptime__table_no_data: 'No available data',
      all: 'all',
      online: 'online',
      offline: 'offline',
      request_help__cta: 'Request help',
      request_help__success_toast: 'Email successfully sent!',
      request_help__header_li: 'Issue name',
      request_help__header_li_2: 'Issue Description',
      request_help__header_li_3: 'Pages with issue',
      request_help__description_p:
        'Send a copy of this issue to your web developer',
      request_help__error_bad_request:
        "The data being sent somehow wasn't right, please get in touch with support",
      request_help__error_unauthorized:
        'Invalid permissions, please contact your admin',
      request_help__form_subject: 'Subject',
      request_help__form_subject_placeholder: "email's subject",
      request_help__form_to: 'To',
      request_help__form_to_placeholder: "web developer's email",
      request_help__form_cc: 'Cc',
      request_help__form_cc_placeholder: 'your email address',
      request_help__form_name: 'Name/Company',
      request_help__form_name_placeholder: "your name or company's name",
      request_help__form_comments: 'Comments',
      request_help__form_comments_placeholder: 'optional comments',
      request_help__form_required: 'required',
      request_help__form_send: 'Send',
      top_issues__title: 'Top Audit Issues',
      info_box__domain: 'domain',
      info_box__last_crawled: 'last crawled',
      info_box__cms: 'detected cms',
      info_box__status: 'status',
      admin_setup__navbar_styling: 'Navbar Styling',
      audit_section__improve: 'Improve',
      site__audit_in_progress:
        'We are currently auditing your site. When your audit completes, the site will update automatically.',
      site__audits_in_progress:
        'We are currently auditing your sites. When your audits complete, the site will update automatically.',
      audit_failed__base_message: 'Last audit failed:',
      audit_failed__no_previous_audit:
        'No Audit data could be displayed because we have not been able to run a successful audit.',
      audit_failed__site_unreachable:
        'The page could not be reached by our crawler.',
      audit_failed__invalid_page_status_code:
        'The page returned a status code of 400 or greater.',
      audit_failed__forbidden_meta_tag:
        'The page contained <meta robots=”noindex”> tags.',
      audit_failed__forbidden_robots:
        'The robots.txt file forbid crawling of the page.',
      audit_failed__forbidden_http_header:
        'The X-Robots-Tag: noindex prevented the page being crawled.',
      audit_failed__too_many_redirects:
        'The first page we tried to crawl had too many redirects.',
      audit_failed__unknown:
        'Our web crawler failed for an unknown reason, please contact support.',
      empty_state_container__when_ready: 'Data will be shown when available.',
    },
  },
  es: {
    translation: {
      monthly_savings: 'Ahorros mensuales estimados',
      welcome: 'Bienvenido',
      show_less: 'Mostrar menos',
      show_more: 'Mostrar más',
      download: 'Descargar',
      ranking_graph__rank_unknown:
        'No se ha podido calcular el cambio de ranking',
      ranking_graph__last_update: 'Última actualización:',
      ranking_graph__go_back: 'Atrás',
      ranking_graph__calculation_error: 'No pudimos calcular el rango de',
      ranking_graph__try_again_one_week:
        'Inténtalo de nuevo dentro de una semana',
      ranking_graph__open_graph: 'Abrir el gráfico',
      ranking_graph__rank_no_movement:
        'El ranking no se ha movido desde la semana pasada',
      ranking_graph__down: 'Abajo',
      ranking_graph__up: 'Arriba',
      ranking_graph__positions_last_week: 'Posición desde la semana pasada',
      ranking_summary__not_ranking:
        'Parece que todavía no estás clasificado para este término.',
      ranking_summary__updating:
        'La información sobre la clasificación se sigue actualizando.',
      ranking_summary__rank: 'Clasificación',
      ranking_info__data_visualisation: 'Visualización de datos',
      ranking_info__announce_graph:
        'Buenas noticias, una vez que tengamos suficiente información registrada presentaremos aquí tu información en un gráfico interactivo ',
      ranking_info__announce_graph_2: 'Observa este espacio',
      ranking_info__no_results:
        'Todavía no estás clasificado para ninguna de las palabras clave más populares',
      export_csv__url: 'URL',
      export_csv__page_title: 'Título de la página',
      export_csv__trust_flow: 'Flujo de confianza',
      export_csv__citation_flow: 'Flujo de citaciones',
      export_csv__target_url: 'URL de destino',
      export_csv__date_discovered: 'Fecha de descubrimiento',
      export_csv__date_removed: 'Fecha de eliminación',
      export_csv__link_type: 'Tipo de enlace',
      search_engine__label:
        'Nos aseguramos de que todas las páginas de tu sitio web se encuentren en',
      search_engine__info_icon:
        'Consigue que te encuentren en los principales motores de búsqueda, te quitamos la complejidad de conseguir que tu sitio web se encuentre en todas partes en línea y ahora te hemos incluido en los motores de búsqueda más populares disponibles.',
      search_engine__index_completed: 'Índice completado',
      search_engine__index_pending: 'Índice pendiente',
      keywords__edit: 'Editar',
      keywords__ranking_on:
        'Posición en los motores de búsqueda cuando se buscan estas palabras clave',
      keywords__graph_after_7_days:
        'Hemos encontrado 3 palabras clave populares que sabemos que envían tráfico a tu sitio web. Después de 7 días tus datos se mostrarán en un gráfico en vivo. Por favor, deja pasar un tiempo después de añadir nuevas palabras clave para que los datos se actualicen.',
      keywords__change: 'Cambia tus palabras clave',
      keywords__modal_info:
        'Tus palabras clave han sido actualizadas recientemente, por favor vuelve a esta página en 10 minutos para ver tus resultados.',
      sitemap_fix__1: 'Detectamos y añadimos un total de',
      sitemap_fix__2: 'páginas a tu mapa del sitio.',
      sitemap_fix__3: 'Descargar mapa del sitio',
      speed_fix__1:
        '¿Qué puedo hacer para que mi sitio web cargue más rápido? ',
      speed_fix__2:
        'Si una cosa es cierta sobre tu sitio web, es que la velocidad de carga es importante. Si tu sitio se carga con lentitud, no sólo ofrecerás una mala experiencia al usuario, sino que también te posicionarás peor en los motores de búsqueda.',
      speed_fix__3: '1. Activar el caché',
      speed_fix__4:
        'Cada vez que visitas un sitio web, ciertos elementos se almacenan en el caché, para que la próxima vez que se visite el sitio se pueda acceder fácilmente a esas partes y cargar mucho más rápido. Con el almacenamiento en caché, en lugar de que tu navegador navegador tiene que descargar todos los recursos, sólo tiene que descargar algunos de ellos.',
      speed_fix__5:
        'Activando el almacenamiento en caché puedes mejorar considerablemente la carga de tu sitio para los visitantes que regresan. Si utilizas un CMS como WordPress puedes instalar un plugin como W3 Total Cache o W3 Super Cache, que te permitirán habilitar el almacenamiento en caché de todo el sitio, o de determinados elementos del sitio.',
      speed_fix__6:
        '2. Eliminar los plugins y complementos que consumen recursos',
      speed_fix__7:
        'Si tu sitio está utilizando demasiados plugins, podrías estar desacelerando tu sitio web. Es posible que necesites unos cuantos plugins para que tu sitio funcione como te gusta; pero lo más probable es que haya algunos de los que puedas prescindir, especialmente si son unos devoradores de recursos.',
      speed_fix__8:
        'La mejor manera de hacerlo es obtener una prueba de referencia de la velocidad de carga a través de una herramienta como "GTMetrix" o "Google Pagespeed Insights". A continuación, recorre tu lista de plugins y desactiva uno por uno. A continuación, ejecuta la prueba de velocidad con el plugin desactivado.',
      speed_fix__9:
        'Esto puede llevar mucho tiempo, pero te ayudará a encontrar los plugins que más perjudican la velocidad de carga de tu sitio. En ese momento, puedes buscar un plugin que consuma menos recursos o encontrar otra solución.',
      speed_fix__10: '3. Optimiza y reduce el tamaño de tus imágenes',
      speed_fix__11:
        'Si tu sitio tiene toneladas de imágenes que no están optimizadas, entonces esto impactará negativamente en la velocidad de carga de tu sitio. Al tener imágenes de gran tamaño, estarás requiriendo que el navegador cargue archivos más grandes. Hay varias maneras de optimizar las imágenes para que se carguen más rápido.',
      speed_fix__12:
        'Asegúrate de que tus imágenes no son innecesariamente grandes. Por ejemplo, si el ancho de la página de tu blog es de 900px, asegúrate de que ese es el ancho de tus imágenes.',
      speed_fix__13:
        'Si utilizas un CMS como WordPress, puedes instalar un plugin como "WPSmush" que reducirá automáticamente el tamaño de los archivos de las imágenes.',
      speed_fix__14:
        'Antes de subir las imágenes, pásalas por una herramienta llamada "Tiny PNG" para reducir el tamaño del archivo de tu imagen, sin sacrificar la calidad.',
      speed_fix__15: '4. Minimiza tu código',
      speed_fix__16:
        'A veces el código de tu sitio web puede quedar un poco desordenado. Cuando esto sucede, tu sitio tardará mucho más en cargar. Cuando estás haciendo muchas personalizaciones, usando un CMS, o incluso usando un constructor de sitios web para crear tu sitio habrá saltos de línea innecesarios, espacios y otros elementos que no necesitan estar allí.',
      speed_fix__17:
        'Si usas WordPress, un plugin como Better WordPress Minify minimizará tu código. O, si estás usando uno de los plugins de caché destacados anteriormente, entonces también debería haber una opción de minificación.',
      speed_fix__18:
        'Si no estás usando un CMS, puedes minificar tu código usando la "PageSpeed Insights Chrome Extension". Esta extensión creará una versiónn minimizada de tu código, para que puedas ver qué versión es más rápida. ',
      speed_fix__19: '5. Utilizar una CDN',
      speed_fix__20:
        'La velocidad de carga de tu sitio se ve afectada por la proximidad del usuario al servidor donde se almacenan los archivos de tu sitio. Cuanto más lejos estén de esta ubicación física, más lento se cargará su sitio. El uso de una CDN puede resolver este problema.',
      speed_fix__21:
        'Una CDN distribuye los archivos de tu sitio a través de una red de servidores globales, de manera que tus usuarios puedan acceder a tu sitio a través del servidor más cercano a ellos.',
      sitemap: 'Hemos añadido',
      sitemap__2: 'páginas a tu',
      sitemap__3: 'Mapa del sitio web',
      backlinks: 'Retroenlaces',
      backlinks__from: 'desde',
      backlinks__websites: 'sitios web',
      backlinks__info:
        'Un retroenlace es simplemente un enlace de un sitio web a otro. Los motores de búsqueda como Google utilizan el retroenlace como una señal de clasificación porque cuando un sitio web enlaza a otro, significa que creen que el contenido es digno de mención.',
      backlinks__export: 'Exportar los 100 mejores retroenlaces',
      backlinks__not_found: 'No se han encontrado retroenlaces',
      competitors__comparison: 'Palabras clave de la competencia',
      competitors__info:
        'Compara tu negocio con el de tus competidores, hacemos un seguimiento de tu visibilidad global para tus palabras clave, la tabla de clasificación se actualizará cuando tú y tus competidores aumenten o disminuyan su posición. Haz clic en "Mostrar más" para ver la lista de palabras clave que utilizamos para encontrar y actualizar a tus competidores.',
      competitors__no_results_title: 'No se han encontrado competidores',
      competitors__no_results_description:
        'No hemos encontrado ningún competidor directo.',
      competitors__no_results_description_keywords:
        'No hemos encontrado ningún competidor directo, porque tu sitio no tiene ninguna palabra clave asociada.',
      savings__top: 'El valor de tu tráfico orgánico',
      savings__info_1: '¿Cómo se calcula el ahorro estimado?',
      savings__info_2:
        'Posición promedio de las palabras clave que encontramos para tu negocio multiplicada por el costo promedio por clic en Google Ads y el volumen de tráfico estimado.',
      savings__saved_in: 'Guardado en',
      savings__monthly: 'Estimación de ahorro mensual',
      alerts__stay_informed: 'Mantente informado',
      alerts: 'Alertas',
      alerts__info:
        'Estas esenciales alertas mantendrán tu sitio web en primer plano, asegurando que te mantengas relevante, encontrado y seguro en línea.',
      alerts__downtime: 'Alerta de inactividad',
      alerts__downtime_info:
        'Reciba una notificación cuando tu sitio web necesite atención, no pierdas el volumen de ventas cuando tu sitio web esté fuera de línea.',
      alerts__security: 'Alerta de seguridad',
      alerts__security_info:
        'Los robots y los piratas informáticos están en todas partes, está atento cuando tu sitio web sea vulnerable a los delincuentes.',
      alerts__competitor: 'Alerta de la competencia',
      alerts__competitor_info:
        'El conocimiento es poder; tú serás el primero en saber cuándo tus competidores se adelantan o se quedan atrás.',
      alerts__crawling: 'Alerta de arrastre',
      alerts__crawling_info:
        'Si tu sitio web no puede ser rastreado, perderás toda la visibilidad para millones de personas en línea, te lo haremos saber en un abrir y cerrar de ojos.',
      label__save: 'Guardar',
      account__settings: 'Configuración',
      account__firstname: 'Nombre de pila',
      account__lastname: 'Apellido',
      account__email: 'Correo electrónico',
      account__password: 'Contraseña ',
      account__phone: 'Número de teléfono',
      account__saved: 'Guardado',
      account__update: 'Actualización',
      admin_clients__total_label: 'Clientes totales ',
      admin_clients__total_title:
        'Número total de sitios web de clientes que estamos optimizando',
      admin_clients__paid_label: 'Clientes de pago',
      admin_clients__paid_title:
        'Suscripciones de pago que no están en una prueba gratuita',
      admin_clients__trial_label: 'Pruebas gratuitas',
      admin_clients__trial_title: 'Número de clientes en prueba gratuita',
      admin_clients__import: 'Importar clientes',
      admin_clients__manage: 'Gestionar usuarios',
      admin_clients__settings: 'Ajustes',
      admin_clients__website: 'Página web',
      admin_clients__email: 'Correo electrónico del cliente',
      admin_clients__status: 'Estado',
      admin_clients__added: 'Añadido',
      admin_clients__cancelled: 'Anulado',
      admin_clients__active_subscription: 'Suscripción activa',
      admin_clients__trial: 'Prueba',
      admin_clients__active: 'Activo',
      admin_clients__link: 'Enlace',
      admin_clients__view: 'Ver',
      admin_clients__processing: 'Procesando',
      admin_clients__actions: 'Acciones',
      admin_clients__remove: 'Eliminar',
      admin_clients__demo_data:
        'Cargar datos de prueba para comprobarlos. Rellena los datos de las clasificaciones históricas para el sitio seleccionado.',
      admin_clients__data: 'Datos',
      admin_clients__remove_confirm_title: 'Confirmar la eliminación',
      admin_clients__remove_confirm:
        '¿Está seguro de que deseas eliminar el sitio?',
      admin_import__back: 'Volver a',
      admin_import__sites: 'Importar sitios',
      admin_setup__back: 'Volver a',
      admin_setup__settings: 'Ajustes',
      admin_setup__business_name: 'Nombre de la empresa',
      admin_setup__domain: 'Dominio',
      admin_setup__domain_description:
        'Los dominios donde se aloja tu versión de la aplicación. Máximo. 10 dominios.',
      admin_setup__send_from: 'Enviar desde la dirección de correo electrónico',
      admin_setup__send_from_description:
        'Contacta con nosotros para cambiar este campo, necesitamos validar tu dominio.',
      admin_setup__shared_secret: 'Secreto compartido',
      admin_setup__custom_css: 'Hoja de estilo personalizada',
      admin_setup__advanced: 'Avanzado',
      admin_setup__css_page: 'Página CSS personalizada para cargar.',
      admin_setup__webhook_endpoints:
        'Puntos finales del suscriptor de Webhooks',
      admin_setup__webhooks_description:
        'Puntos finales para recibir alertas de webhook. Acepta una lista separada por comas.',
      admin_setup__saved: 'Guardado',
      admin_setup__update: 'Actualización',
      admin_setup__logo_dark: 'Logotipo (oscuro)',
      admin_setup__logo_dark_description:
        'Versión oscura, para la página de inicio de sesión y los fondos claros.',
      admin_setup__no_image: 'Todavía no se ha añadido ninguna imagen',
      admin_setup__drag_and_drop:
        'Arrastra y suelta algunos archivos aquí, o haz clic para seleccionarlos',
      admin_setup__favicon: 'Favicon',
      admin_setup__not_allowed: 'No está permitido',
      admin_setup__no_permission:
        'Lo sentimos, no tienes permiso para ver esta página.',
      admin_setup__account_owner_only:
        'Sólo el propietario de la cuenta puede ver esta página en particular.',
      admin_stats__users: 'Usuarios',
      admin_stats__page_scanned: 'Páginas escaneadas',
      admin_stats__pages: 'Páginas',
      admin_stats__audits: 'Auditorías',
      admin_stats__order_count: 'Recuento de pedidos',
      admin_stats__order: 'Solicitar',
      admin_stats__order_value: 'Valor del pedido (£)',
      admin_stats__keywords: 'Palabras clave',
      admin_stats__proposals: 'Propuestas',
      admin_stats__proposals_sent: 'Propuestas enviadas',
      admin_stats__sent_proposals: 'Propuestas enviadas',
      admin_stats__proposals_completed: 'Propuestas completadas',
      admin_stats__completed_proposals: 'Propuestas completadas',
      admin_stats__proposal_count_30_days:
        'Recuento de propuestas (últimos 30 días)',
      admin_stats__proposal_value_30_days:
        'Valor de la propuesta (últimos 30 días)',
      admin_stats__count: 'Contar',
      admin_stats__value: 'Valor (£)',
      admin_stats__download_png: 'descargar PNG',
      admin_stats__download_svg: 'descargar SVG',
      admin_stats__download_pdf: 'descargar PDF',
      admin_users__back: 'Volver a',
      admin_users__email: 'Correo electrónico',
      admin_users__name: 'Nombre',
      admin_users__auth_google: 'Autenticado con Google',
      admin_users__logins: 'Entradas',
      admin_users__status: 'Estado',
      admin_users__actions: 'Acciones',
      admin_users__edit: 'Editar',
      add_domain: 'Añadir dominio',
      add_domain__up_to_10: 'Añadir hasta 10 dominios',
      add_domain__remove_last: 'Eliminar el último dominio',
      add_domain__remove: 'Eliminar el dominio',
      how_are_your_savings_calculated: '¿Cómo se calcula el ahorro estimado?',
      login__sign_in: 'Registrarse',
      login__email: 'Dirección de correo electrónico',
      login__forgot_pwd: '¿Has olvidado tu contraseña?',
      login__error:
        'Lo sentimos, has introducido un nombre de usuario o una contraseña incorrectos.',
      login__failed: 'Error en el inicio de sesión',
      register: 'Registrarse',
      register__account_created: 'Cuenta creada',
      register__creating_account: 'Creando tu cuenta...',
      register__please_wait_1: 'Por favor, espere',
      register__please_wait_2: 'unos segundos.',
      register__registered: 'Ya te has registrado.',
      register__error:
        'Debes aceptar nuestros términos y condiciones para registrarte.',
      register__online_visibility: 'Aumenta tu visibilidad en línea',
      register__detect_issues: 'Detectar problemas en el sitio web',
      register__keyword_research: 'Búsqueda de palabras clave',
      register__issue_monitoring: 'Seguimiento de los problemas',
      register__insights: 'Búsqueda de información',
      register__much_more: 'Y mucho más',
      register_form__create_free_account: 'Crea tu cuenta gratuita',
      register_form__name: 'Tu nombre',
      register_form__email: 'Correo eléctronico ',
      register_form__password: 'Contraseña',
      register_form__agency_name: 'Nombre de la agencia',
      register_form__domain: 'Tu dominio',
      register_form__domain_error:
        'Lo sentimos, ese subdominio no está disponible.',
      register_form__domain_success: 'Perfecto. Ese subdominio está disponible',
      register_form__terms_1: 'Estoy de acuerdo con los',
      register_form__terms_2: 'Términos y condiciones',
      register_form__already_have_account:
        '¿Ya tienes una cuenta? Ingresar ahora',
      register_form__partner_account: 'Crear una cuenta de socio',
      register_agency__terms_error:
        'Debes aceptar nuestros términos y condiciones para registrarte.',
      register_agency__generic_error: 'Lo siento, se ha producido un error',
      register_agency__audit_lead:
        'App de auditoría y generación de contactos con marca completa.',
      register_agency__unlimited_audits: 'Auditorías ilimitadas',
      register_agency__reporting: 'Informes de clientes',
      register_agency__branding: 'Marca personalizada',
      register_agency__sales_proposals: 'Propuestas de venta',
      register_agency__no_cost_upfront: 'No hay costes iniciales',
      register_agency__try_again_later:
        'Si el enlace no funciona inmediatamente, inténtelo de nuevo en una hora.',
      register_agency__terms_1: 'Acepto las',
      register_agency__terms_2: 'condiciones de la agencia',
      site__audit_pending: 'Auditoría del sitio pendiente',
      site__audits_pending: 'Auditoría del sitio pendiente',
      site__site_not_found: 'Sitio no encontrado',
      site__sites_not_found: 'No se han encontrado sitios',
      site__site_not_found_message:
        'No hemos podido encontrar tu sitio. Por favor, contacte con el servicio de asistencia.',
      site__sites_not_found_message:
        'No hemos podido encontrar ningún sitio asociado a tu cuenta. Por favor, ponte en contacto con el servicio de asistencia.',
      theme__all_fields_required: 'Por favor, completa todos los campos',
      theme: 'Tema',
      theme__back: 'Volver atrás',
      theme__customise: 'Personaliza tu tema.',
      theme__sidebar_bg: 'Color de fondo de la barra lateral',
      theme__hex: 'Código de color hexadecimal',
      theme__header_colour: 'Color del texto de cabecera',
      theme__primary_colour: 'Color del texto principal',
      theme__primary_button_colour: 'Color del botón principal',
      theme__custom_font: 'Fuentes personalizadas',
      theme__select: 'Seleccionar',
      theme__disclaimer_1: 'Nota: Puede tardar',
      theme__disclaimer_2: 'hasta una hora',
      theme__disclaimer_3:
        'antes de que el tema sea visible para todos debido al caché del navegador.',
      theme__saved: 'Guardado',
      theme__save: 'Guardar',
      theme__preview: 'Vista previa',
      theme__title: 'Título',
      theme__ok: 'Okay',
      audit_result__pages: 'Páginas',
      audit_result__page: 'Página',
      audit_result__pages_impacted: 'Número de páginas afectadas',
      audit_result__easy: 'Fácil',
      audit_result__easy_fix: 'Fácil de arreglar',
      audit_result__medium: 'Medio',
      audit_result__medium_fix: 'Dificultad media',
      audit_result__difficult: 'Difícil',
      audit_result__difficult_fix: 'Difícil de arreglar',
      audit_result__explanation: 'Explicación',
      audit_result__advice: 'Consejo',
      audit_result__export_list: 'Exportar lista',
      audit_section__yes: 'Si ',
      audit_section__no: 'No',
      audit_section__pass: 'Pasar',
      audit_section__fail: 'Fallo',
      audit_section__show_pages_with_issue:
        'Mostrar las páginas con el problema',
      audit_section__page_url: 'Página URL',
      audit_section__last_scan: 'Último escaneo',
      audit_section__ago: 'hace ',
      audit_section__fix: 'Reparación automática',
      audit_section__learn: 'Aprende a arreglarlo',
      audit_section__what_we_found: '¿Qué hemos descubierto?',
      audit_section__average_speed: 'Velocidad media',
      audit_section__average_speed_description:
        'Los motores de búsqueda dan un trato preferente a los sitios web que se cargan rápidamente. Cuanto más rápido se cargue tu sitio, menor será la tasa de rebote. Si tu sitio es rápido, tienes más posibilidades de posicionarte en los motores de búsqueda que los sitios lentos que tienen un alto índice de rebote.',
      audit_section__is_site_fast: '¿Se carga rápido tu sitio web?',
      audit_section__try_again_later:
        'Todavía no hay datos, inténtalo más tarde.',
      audit_section__security: 'Seguridad',
      audit_section__is_site_secure: '¿Es seguro tu sitio web?',
      audit_section__pages_analyzed: 'Páginas analizadas',
      audit_section__pages_on_site: 'Las páginas de tu sitio web',
      audit_section__mobile_speed: 'Velocidad móvil',
      audit_section__mobile_loading_time:
        'El tiempo de carga de tu sitio en un dispositivo móvil',
      audit_section__mobile_ready: '¿Listo para el móvil?',
      audit_section__mobile_site_works:
        'Tu sitio web funciona correctamente en los dispositivos móviles',
      audit_section__no_screenshot: 'Captura de pantalla no disponible',
      audit_section__what_to_look_at: '¿Qué necesitas mirar?',
      audit_section__no_issues: 'No se ha encontrado ningún problema.',
      audit_section__what_done_well: '¿Qué has hecho bien?',
      audit_summary__overall_score:
        'Tu puntuación global es una media de las cuatro puntuaciones específicas que se detallan a continuación.',
      audit_summary__seo: 'Optimización de motores de búsqueda (SEO)',
      audit_summary__seo_2: 'Rendimiento del SEO',
      audit_summary__seo_bis: 'Optimización de los motores de búsqueda',
      audit_summary__perf: 'Rendimiento',
      audit_summary__perf_2: 'Velocidad de carga',
      audit_summary__security: 'Seguridad',
      audit_summary__security_2: 'La seguridad del sitio',
      audit_summary__mobile: 'Compatible con el móvil',
      audit_summary__mobile_2: 'La facilidad de uso de tu sitio en el móvil',
      audit_summary__pending:
        'Puntuación pendiente. Por favor, inténtalo más tarde.',
      audit_tab_menu__seo: 'SEO',
      audit_tab_menu__performance: 'Rendimiento',
      audit_tab_menu__security: 'Seguridad',
      audit_tab_menu__mobile: 'Móvil',
      backlink_modal__backlinks: 'Enlaces de retorno',
      backlink_modal__website: 'Sitio web',
      backlink_modal__trust_flow: 'Flujo de confianza',
      backlink_modal__citation_flow: 'Flujo de citas',
      backlink_modal__detected: 'Detectado',
      backlink_modal__detected_on: 'Detectado en',
      backlink_modal__close: 'Cierre',
      generic_modal__close: 'Cierre',
      generic_modal__ok: 'OK',
      google_button__sign_in: 'Iniciar sesión con Google',
      radial_chart__score: 'Puntuación',
      header__error: 'Lo siento, se ha producido un error',
      header__dashboard: 'Panel de control ',
      header__audit: 'Auditoría',
      header__alerts: 'Alertas',
      header__admin: 'Admin',
      header__policy: 'Política',
      header__terms: 'Términos',
      import_modal__title: 'Importar',
      import_modal__step_1:
        '1) Descarga opcionalmente nuestro archivo de plantilla',
      import_modal__step_2: '2) Cargar la hoja de cálculo para importar',
      import_modal__step_2_def:
        'Admitimos los siguientes tipos de archivos: CSV, XLS y XLSX',
      import_modal__download_template: 'Descargar plantilla',
      import_modal__upload: 'Subir a',
      import_modal__close: 'Cerrar',
      import_modal__next: 'Siguiente',
      import_wizard__template_error:
        'Lo sentimos, la hoja de cálculo que ha subido no es válida. Asegúrate de que existen todas las columnas necesarias o descargue nuestra plantilla.',
      import_wizard__import_clients: 'Importar clientes',
      import_wizard__confirm: 'Confirmar',
      no_results: 'No hay resultados disponibles',
      no_results__not_discovered:
        'Esto podría deberse a que este sitio web no ha sido descubierto por Google.',
      permissions_modal__edit: 'Permisos de edición',
      permissions_modal__tip:
        'Consejo: Es posible que los usuarios tengan que cerrar la sesión para ver los nuevos permisos que asignes.',
      permissions_modal__select: 'Selecciona',
      permissions_modal__user: 'Usuario',
      permissions_modal__admin: 'Administrador',
      permissions_modal__acc_owner: 'Propietario de la cuenta',
      permissions_modal__fulfilment: 'Cumplimiento de solicitudes',
      permissions_modal__close: 'Cierre',
      permissions_modal__update: 'Actualización',
      rate_limit__too_many_req: 'Demasiadas peticiones',
      rate_limit__try_again: 'Lo siento, por favor, inténtalo más tarde.',
      site_task__auto_plan: 'Plan de Automatización Activo',
      site_task__monitoring:
        'Estamos monitoreando tu sitio para cualquier cambio para asegurar la mejor experiencia del usuario y el tiempo de carga.',
      site_task__fast_load: 'Tiempos de carga rapidísimos',
      site_task__seo: 'SEO y correcciones de marcado',
      site_task__img_opti: 'Optimización de la imagen',
      site_task__sitemap: 'Generación del mapa del sitio web',
      site_task__server_config: 'Configuración del servidor',
      site_task__seo_plan: 'Plan SEO Activo',
      site_task__increased_vis:
        'Nuestro equipo de expertos trabaja para aumentar tu visibilidad en línea. Tareas actuales:',
      site_task__completed: 'Tarea completada',
      site_task__in_progress: 'Tarea en curso para la página:',
      site_task__view_all: 'Ver todo',
      audit_doctype__descr:
        'El doctype es el primer elemento del código fuente de una página web y debería formar parte de todo documento HTML. Sin uno, tu navegador entra en un modo bastante extraño. Parece que no tienes un Doctype, y aunque puedes arreglar esto tú mismo, es un poco complicado a menos que sepas cómo codificar. Te sugerimos que envíes esto a tu desarrollador para que lo resuelva.',
      audit_doctype__fail_msg:
        'Uh oh, tu Doctype necesita ser configurado correctamente',
      audit_doctype__fail_name: 'No hay tipo de documento',
      audit_doctype__pass_msg:
        'Gran trabajo, tu tipo de documento está configurado correctamente',
      audit_doctype__pass_name: 'Tipo de documento',
      audit_meta__descr:
        'Las meta etiquetas son fragmentos de texto que describen el contenido de una página en el código fuente de la misma. Aunque las meta etiquetas no aparecen en la propia página, son pequeños descriptores del contenido que indican a los motores de búsqueda como Google de qué trata una página web. Las meta etiquetas sólo existen en HTML, normalmente en la cabecera de la página, por lo que sólo son visibles para los motores de búsqueda (y las personas que saben dónde buscar). Hay que añadir meta etiquetas.',
      audit_meta__fail_msg:
        'Parece que faltan tus etiquetas meta en la(s) página(s) de $$issuecount',
      audit_meta__fail_name: 'No hay meta etiquetas',
      audit_meta__pass_msg: 'Tus etiquetas meta se ven bien',
      audit_meta__pass_name: 'Etiquetas meta',
      audit_h1__descr:
        "No se han encontrado etiquetas 'H1' (encabezados) en tu página. Sin embargo, son un elemento importante de cualquier sitio web, ya que estructuran el contenido y el texto de forma significativa y jerárquica. Los encabezados mejoran significativamente la legibilidad del contenido para los visitantes de un sitio. Esto funciona de forma similar con los motores de búsqueda, ya que ofrecen a Google una visión general del contenido de la página y la ponderación de los distintos subtemas. Debería añadir H1s a tu sitio web accediendo a tu sistema de gestión de contenidos y buscando la opción de encabezados.",
      audit_h1__fail_msg:
        'Uh oh, parece que te faltan las etiquetas H1 en las páginas de $$issuecount(s)',
      audit_h1__fail_name: 'Falta el título de la página (H1)',
      audit_h1__pass_msg:
        'Todas tus etiquetas H1 parecen ser visibles y estar correctamente formateadas',
      audit_h1__pass_name: 'Título de la página (H1)',
      audit_compression__descr:
        "Cuanto más pequeños sean los archivos necesarios para cargar una página web, más rápido se podrá cargar la página completa. Por tanto, comprimir los archivos HTML, CSS y JavaScript ayuda a mejorar la velocidad de la página. La compresión de estos archivos es posible acortando el código, por ejemplo, eliminando comentarios, saltos de línea, caracteres innecesarios y espacios. Además, el tamaño de los archivos puede comprimirse para su transferencia en el lado del servidor utilizando Gzip (un formato de archivo y una aplicación de software utilizados para la compresión y descompresión de archivos). La compresión como 'Gzip' no está habilitada o configurada para tu sitio. Es necesario cambiar esto. Sin embargo, como esta tarea puede ser un reto o estar restringida en tu CMS, te sugerimos que envíes esta tarea a tus desarrolladores.",
      audit_compression__fail_msg:
        'Parece que tienes la compresión desactivada. Puede ser difícil de arreglar, pero valdrá la pena.',
      audit_compression__fail_name: 'Compresión desactivada',
      audit_compression__pass_msg:
        'Parece que tu compresión está habilitada, gran cosa.',
      audit_compression__pass_name: 'Compresión activada',
      audit_content_rate__descr:
        'Sólo existe una pequeña cantidad de contenido en comparación con el HTML de la página. Al igual que el contenido débil, el contenido no válido es el que tiene poco o ningún valor para el usuario. Google considera como tales las páginas de entrada, las páginas de afiliación de baja calidad o simplemente las páginas con muy poco o ningún contenido. Resuelve esto produciendo contenido de alta calidad, relevante y original.',
      audit_content_rate__fail_msg:
        '¡Oh, oh! Todavía tienes que añadir más contenido en la(s) página(s) de $$issuecount',
      audit_content_rate__fail_name: 'Contenido no válido',
      audit_content_rate__pass_msg:
        'Buen trabajo. Ese contenido se ve muy bien',
      audit_content_rate__pass_name: 'Ningún contenido inválido',
      audit_content_size__descr:
        'El contenido delgado es un contenido que tiene poco o ningún valor para el usuario. Google considera que las páginas de entrada, las páginas de afiliación de baja calidad o simplemente las páginas con muy poco o ningún contenido son páginas con poco contenido. Pero no caigas en la trampa de producir sólo un montón de contenido muy similar: las páginas no originales y las páginas con contenido raspado y duplicado también se consideran páginas de contenido delgado. Además, a Google tampoco le gustan las páginas llenas de palabras clave y ahora puede distinguir entre el contenido valioso y el de baja calidad. Resuelve esto produciendo contenido de alta calidad, relevante y original.',
      audit_content_size__fail_msg:
        '¡Oh, oh! Parece que todavía tienes algo de contenido delgado en la(s) página(s) de $$issuecount. Arreglarlo mejorará tu ranking en Google y hará que tu sitio sea más fácil de usar',
      audit_content_size__fail_name: 'Contenido delgado encontrado',
      audit_content_size__pass_msg:
        'Buen trabajo. Todas las páginas de tu sitio muestran un contenido único y de calidad',
      audit_content_size__pass_name: 'No se ha encontrado contenido delgado',
      audit_content_read__descr:
        'El contenido de tu página puede ser difícil de leer. Hay muchos algoritmos diseñados para medir la legibilidad del contenido (esto es lo que utilizan también los robots y los motores de búsqueda). Uno de los algoritmos más populares para calcular la legibilidad del contenido es la puntuación de legibilidad de Flesch-Kincaid. Otros algoritmos son Gunning-Fog, Coleman-Liau, el índice SMOG, etc. Debe mejorar la legibilidad de tu contenido.',
      audit_content_read__fail_msg:
        'El contenido de la(s) página(s) de $$issuecount es todavía difícil de leer. Puede ser difícil de arreglar pero definitivamente vale la pena si quieres que tus usuarios se involucren con tu sitio',
      audit_content_read__fail_name: 'Escasa claridad de lectura',
      audit_content_read__pass_msg:
        '¡Buen trabajo! Tu contenido es fácil de leer y puede atraer a tus usuarios',
      audit_content_read__pass_name: 'Buena lectura',
      audit_html_old__descr: `Tu sitio contiene etiquetas HTML que ya no deben utilizarse. Generalmente, "deprecated" significa "algo desaprobado". En informática, los elementos obsoletos son aquellos que están permitidos, pero no se recomiendan y están siendo sustituidos por otros más nuevos. Por lo tanto, un 'código desaprobado' significa que no se recomienda su uso ya que hay una alternativa mejor en ese software para utilizar en su lugar. Intente utilizar una alternativa.`,
      audit_html_old__fail_msg:
        'Uh oh, parece que todavía tienes algunas etiquetas HTML obsoletas en las páginas de $$issuecount',
      audit_html_old__fail_name: 'Etiquetas HTML obsoletas',
      audit_html_old__issue: 'Etiquetas HTML obsoletas encontradas',
      audit_html_old__pass_msg:
        'Good work! All of your HTML tags look shiny, new, and beautiful!',
      audit_html_old__pass_name: 'No hay etiquetas HTML obsoletas',
      audit_meta_descr__descr:
        'Una de tus páginas contiene varias etiquetas meta de "descripción", lo que puede hacer que Google muestre la incorrecta. Sólo debería tener una en cada página. Ni las descripciones meta ni las palabras clave meta influyen en los algoritmos de clasificación de Google para la búsqueda web. Sin embargo, las descripciones meta pueden influir en el CTR (porcentaje de clics) de una página en Google, lo que puede influir positivamente en la capacidad de clasificación de una página. Por esta razón, entre otras, es importante poner algo de esfuerzo en las descripciones meta.',
      audit_meta_descr__fail_msg:
        'Parece que todavía tienes varias etiquetas de descripción en la(s) página(s) de $$issuecount',
      audit_meta_descr__fail_name: 'Descripciones múltiples',
      audit_meta_descr__pass_msg:
        'Buen trabajo. Todas tus etiquetas meta se ven bien',
      audit_meta_descr__pass_name: 'Una descripción',
      audit_meta_tags__descr:
        "Algunas etiquetas meta en tu sitio han sido duplicadas, elimínalas para que sólo tengas una de cada tipo de etiqueta 'meta'.",
      audit_meta_tags__fail_msg:
        'Oh oh, parece que todavía tienes algunas etiquetas meta duplicadas en la(s) página(s) de $$issuecount',
      audit_meta_tags__fail_name: 'Etiquetas duplicadas',
      audit_meta_tags__issue: 'Se han encontrado etiquetas meta duplicadas',
      audit_meta_tags__pass_msg:
        'Buen trabajo. Todos tus meta tags se ven bien',
      audit_meta_tags__pass_name:
        'No se han encontrado etiquetas meta duplicadas',
      audit_dup_titles__descr:
        'Parece que tienes varias etiquetas <title> en tu página, sólo deberías usar una. La etiqueta de título ayuda tanto a los usuarios como a los motores de búsqueda a entender rápidamente qué contenido pueden esperar encontrar en tu página. Si hay varias etiquetas de título en la página, puede significar que los motores de búsqueda muestren la etiqueta "equivocada", lo que a su vez puede conducir a una menor participación o CTR de los resultados de búsqueda. También puede tener un impacto negativo en el SEO.',
      audit_dup_titles__fail_msg:
        '¡Oh, oh! Todavía tienes múltiples etiquetas de título en la(s) página(s) de $$issuecount. Arregla esto pronto.',
      audit_dup_titles__fail_name: 'Varios títulos',
      audit_dup_titles__issue: 'Títulos duplicados encontrados',
      audit_dup_titles__pass_msg:
        'Excelente trabajo. Todas tus etiquetas de título se ven bien',
      audit_dup_titles__pass_name: 'No se han encontrado títulos duplicados',
      audit_favicon__descr:
        "No se ha podido encontrar ningún 'favicon' para tu sitio. Sin embargo, un favicon aporta muchos beneficios indirectos para el SEO de tus páginas. Tener un favicon junto al título de tu sitio web en las pestañas del navegador, los marcadores y los archivos del historial, etc., ayuda a un usuario a ahorrar tiempo, ya que puede identificar y navegar por tu sitio web sin dificultades. Si no hay un favicon para tu sitio, también puedes perder la oportunidad de estar en la lista de favoritos de un navegador Chrome y, por lo tanto, perder indirectamente una pequeña pero significativa señal de clasificación de búsqueda. Deberías conseguir un favicon para tu sitio.",
      audit_favicon__fail_msg:
        '¡Oh, oh! Parece que tu favicon sigue sin aparecer',
      audit_favicon__fail_name: 'No hay Favicon en tu sitio web',
      audit_favicon__pass_msg: 'Grandes noticias, tienes un favicon',
      audit_favicon__pass_name: 'Favicon en tu sitio web',
      audit_robot__descr:
        'No pudimos encontrar un archivo de robots para tu sitio, pero este es un archivo importante para los motores de búsqueda. Robots.txt es un archivo de texto que los webmasters crean para instruir a los robots de la web (normalmente los robots de los motores de búsqueda) sobre cómo rastrear las páginas de tu sitio web. El archivo robots.txt forma parte del protocolo de exclusión de robots (REP), un grupo de normas web que regulan el modo en que los robots rastrean la web, acceden al contenido y lo indexan, y sirven ese contenido a los usuarios. Obtenga un archivo de robots para tu sitio o habla con tu desarrollador.',
      audit_robot__fail_msg:
        'Oh, oh... Parece que tu archivo de robots sigue sin aparecer',
      audit_robot__fail_name: 'Archivo de robots desaparecidos',
      audit_robot__pass_msg:
        'Tu archivo de robots ha sido añadido y se ve bien',
      audit_robot__pass_name: 'Archivo de robots',
      audit_sitemap__descr:
        'No pudimos encontrar un mapa del sitio. Un mapa del sitio es un plano de tu sitio web que ayuda a los motores de búsqueda a encontrar, rastrear e indexar todo el contenido de tu sitio web. Los mapas del sitio también indican a los motores de búsqueda qué páginas de tu sitio son las más importantes. Añadir un mapa del sitio es complicado, pero mejorará significativamente tu posicionamiento SEO. Añade un mapa del sitio o habla con tu desarrollador.',
      audit_sitemap__fail_msg:
        'Parece que todavía te falta un mapa del sitio. Añadir un mapa del sitio es complicado, pero tener uno mejorará significativamente tu posicionamiento SEO',
      audit_sitemap__fail_name: 'No se ha encontrado ningún sitio web',
      audit_sitemap__pass_msg: 'Gran trabajo, ¡tienes un mapa del sitio!',
      audit_sitemap__pass_name: 'Mapa del sitio encontrado',
      audit_no_alt__descr:
        'Las imágenes de tu sitio no siempre incluyen una etiqueta "alt". Tener etiquetas alt es una buena práctica para la accesibilidad de la web (ayudar a los usuarios con problemas de visión a entender una imagen en la página), y es útil para aumentar tu visibilidad en los motores de búsqueda. Añada texto alternativo a las fotos.',
      audit_no_alt__fail_msg:
        'Tus imágenes siguen sin etiquetas alt en la(s) página(s) de $$issuecount',
      audit_no_alt__fail_name: 'Falta de etiquetas Alt en tus imágenes',
      audit_no_alt__pass_msg:
        'Muy bien. Todas tus imágenes tienen etiquetas alt. ¡Eres un héroe de la accesibilidad!',
      audit_no_alt__pass_name: 'Etiquetas Alt en tus imágenes',
      audit_links__descr:
        'Nos hemos dado cuenta de que algunos de los enlaces de tu sitio están rotos. Los motores de búsqueda ven los enlaces como un voto a la calidad de un sitio web. Los enlaces a tu sitio web y los enlaces dentro de tu sitio web pueden afectar a la posición que ocupa tu sitio web en los resultados de búsqueda. Por ello, es una buena práctica eliminar o actualizar los enlaces rotos.',
      audit_links__fail_msg:
        '¡Oh, oh! Parece que todavía tienes algunos enlaces rotos en la(s) página(s) de $$issuecount',
      audit_links__fail_name: 'Enlaces rotos que apuntan a tu sitio web',
      audit_links__pass_msg:
        'Buen trabajo. Todos tus enlaces funcionan de maravilla',
      audit_links__pass_name: 'No hay enlaces rotos que apunten a tu sitio web',
      audit_empty_meta_descr__descr:
        'La etiqueta meta description existe, pero no parece tener un valor. La descripción meta se considera importante, ya que ayuda a los usuarios a entender rápidamente qué contenido pueden esperar encontrar en la página al hacer clic desde la página de resultados del motor de búsqueda. Las descripciones meta bien escritas suelen conseguir un mayor porcentaje de clics. Si la descripción meta está vacía, representa una oportunidad de optimización perdida. Además, esto significa que los motores de búsqueda escribirán la descripción meta por ti, a veces con resultados bastante desastrosos. Redacta una descripción meta bien redactada que coincida con el contenido de tu página.',
      audit_empty_meta_descr__fail_msg:
        'Tu descripción meta parece faltar en la(s) página(s) de $$issuecount',
      audit_empty_meta_descr__fail_name:
        'Se han encontrado descripciones META en blanco',
      audit_empty_meta_descr__pass_msg: 'Tu descripción meta se ve bien',
      audit_empty_meta_descr__pass_name:
        'No se han encontrado descripciones META en blanco',
      audit_wrong_meta_descr__descr:
        'Parece que la etiqueta de descripción meta podría no coincidir con el contenido real de tu página. La descripción meta se considera importante para ayudar a los usuarios a entender rápidamente qué contenido pueden esperar encontrar en la página al hacer clic desde la página de resultados del motor de búsqueda. Las descripciones meta bien escritas suelen conseguir un mayor porcentaje de clics. Si la descripción meta está vacía, representa una oportunidad de optimización perdida. Además, esto significa que los motores de búsqueda escribirán la descripción meta por ti, a veces con resultados bastante desastrosos. Redacta bien las descripciones meta que coincidan con el contenido de tu página.',
      audit_wrong_meta_descr__fail_msg:
        'Lo sentimos, tienes una descripción meta inapropiada en la(s) página(s) de $$issuecount. Intenta incluir un breve resumen del contenido de la página en la meta descripción',
      audit_wrong_meta_descr__fail_name: 'Descripciones META inadecuadas',
      audit_wrong_meta_descr__pass_msg:
        '¡Gran trabajo! La descripción meta coincide con el contenido de la página',
      audit_wrong_meta_descr__pass_name:
        'No hay descripciones META inapropiadas',
      audit_page_size__descr:
        'HTML roto o contenido no compatible en tus páginas: Si Googlebot no puede analizar el contenido de la página, no podrá rastrearlas. Utiliza Fetch as Google para ver cómo ve Googlebot tu página.',
      audit_page_size__fail_msg:
        'Oh oh, parece que todavía tienes tamaños de contenido no válidos en la(s) página(s) de $$issuecount',
      audit_page_size__fail_name:
        'Las páginas del sitio web tienen tamaños no válidos',
      audit_page_size__pass_msg:
        'Gran trabajo, los tamaños de las páginas se ven bien',
      audit_page_size__pass_name:
        'Las páginas del sitio web tienen tamaños válidos',
      audit_flash__descr:
        'Las arañas de los motores de búsqueda no pueden rastrear Flash para averiguar qué hay en esa zona. El foro de soporte para webmasters de Google dice lo siguiente: "En general, los motores de búsqueda se basan en el texto. Esto significa que para ser rastreado e indexado, su contenido debe estar en formato de texto. Esto no significa que no pueda incluir contenido multimedia enriquecido como Flash, Silverlight o vídeos en su sitio; sólo significa que cualquier contenido que incruste en estos archivos debe estar también disponible en formato de texto o no será accesible para los motores de búsqueda."',
      audit_flash__fail_msg:
        'Parece que aún tienes contenido flash en la(s) página(s) de $$issuecount',
      audit_flash__fail_name: 'Parece que sigues usando Flash',
      audit_flash__pass_msg:
        'Buen trabajo, todo tu contenido multimedia es amigable con los motores de búsqueda',
      audit_flash__pass_name: 'Parece que no estás usando Flash',
      audit_iframe__descr:
        'Google tiene cierta capacidad para rastrear el contenido en iFrame en las páginas web si el iFrame es enlace SEO comprometido. Se sabe que Google reconoce el iFrame a través de enlaces, pero sigue siendo mejor desarrollar tu sitio web sin iFrame.',
      audit_iframe__fail_msg:
        'Uh oh, todavía se muestra iFrame en la(s) página(s) de $$issuecount',
      audit_iframe__fail_name: 'iFrame encontrado dentro de tu sitio web',
      audit_iframe__pass_msg:
        'Buen trabajo, no se encontró ningún iFrame en tu',
      audit_iframe__pass_name: 'No se encuentran iframes en tu sitio web',
      audit_lorem__descr:
        'Los desarrolladores utilizan el texto Lorem ipsum como marcador de posición para el diseño de la página. Después de configurar la plantilla, todo ese texto debe ser sustituido por un tema único y relevante para el sitio web, de lo contrario los motores de búsqueda pueden considerar que la página no es relevante o está duplicada. Sustitúyalo por algún contenido relevante para mejorar tu puntuación SEO.',
      audit_lorem__fail_msg:
        'Parece que todavía tienes algún texto de marcador de posición en la(s) página(s) de $$issuecount. Sustitúyelo por algún contenido relevante para mejorar tu puntuación SEO',
      audit_lorem__fail_name: 'Lorem Ipsum encontrado',
      audit_lorem__pass_msg:
        'No se ha encontrado ningún texto marcador de posición en tu página web, ¡buen trabajo!',
      audit_lorem__pass_name: 'No se ha encontrado Lorem Ipsum',
      audit_400__descr:
        'Los errores con código de estado HTTP 4xx son básicamente errores del lado del cliente. Esto puede deberse a un problema de autenticación o de formato de representación, etc. Así que el problema está en el lado del cliente. Comprueba la consola para obtener una descripción completa del error. Esto incluye el código de estado completo, por ejemplo (400,401,etc). Cada código se refiere a un problema diferente del lado del cliente. Conoce el problema y aplica las correcciones correspondientes.',
      audit_400__fail_msg: '¡Uh, oh! Sigue apareciendo un error 4xx',
      audit_400__fail_name: 'Errores 4xx encontrados en tu página web',
      audit_400__pass_msg:
        'No se han encontrado errores 4xx en tu página web, ¡buen trabajo!',
      audit_400__pass_name: 'No se han encontrado errores 4xx en tu página web',
      audit_500__descr:
        'Un error de servidor 5xx es un error que se produce cuando el servidor no satisface una solicitud. Hay 10 tipos de este error. Es bastante difícil detectar y arreglar cada aparición de estos errores, pero a los motores de búsqueda no les gustan, especialmente los errores 500 y 503.',
      audit_500__fail_msg: '¡Uh, oh! Sigue apareciendo un error 5xx',
      audit_500__fail_name: 'Se han encontrado 5xx errores',
      audit_500__pass_msg: 'No se han encontrado errores 5xx, ¡buen trabajo!',
      audit_500__pass_name: 'No se han encontrado errores 5xx',
      audit_seo_url__descr:
        'Las URLs optimizadas para el SEO son URLs que están diseñadas para satisfacer las necesidades de los usuarios y de los buscadores. En concreto, las URL optimizadas para el SEO tienden a ser cortas y ricas en palabras clave. Asegúrate de que tu URL es concisa e incluye una palabra clave relevante.',
      audit_seo_url__fail_msg:
        'Las URLs de las páginas de $$issuecount$$ aún no son amigables para el SEO. Asegúrate de que tu URL es concisa e incluye una palabra clave relevante',
      audit_seo_url__fail_name: 'Esto no es una URL amigable para el SEO',
      audit_seo_url__pass_msg: 'Muy buen trabajo. ¡Este URL se ve muy bien!',
      audit_seo_url__pass_name: 'Este es un URL amigable para el SEO',
      audit_ssl__descr:
        'Puedes estar seguro de que tener un certificado SSL en tu sitio es valioso. Muestra a los visitantes que tu sitio está verificado y que está a salvo de los hackers. Además, mejora tu posicionamiento SEO. Consigue un certificado SSL.',
      audit_ssl__fail_msg:
        'Las páginas de $$issuecount siguen apareciendo como páginas no seguras',
      audit_ssl__fail_name: 'Necesitas un certificado SSL',
      audit_ssl__pass_msg:
        '¡Tiene buen pinta! Tienes un certificado SSL, así que tus usuarios sabrán que tu sitio es seguro',
      audit_ssl__pass_name: 'Tienes un certificado SSL instalado',
      audit_ssl_valid__descr:
        'Puedes estar seguro de que tener un certificado SSL en tu sitio es valioso. Muestra a los visitantes que tu sitio está verificado y que está a salvo de los hackers. Además, mejora tu posicionamiento SEO.',
      audit_ssl_valid__fail_msg:
        'La página $$issuecount se muestra como una página no segura',
      audit_ssl_valid__fail_name: 'Certificado SSL inválido',
      audit_ssl_valid__pass_msg:
        '¡Tiene buena pinta! Tus usuarios sabrán que tu sitio es seguro',
      audit_ssl_valid__pass_name: 'Certificado SSL válido',
      audit_test_server__descr:
        "Cuando un usuario solicita una URL de tu sitio web, el servidor en el que está alojado tu sitio web, devolverá un código de estado del servidor de cabecera. Lo ideal es que el servidor devuelva un código de estado '200 OK' para informar al navegador de que todo está bien con la página y que la página existe en la ubicación solicitada. Hay errores del servidor que deben ser corregidos.",
      audit_test_server__fail_msg:
        '¡Oh, oh! Tu sitio sigue mostrando un error del servidor',
      audit_test_server__fail_name: 'Errores en el servidor',
      audit_test_server__pass_msg:
        'El error del servidor está arreglado, ¡bien hecho!',
      audit_test_server__pass_name:
        'No se han encontrado errores en el servidor',
      audit_load_high__descr:
        'Una velocidad lenta del sitio web perjudica tu clasificación y la experiencia de los usuarios. Google ha indicado que la velocidad del sitio (y, en consecuencia, la velocidad de la página) es una de las señales que utiliza su algoritmo para clasificar las páginas. Además, una velocidad de página lenta significa que los motores de búsqueda pueden rastrear menos páginas utilizando su presupuesto de rastreo asignado, y esto podría afectar negativamente a su indexación. La velocidad de la página también es importante para la experiencia del usuario. Las páginas con un tiempo de carga más largo tienden a tener tasas de rebote más duras, los usuarios pasan menos tiempo en la página y las conversiones se ven afectadas negativamente. Trabaje para mejorar la velocidad de tu sitio web.',
      audit_load_high__fail_msg:
        '¡Oh, oh! Tu sitio web sigue cargando muy lentamente',
      audit_load_high__fail_name: 'La velocidad de tu sitio web no es buena',
      audit_load_high__pass_msg:
        '¡Gran trabajo! ¡Tu sitio web se carga a la velocidad del rayo!',
      audit_load_high__pass_name: 'La velocidad de tu sitio web es genial',
      audit_dup_title__descr:
        'A veces sucede que quieres la misma página en dos lugares diferentes de tu sitio web. Imagina que tienes un producto que ofreces a tus clientes comerciales y también a tus clientes particulares. Entonces podrías querer poner esta página en dos lugares/URLs diferentes en tu sitio web. Ejemplo: www.example.com/business/myproduct.html (Título de la página: "Mi producto")\\Nwww.example.com/private/myproduct.html (Título de la página: "Mi producto") Ambas páginas tienen derecho a existir, pero Google no sabrá cuál es la más potente (la original), por lo que dividirá más o menos el link juice (poder de posicionamiento) de las dos páginas. En este caso debes utilizar la etiqueta rel="canonical". La etiqueta rel="canonical" debe colocarse en la página duplicada y debe apuntar a la página original. Si el original de nuestros ejemplos anteriores debe ser la página en la carpeta de negocios, entonces la etiqueta rel="canonical" debe encontrarse en la página en la carpeta privada (la página duplicada) y debe tener este aspecto: <link rel="canonical" href="www.example.com/business/myproduct.html" />',
      audit_dup_title__fail_msg:
        'La(s) página(s) de $$issuecount todavía tiene(n) etiquetas de título duplicadas',
      audit_dup_title__fail_name: 'Tienes etiquetas de título duplicadas',
      audit_dup_title__pass_msg:
        'Esto se ve muy bien. El título parece lo suficientemente diferente como para que ambas páginas se posicionen bien',
      audit_dup_title__pass_name: 'No tiene etiquetas de título duplicadas',
      audit_empty_title__descr:
        'La etiqueta del título se considera importante para ayudar tanto a los usuarios como a los motores de búsqueda a entender rápidamente qué contenido pueden esperar encontrar en la página. La etiqueta del título se considera uno de los factores más importantes del SEO en la página, por lo que su ausencia representa un problema que puede afectar a la clasificación en los motores de búsqueda y al porcentaje de clics en los resultados de búsqueda. Intenta añadir alguna para mejorar el rendimiento del SEO.',
      audit_empty_title__fail_msg:
        'Uh oh, todavía parece que te faltan algunas etiquetas en la(s) página(s) de $$issuecount. Intenta añadir algunas para mejorar el rendimiento SEO',
      audit_empty_title__fail_name:
        'Las etiquetas de los títulos de tus sitios web están vacías',
      audit_empty_title__pass_msg:
        'Bien hecho, ¡las etiquetas tienen buena pinta!',
      audit_empty_title__pass_name:
        'Las etiquetas de los títulos de tus sitios web no están vacías',
      audit_wrong_title__descr:
        'Haz que tus títulos sean más relevantes y menos spam, no te limites a enumerar palabras clave y asegúrate de que escribes pensando primero en el usuario. Asegúrate de que son claros, concisos y transmiten el valor del contenido.',
      audit_wrong_title__fail_msg:
        'Parece que todavía tienes que retocar la(s) página(s) de $$issuecount. Asegúrate de que son claras, concisas y transmiten el valor del contenido',
      audit_wrong_title__fail_name: 'Títulos inapropiados en tu página web',
      audit_wrong_title__issue: 'Títulos inapropiados encontrados',
      audit_wrong_title__pass_msg:
        'Excelente trabajo. Esos títulos son geniales',
      audit_wrong_title__pass_name:
        'No se encuentran títulos inapropiados en tu página web',
      audit_long_title__descr:
        'Google suele mostrar los primeros 50-60 caracteres de una etiqueta de título. Si mantienes tus títulos con menos de 60 caracteres, nuestra investigación sugiere que puedes esperar que alrededor del 90% de tus títulos se muestren correctamente. Los títulos de tu sitio web son demasiado largos. Prueba con algo más corto.',
      audit_long_title__fail_msg:
        'Lo siento, los títulos de tu sitio web siguen siendo demasiado largos. Pruebe con algo más corto',
      audit_long_title__fail_name:
        'Tu sitio web tiene títulos demasiado largos',
      audit_long_title__issue: 'Títulos largos encontrados',
      audit_long_title__pass_msg:
        'Genial. Los títulos de tu página web tienen una gran longitud.',
      audit_long_title__pass_name:
        'Tu sitio web no tiene títulos demasiado largos',
      audit_short_title__descr:
        'Google suele mostrar los primeros 50-60 caracteres de una etiqueta de título. Si mantienes tus títulos con menos de 60 caracteres, nuestra investigación sugiere que puedes esperar que alrededor del 90% de tus títulos se muestren correctamente. Los títulos de tu sitio web son demasiado cortos.',
      audit_short_title__fail_msg:
        'Lo siento, los títulos de tu sitio web siguen siendo demasiado cortos.',
      audit_short_title__fail_name:
        'Tu sitio web tiene títulos demasiado cortos',
      audit_short_title__issue: 'Títulos cortos encontrados',
      audit_short_title__pass_msg:
        'Genial. Los títulos de tu página web tienen una gran longitud.',
      audit_short_title__pass_name:
        'Tu sitio web no tiene títulos demasiado cortos',
      audit_label__easy: 'Facíl ',
      audit_label__medium: 'Medio',
      audit_label__difficult: 'Dificíl',
      audit_title: 'Audite su Sitio',
      audit_continuous:
        'Auditamos continuamente tu sitio web y te mantenemos al día sobre lo que hay que arreglar y cómo hacerlo.',
      audit_seo__short: 'SEO',
      audit_seo__description:
        'La optimización SEO es importante para garantizar que puedas maximizar el potencial de clasificación y dirigir el tráfico a tu sitio web desde los motores de búsqueda. Debe asegurarse de que tu página cumple con las mejoras comunes recomendadas aquí antes de pasar a estrategias de SEO más avanzadas.',
      audit_performance: 'Rendimiento',
      audit_performance__descr:
        'El rendimiento es importante para garantizar una buena experiencia de usuario y reducir las tasas de rebote (que también pueden afectar indirectamente a tu clasificación en los motores de búsqueda) Continúa supervisando tu rendimiento a lo largo del tiempo para asegurarte de que no hay fluctuaciones periódicas.',
      audit_security: 'Seguridad',
      audit_security__descr:
        'La seguridad es importante para garantizar que tu sitio web proteja los datos de los usuarios, que no se vea comprometido ni sufra un tiempo de inactividad o una pérdida de datos. Recomendamos la aplicación continua de parches y el uso permanente de herramientas de supervisión de la protección.',
      audit_mobile: 'Móvil',
      audit_mobile__descr:
        'Cada vez es más importante que las empresas tengan un sitio web apto para móviles. No sólo puede ayudar a los clientes a llegar más fácilmente cuando están en movimiento, sino que tendrá un impacto en la optimización de los motores de búsqueda.',
      audit_fix_issue: 'Solucionar el problema',
      savings_table__keyword: 'Palabra clave',
      savings_table__keyword_explained:
        'Si tenemos una URL en la que la palabra clave está clasificada en tu dominio, la palabra clave se convertirá en un enlace',
      savings_table__position: 'Posición',
      savings_table__cpc: 'CPC',
      savings_table__cpc_explained: 'Coste por clic',
      savings_table__monthly_search: 'Búsquedas mensuales',
      savings_table__savings: 'Ahorro',
      competitors_table__modal_title:
        'Palabras clave utilizadas para comparar la competencia',
      competitors_table__table_header: 'Palabras clave ',
      screenshot__not_available: 'A la espera de la captura de pantalla',
      english: 'Inglés',
      dutch: 'Holandés',
      french: 'Francés',
      german: 'Alemán',
      espanol: 'Español ',
      portuguese: 'Portugués',
      italian: 'Italiano ',
      turkish: 'Turco',
      wp_auto__header_1: 'Buenas noticias. Parece que estás utilizando',
      wp_auto__header_2: 'WordPress',
      wp_auto__header_3:
        'Lo que significa que podemos intentar solucionar muchos problemas por ti de forma automática.',
      wp_auto__header_4:
        'Pero para que podamos hacerlo, necesitarás introducir tu',
      wp_auto__header_5: 'usuario ',
      wp_auto__header_6: 'y ',
      wp_auto__header_7: 'contraseña',
      wp_auto__header_8:
        'y asegúrate de que la URL del apartado de administración es correcta.',
      wp_auto__form_1: 'URL ',
      wp_auto__form_2:
        'Asumimos que la sección de administración de tu sitio es la establecida por defecto por wordpress, si no es así por favor edítala.',
      wp_auto__form_3: 'Usuario ',
      wp_auto__form_4: 'Contraseńa',
      wp_auto__form_installed: 'instalado',
      wp_auto__form_success_header:
        'Los plugins se han instalado y configurado correctamente.',
      wp_auto__form_success_msg:
        'Deberías empezar a ver mejoras con tu próxima auditoría.',
      wp_auto__form_success_breakdown:
        'A continuación se detalla lo que se ha instalado y configurado en tu sitio:',
      wp_auto__request_error:
        'No se ha podido acceder al servidor, inténtalo más tarde.',
      wp_auto__page_not_found: 'No se ha podido encontrar la URL proporcionada',
      wp_auto__login_not_found:
        'No se ha podido encontrar el formulario de acceso, por favor, comprueba la URL',
      wp_auto__login_error:
        'Has introducido un nombre de usuario o una contraseña no válidos',
      wp_auto__file_upload_error:
        'Los plugins no pudieron ser cargados, el usuario necesita ser un administrador para que esto funcione',
      wp_auto__loading_msg_1:
        'Intentando encontrar el formulario de acceso y utilizar las credenciales...',
      wp_auto__loading_msg_2: 'Subir plugins a tu sitio de wordpress...',
      wp_auto__loading_msg_3:
        'Instalar y configurar los plugins, esto puede llevar unos minutos...',
      uptime__header_title: 'Historial del tiempo de actividad',
      uptime__header_status: 'Estado actual',
      uptime__table_date: 'Fecha',
      uptime__table_time: 'Hora (UTC)',
      uptime__table_status: 'Estado',
      uptime__table_no_data: 'No hay datos disponibles',
      all: 'todo',
      online: 'online',
      offline: 'sin conexión',
      request_help__cta: 'Solicitar ayuda',
      request_help__success_toast: '¡Correo electrónico enviado con éxito!',
      request_help__header_li: 'Nombre de la incidencia',
      request_help__header_li_2: 'Descripción de la incidencia',
      request_help__header_li_3: 'Páginas con la incidencia',
      request_help__description_p:
        'Envía una copia de esta incidencia a tu desarrollador web',
      request_help__error_bad_request:
        'Los datos enviados no son correctos, por favor, ponte en contacto con soporte',
      request_help__error_unauthorized:
        'Permisos no válidos, por favor, ponte en contacto con tu administrador',
      request_help__form_subject: 'Asunto',
      request_help__form_subject_placeholder: 'asunto del correo electrónico',
      request_help__form_to: 'Para',
      request_help__form_to_placeholder:
        'correo electrónico del desarrollador web',
      request_help__form_cc: 'Cc',
      request_help__form_cc_placeholder: 'tu dirección de correo electrónico',
      request_help__form_name: 'Nombre/Empresa',
      request_help__form_name_placeholder: 'tu nombre o el de tu empresa',
      request_help__form_comments: 'Comentarios',
      request_help__form_comments_placeholder: 'comentarios opcionales',
      request_help__form_required: 'requerido',
      request_help__form_send: 'Enviar',
      top_issues__title: 'Principales cuestiones de auditoría',
      info_box__domain: 'dominio',
      info_box__last_crawled: 'último rastreo',
      info_box__cms: 'cms detectados',
      info_box__status: 'estado',
      admin_setup__navbar_styling: 'Estilo de la barra de navegación',
      audit_section__improve: 'Mejorar',
      site__audit_in_progress:
        'Actualmente estamos auditando tu sitio. Cuando la auditoría finalice, el sitio se actualizará automáticamente.',
      site__audits_in_progress:
        'Actualmente estamos auditando tus sitios. Cuando las auditorías finalicen, el sitio se actualizará automáticamente.',
    },
  },
  nl: {
    translation: {
      monthly_savings: 'Geschatte maandelijkse besparingen',
      welcome: 'Welkom',
      show_less: 'Minder tonen',
      show_more: 'Meer tonen',
      download: 'Download',
      ranking_graph__rank_unknown: 'Rang beweging kon niet worden berekend',
      ranking_graph__last_update: 'Laatste update: ',
      ranking_graph__go_back: 'Terug',
      ranking_graph__calculation_error: 'We konden de rang niet berekenen van',
      ranking_graph__try_again_one_week: 'Probeer het over een week nog eens.',
      ranking_graph__open_graph: 'Open grafiek',
      ranking_graph__rank_no_movement:
        'De rang is niet veranderd sinds vorige week.',
      ranking_graph__down: 'Beneden',
      ranking_graph__up: 'Boven',
      ranking_graph__positions_last_week: 'posities sinds vorige week',
      ranking_summary__not_ranking:
        'Het ziet er niet naar uit dat je op deze term scoort.',
      ranking_summary__updating:
        'De informatie over de rangschikking wordt nog bijgewerkt.',
      ranking_summary__rank: 'Rang',
      ranking_info__data_visualisation: 'Visualisatie van gegevens',
      ranking_info__announce_graph:
        'Goed nieuws, zodra we genoeg informatie hebben geregistreerd, zullen we je informatie hier in een interactieve grafiek presenteren',
      ranking_info__announce_graph_2: 'Hou deze plaats in de gaten.',
      ranking_info__no_results: 'Je scoort nog niet voor populaire zoekwoorden',
      export_csv__url: 'URL',
      export_csv__page_title: 'Pagina Titel',
      export_csv__trust_flow: 'Vertrouwensstroom',
      export_csv__citation_flow: 'Citatiestroom',
      export_csv__target_url: 'Doel-URL',
      export_csv__date_discovered: 'Datum Ontdekt',
      export_csv__date_removed: 'Datum Verwijderd',
      export_csv__link_type: 'Link Type',
      search_engine__label:
        "Wij zorgen ervoor dat alle pagina's van je website gevonden worden op",
      search_engine__info_icon:
        '"Word gevonden op alle grote zoekmachines, wij nemen weg van de complexiteit om je website overal online gevonden te krijgen en hebben je nu opgenomen op de meest populaire beschikbare zoekmachines."',
      search_engine__index_completed: 'Index voltooid',
      search_engine__index_pending: 'Index in behandeling',
      keywords__edit: 'Bewerken',
      keywords__ranking_on:
        'Positie in zoekmachines bij het zoeken op deze trefwoorden',
      keywords__graph_after_7_days:
        '"We hebben 3 populaire trefwoorden gevonden waarvan we weten dat ze verkeer naar je website sturen. Na 7 dagen zullen je gegevens worden getoond in een live grafiek. Houd rekening met enige tijd na het toevoegen van nieuwe zoekwoorden voordat de gegevens worden bijgewerkt."',
      keywords__change: 'Wijzig je trefwoorden',
      keywords__modal_info:
        'Je trefwoorden zijn onlangs bijgewerkt, kom over 10 minuten terug naar deze pagina om je resultaten te bekijken.',
      sitemap_fix__1: 'We ontdekten en voegden in totaal',
      sitemap_fix__2: "pagina's aan je sitemap.",
      sitemap_fix__3: 'Download Sitemap',
      speed_fix__1: 'Wat doe ik eraan dat mijn website traag laadt?',
      speed_fix__2:
        'Als er één ding waar is voor je website, dan is het wel dat laadsnelheid belangrijk is. Als je site traag laadt, heb je niet alleen een slechte gebruikerservaring, maar rank je ook lager in de zoekmachines.',
      speed_fix__3: '1. Caching inschakelen',
      speed_fix__4:
        '"Telkens wanneer je een website bezoekt, worden bepaalde elementen opgeslagen in een cache, zodat de volgende keer dat je de site bezoekt het gemakkelijk toegang kan krijgen die onderdelen en veel sneller laden. Met caching, in plaats van dat je browser elke bron moet downloaden, hoeft hij maar maar een paar van hen te downloaden."',
      speed_fix__5:
        '"Door caching aan te zetten kun je het laden van je site laden voor terugkerende bezoekers. Als je een CMS zoals WordPress gebruikt kunt je een plugin installeren zoals W3 Total Cache of W3 Super Cache, waarmee je site-breed caching kunt inschakelen, of caching van bepaalde site-elementen."',
      speed_fix__6: '2. Verwijder Plugins en Add-ons die veel bronnen belasten',
      speed_fix__7:
        '"Als je site op dit moment te veel plugins heeft, kan dat mogelijk je website vertragen. Je hebt misschien een handvol plugins nodig om je site te laten functioneren zoals jij dat wilt, maar de kans is groot dat er een paar zijn waarbij je zonder kunt, vooral als ze resource hogs zijn. "',
      speed_fix__8:
        '"De beste manier om dit te doen is om een baseline test van je laadsnelheid snelheid via een tool als GTMetrix of Google Pagespeed Insights. Dan, ga je je plugin-lijst af en deactiveer je één plugin per keer. Vervolgens voer je de snelheidstest uit met de plugin gedeactiveerd. "',
      speed_fix__9:
        '"Dit kan tijdrovend zijn, maar het zal je helpen de plugins te vinden die de laadsnelheid van je site het meest schaden. Op dat punt, kun je zoeken naar een minder zware plugin of een andere workaround vinden."',
      speed_fix__10:
        '3. Optimaliseer en verklein de grootte van je afbeeldingen',
      speed_fix__11:
        '"Als je site bergen afbeeldingen heeft die niet geoptimaliseerd zijn, dan zal dit een negatieve invloed hebben op de laadsnelheid van je site. Door het hebben van te grote afbeeldingen, moet de browser grotere bestanden te laden. Er zijn een paar verschillende manieren om je afbeeldingen te optimaliseren om sneller te laden."',
      speed_fix__12:
        '"Zorg ervoor dat je afbeeldingen niet onnodig groot zijn. Bijvoorbeeld, als de breedte van je blogpagina 900px is, zorg er dan voor dat je afbeeldingen inderdaad zo breed zijn."',
      speed_fix__13:
        '"Als je een CMS zoals WordPress gebruikt, kun je een plugin installeren zoals WPSmush die automatisch de bestandsgrootte van de afbeeldingen verkleint."',
      speed_fix__14:
        '"Voordat je afbeeldingen uploadt, moet je ze eerst door een programma genaamd Tiny PNG halen om de bestandsgrootte van je afbeelding te verkleinen, zonder de kwaliteit te verminderen."',
      speed_fix__15: '4. Beperk je code tot een minimum',
      speed_fix__16:
        '"Soms kan de code van je website een beetje rommelig worden. Wanneer dit gebeurt, duurt het veel langer voordat je site geladen is. Wanneer je veel aanpassingen maakt, een CMS gebruikt, of zelfs een website bouwer gebruikt om je site te maken zullen er onnodige regeleinden zijn, spaties, en andere elementen die er niet hoeven te zijn."',
      speed_fix__17:
        '"Als je WordPress gebruikt, dan zal een plugin zoals Better WordPress Minify je code minimaliseren. Of, als je een van de caching plugins gebruikt, dan zou er ook een minify optie moeten zijn."',
      speed_fix__18:
        '"Als je geen CMS gebruikt, kun je je code minifiëren met behulp van de Pagespeed Insights Chrome Extension te gebruiken. Deze extensie maakt een geminimaliseerde versie van je code, zodat je kunt zien welke versie sneller is."',
      speed_fix__19: '5. Gebruik een CDN',
      speed_fix__20:
        '"De laadsnelheid van je site wordt beïnvloed door de nabijheid van de gebruiker tot de server waar de bestanden van je site zijn opgeslagen. Hoe verder weg ze zijn van deze fysieke locatie, hoe langzamer je site zal laden. Het gebruik van een CDN kan dit probleem oplossen."',
      speed_fix__21:
        '"Een CDN verdeelt de bestanden van je site over een netwerk van wereldwijde servers, op die manier kunnen je gebruikers je site bereiken via de server die het dichtst bij hen staat."',
      sitemap: 'We hebben toegevoegd',
      sitemap__2: "pagina's naar jouw",
      sitemap__3: 'Sitemap',
      backlinks: 'backlinks',
      backlinks__from: 'van',
      backlinks__websites: 'websites',
      backlinks__info:
        'Een backlink is simpelweg een link van de ene website naar de andere. Zoekmachines zoals Google gebruiken backlinks als een ranking signaal omdat wanneer een website linkt naar een andere, het betekent dat ze geloven dat de inhoud opmerkelijk is.',
      backlinks__export: 'Exporteer de top 100 backlinks',
      backlinks__not_found: 'Geen backlinks gevonden',
      competitors__comparison: 'Trefwoord Concurrenten',
      competitors__info: `"Benchmark je bedrijf ten opzichte van je concurrenten, we volgen je algemene zichtbaarheid voor je doelgerichte zoekwoorden, het leaderboard wordt bijgewerkt wanneer jij en je concurrenten stijgen of dalen in rankings. Klik op 'Toon meer' om de lijst van trefwoorden te zien die we gebruiken om je concurrenten te vinden, en bij te werken."`,
      competitors__no_results_title: 'Geen concurrenten gevonden',
      competitors__no_results_description:
        'We hebben geen directe concurrenten gevonden.',
      competitors__no_results_description_keywords:
        'We hebben geen directe concurrenten gevonden, omdat je site geen geassocieerde zoekwoorden heeft.',
      savings__top: 'De waarde van jouw autonome verkeer',
      savings__info_1: 'Hoe worden jouw geschatte besparingen berekend?',
      savings__info_2:
        'Gemiddelde positie van de zoekwoorden die we hebben gevonden voor jouw bedrijf, vermenigvuldigd met de gemiddelde kosten per klik op Google Ads en het geschatte bezoekersvolume.',
      savings__saved_in: 'Opgeslagen in',
      savings__monthly: 'Geschatte maandelijkse besparingen',
      alerts__stay_informed: 'Blijf op de hoogte',
      alerts: 'Waarschuwingen',
      alerts__info:
        '"Deze essentiële waarschuwingen houden je website op de voorgrond en zorgen ervoor dat je online relevant, gevonden en veilig blijft."',
      alerts__downtime: 'Alarm bij uitval',
      alerts__downtime_info:
        '"Ontvang een melding wanneer je website aandacht nodig heeft, verlies geen business wanneer je website offline is."',
      alerts__security: 'Security Alert',
      alerts__security_info:
        '"Bots en hackers zijn overal, laat je erop wijzen wanneer je website kwetsbaar is voor de kwaadwilligen."',
      alerts__competitor: 'Concurrenten alarm',
      alerts__competitor_info:
        '"Kennis is macht; je zult de eerste zijn die het weet als je concurrenten vooruitgaan of op achterstand komen."',
      alerts__crawling: 'Crawling Alarm',
      alerts__crawling_info:
        '"Als je website niet gecrawld kan worden dan verlies je alle zichtbaarheid voor miljoenen mensen online, wij laten het je in een oogwenk weten."',
      label__save: 'Opslaan',
      account__settings: 'Instellingen',
      account__firstname: 'Voornaam',
      account__lastname: 'Achternaam',
      account__email: 'E-mail',
      account__password: 'Wachtwoord',
      account__phone: 'Telefoon',
      account__saved: 'Opgeslagen',
      account__update: 'Update',
      admin_clients__total_label: 'Totaal klanten',
      admin_clients__total_title:
        'Totaal aantal websites van klanten die we optimaliseren',
      admin_clients__paid_label: 'Betaalde klanten',
      admin_clients__paid_title:
        'Betaalde abonnementen die geen gratis proefabonnement zijn',
      admin_clients__trial_label: 'Gratis testen',
      admin_clients__trial_title: 'Aantal klanten met een gratis proefperiode',
      admin_clients__import: 'Klanten importeren',
      admin_clients__manage: 'Gebruikers beheren',
      admin_clients__settings: 'Instellingen',
      admin_clients__website: 'Website',
      admin_clients__email: 'Klant E-mail',
      admin_clients__status: 'Status',
      admin_clients__added: 'Toegevoegd',
      admin_clients__cancelled: 'Geannuleerd',
      admin_clients__active_subscription: 'Actief abonnement',
      admin_clients__trial: 'Proef',
      admin_clients__active: 'Aktief',
      admin_clients__link: 'Link',
      admin_clients__view: 'Bekijk',
      admin_clients__processing: 'Verwerking',
      admin_clients__actions: 'Acties',
      admin_clients__remove: 'Verwijderen',
      admin_clients__demo_data:
        'Laad demo data voor testdoeleinden. Vul de historische ranglijstgegevens in voor de geselecteerde site.',
      admin_clients__data: 'Gegevens',
      admin_clients__remove_confirm_title: 'Bevestig verwijdering',
      admin_clients__remove_confirm:
        'Weet je zeker dat je de site wilt verwijderen',
      admin_import__back: 'Terug',
      admin_import__sites: 'Sites importeren',
      admin_setup__back: 'Terug',
      admin_setup__settings: 'Instellingen',
      admin_setup__business_name: 'Bedrijfsnaam',
      admin_setup__domain: 'Domein',
      admin_setup__domain_description:
        'De domeinen waar jouw versie van de app gehost wordt. Max. 10 domeinen.',
      admin_setup__send_from: 'Verzend van e-mail adres',
      admin_setup__send_from_description:
        'Neem contact met ons op om dit veld te veranderen, we moeten je domein valideren.',
      admin_setup__shared_secret: 'Gedeeld geheim',
      admin_setup__custom_css: 'Aangepast stylesheet',
      admin_setup__advanced: 'Geavanceerd',
      admin_setup__css_page: 'Aangepaste CSS pagina om te laden.',
      admin_setup__webhook_endpoints: 'Webhook abonnee eindpunten',
      admin_setup__webhooks_description:
        "Eindpunten om webhook waarschuwingen te ontvangen. Accepteert een door komma's gescheiden lijst.",
      admin_setup__saved: 'Gered!',
      admin_setup__update: 'Update',
      admin_setup__logo_dark: 'Logo (Donker)',
      admin_setup__logo_dark_description:
        'Donkere versie, voor de login pagina en lichte achtergronden.',
      admin_setup__no_image: 'Er is nog geen afbeelding toegevoegd',
      admin_setup__drag_and_drop:
        "Drag 'n' drop hier wat bestanden, of klik om bestanden te selecteren",
      admin_setup__favicon: 'Favicon',
      admin_setup__not_allowed: 'Niet toegestaan',
      admin_setup__no_permission:
        'Sorry, je hebt geen toestemming om deze pagina te bekijken.',
      admin_setup__account_owner_only:
        'Alleen de eigenaar van de account kan deze specifieke pagina bekijken.',
      admin_stats__users: 'Gebruikers',
      admin_stats__page_scanned: "Gescande pagina's",
      admin_stats__pages: "Pagina's",
      admin_stats__audits: 'Controles',
      admin_stats__order_count: 'Aantal bestellingen',
      admin_stats__order: 'Bestel',
      admin_stats__order_value: 'Waarde bestelling (£)',
      admin_stats__keywords: 'Trefwoorden',
      admin_stats__proposals: 'Voorstellen',
      admin_stats__proposals_sent: 'Verzonden voorstellen',
      admin_stats__sent_proposals: 'Verzonden voorstellen',
      admin_stats__proposals_completed: 'Voltooide voorstellen',
      admin_stats__completed_proposals: 'Afgeronde voorstellen',
      admin_stats__proposal_count_30_days:
        'Aantal voorstellen (laatste 30 dagen)',
      admin_stats__proposal_value_30_days: 'Waarde voorstel (laatste 30 dagen)',
      admin_stats__count: 'Tel',
      admin_stats__value: 'Waarde (£)',
      admin_stats__download_png: 'PNG downloaden',
      admin_stats__download_svg: 'SVG downloaden',
      admin_stats__download_pdf: 'PDF downloaden',
      admin_users__back: 'Terug',
      admin_users__email: 'E-mail',
      admin_users__name: 'Naam',
      admin_users__auth_google: 'Geauthenticeerd met Google',
      admin_users__logins: 'Logins',
      admin_users__status: 'Status',
      admin_users__actions: 'Acties',
      admin_users__edit: 'Bewerken',
      add_domain: 'Domein toevoegen',
      add_domain__up_to_10: 'Voeg tot 10 domeinen toe',
      add_domain__remove_last: 'Laatste domein verwijderen',
      add_domain__remove: 'Domein verwijderen',
      how_are_your_savings_calculated:
        'Hoe worden je geschatte besparingen berekend?',
      login__sign_in: 'Aanmelden',
      login__email: 'Email Adres',
      login__forgot_pwd: 'Wachtwoord vergeten?',
      login__error:
        'Sorry, je hebt een onjuiste gebruikersnaam of wachtwoord ingevoerd.',
      login__failed: 'Inloggen mislukt',
      register: 'Registreren',
      register__account_created: 'Account aangemaakt',
      register__creating_account: 'Jouw account aanmaken...',
      register__please_wait_1: 'Even geduld a.u.b.',
      register__please_wait_2: 'seconden.',
      register__registered: 'Je bent al geregistreerd.',
      register__error:
        'Je moet onze algemene voorwaarden accepteren om je te kunnen registreren.',
      register__online_visibility: 'Verhoog je online zichtbaarheid',
      register__detect_issues: 'Website problemen detecteren',
      register__keyword_research: 'Trefwoord Onderzoek',
      register__issue_monitoring: 'Probleembewaking',
      register__insights: 'Inzichten zoeken',
      register_form__create_free_account: 'Maak je gratis account aan',
      register_form__name: 'Jouw naam',
      register_form__email: 'E-mail',
      register_form__password: 'Wachtwoord',
      register_form__agency_name: 'Naam agentschap',
      register_form__domain: 'Jouw domein',
      register_form__domain_error: 'Sorry, dat subdomein is niet beschikbaar.',
      register_form__domain_success: 'Perfect! Dat subdomein is beschikbaar',
      register_form__terms_1: 'Ik ga akkoord met de',
      register_form__terms_2: 'Algemene voorwaarden',
      register_form__already_have_account: 'Heb je al een account? Nu inloggen',
      register_form__partner_account: 'Partner-account aanmaken',
      register_agency__terms_error:
        'Je moet onze algemene voorwaarden accepteren om je te kunnen registreren.',
      register_agency__generic_error: 'Sorry, er is een fout opgetreden.',
      register_agency__audit_lead:
        'Volledig gebrande audit en lead generatie app.',
      register_agency__unlimited_audits: 'Onbeperkte audits',
      register_agency__reporting: 'Klanten Rapportage',
      register_agency__branding: 'Branding op maat',
      register_agency__sales_proposals: 'Verkoopvoorstellen',
      register_agency__no_cost_upfront: 'Geen kosten vooraf',
      register_agency__try_again_later:
        'Als de link niet meteen werkt, probeer het dan over een uur nog eens.',
      register_agency__terms_1: 'Ik accepteer de',
      register_agency__terms_2: 'algemene voorwaarden van het agentschap',
      site__audit_pending: 'Site Audit in afwachting van',
      site__audits_pending: 'Site Audits in afwachting',
      site__site_not_found: 'Site niet gevonden',
      site__sites_not_found: 'Geen sites gevonden',
      site__site_not_found_message:
        'We konden je site niet vinden. Neem contact op met support.',
      site__sites_not_found_message:
        'We konden geen sites vinden die aan jouw account gekoppeld zijn. Neem contact op met support.',
      theme__all_fields_required: 'Vul alle velden in a.u.b.',
      theme: 'Thema',
      theme__back: 'Terug',
      theme__customise: 'Pas je thema aan.',
      theme__sidebar_bg: 'Kleur zijbalk achtergrond',
      theme__hex: 'Hex kleurcode',
      theme__header_colour: 'Kleur koptekst',
      theme__primary_colour: 'Primaire tekstkleur',
      theme__primary_button_colour: 'Primaire knopkleur',
      theme__custom_font: 'Aangepast lettertype',
      theme__select: 'Selecteer',
      theme__disclaimer_1: 'Opmerking: Het kan',
      theme__disclaimer_2: 'tot een uur',
      theme__disclaimer_3:
        'voordat het thema voor iedereen zichtbaar is door browser caching.',
      theme__saved: 'Opgeslagen!',
      theme__save: 'Opslaan',
      theme__preview: 'Voorbeeld',
      theme__title: 'Titel',
      theme__ok: 'OK',
      audit_result__pages: "Pagina's",
      audit_result__page: 'Pagina',
      audit_result__pages_impacted: "Aantal getroffen pagina's",
      audit_result__easy: 'Makkelijk',
      audit_result__easy_fix: 'Gemakkelijk te repareren.',
      audit_result__medium: 'Gemiddeld',
      audit_result__medium_fix: 'Gemiddelde moeilijkheidsgraad',
      audit_result__difficult: 'Moeilijk',
      audit_result__difficult_fix: 'Moeilijk te repareren',
      audit_result__explanation: 'Uitleg',
      audit_result__advice: 'Advies',
      audit_result__export_list: 'Exportlijst',
      audit_section__yes: 'Ja',
      audit_section__no: 'Nee',
      audit_section__pass: 'Succes',
      audit_section__fail: 'Verbeteren',
      audit_section__show_pages_with_issue: "Toon pagina's met nummer",
      audit_section__page_url: 'Pagina URL',
      audit_section__last_scan: 'Laatste scan',
      audit_section__ago: 'geleden',
      audit_section__fix: 'Auto Reparatie',
      audit_section__learn: 'Leer te repareren',
      audit_section__what_we_found: 'Wat hebben we gevonden?',
      audit_section__average_speed: 'Gemiddelde snelheid',
      audit_section__average_speed_description:
        'Zoekmachines geven een voorkeursbehandeling aan websites die snel laden. Hoe sneller je site laadt, hoe lager het bouncepercentage. Als je site snel is, heb je een betere kans om te scoren in zoekmachines dan trage sites die hoge bounce rates hebben.',
      audit_section__is_site_fast: 'Laadt je site snel?',
      audit_section__try_again_later:
        'Nog geen gegevens, probeer later nog eens.',
      audit_section__security: 'Beveiliging',
      audit_section__is_site_secure: 'Is je site veilig?',
      audit_section__pages_analyzed: "Pagina's geanalyseerd",
      audit_section__pages_on_site: "De pagina's op je site",
      audit_section__mobile_speed: 'Mobiele snelheid',
      audit_section__mobile_loading_time:
        'De laadtijd van je site op een mobiel apparaat',
      audit_section__mobile_ready: 'Klaar voor mobiel?',
      audit_section__mobile_site_works:
        'Je site werkt correct op mobiele apparaten',
      audit_section__no_screenshot: 'Screenshot niet beschikbaar',
      audit_section__what_to_look_at: 'Waar moet je naar kijken?',
      audit_section__no_issues: 'Geen problemen gevonden.',
      audit_section__what_done_well: 'Wat heb je goed gedaan?',
      audit_summary__overall_score:
        'Je totaalscore is een gemiddelde van je vier specifieke scores die hieronder worden beschreven.',
      audit_summary__seo: 'Zoekmachine optimalisatie (SEO)',
      audit_summary__seo_2: 'SEO-prestaties',
      audit_summary__seo_bis: 'Zoekmachine Optimalisatie',
      audit_summary__perf: 'Prestaties',
      audit_summary__perf_2: 'Laad snelheid',
      audit_summary__security: 'Beveiliging',
      audit_summary__security_2: 'Veiligheid van de site',
      audit_summary__mobile: 'Mobiel vriendelijk',
      audit_summary__mobile_2:
        'Hoe gemakkelijk jouw site te gebruiken is op mobiele telefoons',
      audit_summary__pending:
        'Scores in behandeling. Probeer het later nog eens.',
      audit_tab_menu__seo: 'SEO',
      audit_tab_menu__performance: 'Prestatie',
      audit_tab_menu__security: 'Beveiliging',
      audit_tab_menu__mobile: 'Mobiel',
      backlink_modal__backlinks: 'Backlinks',
      backlink_modal__website: 'Website',
      backlink_modal__trust_flow: 'Vertrouwensstroom',
      backlink_modal__citation_flow: 'Citatiestroom',
      backlink_modal__detected: 'Gedetecteerd',
      backlink_modal__detected_on: 'Gedetecteerd op',
      backlink_modal__close: 'Sluiten',
      generic_modal__close: 'Sluiten',
      generic_modal__ok: 'OK',
      google_button__sign_in: 'Log in met Google',
      radial_chart__score: 'SCORE',
      header__error: 'Sorry, er is een fout opgetreden.',
      header__dashboard: 'Dashboard',
      header__audit: 'Controle',
      header__alerts: 'Waarschuwingen',
      header__admin: 'Admin',
      header__policy: 'Beleid',
      header__terms: 'Voorwaarden',
      import_modal__title: 'Importeren',
      import_modal__step_1: '1) Download optioneel ons sjabloonbestand',
      import_modal__step_2: '2) Upload de spreadsheet om te importeren',
      import_modal__step_2_def:
        'Wij ondersteunen de volgende bestandstypen: CSV, XLS en XLSX',
      import_modal__download_template: 'Sjabloon downloaden',
      import_modal__upload: 'Upload',
      import_modal__close: 'Sluiten',
      import_modal__next: 'Volgende',
      import_wizard__template_error:
        'Sorry, de spreadsheet die je hebt geüpload is ongeldig. Zorg ervoor dat alle vereiste kolommen bestaan of download onze sjabloon.',
      import_wizard__import_clients: 'Klanten importeren',
      import_wizard__confirm: 'Bevestig',
      no_results: 'Geen resultaten beschikbaar',
      no_results__not_discovered:
        'Dit kan zijn omdat deze website niet door Google is ontdekt.',
      permissions_modal__edit: 'Wijzig Rechten',
      permissions_modal__tip:
        'Tip: Het kan zijn dat gebruikers moeten uitloggen om de nieuwe machtigingen die je toekent te zien.',
      permissions_modal__select: 'Selecteer',
      permissions_modal__user: 'Gebruiker',
      permissions_modal__admin: 'Admin',
      permissions_modal__acc_owner: 'Account eigenaar',
      permissions_modal__fulfilment: 'Aanvraag Vervulling',
      permissions_modal__close: 'Sluiten',
      permissions_modal__update: 'Update',
      rate_limit__too_many_req: 'Te veel verzoeken',
      rate_limit__try_again: 'Sorry, probeer het later nog eens.',
      site_task__auto_plan: 'Automatiseringsplan Actief',
      site_task__monitoring:
        'Wij controleren je site op eventuele wijzigingen om de beste gebruikerservaring en laadtijd te garanderen.',
      site_task__fast_load: 'Razendsnelle laadtijden',
      site_task__seo: 'SEO en opmaak reparaties',
      site_task__img_opti: 'Beeldoptimalisatie',
      site_task__sitemap: 'Sitemap genereren',
      site_task__server_config: 'Server configuratie',
      site_task__seo_plan: 'SEO Plan Actief',
      site_task__increased_vis:
        'Ons team van experts werkt aan het vergroten van je online zichtbaarheid. Huidige taken:',
      site_task__completed: 'Taak voltooid',
      site_task__in_progress: 'Taak in uitvoering voor pagina:',
      site_task__view_all: 'Bekijk alle',
      audit_doctype__descr:
        'Doc type is het eerste element in de broncode van een webpagina en zou deel moeten uitmaken van elk HTML-document. Zonder een Doctype gaat je browser in een nogal vreemde modus. Het lijkt erop dat je geen Doctype hebt, en hoewel je dit zelf kunt oplossen, is het een beetje lastig tenzij je weet hoe je moet coderen. We raden aan om dit naar je ontwikkelaar te sturen om op te lossen.',
      audit_doctype__fail_msg: 'Uh oh, je Doc type moet juist ingesteld zijn',
      audit_doctype__fail_name: 'Geen Doc type',
      audit_doctype__pass_msg: 'Goed gedaan, je Doc type is juist ingesteld',
      audit_doctype__pass_name: 'Doc Type',
      audit_meta__descr:
        "Metatags zijn stukjes tekst die de inhoud van een pagina beschrijven in de broncode van de pagina. Hoewel metatags niet op de pagina zelf staan, zijn het kleine inhoudsbeschrijvingen die zoekmachines zoals Google vertellen waar een webpagina over gaat. Metatags bestaan alleen in HTML, meestal in de 'head' van de pagina, en zijn dus alleen zichtbaar voor zoekmachines (en mensen die weten waar ze moeten kijken). Je moet metatags toevoegen.",
      audit_meta__fail_msg:
        "Je meta tags lijken te ontbreken op $$issuecount pagina('s)",
      audit_meta__fail_name: 'Geen Meta Tags',
      audit_meta__pass_msg: 'Je meta tags zien er goed uit',
      audit_meta__pass_name: 'Meta Tags',
      audit_h1__descr:
        "Er zijn geen 'H1'-tags (koppen) gevonden op je pagina. Deze zijn echter een belangrijk element van elke website, omdat ze inhoud en tekst op een zinvolle en hiërarchische manier structureren. Koppen verbeteren de leesbaarheid van de inhoud voor bezoekers van een site aanzienlijk. Dit werkt ook zo bij zoekmachines, omdat ze Google een overzicht geven van de pagina-inhoud en de weging van afzonderlijke deelonderwerpen. Je moet H1's toevoegen aan je website door naar je content management systeem te gaan en te zoeken naar de optie headers.",
      audit_h1__fail_msg:
        "Uh oh, je lijkt H1 tags te missen op $$issuecount pagina('s)",
      audit_h1__fail_name: 'Ontbrekende paginatitel (H1)',
      audit_h1__pass_msg:
        'Al je H1 tags lijken zichtbaar te zijn en correct geformatteerd',
      audit_h1__pass_name: 'Titel van de pagina (H1)',
      audit_compression__descr:
        "Hoe kleiner de bestanden die nodig zijn om een webpagina te laden, hoe sneller de volledige pagina kan worden geladen. Het comprimeren van HTML-, CSS- en JavaScript-bestanden helpt dus om de snelheid van je pagina te verbeteren. Het comprimeren van dergelijke bestanden is mogelijk door code in te korten, bijvoorbeeld door commentaar, regeleinden, onnodige tekens en spaties te verwijderen. Bovendien kan de bestandsgrootte worden gecomprimeerd voor overdracht aan de serverzijde met behulp van Gzip (een bestandsformaat en een softwaretoepassing die wordt gebruikt voor het comprimeren en decomprimeren van bestanden). Compressie zoals 'Gzip' is ofwel niet ingeschakeld of geconfigureerd voor je site. Je moet dit veranderen. Aangezien deze taak echter een uitdaging kan vormen of beperkt kan zijn op je CMS, stellen we voor dat je deze taak doorstuurt naar je ontwikkelaars.",
      audit_compression__fail_msg:
        'Je lijkt compressie te hebben uitgeschakeld. Het kan lastig zijn om het op te lossen, maar het is het zeker waard.',
      audit_compression__fail_name: 'Compressie uitgeschakeld',
      audit_compression__pass_msg:
        'Het lijkt erop dat je compressie is ingeschakeld, goed bezig.',
      audit_compression__pass_name: 'Compressie Ingeschakeld',
      audit_content_rate__descr:
        "Er bestaat slechts een kleine hoeveelheid inhoud in vergelijking met de HTML op de pagina. Net als dunne inhoud, is ongeldige inhoud inhoud die weinig of geen waarde heeft voor de gebruiker. Google beschouwt doorway-pagina's, affiliate-pagina's van lage kwaliteit, of gewoon pagina's met zeer weinig of geen inhoud als dergelijke pagina's. Los dit op door relevante en originele inhoud van hoge kwaliteit te produceren.",
      audit_content_rate__fail_msg:
        "Uh oh! Je moet nog steeds meer inhoud toevoegen op $$issuecount pagina('s)",
      audit_content_rate__fail_name: 'Ongeldige inhoud',
      audit_content_rate__pass_msg: 'Goed werk! Die inhoud ziet er mooi uit',
      audit_content_rate__pass_name: 'Geen ongeldige inhoud',
      audit_content_size__descr:
        "Thin content is inhoud die weinig of geen waarde heeft voor de gebruiker. Google beschouwt doorway-pagina's, affiliate-pagina's van lage kwaliteit, of gewoon pagina's met heel weinig of geen inhoud als pagina's met thin content. Maar trap niet in de val van het produceren van veel vergelijkbare inhoud - niet-originele pagina's en pagina's met geschraapte en dubbele inhoud worden ook beschouwd als pagina's met thin content. Bovendien houdt Google niet van pagina's die volgepropt zijn met zoekwoorden en kan nu onderscheid maken tussen waardevolle en minderwaardige inhoud. Los dit op door kwalitatief hoogwaardige, relevante en originele content te produceren.",
      audit_content_size__fail_msg:
        "Uh oh! Het lijkt erop dat je nog steeds wat thin content hebt op de $$issuecount pagina('s). Dit verhelpen zal je Google ranking verbeteren en je site gebruiksvriendelijker maken",
      audit_content_size__fail_name: 'Thin Content Gevonden',
      audit_content_size__pass_msg:
        "Goed werk! Alle pagina's op je site tonen unieke, kwalitatieve inhoud",
      audit_content_size__pass_name: 'Geen Thin Content gevonden',
      audit_content_read__descr:
        'De inhoud van je pagina kan moeilijk te lezen zijn. Er zijn tal van algoritmen die zijn ontworpen om de leesbaarheid van inhoud te meten (dit is wat robots en zoekmachines ook gebruiken). Een van de populairste algoritmen voor het berekenen van de leesbaarheid van inhoud is de Flesch-Kincaid leesbaarheidsscore. Andere algoritmen zijn Gunning-Fog, Coleman-Liau, SMOG index, enz. Je moet de leesbaarheid van je inhoud verbeteren.',
      audit_content_read__fail_msg:
        "De inhoud van $$issuecount pagina('s) is nog steeds moeilijk te lezen. Het kan lastig zijn om dit te verhelpen, maar het is zeker de moeite waard als je wilt dat je gebruikers zich betrokken voelen bij je site",
      audit_content_read__fail_name: 'Slechte leesbaarheid',
      audit_content_read__pass_msg:
        'Goed werk! Je inhoud is makkelijk te lezen en zal je gebruikers waarschijnlijk aanspreken.',
      audit_content_read__pass_name: 'Goede leesbaarheid',
      audit_html_old__descr:
        "Je site bevat HTML-tags die niet meer gebruikt mogen worden. Over het algemeen betekent 'deprecated' 'iets afgekeurd'. In de IT zijn deprecated elementen die elementen die zijn toegestaan, maar niet worden aanbevolen en die worden vervangen door nieuwere. Dus, een 'deprecated code' betekent dat het niet wordt aanbevolen om te gebruiken omdat er een beter alternatief in die software is om in plaats daarvan te gebruiken. Probeer een alternatief te gebruiken.",
      audit_html_old__fail_msg:
        "Uh oh, het lijkt erop dat je nog steeds een aantal deprecated HTML tags hebt op $$issuecount pagina('s)",
      audit_html_old__fail_name: 'Deprecated HTML-tags',
      audit_html_old__issue: 'Deprecated HTML-tags gevonden',
      audit_html_old__pass_msg:
        'Goed werk! Al je HTML tags zien er glanzend, nieuw en mooi uit!',
      audit_html_old__pass_name: 'Geen Deprecated HTML-tags',
      audit_meta_descr__descr:
        "Een van je pagina's bevat meerdere 'description' meta tags, waardoor Google mogelijk de verkeerde weergeeft. Je zou er maar één op elke pagina moeten hebben. Noch metabeschrijvingen, noch meta trefwoorden spelen een rol in Google's ranking algoritmes voor zoeken op het web. Metabeschrijvingen kunnen echter wel invloed hebben op de CTR (click-through-rate) van een pagina op Google, wat een positieve invloed kan hebben op het vermogen van een pagina om te ranken. Onder andere om die reden is het belangrijk om wat moeite te steken in meta beschrijvingen.",
      audit_meta_descr__fail_msg:
        "Het lijkt erop dat je nog steeds meerdere beschrijvingstags hebt op $$issuecount pagina('s)",
      audit_meta_descr__fail_name: 'Meerdere beschrijvingen',
      audit_meta_descr__pass_msg: 'Goed werk! Al je meta tags zien er goed uit',
      audit_meta_descr__pass_name: 'Een beschrijving',
      audit_meta_tags__descr:
        "Sommige meta-tags op je site zijn gedupliceerd, verwijder ze zodat je er maar één hebt van elk 'meta'-tagtype'.",
      audit_meta_tags__fail_msg:
        "Uh oh, het lijkt erop dat je nog steeds een aantal dubbele meta tags hebt op $$issuecount pagina('s)",
      audit_meta_tags__fail_name: 'Duplicaat tags',
      audit_meta_tags__issue: 'Duplicaat meta tags gevonden',
      audit_meta_tags__pass_msg:
        'Goed gedaan. Al je meta tags zien er goed uit',
      audit_meta_tags__pass_name: 'Geen duplicaat meta tags gevonden',
      audit_dup_titles__descr:
        "Het lijkt erop dat je meerdere <title> tags op je pagina hebt, je zou er maar één moeten gebruiken. De title-tag helpt zowel gebruikers als zoekmachines om snel te begrijpen welke inhoud ze op je pagina kunnen verwachten. Als er meerdere title-tags op de pagina staan, kan dat betekenen dat zoekmachines de 'verkeerde' weergeven, wat weer kan leiden tot een lagere betrokkenheid of CTR bij de zoekresultaten. Het kan ook een negatief effect hebben op SEO.",
      audit_dup_titles__fail_msg:
        "Uh oh! Je hebt nog steeds meerdere title tags op $$issuecount pagina('s). Los dit snel op.",
      audit_dup_titles__fail_name: 'Meerdere titels',
      audit_dup_titles__issue: 'Duplicaat titels gevonden',
      audit_dup_titles__pass_msg:
        'Goed werk! Al je title tags zien er goed uit',
      audit_dup_titles__pass_name: 'Geen duplicaat titels gevonden',
      audit_favicon__descr:
        "Er kon geen 'favicon' voor je site worden gevonden. Een favicon brengt echter veel indirecte voordelen met zich mee voor de SEO van je pagina's. Het hebben van een favicon naast de titel van je website op browsertabbladen, bladwijzers, en geschiedenisarchieven etc. helpt een gebruiker tijd te besparen omdat ze je website zonder problemen kunnen identificeren en doorbladeren. Als er geen favicon bij je site staat, kun je ook de kans verliezen om in een favorietenlijst van een Chrome-browser te staan en zo indirect een klein maar belangrijk zoeksignaal te verliezen. Je moet een favicon voor je site krijgen.",
      audit_favicon__fail_msg:
        'Uh oh! Het lijkt erop dat je favicon nog steeds ontbreekt.',
      audit_favicon__fail_name: 'Geen favicon op je website',
      audit_favicon__pass_msg: 'Goed nieuws, je hebt een favicon',
      audit_favicon__pass_name: 'Favicon op je website',
      audit_robot__descr:
        "We konden geen robots-bestand voor je site vinden, maar dit is een belangrijk bestand voor zoekmachines. Robots.txt is een tekstbestand dat webmasters maken om webrobots (meestal zoekmachinerobots) te instrueren hoe ze pagina's op hun website moeten crawlen. Het robots.txt-bestand maakt deel uit van het robots exclusion protocol (REP), een groep webstandaarden die regelen hoe robots het web doorzoeken, inhoud openen en indexeren, en deze inhoud aan gebruikers aanbieden. Vraag een robots-bestand voor je site aan of neem contact op met je ontwikkelaar.",
      audit_robot__fail_msg:
        'Uh oh! Het lijkt erop dat je robots bestand nog steeds ontbreekt',
      audit_robot__fail_name: 'Ontbrekende Robots File',
      audit_robot__pass_msg:
        'Je robots bestand is toegevoegd en ziet er goed uit',
      audit_robot__pass_name: 'Robots Bestand',
      audit_sitemap__descr:
        "We konden geen sitemap vinden. Een sitemap is een blauwdruk van je website die zoekmachines helpt om alle inhoud van je website te vinden, te crawlen en te indexeren. Sitemaps vertellen zoekmachines ook welke pagina's op je site het belangrijkst zijn. Het toevoegen van een sitemap is lastig, maar zal je SEO ranking aanzienlijk verbeteren. Voeg een sitemap toe of praat met je ontwikkelaar.",
      audit_sitemap__fail_msg:
        'Het lijkt erop dat je nog steeds een sitemap mist. Het toevoegen van een sitemap is lastig, maar het zal je SEO positie aanzienlijk verbeteren',
      audit_sitemap__fail_name: 'Geen Sitemap Gevonden',
      audit_sitemap__pass_msg: 'Goed werk, je hebt een sitemap!',
      audit_sitemap__pass_name: 'Sitemap gevonden',
      audit_no_alt__descr:
        "Afbeeldingen op je site bevatten niet altijd een 'alt'-tag. Het hebben van alt-tags is een best practice voor webtoegankelijkheid (het helpen van visueel gehandicapte gebruikers om een afbeelding op de pagina te begrijpen), en helpt bij het vergroten van je zichtbaarheid in zoekmachines. Voeg alternatieve tekst toe aan foto's.",
      audit_no_alt__fail_msg:
        "Je afbeeldingen missen nog steeds alt tags op $$issuecount pagina('s)",
      audit_no_alt__fail_name: 'Ontbrekende Alt-tags op je afbeeldingen',
      audit_no_alt__pass_msg:
        'Geweldig. Al je afbeeldingen hebben alt tags. Je bent een toegankelijkheids held!',
      audit_no_alt__pass_name: 'Alt-tags op je afbeeldingen',
      audit_links__descr:
        'Het viel ons op dat sommige links op je site gebroken waren. Zoekmachines zien links als een stem voor de kwaliteit van een website. Links naar je website en links binnen je website kunnen invloed hebben op de positie van je website in de zoekresultaten. Daarom is het een goed idee om gebroken links te verwijderen of bij te werken.',
      audit_links__fail_msg:
        "Uh oh! Het lijkt erop dat je nog steeds een aantal gebroken links hebt op de $$issuecount pagina('s)",
      audit_links__fail_name: 'Gebroken links die naar je website verwijzen',
      audit_links__pass_msg: 'Mooi werk! Al je links werken geweldig.',
      audit_links__pass_name:
        'Geen kapotte links die naar je website verwijzen',
      audit_empty_meta_descr__descr:
        'De meta description tag bestaat, maar lijkt geen waarde te hebben. De meta description wordt belangrijk geacht omdat het gebruikers helpt om snel te begrijpen welke inhoud ze kunnen verwachten op de pagina wanneer ze doorklikken vanuit de resultatenpagina van de zoekmachine. Goed geschreven meta beschrijvingen bereiken meestal een betere click-through-rate. Als de meta omschrijving leeg is, betekent dit een gemiste kans op optimalisatie. Bovendien betekent dit dat zoekmachines de meta description voor je gaan schrijven, soms met behoorlijk desastreuze resultaten. Maak een goed geschreven meta description die past bij de inhoud van je pagina.',
      audit_empty_meta_descr__fail_msg:
        "Je meta beschrijving lijkt te ontbreken op $$issuecount pagina('s)",
      audit_empty_meta_descr__fail_name: 'Blanco META Beschrijvingen Gevonden',
      audit_empty_meta_descr__pass_msg: 'Je meta omschrijving ziet er goed uit',
      audit_empty_meta_descr__pass_name:
        'Geen blanco META beschrijvingen gevonden',
      audit_wrong_meta_descr__descr:
        'Het lijkt erop dat de meta description tag niet overeenkomt met de daadwerkelijke inhoud van je pagina. De meta beschrijving wordt belangrijk geacht om gebruikers te helpen snel te begrijpen welke inhoud ze kunnen verwachten te vinden op de pagina wanneer ze doorklikken vanuit de resultatenpagina van de zoekmachine. Goed geschreven meta beschrijvingen bereiken meestal een betere click-through-rate. Als de metabeschrijving leeg is, betekent dit een gemiste kans op optimalisatie. Bovendien betekent dit dat zoekmachines de meta description voor je gaan schrijven, soms met behoorlijk desastreuze resultaten. Maak goed geschreven meta descriptions die passen bij de inhoud van je pagina.',
      audit_wrong_meta_descr__fail_msg:
        "Sorry, je hebt een ongepaste meta beschrijving op $$issuecount pagina('s). Probeer een korte samenvatting van de pagina-inhoud in de meta description op te nemen",
      audit_wrong_meta_descr__fail_name: 'Ongepaste META Beschrijvingen',
      audit_wrong_meta_descr__pass_msg:
        'Goed werk! De meta beschrijving komt overeen met de pagina inhoud',
      audit_wrong_meta_descr__pass_name: 'Geen ongepaste META-beschrijvinge',
      audit_page_size__descr:
        "Gebroken HTML of niet-ondersteunde inhoud op je pagina's: Als Googlebot de inhoud van de pagina niet kan parseren zal hij ze niet kunnen crawlen. Gebruik Fetch as Google om te zien hoe Googlebot uw pagina ziet.",
      audit_page_size__fail_msg:
        "Uh oh, het lijkt erop dat je nog steeds ongeldige inhoudsmaten hebt op $$issuecount pagina('s)",
      audit_page_size__fail_name:
        "Pagina's op de website hebben ongeldige afmetingen",
      audit_page_size__pass_msg:
        'Goed werk, je paginaformaten zien er goed uit',
      audit_page_size__pass_name:
        "Pagina's op de website hebben geldige afmetingen",
      audit_flash__descr: `Zoekmachine spiders kunnen Flash niet crawlen om uit te vinden wat er in dat gebied staat. Het google webmasters support forum zegt het volgende: "In het algemeen zijn zoekmachines gebaseerd op tekst. Dit betekent dat om gecrawld en geïndexeerd te worden, je content in tekst formaat moet zijn. Dit betekent niet dat je geen rijke media-inhoud zoals Flash, Silverlight of video's op je site kunt zetten; het betekent alleen dat alle inhoud die je in deze bestanden insluit ook beschikbaar moet zijn in tekstformaat, anders is het niet toegankelijk voor zoekmachines."`,
      audit_flash__fail_msg:
        "Het lijkt erop dat je nog steeds flash content hebt op $$issuecount pagina('s)",
      audit_flash__fail_name: 'Het lijkt erop dat je nog steeds Flash gebruikt',
      audit_flash__pass_msg:
        'Mooi werk, al je media-inhoud is zoekmachinevriendelijk',
      audit_flash__pass_name: 'Het lijkt erop dat je geen Flash gebruikt',
      audit_iframe__descr:
        "Google heeft enige mogelijkheid om inhoud in iFrame op webpagina's te crawlen als het iFrame SEO link bezig is. Het is bekend dat Google iFrame erkent via links, maar het is nog steeds beter om je website zonder iFrame te ontwikkelen.",
      audit_iframe__fail_msg:
        "Uh oh, er is nog steeds iFrame te zien op $$issuecount pagina('s)",
      audit_iframe__fail_name: 'iFrame gevonden op je website',
      audit_iframe__pass_msg:
        'Mooi werk, er is geen iFrame gevonden op je website',
      audit_iframe__pass_name: 'Geen iframes gevonden op je website',
      audit_lorem__descr:
        'Ontwikkelaars gebruiken de tekst Lorem ipsum als een plaatshouder voor de pagina-indeling. Na het opzetten van het sjabloon, moet al deze tekst vervangen worden door een uniek en relevant website thema, anders kunnen zoekmachines de pagina als niet relevant of duplicaat beschouwen. Vervang het door wat relevante inhoud om uw SEO score te verbeteren.',
      audit_lorem__fail_msg:
        "Het lijkt erop dat je nog wat placeholder tekst hebt op de $$issuecount pagina('s). Vervang het door wat relevante inhoud om je SEO score te verbeteren",
      audit_lorem__fail_name: 'Lorem Ipsum Gevonden',
      audit_lorem__pass_msg:
        'Geen placeholder tekst gevonden op je homepage, goed werk!',
      audit_lorem__pass_name: 'Geen Lorem Ipsum gevonden',
      audit_400__descr:
        'Fouten met HTTP-statuscode 4xx zijn in principe fouten aan de clientzijde. Dit kan te wijten zijn aan een probleem met de authenticatie of het weergaveformaat enz. Het probleem zit dus aan de kant van de client. Controleer de console voor een volledige beschrijving van de fout. Dit omvat de volledige statuscode, bijv. (400,401,etc). Elke code verwijst naar een ander client-side probleem. Begrijp het probleem en pas dienovereenkomstig oplossingen toe.',
      audit_400__fail_msg: 'Uh oh! Een 4xx error is nog steeds te zien',
      audit_400__fail_name: '4xx fouten gevonden op je homepage',
      audit_400__pass_msg:
        'Geen 4xx fouten gevonden op je homepage, goed werk!',
      audit_400__pass_name: 'Geen 4xx-fouten gevonden op je homepage',
      audit_500__descr:
        'Een 5xx serverfout is een fout die optreedt wanneer de server niet aan een verzoek voldoet. Er zijn 10 soorten van deze fout. Het is vrij moeilijk om elk van deze fouten op te sporen en te herstellen, maar zoekmachines houden er niet van, vooral 500 en 503 fouten niet.',
      audit_500__fail_msg: 'Uh oh! Een 5xx error is nog steeds te zien',
      audit_500__fail_name: '5xx fouten gevonden',
      audit_500__pass_msg: 'Geen 5xx-fouten gevonden',
      audit_seo_url__descr:
        "SEO vriendelijke URL's zijn URL's die zijn ontworpen om aan de behoeften van gebruikers en zoekers te voldoen. Specifiek, URL's die geoptimaliseerd zijn voor SEO hebben de neiging kort en trefwoord-rijk te zijn. Zorg ervoor dat je URL beknopt is en een relevant trefwoord bevat.",
      audit_seo_url__fail_msg:
        "URL's op $$issuecount pagina('s) zijn nog steeds niet SEO vriendelijk. Zorg ervoor dat je URL beknopt is en een relevant trefwoord bevat",
      audit_seo_url__fail_name: 'Dit is geen SEO vriendelijke URL',
      audit_seo_url__pass_msg: 'Goed werk! Die URL ziet er goed uit!',
      audit_seo_url__pass_name: 'Dit is een SEO vriendelijke URL',
      audit_ssl__descr:
        'Je kunt er zeker van zijn dat het hebben van een SSL-certificaat op je site waardevol is. Het laat bezoekers zien dat je site geverifieerd is en dat hij veilig is voor hackers. Het verbetert ook je SEO rankings. Zorg voor een SSL-certificaat.',
      audit_ssl__fail_msg:
        "$$issuecount pagina('s) wordt nog steeds weergegeven als een niet-beveiligde pagina",
      audit_ssl__fail_name: 'Je hebt een SSL-certificaat nodig',
      audit_ssl__pass_msg:
        'Ziet er goed uit! Je hebt een SSL certificaat, dus je gebruikers zullen weten dat je site veilig is',
      audit_ssl__pass_name: 'Je hebt een SSL-certificaat geïnstalleerd',
      audit_ssl_valid__descr:
        'Je kunt er zeker van zijn dat het hebben van een SSL-certificaat op je site waardevol is. Het laat bezoekers zien dat je site geverifieerd is en dat hij veilig is voor hackers. Het verbetert ook je SEO rankings.',
      audit_ssl_valid__fail_msg:
        "$$issuecount pagina('s) wordt weergegeven als een niet-beveiligde pagina",
      audit_ssl_valid__fail_name: 'SSL-certificaat ongeldig',
      audit_ssl_valid__pass_msg:
        'Ziet er goed uit! Je gebruikers zullen weten dat je site veilig is',
      audit_ssl_valid__pass_name: 'SSL-certificaat geldig',
      audit_test_server__descr:
        "Wanneer een gebruiker een URL opvraagt van zijn website, de server waarop je website gehost wordt, zal de server een header server status code terugsturen. Idealiter zou de server een '200 OK' statuscode moeten terugsturen om de browser te informeren dat alles in orde is met de pagina en dat de pagina bestaat op de gevraagde locatie. Je hebt serverfouten die hersteld moeten worden.",
      audit_test_server__fail_msg:
        'Uh oh! Je site geeft nog steeds een server error',
      audit_test_server__fail_name: 'Serverfouten gevonden',
      audit_test_server__pass_msg: 'Serverfout is hersteld, goed gedaan!',
      audit_test_server__pass_name: 'Geen serverfouten gevonden',
      audit_load_high__descr:
        "Een trage website snelheid schaadt je rankings en de gebruikers ervaring. Google heeft aangegeven dat sitesnelheid (en dus ook paginasnelheid) een van de signalen is die het algoritme gebruikt om pagina's te rangschikken. Bovendien betekent een trage paginasnelheid dat zoekmachines minder pagina's kunnen crawlen met het hun toegewezen crawlbudget, en dit kan een negatieve invloed hebben op je indexering. Paginasnelheid is ook belangrijk voor de gebruikerservaring. Pagina's met een langere laadtijd hebben de neiging om HARDER bounce rates te hebben, gebruikers besteden minder tijd op de pagina, en conversies worden negatief beïnvloed. Werk aan het verbeteren van je website snelheid.",
      audit_load_high__fail_msg: 'Uh oh! Je website laadt nog steeds te traag',
      audit_load_high__fail_name: 'Je website snelheid is niet geweldig',
      audit_load_high__pass_msg: 'Goed werk! Je website laadt razendsnel!',
      audit_load_high__pass_name: 'Je website snelheid is geweldig',
      audit_dup_title__descr: `Het komt wel eens voor dat je dezelfde pagina op twee verschillende plaatsen op je website wilt hebben. Stel dat je een product hebt dat je aanbiedt aan je zakelijke klanten en ook aan je particuliere klanten. Dan zou je deze pagina op twee verschillende plaatsen/URLs op je website willen zetten. Voorbeeld: www.example.com/business/myproduct.html (Pagina Titel: "Mijn Product")www.example.com/private/myproduct.html (Pagina Titel: "Mijn Product") Beide pagina's hebben bestaansrecht maar Google zal niet weten welke de krachtigere is (de originele), dus zal het min of meer de link juice (ranking power) verdelen voor de twee pagina's. In dit geval moet je de rel="canonical" tag gebruiken. De rel="canonical" tag moet in de gedupliceerde pagina worden gezet en moet verwijzen naar de originele pagina. Als het origineel uit onze voorbeelden hierboven de pagina in de bedrijfsmap moet zijn, dan moet de rel="canonical" tag in de pagina in de privémap (de duplicaatpagina) staan en er als volgt uitzien: <link rel="canonical" href="www.example.com/business/myproduct.html" />`,
      audit_dup_title__fail_msg:
        "Uh oh! $$issuecount pagina('s) hebben nog steeds duplicate title tags",
      audit_dup_title__fail_name: 'Je hebt duplicaat title tags',
      audit_dup_title__pass_msg:
        "Dit ziet er goed uit. De titel ziet er verschillend genoeg uit om beide pagina's goed te laten scoren",
      audit_dup_title__pass_name: 'Je hebt geen duplicaat title tags',
      audit_empty_title__descr:
        'De title-tag wordt belangrijk geacht om zowel gebruikers als zoekmachines te helpen snel te begrijpen welke inhoud zij op de pagina kunnen verwachten te vinden. De title-tag wordt beschouwd als een van de belangrijkste on-page SEO-factoren, dus als deze ontbreekt, is dit een probleem dat van invloed kan zijn op de rangschikking in zoekmachines en de click-through-rate van de zoekresultaten. Probeer er een paar toe te voegen om de SEO prestaties te verbeteren.',
      audit_empty_title__fail_msg:
        "Uh oh, het lijkt er nog steeds op dat je een aantal tags mist op de $$issuecount pagina('s). Probeer er een paar toe te voegen om de SEO prestaties te verbeteren",
      audit_empty_title__fail_name: 'De title-tags van je website zijn leeg',
      audit_empty_title__pass_msg: 'Goed gedaan, die tags zien er goed uit!',
      audit_empty_title__pass_name:
        'De title-tags van je website zijn niet leeg',
      audit_wrong_title__descr:
        'Maak je titels relevanter en minder spammy, geef niet alleen een opsomming van zoekwoorden en zorg ervoor dat je schrijft met de gebruiker in het achterhoofd. Zorg ervoor dat ze duidelijk en beknopt zijn, en de waarde van de inhoud overbrengen',
      audit_wrong_title__fail_msg:
        "Het lijkt erop dat je nog steeds de $$issuecount pagina('s) moet aanpassen. Zorg ervoor dat ze duidelijk en beknopt zijn, en de waarde van de inhoud overbrengen",
      audit_wrong_title__fail_name: 'Ongepaste titels op je homepage',
      audit_wrong_title__issue: 'Ongepaste titels gevonden',
      audit_wrong_title__pass_msg: 'Goed werk! Die titels zijn geweldig',
      audit_wrong_title__pass_name:
        'Geen ongepaste titels gevonden op je homepage',
      audit_long_title__descr:
        'Google toont doorgaans de eerste 50-60 tekens van een title-tag. Als je je titels onder de 60 tekens houdt, kun je volgens ons verwachten dat ongeveer 90% van je titels goed wordt weergegeven. De titels van je website zijn te lang. Probeer iets korter.',
      audit_long_title__fail_msg:
        'Sorry, je website titels zijn nog steeds te lang. Probeer iets korter',
      audit_long_title__fail_name: 'Je website heeft titels die te lang zijn',
      audit_long_title__issue: 'Lange titels gevonden',
      audit_long_title__pass_msg:
        'Nice! Je website titels zijn een grote lengte.',
      audit_long_title__pass_name:
        'Je website heeft geen titels die te lang zijn',
      audit_short_title__descr:
        'Google toont doorgaans de eerste 50-60 tekens van een title-tag. Als je je titels onder de 60 tekens houdt, kun je volgens ons verwachten dat ongeveer 90% van je titels goed wordt weergegeven. Je websitetitels zijn te kort.',
      audit_short_title__fail_msg:
        'Sorry, je website titels zijn nog steeds te kort.',
      audit_short_title__fail_name: 'Je website heeft titels die te kort zijn',
      audit_short_title__issue: 'Korte titels gevonden',
      audit_short_title__pass_msg:
        'Nice! Je website titels zijn een grote lengte.',
      audit_short_title__pass_name: 'Je website heeft geen te korte titels',
      audit_label__easy: 'Makkelijk',
      audit_label__medium: 'Gemiddeld',
      audit_label__difficult: 'Moeilijk',
      audit_title: 'Controleer je site',
      audit_continuous:
        'Wij controleren voortdurend je website en houden je op de hoogte van wat er moet worden hersteld en hoe dit moet gebeuren.',
      audit_seo__short: 'SEO',
      audit_seo__description:
        'SEO optimalisatie is belangrijk om ervoor te zorgen dat je een maximale ranking kunt halen en verkeer naar je website kunt leiden vanuit zoekmachines. Zorg ervoor dat je pagina voldoet aan de gebruikelijke verbeteringen die hier worden aanbevolen voordat je verder gaat met meer geavanceerde SEO-strategieën.',
      audit_performance: 'Prestatie',
      audit_performance__descr:
        'Prestaties zijn belangrijk voor een goede gebruikerservaring en lagere bounce rates (die indirect ook van invloed kunnen zijn op je zoekmachine rankings). Blijf je prestaties in de loop van de tijd monitoren om ervoor te zorgen dat er geen periodieke schommelingen zijn.',
      audit_security: 'Beveiliging',
      audit_security__descr:
        'Beveiliging is belangrijk om ervoor te zorgen dat je website gebruikersgegevens beschermt, niet gecompromitteerd raakt en niet te maken krijgt met downtime of gegevensverlies. Wij adviseren voortdurende patching en het voortdurende gebruik van beschermende monitoringtools.',
      audit_mobile: 'Mobiel',
      audit_mobile__descr:
        'Het wordt steeds belangrijker voor bedrijven om een mobielvriendelijke website te hebben. Niet alleen kunnen klanten je zo gemakkelijker bereiken als ze onderweg zijn, maar het heeft ook invloed op de optimalisatie van zoekmachines.',
      audit_fix_issue: 'Probleem oplossen',
      savings_table__keyword: 'Trefwoord',
      savings_table__keyword_explained:
        'Als we een URL hebben van waar het trefwoord gerankt wordt op je domein, zal het trefwoord omgezet worden in een link',
      savings_table__position: 'Positie',
      savings_table__cpc: 'CPC',
      savings_table__cpc_explained: 'Cost Per Click',
      savings_table__monthly_search: 'Maandelijkse Zoekopdrachten',
      savings_table__savings: 'Besparingen',
      competitors_table__modal_title:
        'Zoekwoorden gebruikt voor vergelijking met concurrenten',
      competitors_table__table_header: 'Trefwoorden',
      screenshot__not_available: 'In afwachting van schermafdruk',
      english: 'Engels',
      dutch: 'Nederlands',
      french: 'Frans',
      german: 'Duits',
      espanol: 'Spaans',
      portuguese: 'Portugees',
      italian: 'Italiaans',
      turkish: 'Turks',
      wp_auto__header_1: 'Goed nieuws! Het lijkt erop dat je',
      wp_auto__header_2: 'WordPress',
      wp_auto__header_3:
        'Wat betekent dat wij kunnen proberen een heleboel problemen automatisch voor je op te lossen.',
      wp_auto__header_4: 'Maar om ons dit te laten doen, moet je je',
      wp_auto__header_5: 'gebruikersnaam',
      wp_auto__header_6: 'en',
      wp_auto__header_7: 'wachtwoord',
      wp_auto__header_8:
        'en zorg ervoor dat de URL voor het admin gedeelte correct is.',
      wp_auto__form_1: 'URL',
      wp_auto__form_2:
        'We gaan er van uit dat het admin gedeelte van je site als standaard is ingesteld door wordpress, zo niet pas het dan aan.',
      wp_auto__form_3: 'Gebruikersnaam',
      wp_auto__form_4: 'Wachtwoord',
      wp_auto__form_installed: 'geïnstalleerd',
      wp_auto__form_success_header:
        'Plugins zijn succesvol geïnstalleerd en ingesteld!',
      wp_auto__form_success_msg:
        'Bij de volgende controle zou je verbeteringen moeten zien.',
      wp_auto__form_success_breakdown:
        'Hier is een overzicht van wat er op je site is geïnstalleerd en geconfigureerd:',
      wp_auto__request_error:
        'De server kon niet worden bereikt, probeer het later nog eens.',
      wp_auto__page_not_found: 'De opgegeven URL kon niet worden gevonden',
      wp_auto__login_not_found:
        'Het inlogformulier kon niet worden gevonden, controleer de URL',
      wp_auto__login_error:
        'Je hebt een ongeldige gebruikersnaam of wachtwoord ingevoerd',
      wp_auto__file_upload_error:
        'Plugins konden niet worden geüpload, de gebruiker moet een admin zijn om dit te laten werken',
      wp_auto__loading_msg_1:
        'Tracht login formulier te vinden en gebruik inloggegevens...',
      wp_auto__loading_msg_2: 'Plugins uploaden naar je wordpress site...',
      wp_auto__loading_msg_3:
        'Plugins installeren en configureren, dit kan een paar minuten duren...',
      uptime__header_title: 'Uptime Geschiedenis',
      uptime__header_status: 'Huidige Status',
      uptime__table_date: 'Datum',
      uptime__table_time: 'Tijd (UTC)',
      uptime__table_status: 'Status',
      uptime__table_no_data: 'Geen gegevens beschikbaar',
      all: 'alle',
      online: 'online',
      offline: 'offline',
      request_help__cta: 'Hulp vragen',
      request_help__success_toast: 'E-mail succesvol verzonden!',
      request_help__header_li: 'Naam van het probleem',
      request_help__header_li_2: 'Beschrijving van het probleem',
      request_help__header_li_3: "Pagina's met probleem",
      request_help__description_p:
        'Stuur een kopie van dit probleem naar je web developer',
      request_help__error_bad_request:
        'De gegevens die werden verzonden waren niet juist, neem contact op met support',
      request_help__error_unauthorized:
        'Ongeldige rechten, neem contact op met je beheerder',
      request_help__form_subject: 'Onderwerp',
      request_help__form_subject_placeholder: 'onderwerp van de e-mail',
      request_help__form_to: 'Naar',
      request_help__form_to_placeholder: "web developer's e-mail",
      request_help__form_cc: 'Cc',
      request_help__form_cc_placeholder: 'jouw e-mail adres',
      request_help__form_name: 'Naam/Bedrijf',
      request_help__form_name_placeholder:
        'jouw naam of de naam van je bedrijf',
      request_help__form_comments: 'Opmerkingen',
      request_help__form_comments_placeholder: 'optionele opmerkingen',
      request_help__form_required: 'verplicht',
      request_help__form_send: 'Verstuur',
      top_issues__title: 'Top audit problemen',
      info_box__domain: 'domein',
      info_box__last_crawled: 'laatst gecrawld',
      info_box__cms: 'ontdekte cms',
      info_box__status: 'status',
      admin_setup__navbar_styling: 'Navbar Styling',
      audit_section__improve: 'Verbeteren',
      site__audit_in_progress:
        'We zijn momenteel je site aan het controleren. Wanneer de audit is voltooid, zal de site automatisch worden bijgewerkt.',
      site__audits_in_progress:
        'We zijn momenteel je sites aan het controleren. Wanneer de audits voltooid zijn, zal de site automatisch worden bijgewerkt.',
    },
  },
  tr: {
    translation: {
      monthly_savings: 'Aylık tahmini tasarruf',
      welcome: 'Hoş geldiniz',
      show_less: 'Daha az göster',
      show_more: 'Daha fazla göster',
      download: 'Indir',
      ranking_graph__rank_unknown: 'Sıralama hareketi hesaplanamadı',
      ranking_graph__last_update: 'Son güncelleme:',
      ranking_graph__go_back: 'Geri',
      ranking_graph__calculation_error: 'Sıralama hesaplanamadı:',
      ranking_graph__try_again_one_week: 'Bir hafta içinde tekrar deneyin',
      ranking_graph__open_graph: 'Grafiği aç',
      ranking_graph__rank_no_movement: 'Sıralama geçen haftadan beri değişmedi',
      ranking_graph__down: 'Aşağı',
      ranking_graph__up: 'Yukarı',
      ranking_graph__positions_last_week: 'geçen haftadan beri pozisyonlar',
      ranking_summary__not_ranking:
        'Bu dönem için henüz sıralamaya katılmamış görünüyorsunuz.',
      ranking_summary__updating:
        'Sıralama bilgisi güncellemesi devam etmektedir.',
      ranking_summary__rank: 'Sıra',
      ranking_info__data_visualisation: 'Veri Görselleştirme',
      ranking_info__announce_graph:
        'Haberler harika! Yeterli bilgi kaydettikten sonra bilgilerinizi burada etkileşimli bir çizelgede sunacağız',
      ranking_info__announce_graph_2: 'Bu alanı izleyin',
      ranking_info__no_results:
        'Henüz popüler bir anahtar kelime için sıralama almadınız',
      export_csv__url: 'URL',
      export_csv__page_title: 'Sayfa Başlığı',
      export_csv__trust_flow: 'Güven Akışı',
      export_csv__citation_flow: 'Alıntı Akışı',
      export_csv__target_url: 'Hedef URL',
      export_csv__date_discovered: 'Keşif Tarihi',
      export_csv__date_removed: 'Kaldırılma Tarihi',
      export_csv__link_type: 'Bağlantı Türü',
      search_engine__label:
        'İnternet sitenizdeki tüm sayfaların görüntüleneceği yer',
      search_engine__info_icon:
        'Tüm büyük arama motorlarında bulunabilir hale gelin. İnternet sitenizin çevrimiçi her yerde görünmesi için gereken karmaşayı ortadan kaldırıyoruz. En popüler arama motorlarında listelenmenizi sağladık.',
      search_engine__index_completed: 'Dizin tamamlandı',
      search_engine__index_pending: 'Dizin bekleniyor',
      keywords__edit: 'Düzenle',
      keywords__ranking_on:
        'Bu anahtar kelimeler aratıldığında arama motorlarındaki sıralama',
      keywords__graph_after_7_days:
        'İnternet sitenize trafik yönlendirdiğini bildiğimiz 3 adet popüler kelime bulduk. 7 gün sonra verileriniz canlı bir grafikte gösterilecek. Güncellenecek veriler arasına yeni anahtar kelimeler ekledikten sonra lütfen biraz bekleyin.',
      keywords__change: 'Anahtar kelimelerinizi değiştirin',
      keywords__modal_info:
        'Anahtar kelimeleriniz kısa süre önce güncellendi. Sonuçlarınızı görmek için lütfen bu sayfayı 10 dakika sonra ziyaret edin.',
      sitemap_fix__1: 'Tespit ettiğimiz sayfa sayısı:',
      sitemap_fix__2: 'Sayfalar site haritanıza eklendi.',
      sitemap_fix__3: 'Site Haritasını İndir',
      speed_fix__1:
        'İnternet Sitemin Yavaş Yüklenmesi Konusunda Ne Yapabilirim ?',
      speed_fix__2:
        'Sayfanız hakkında tek bir konudan bahsetmek zorunda kalsaydık konumuz, yüklenme hızının önemi olurdu. Siteniz yavaş yüklendiğinde yalnızca kötü bir kullanıcı deneyimi sunmakla kalmaz aynı zamanda da arama motorlarında daha alt sıralarda yer alırsınız.',
      speed_fix__3: '1. Ön Belleklemeyi Etkinleştirin',
      speed_fix__4:
        'Bir internet sitesini ziyaret ettiğinizde belirli elemanlar önbelleğe atılır ve böylece sonraki ziyaretinizde bu parçalara kolayca erişilir ve sayfa çok daha hızlı yüklenir. Ön bellekleme etkinken tarayınız her kaynağı indirmek zorunda kalmaz ve yalnızca bir kısmını indirmesi yeterli olur.',
      speed_fix__5:
        'Ön belleklemeyi etkinleştirerek, sitenizi tekrar ziyaret eden kullanıcıların yükleme sürelerini ciddi ölçüde iyileştirebilirsiniz. WordPress gibi bir CMS kullanıyorsanız W3 Total Cache veya W3 Super Cache gibi bir eklenti kurabilirsiniz. Bu eklentilerin ikisi de sayfa genelinde veya belirli elemanları ön bellekleme olanağı tanır.',
      speed_fix__6: '2. Yoğun Kaynak Tüketen Eklentileri Kaldırın',
      speed_fix__7:
        'Sitenizde çok fazla eklenti çalıştırıyorsanız internet sitenizi yavaşlatıyor olabilirsiniz. Sitenizin istediğiniz şekilde çalışması için pek çok eklentiye ihtiyacınız olabilir. Ancak bazı eklentiler, özellikle de yoğun kaynak tüketenler olmadan da idare etmeniz mümkün olabilir.',
      speed_fix__8:
        'Bunun en kolay yolu, GTMetrix veya Google Pagespeed Insights gibi bir araç kullanarak temel yüklenme hızlarınızı test etmektir. Ardından eklenti listesini inceleyerek eklentileri tek tek devre dışı bırakabilirsiniz. Ardından hız testini, eklenti devre dışıyken tekrarlamalısınız.',
      speed_fix__9:
        'Bu biraz vakit alabilir ancak sitenizin yüklenme hızını en olumsuz etkileyen eklentileri bulmanıza yardımcı olacaktır. Bu noktada, daha az kaynak tüketen bir eklenti arayabilir veya başka bir çözüm üretebilirsiniz.',
      speed_fix__10: '3. Görsellerinizi Optimize Edin ve Boyutlarını Azaltın',
      speed_fix__11:
        'Sitenizde optimize edilmemiş çok sayıda görsel varsa sitenizin yüklenme hızını olumsuz etkiler. Çok büyük görseller kullanarak tarayıcıyı daha büyük dosyalar yüklemek zorunda bırakırsınız. Daha hızlı yükleme için görsellerinizi optimize edebileceğiniz birkaç farklı yol bulunur.',
      speed_fix__12:
        'Görsellerinizin gereksiz büyük olmadıklarından emin olun. Örneğin blog sayfanızın genişliği 900 px ise görsellerinizin de aynı genişliğe sahip olduklarından emin olun.',
      speed_fix__13:
        'WordPress gibi bir CMS kullanıyorsanız görsellerin dosya boyutlarını otomatik olarak küçültmek için WPSmush gibi bir eklenti kurabilirsiniz.',
      speed_fix__14:
        'Görselleri siteye yüklemeden önce kaliteden ödün vermeden görselinizin dosya boyunu küçültmek için Tiy PNG adlı araçtan geçirin.',
      speed_fix__15: '4. Kodunuzu Minimize Edin',
      speed_fix__16:
        'İnternet sitesi kodunuz bazen çok dağınık bir hal alabilir. Bu olduğunda sitenizin yüklenmesi çok daha uzun sürer. CMS ile çok fazla özelleştirme yaptığınızda ve hatta sitenizi oluşturmak için bir internet sitesi oluşturma aracı kullandığınızda, bulunmasına gerek olmayan satır sonları, boşluklar ve elemanlar oluşur.',
      speed_fix__17:
        'WordPress kullanıyorsanız Better WordPress Minify gibi bir eklenti kodunuzu minimize eder. Yukarıda vurgulanan ön bellekleme eklentilerinden birini kullanıyorsanız minimize etme işlevi her zaman bulunacaktır.',
      speed_fix__18:
        'CMS kullanmıyorsanız kodunuzu, Pagespeed Insights Chrome Extension kullanarak minimize edebilirsiniz. Bu uzantı, kodunuzun minimize edilmiş bir sürümünü oluşturur. Böylece hangi sürümün daha hızlı olduğunu görebilirsiniz.',
      speed_fix__19: '5. CDN Kullanın',
      speed_fix__20:
        'Sitenizin yüklenme hızı, kullanıcının, site dosyalarınızın bulunduğu sunucuyla arasındaki uzaklıktan etkilenir. Fiziksel konumları ne kadar uzak olursa sitenizin yüklenmesi de o kadar yavaş olur. CDN kullanmak bu sorunu giderebilir.',
      speed_fix__21:
        'CDN, site dosyalarınızı küresel sunuculardan oluşan bir ağa dağıtır. Böylece kullanıcılarınız, sitenize kendilerine en yakın sunucu üzerinden erişebilir.',
      sitemap: 'Site haritanıza',
      sitemap__2: 'sayfalar',
      sitemap__3: 'ekledik',
      backlinks: 'internet',
      backlinks__from: 'sitelerinden',
      backlinks__websites: 'bağlantılar (backlink)',
      backlinks__info:
        'Basit şekilde ifade etmek gerekirse "backlink" bir internet sitesinden diğerine giden bir bağlantıdır. Google gibi arama motorları, bir internet sitesinden başka bir internet sitesine bağlantı verildiğinde içeriğin bahse değer olduğuna inandıkları için "backlink"leri sıralama sinyali olarak kullanırlar.',
      backlinks__export: "En iyi 100 backlink'i dışa aktar",
      backlinks__not_found: 'Backlink Bulunamadı',
      competitors__comparison: 'Anahtar Kelime Rakipleri',
      competitors__info:
        'İşletmenizi rakiplerinizle kıyaslayın. Hedeflediğiniz anahtar kelimeler için genel görünürlüğünüzü izleriz. Sizin veya rakiplerinizin sıralamasında bir değişiklik olduğunda sıralama tahtasını güncelleriz. Rakiplerinizi bulmak ve güncellemekte kullandığımız anahtar kelimelerin listesini görmek için "Daha fazla göster"e tıklayın.',
      competitors__no_results_title: 'Rakip bulunamadı',
      competitors__no_results_description: 'Doğrudan rakip bulamadık.',
      competitors__no_results_description_keywords:
        'Sitenizle ilişkilendirilmiş anahtar kelime olmadığından doğrudan rakip bulamadık.',
      savings__top: 'Organik trafiğinizin değeri',
      savings__info_1: 'Tahmini tasarruflarınız nasıl hesaplanıyor?',
      savings__info_2:
        "İşletmeniz için bulduğumuz anahtar kelimelerin ortalama pozisyonu ile Google Ads'te tıklama başına ortalama maliyet ve tahmini trafik hacmi çarpılır.",
      savings__saved_in: 'Tasarruf',
      savings__monthly: 'Aylık tahmini tasarruf',
      alerts__stay_informed: 'Bildirim Alın',
      alerts: 'Uyarılar',
      alerts__info:
        'Bu önemli uyarılar, internet sitenizin daima aklınızda olmasını sağlayarak çevrimiçi ortamda her zaman güncel, bulunabilir ve güvenli kalmanızı temin eder.',
      alerts__downtime: 'Aksaklık Uyarısı',
      alerts__downtime_info:
        'İnternet sitenizin bakıma ihtiyacı olduğunda bildirim alarak internet siteniz çevrimdışıyken iş kaybetmeyin.',
      alerts__security: 'Güvenlik Uyarısı',
      alerts__security_info:
        'Botlar ve bilgisayar korsanları her yerde. İnternet siteniz korunaksız kaldığında bildirim alın.',
      alerts__competitor: 'Rekabet Uyarısı',
      alerts__competitor_info:
        'Bilgi, güçtür. Rakipleriniz öne geçtiğinde veya gerilediğinde bunu öğrenen ilk siz olun.',
      alerts__crawling: 'Tarama Uyarısı',
      alerts__crawling_info:
        'İnternet siteniz taramalarda görüntülenmiyorsa, internetteki milyonlarca insan için görünür olamazsınız. Böyle bir durum olması halinde size hemen haber veririz.',
      label__save: 'Kaydet',
      account__settings: 'Ayarlar',
      account__firstname: 'Adı',
      account__lastname: 'Soyadı',
      account__email: 'E-posta',
      account__password: 'Parola',
      account__phone: 'Telefon',
      account__saved: 'Kaydedildi',
      account__update: 'Güncelle',
      admin_clients__total_label: 'Toplam istemci',
      admin_clients__total_title:
        'İnternet sitesini optimize ettiğimiz istemci sayısı toplamı',
      admin_clients__paid_label: 'Ücretli istemciler',
      admin_clients__paid_title:
        'Ücretsiz deneme sürümünde olmayan ücretli abonelikler',
      admin_clients__trial_label: 'Ücretsiz deneme sürümü',
      admin_clients__trial_title:
        'Ücretsiz deneme sürümü kullanmakta olan istemci sayısı',
      admin_clients__import: 'İstemcileri İçe Aktar',
      admin_clients__manage: 'Kullanıcıları Yönet',
      admin_clients__settings: 'Ayarlar',
      admin_clients__website: 'İnternet sitesi',
      admin_clients__email: 'İstemci E-Posta',
      admin_clients__status: 'Durum',
      admin_clients__added: 'Eklendi',
      admin_clients__cancelled: 'İptal edildi',
      admin_clients__active_subscription: 'Aktif Abonelik',
      admin_clients__trial: 'Deneme',
      admin_clients__active: 'Aktif',
      admin_clients__link: 'Bağlantı',
      admin_clients__view: 'Görüntülenme',
      admin_clients__processing: 'İşleniyor',
      admin_clients__actions: 'Eylemler',
      admin_clients__remove: 'Çıkart',
      admin_clients__demo_data:
        'Test amaçlı demo veri yükle. Seçilen site için geçmiş sıralama verisini doldur.',
      admin_clients__data: 'Veri',
      admin_clients__remove_confirm_title: 'Kaldırmayı Doğrula',
      admin_clients__remove_confirm:
        'Siteyi kaldırmak istediğinize emin misiniz',
      admin_import__back: 'Geri',
      admin_import__sites: 'Siteleri İçe Aktar',
      admin_setup__back: 'Geri',
      admin_setup__settings: 'Ayarlar',
      admin_setup__business_name: 'İşletme Adı',
      admin_setup__domain: 'Etki Alanı',
      admin_setup__domain_description:
        'Etki alanları, uygulama sürümünüzün barındırıldığı yerlerdir. Maks. 10 etki alanı.',
      admin_setup__send_from: 'Gönderim E-Posta Adresi',
      admin_setup__send_from_description:
        'Bu alanı değiştirmek için bizimle iletişime geçin. Etki alanınızı doğrulamamız gerekir.',
      admin_setup__shared_secret: 'Paylaşılan sır',
      admin_setup__custom_css: 'Özel stil sayfası',
      admin_setup__advanced: 'Gelişmiş',
      admin_setup__css_page: 'Yüklenecek özel CSS sayfası.',
      admin_setup__webhook_endpoints: 'Webhook Abone Uç Noktaları',
      admin_setup__webhooks_description:
        'Webhook uyarıları alacak uç noktalar. Virgülle ayrılmış liste kabul eder.',
      admin_setup__saved: 'Kaydedildi!',
      admin_setup__update: 'Güncelle',
      admin_setup__logo_dark: 'Logo (Koyu)',
      admin_setup__logo_dark_description:
        'Oturum açma sayfası ve açık renk arka planlar için koyu renkli sürüm.',
      admin_setup__no_image: 'Henüz görsel eklenmedi',
      admin_setup__drag_and_drop:
        'Dosya sürükleyip buraya bırakın veya dosyaları seçmek için tıklayın',
      admin_setup__favicon: 'Favicon',
      admin_setup__not_allowed: 'İzin verilmez',
      admin_setup__no_permission:
        'Üzgünüz, bu sayfayı görüntüleme izniniz yok.',
      admin_setup__account_owner_only:
        'Bu sayfayı yalnızca hesap sahibi görüntüleyebilir.',
      admin_stats__users: 'Kullanıcılar',
      admin_stats__page_scanned: 'Taranan Sayfalar',
      admin_stats__pages: 'Sayfalar',
      admin_stats__audits: 'Denetimler',
      admin_stats__order_count: 'Sipariş Sayısı',
      admin_stats__order: 'Sipariş',
      admin_stats__order_value: 'Sipariş Değeri (£)',
      admin_stats__keywords: 'Anahtar kelimeler',
      admin_stats__proposals: 'Teklifler',
      admin_stats__proposals_sent: 'Teklifler Gönderildi',
      admin_stats__sent_proposals: 'Gönderilen Teklifler',
      admin_stats__proposals_completed: 'Teklifler Tamamlandı',
      admin_stats__completed_proposals: 'Tamamlanan Teklifler',
      admin_stats__proposal_count_30_days: 'Teklif Sayısı (Son 30 gün)',
      admin_stats__proposal_value_30_days: 'Teklif Değeri (Son 30 gün)',
      admin_stats__count: 'Sayı',
      admin_stats__value: 'Değer (£)',
      admin_stats__download_png: 'PNG indir',
      admin_stats__download_svg: 'SVG indir',
      admin_stats__download_pdf: 'PDF indir',
      admin_users__back: 'Geri',
      admin_users__email: 'E-posta',
      admin_users__name: 'İsim',
      admin_users__auth_google: 'Google ile Kimlik Doğrulandı',
      admin_users__logins: 'Oturum açma eylemleri',
      admin_users__status: 'Durum',
      admin_users__actions: 'Eylemler',
      admin_users__edit: 'Düzenle',
      add_domain: 'Etki alanı ekle',
      add_domain__up_to_10: 'Maks. 10 etki alanı ekle',
      add_domain__remove_last: 'Son etki alanını kaldır',
      add_domain__remove: 'Etki alanı kaldır',
      how_are_your_savings_calculated:
        'Tahmini tasarruflarınız nasıl hesaplanıyor?',
      login__sign_in: 'Giriş Yap',
      login__email: 'E-posta Adresi',
      login__forgot_pwd: 'Parolanızı Mı Unuttunuz?',
      login__error: 'Üzgünüz, yanlış kullanıcı adı veya parola girdiniz.',
      login__failed: 'Oturum açılamadı',
      register: 'Kayıt Olun',
      register__account_created: 'Hesap Oluşturuldu',
      register__creating_account: 'Hesabınız Oluşturuluyor...',
      register__please_wait_1: 'Lütfen',
      register__please_wait_2: 'saniye bekleyin.',
      register__registered: 'Zaten kayıt oldunuz.',
      register__error:
        'Kayıt olmak için hüküm ve koşullarımızı kabul etmelisiniz.',
      register__online_visibility: 'Çevrimiçi Görünürlüğünüzü Artırın',
      register__detect_issues: 'İnternet Sitesi Sorunlarını Tespit Edin',
      register__keyword_research: 'Anahtar Kelime Araştırması',
      register__issue_monitoring: 'Sorun Takibi',
      register__insights: 'Arama İçgörüleri',
      register__much_more: 'Ve Çok Daha Fazlası',
      register_form__create_free_account: 'Ücretsiz Hesabınızı Oluşturun',
      register_form__name: 'Adınız',
      register_form__email: 'E-posta',
      register_form__password: 'Parola',
      register_form__agency_name: 'Ajans adınız',
      register_form__domain: 'Etki alanınız',
      register_form__domain_error: 'Üzgünüz bu alt etki alanı müsait değil.',
      register_form__domain_success: 'Mükemmel! Alt etki alanı müsait',
      register_form__terms_1: 'Hüküm ve koşulları',
      register_form__terms_2: 'kabul ediyorum',
      register_form__already_have_account:
        'Hesabınız var mı? Hemen oturum açın',
      register_form__partner_account: 'Partner Hesabı Oluştur',
      register_agency__terms_error:
        'Kayıt olmak için hüküm ve koşullarımızı kabul etmelisiniz.',
      register_agency__generic_error: 'Üzgünüz, bir hata oluştu',
      register_agency__audit_lead:
        'Tamamen markalı denetim ve lider oluşturma uygulaması.',
      register_agency__unlimited_audits: 'Sınırsız Denetim',
      register_agency__reporting: 'İstemci Raporlama',
      register_agency__branding: 'Özel Markalama',
      register_agency__sales_proposals: 'Satış Teklifleri',
      register_agency__no_cost_upfront: 'Peşinat Yok',
      register_agency__try_again_later:
        'Bağlantı hemen çalışmazsa lütfen bir saat sonra tekrar deneyin.',
      register_agency__terms_1: 'Ajans hüküm ve koşullarını',
      register_agency__terms_2: 'kabul ediyorum',
      site__audit_pending: 'Site Denetimi Bekliyor',
      site__audits_pending: 'Site Denetimleri Bekliyor',
      site__site_not_found: 'Site Bulunamadı',
      site__sites_not_found: 'Hiçbir Site Bulunamadı',
      site__site_not_found_message:
        'Sitenizi bulamadık. Lütfen destek ile iletişime geçin.',
      site__sites_not_found_message:
        'Hesabınızla ilişkilendirilmiş bir site bulamadık. Lütfen destek ile iletişime geçin.',
      theme__all_fields_required: 'Lütfen tüm alanları doldurun',
      theme: 'Tema',
      theme__back: 'Geri',
      theme__customise: 'Temanızı özelleştirin.',
      theme__sidebar_bg: 'Kenar çubuğu arka plan rengi',
      theme__hex: 'Altılı renk kodu',
      theme__header_colour: 'Sayfa Başlığı Metin Rengi',
      theme__primary_colour: 'Birincil Metin Rengi',
      theme__primary_button_colour: 'Birincil Düğme Rengi',
      theme__custom_font: 'Özel Yazı Tipi',
      theme__select: 'Seç',
      theme__disclaimer_1: 'Not: Tarayıcı ön belleklemesi',
      theme__disclaimer_2: 'nedeniyle',
      theme__disclaimer_3:
        'temanın herkes tarafından görülebilmesi için bir saate kadar süre geçmesi gerekebilir.',
      theme__saved: 'Kaydedildi!',
      theme__save: 'Kaydet',
      theme__preview: 'Ön izleme',
      theme__title: 'Başlık',
      theme__ok: 'Tamam',
      audit_result__pages: 'Sayfalar',
      audit_result__page: 'Sayfa',
      audit_result__pages_impacted: 'Etkilenen sayfa sayısı',
      audit_result__easy: 'Kolay',
      audit_result__easy_fix: 'Düzeltilmesi kolay',
      audit_result__medium: 'Orta',
      audit_result__medium_fix: 'Orta zorluk',
      audit_result__difficult: 'Zor',
      audit_result__difficult_fix: 'Düzeltilmesi zor',
      audit_result__explanation: 'Açıklama',
      audit_result__advice: 'Tavsiye',
      audit_result__export_list: 'Listeyi Dışa Aktar',
      audit_section__yes: 'Evet',
      audit_section__no: 'Hayır',
      audit_section__pass: 'Başarılı',
      audit_section__fail: 'Başarısız',
      audit_section__show_pages_with_issue: 'Sorunlu sayfaları göster',
      audit_section__page_url: 'Sayfa URL’si',
      audit_section__last_scan: 'Son tarama',
      audit_section__ago: 'önce',
      audit_section__fix: 'Otomatik düzeltme',
      audit_section__learn: 'Düzeltmeyi öğrenin',
      audit_section__what_we_found: 'Ne bulduk?',
      audit_section__average_speed: 'Ortalama hız',
      audit_section__average_speed_description:
        'Arama motorları, hızlı yüklenen internet sitelerine tercihen öncelik verir. Siteniz ne kadar hızlı yüklenirse siteden çıkma oranı o kadar düşük olur. Siteniz hızlıysa arama motorlarında siteden çıkma oranları yüksek olan yavaş sitelere göre sıralama avantajına sahip olabilirsiniz.',
      audit_section__is_site_fast: 'Siteniz hızlı yükleniyor mu?',
      audit_section__try_again_later:
        'Henüz veri yok. Daha sonra tekrar deneyin.',
      audit_section__security: 'Güvenlik',
      audit_section__is_site_secure: 'Siteniz güvenli mi?',
      audit_section__pages_analyzed: 'İncelenen sayfalar',
      audit_section__pages_on_site: 'Sitenizdeki sayfalar',
      audit_section__mobile_speed: 'Mobil hız',
      audit_section__mobile_loading_time:
        'Sitenizin mobil bir cihazda yüklenme süresi',
      audit_section__mobile_ready: 'Mobil kullanıma hazır mı?',
      audit_section__mobile_site_works:
        'Siteniz, mobil cihazlarda doğru çalışıyor',
      audit_section__no_screenshot: 'Ekran görüntüsü kullanılamıyor',
      audit_section__what_to_look_at: 'İncelemeniz gerekenler neler?',
      audit_section__no_issues: 'Sorun bulunamadı.',
      audit_section__what_done_well: 'İyi yaptığınız işler neler?',
      audit_summary__overall_score:
        'Genel puanınız, aşağıda açıklanan dört özel puanın ortalamasıdır.',
      audit_summary__seo: 'Arama Motoru Optimizasyonu (SEO)',
      audit_summary__seo_2: 'SEO performansı',
      audit_summary__seo_bis: 'Arama Motoru Optimizasyonu',
      audit_summary__perf: 'Performans',
      audit_summary__perf_2: 'Yüklenme hızı',
      audit_summary__security: 'Güvenlik',
      audit_summary__security_2: 'Site güvenliği',
      audit_summary__mobile: 'Mobil araçlara uygun',
      audit_summary__mobile_2: 'Sitenizin mobil araçlarda kullanım kolaylığı',
      audit_summary__pending:
        'Puanlama bekliyor. Lütfen daha sonra tekrar deneyin.',
      audit_tab_menu__seo: 'SEO',
      audit_tab_menu__performance: 'Performans',
      audit_tab_menu__security: 'Güvenlik',
      audit_tab_menu__mobile: 'Mobil',
      backlink_modal__backlinks: "Backlink'ler",
      backlink_modal__website: 'İnternet sitesi',
      backlink_modal__trust_flow: 'Güven Akışı',
      backlink_modal__citation_flow: 'Alıntı Akışı',
      backlink_modal__detected: 'Tespit Edildi',
      backlink_modal__detected_on: 'Tespit Edilen Tarih',
      backlink_modal__close: 'Kapat',
      generic_modal__close: 'Kapat',
      generic_modal__ok: 'Tamam',
      google_button__sign_in: 'Google ile oturum aç',
      radial_chart__score: 'PUAN',
      header__error: 'Üzgünüz, bir hata oluştu',
      header__dashboard: 'Pano',
      header__audit: 'Denetim',
      header__alerts: 'Uyarılar',
      header__admin: 'Yönetici',
      header__policy: 'İlke',
      header__terms: 'Hükümler',
      import_modal__title: 'İçe Aktar',
      import_modal__step_1: '1) İsteğe bağlı olarak şablon sayfamızı indirin',
      import_modal__step_2: '2) İçe aktarılacak hesap tablosunu yükleyin',
      import_modal__step_2_def:
        'Desteklediğimiz dosya türleri: CSV, XLS ve XLSX',
      import_modal__download_template: 'Şablon İndir',
      import_modal__upload: 'Yükle',
      import_modal__close: 'Kapat',
      import_modal__next: 'İleri',
      import_wizard__template_error:
        'Üzgünüz, yüklediğiniz hesap tablosu geçerli değil. Lütfen gerekli tüm sütunların mevcut olduğundan emin olun veya şablonumuzu indirin.',
      import_wizard__import_clients: 'İstemcileri İçe Aktar',
      import_wizard__confirm: 'Onayla',
      no_results: 'Sonuç yok',
      no_results__not_discovered:
        'Bunun nedeni, bu internet sitesinin henüz Google tarafından keşfedilmemesi olabilir.',
      permissions_modal__edit: 'İzinleri Düzenle',
      permissions_modal__tip:
        'İpucu: Atadığınız yeni izinleri görebilmek için kullanıcıların oturumları kapatmaları gerekebilir.',
      permissions_modal__select: 'Seç',
      permissions_modal__user: 'Kullanıcı',
      permissions_modal__admin: 'Yönetici',
      permissions_modal__acc_owner: 'Hesap Sahibi',
      permissions_modal__fulfilment: 'Uygulama İcrası',
      permissions_modal__close: 'Kapat',
      permissions_modal__update: 'Güncelle',
      rate_limit__too_many_req: 'Çok Fazla İstek',
      rate_limit__try_again: 'Üzgünüz, lütfen daha sonra tekrar deneyin.',
      site_task__auto_plan: 'Otomasyon Planı Aktif',
      site_task__monitoring:
        'En iyi kullanıcı deneyimi ve yüklenme süresi için sitenizdeki değişiklikleri izliyoruz.',
      site_task__fast_load: 'Işık hızında yüklenme süreleri',
      site_task__seo: 'SEO ve yükseltme işlemleri',
      site_task__img_opti: 'Görsel optimizasyonu',
      site_task__sitemap: 'Site haritası oluşturma',
      site_task__server_config: 'Sunucu yapılandırması',
      site_task__seo_plan: 'SEO Planı Aktif',
      site_task__increased_vis:
        'Uzmanlardan oluşan ekibimiz, çevrimiçi görünürlüğünüzü artırmaya çalışıyor. Geçerli görevler:',
      site_task__completed: 'Görev tamamlandı',
      site_task__in_progress: 'Görevin ifa edildiği sayfa:',
      site_task__view_all: 'Hepsini Görüntüle',
      audit_doctype__descr:
        'Doctype, internet sayfasının kaynak kodundaki ilk elemandır ve her HTML belgesinin parçası olmalıdır. Mevcut olmadığında tarayıcınız ilginç bir moda girer. Doctype bulunmadığını görüyoruz. Bu durumu kendiniz düzeltebilecek olsanız da kodlamayı bilmiyorsanız biraz karmaşık olabilir. Çözülmek üzere geliştiricinize göndermenizi öneririz.',
      audit_doctype__fail_msg: "Ah, Doctype'ınızın doğru ayarlanması gerekiyor",
      audit_doctype__fail_name: 'Doctype yok',
      audit_doctype__pass_msg: "Harika, Doctype'ınız başarıyla ayarlandı",
      audit_doctype__pass_name: 'Doctype',
      audit_meta__descr: `Meta etiketler, sayfanın kaynak kodundaki sayfa içeriğini açıklayan metin parçalarıdır. Meta etiketler sayfada görünmeseler de Google gibi arama motorlarına sayfanın içeriği hakkında açıklama sağlayan küçük içerik tanımlayıcılardır. Meta etiketler yalnızca HTML'de mevcuttur; genellikle sayfanın "başında" bulunurlar ve yalnızca arama motorları (ve nereye bakması gerektiğini bilen insanlar) tarafından görülebilir. Meta etiketler eklemeniz gerekiyor.`,
      audit_meta__fail_msg: '$$issuecount sayfada meta etiketiniz yok',
      audit_meta__fail_name: 'Meta Etiket Yok',
      audit_meta__pass_msg: 'Meta etiketleriniz iyi durumda',
      audit_meta__pass_name: 'Meta Etiketler',
      audit_h1__descr: `Sayfanızda "H1" etiketi (başlık) bulunamadı. Bunlar, içeriği ve metni anlamlı ve hiyerarşik şekilde yapılandırdıkları için internet siteleri açısından önemli elemanlardır. Başlıklar, site ziyaretçileri için içeriğin okunabilirliğini ciddi ölçüde artırır. Google'a sayfa içeriği ve münferit alt başlıkların ağırlığı hakkında genel bilgi sağladığı için arama motorlarında da benzer şekilde çalışır. İçerik yönetim sisteminize erişip başlıklar seçeneğini arayarak internet sitenize H1 eklemelisiniz.`,
      audit_h1__fail_msg: 'Ah, $$issuecount sayfada H1 etiketiniz yok',
      audit_h1__fail_name: 'Sayfa Başlığı Eksik (H1)',
      audit_h1__pass_msg: 'Tüm H1 etiketleriniz görünür ve doğru formatlı',
      audit_h1__pass_name: 'Sayfa Başlığı (H1)',
      audit_compression__descr: `İnternet sayfasının yüklenmesi için gereken dosyalar ne kadar küçük olursa sayfa yükleme işleminin tamamlanması o kadar kısa sürer. Bu nedenle HTML, CSS ve JavaScript dosyalarının sıkıştırılması sayfanızın hızını artırmaya yardımcı olur. Bu tip dosyalar, kod kısaltılarak, örneğin yorumlar, satır sonları, gereksiz karakterler ve boşluklar silinerek sıkıştırılır. Ek olarak, Gzip (dosya formatı ve dosya sıkıştırmak ve açmak için kullanılan bir yazılım uygulaması) kullanılarak sunucu tarafında aktarım için dosya boyutları sıkıştırılabilir. Sitenizde "Gzip" benzeri bir sıkıştırma etkinleştirilmedi veya yapılandırılmadı. Bunu değiştirmeniz gerekiyor. Ancak bu işlem zor veya CMS'niz tarafından yapılmak üzere sınırlandırılmış olabileceği için bu işlemi geliştiricilerinize göndermenizi öneririz.`,
      audit_compression__fail_msg:
        'Sıkıştırma devre dışı görünüyor. Sorunu gidermek karmaşık olabilir ancak sonuç, uğraştığınıza değecek.',
      audit_compression__fail_name: 'Sıkıştırma Devre Dışı',
      audit_compression__pass_msg: 'Sıkıştırma etkin görünüyor, tebrikler.',
      audit_compression__pass_name: 'Sıkıştırma Etkin',
      audit_content_rate__descr:
        'Sayfadaki HTML ile kıyaslandığında yalnızca küçük miktarda içerik mevcut. Zayıf içerik gibi geçersiz içerik de kullanıcı açısından az değerli veya değersizdir. Google, düşük kaliteli giriş sayfalarını veya çok az veya hiç içerik bulunmayan sayfaları bu tür sayfalar olarak değerlendirir. Bu sorunu, yüksek kaliteli, konuyla ilgili ve orijinal içerik üreterek çözün.',
      audit_content_rate__fail_msg:
        'Ah! Hala $$issuecount sayfaya içerik eklemeniz gerekiyor',
      audit_content_rate__fail_name: 'Geçersiz İçerik',
      audit_content_rate__pass_msg: 'Tebrikler! İçerik hoş görünüyor',
      audit_content_rate__pass_name: 'Geçersiz İçerik Yok',
      audit_content_size__descr:
        'Zayıf içerik, kullanıcı açısından az değerli veya değersizdir. Google, düşük kaliteli giriş sayfalarını veya çok az veya hiç içerik bulunmayan sayfaları zayıf içerikli sayfalar olarak değerlendirir. Çok benzer içerikli bir sürü sayfa oluşturma tuzağına da düşmeyin. Orijinal olmayan sayfalar ve çalıntı ve kopya içeriklerin bulunduğu sayfalar da zayıf içerikli sayfalar olarak değerlendirilir. Google, anahtar kelimeler doldurulmuş sayfalardan da hoşlanmaz ve değerli ve düşük kaliteli içeriği birbirinden ayırabilir. Bu sorunu, yüksek kaliteli, konuyla ilgili ve orijinal içerik üreterek çözün.',
      audit_content_size__fail_msg:
        'Ah! Hala $$issuecount sayfada zayıf içerik var. Bu sorunu gidermek Google sıralamanızı artırır ve sitenizi daha kullanıcı dostu hale getirir',
      audit_content_size__fail_name: 'Zayıf İçerik Bulundu',
      audit_content_size__pass_msg:
        'Tebrikler! Sitenizdeki tüm sayfalarda benzersiz, kaliteli içerik var',
      audit_content_size__pass_name: 'Zayıf İçerik Yok',
      audit_content_read__descr:
        'Sayfanızdaki içerik okunaklı olmayabilir. İçeriğin okunabilirliğini ölçmek için tasarlanmış çok sayıda algoritma mevcuttur (robotlar ve arama motorları da bunu kullanır). İçeriğin okunabilirliğini hesaplamada kullanılan en popüler algoritmalardan biri Flesch-Kincaid-Kincaid okunabilirlik skorudur. Gunning-Fog, Coleman-Liau, SMOG index vb. başka algoritmalar da mevcuttur. İçeriğinizin okunabilirliğini artırmanız gerekiyor.',
      audit_content_read__fail_msg:
        '$$issuecount sayfadaki içeriğinizi okumak hala güç. Sorunun giderilmesi karmaşık olabilir ancak kullanıcıların sayfanızla etkileşime geçmesini istiyorsanız kesinlikle değerli bir adımdır',
      audit_content_read__fail_name: 'Düşük Okunabilirlik',
      audit_content_read__pass_msg:
        'Harika bir iş! İçeriğiniz kolaylıkla okunuyor ve kullanıcılarınıza ulaşma olasılığı yüksek',
      audit_content_read__pass_name: 'İyi Okunabilirlik',
      audit_html_old__descr:
        'Sitenizde, artık kullanılmaması gereken HTML etiketleri var. Genel olarak "deprecated" (modası geçmiş), "onaylanmamış öğe" anlamına gelir. BT alanında, modası öğeler, kullanılmalarına izin verilen ancak kullanılmaları tavsiye edilmeyen ve yenileriyle değiştirilen öğelerdir. "Deprecated code" (modası geçmiş kod), yazılımda kendisi yerine kullanılabilecek daha iyi bir alternatif bulunduğu için kullanılması tavsiye edilmeyen bir koddur. Alternatifi kullanmayı deneyin.',
      audit_html_old__fail_msg:
        'Ah, hala $$issuecount sayfada modası geçmiş HTML etiketleri mevcut',
      audit_html_old__fail_name: 'Modası geçmiş HTML Etiketleri',
      audit_html_old__issue: 'Modası geçmiş HTML etiketleri bulundu',
      audit_html_old__pass_msg:
        'Tebrikler! Tüm HTML etiketleriniz ışıl ışıl, yeni ve güzel görünüyor!',
      audit_html_old__pass_name: 'Modası geçmiş HTML Etiketi yok',
      audit_meta_descr__descr: `Sayfalarınızdan bir tanesi birden çok "tanım" meta etiketine sahip ve bu nedenle Google tarafından hatalı olan görüntülenebilir. Her sayfada yalnızca bir tane olmalıdır. Meta tanımları veya meta anahtar kelimeleri Google'ın internet aramasına ilişkin sıralama algoritmalarını etkilemez. Ancak meta tanımları, sayfanın Google CTR'sini (bağlantı tıklama oranı) etkileyerek sayfanın sıralanma kapasitesini olumlu etkileyebilir. Bu ve diğer nedenlerle meta tanımlarına özen göstermek önemlidir.`,
      audit_meta_descr__fail_msg:
        'Hala $$issuecount sayfada çoğul tanım etiketi var',
      audit_meta_descr__fail_name: 'Çoğul Tanım',
      audit_meta_descr__pass_msg:
        'Harika bir iş! Tüm meta etiketleriniz iyi görünüyor',
      audit_meta_descr__pass_name: 'Bir Tanım',
      audit_meta_tags__descr:
        'Sitenizdeki bazı meta etiketleri tekrarlanıyor. Her "meta" etiket türünden bir tane kalması için kopyaları kaldırın.',
      audit_meta_tags__fail_msg:
        'Ah hala $$issuecount sayfada tekrarlanan meta etiket var',
      audit_meta_tags__fail_name: 'Tekrarlanan Etiketler',
      audit_meta_tags__issue: 'Tekrarlanan meta etiketler bulundu',
      audit_meta_tags__pass_msg:
        'Tebrikler! Tüm meta etiketleriniz iyi görünüyor',
      audit_meta_tags__pass_name: 'Tekrarlanan meta etiket yok',
      audit_dup_titles__descr: `Sayfanızda birden çok sayıda <title> etiketi mevcut; yalnızca bir tane kullanmalısınız. Title (başlık) etiketi, kullanıcıların ve arama motorlarının sayfanızda ne tür bir içerik bulmayı bekleyebileceklerini hemen anlamalarını sağlar. Sayfada çoğul başlık etiketi varsa arama motorları "yanlış" etiketi görüntüleyebilir ve bu da arama sonuçlarından düşük etkileşime veya CTR'ye neden olabilir. SEO üstünde de olumsuz etkiye sahip olabilir.`,
      audit_dup_titles__fail_msg:
        'Ah! Hala $$issuecount sayfada çoğul başlık etiketi var. Vakit geçmeden sorunu giderin.',
      audit_dup_titles__fail_name: 'Çoğul Başlık',
      audit_dup_titles__issue: 'Tekrarlanan başlıklar var',
      audit_dup_titles__pass_msg:
        'Harika bir iş! Tüm başlık etiketleriniz iyi görünüyor',
      audit_dup_titles__pass_name: 'Tekrarlanan başlık yok',
      audit_favicon__descr: `Sitenizde "favicon" bulunmadı. Ancak favicon sayfalarınızın SEO'suna pek çok dolaylı fayda sağlar. Tarayıcı sekmelerinde, yer imlerinde, arşivde vb. internet sitenizin başlığının yanında favicon bulunması kullanıcının internet sitenizi zorlanmadan tanıyarak gezinmesini sağlayarak zaman tasarrufu etmesine yardımcı olur. Sitenizde favicon yoksa Chrome tarayıcının yer imleri listesinde bulunma fırsatını kaçırabilir ve bu nedenle de küçük ancak önemli bir sıralama sinyalinden olabilirsiniz. Sitenizde favicon bulunmalı.`,
      audit_favicon__fail_msg: "Ah! Hala favicon'unuz yok",
      audit_favicon__fail_name: 'İnternet Sitenizde Favicon Yok',
      audit_favicon__pass_msg: "Harika, favicon'unuz var",
      audit_favicon__pass_name: 'İnternet Sitenizde Favicon Var',
      audit_robot__descr:
        'Siteniz için robot dosyası bulamadık. Bu, arama motorları için önemli bir dosyadır. Robots.txt, site tasarımcılarının internet robotlarının (genellikle arama motoru robotlarının) internet sitelerinde nasıl gezineceğini yönlendirmek için oluşturdukları bir metin dosyasıdır. Robots.txt dosyası, robotların internette nasıl dolaştıklarını, içeriğe nasıl erişip dizinlediklerini ve bu içeriği kullanıcılara nasıl sunduklarını düzenleyen bir internet standartları grubu olan robot istisna protokolünün (robots exclusion protocol veya REP) bir parçasıdır. Sitenize robot dosyası ekleyin veya geliştiriciniz ile görüşün.',
      audit_robot__fail_msg: 'Ah! Hala robot dosyanız yok',
      audit_robot__fail_name: 'Robot Dosyanız Yok',
      audit_robot__pass_msg: 'Robot dosyanız eklendi ve iyi görünüyor',
      audit_robot__pass_name: 'Robot Dosyası',
      audit_sitemap__descr:
        'Site haritası bulamadık. Site haritası, arama motorlarının, internet sitenizdeki tüm içeriği bulmalarına, dolaşmalarına ve dizinlemelerine yardımcı olan bir plandır. Site haritaları aynı zamanda arama motorlarına sitenizdeki hangi sayfaların en önemli olduğunu da söyler. Site haritası eklemek karmaşık bir iştir ancak SEO sıralamanızı ciddi ölçüde geliştirin. Site haritası ekleyin veya geliştiriciniz ile görüşün.',
      audit_sitemap__fail_msg:
        'Öyle görünüyor ki hala bir site haritanız yok. Site haritası eklemek karmaşık bir iştir ancak bir site haritasına sahip olmak SEO sıralamanızı ciddi ölçüde geliştirin',
      audit_sitemap__fail_name: 'Site Haritası Bulunamadı',
      audit_sitemap__pass_msg: 'Harika, bir site haritanız var!',
      audit_sitemap__pass_name: 'Site Haritası Bulundu',
      audit_no_alt__descr:
        'Sitenizdeki görseller her zaman "alt" etiketine sahip olmazlar. İnternet erişilebilirliği için alt etiketlerine sahip olmak en iyi uygulamadır (görme bozukluklarına sahip kullanıcıların sayfadaki görseli anlamalarına katkıda bulunur) ve arama motoru görünürlüğünüzü artırmanıza yardımcı olur. Fotoğraflara alternatif metin ekleyin.',
      audit_no_alt__fail_msg:
        'Hala $$issuecount sayfada görsellerinizde alt etiketleri yok',
      audit_no_alt__fail_name: 'Görsellerinizde Alt Etiketleri Yok',
      audit_no_alt__pass_msg:
        'Harika! Tüm görsellerinizde alt etiketleri var. Erişilebilirlik açısından bir kahramansınız!',
      audit_no_alt__pass_name: 'Görsellerinizdeki Alt Etiketleri',
      audit_links__descr:
        'Sitenizdeki bağlantılardan bazılarının bozuk olduğunu fark ettik. Arama motorları, bağlantıları, bir internet sitesi için kalite unsuru olarak görür. İnternet sitenize açılan bağlantılar ve internet sitenizdeki bağlantılar arama sonuçlarında internet sitenizin sahip olacağı sıralamayı etkileyebilir. Bu nedenle bozuk bağlantıları kaldırmak veya güncellemek en iyi uygulamadır.',
      audit_links__fail_msg: 'Ah! Hala $$issuecount sayfada bozuk bağlantı var',
      audit_links__fail_name: 'İnternet Sitenize Açılan Bozuk Bağlantılar',
      audit_links__pass_msg: 'Tebrikler! Tüm bağlantılarınız harika çalışıyor',
      audit_links__pass_name: 'İnternet Sitenize Açılan Bozuk Bağlantı Yok',
      audit_empty_meta_descr__descr:
        'Description (tanım) meta etiketi var ancak bir değere sahip değil. Meta tanım etiketi, kullanıcıların, arama motoru sonuçlar sayfasında tıklarken sayfanızda hangi içerikleri bulmayı bekleyebileceklerini kısa sürede anlayabilmelerine yardımcı olduğu için önemlidir. İyi yazılan meta tanımlar tipik olarak daha iyi tıklama oranları sağlar. Metanı tanımların boş bırakılması bir optimizasyon fırsatının kaçırılması demektir. Bu aynı zamanda, meta tanımların sizin yerinize arama motorları tarafından yazılması anlamına gelir ve bunlar bazen çok kötü sonuçlar doğurabilmektedir. Sayfanızdaki içeriğe uygun güzel bir meta tanım oluşturun.',
      audit_empty_meta_descr__fail_msg: '$$issuecount sayfada meta tanım eksik',
      audit_empty_meta_descr__fail_name: 'Boş META Tanımlar Bulundu',
      audit_empty_meta_descr__pass_msg: 'Meta tanımınız iyi görünüyor',
      audit_empty_meta_descr__pass_name: 'Boş META Tanım Bulunmadı',
      audit_wrong_meta_descr__descr:
        'Meta tanım etiketi, sayfanızdaki içerikle eşleşmiyor gibi görünüyor. Meta tanım etiketi, kullanıcıların, arama motoru sonuçlar sayfasında tıklarken sayfanızda hangi içerikleri bulmayı bekleyebileceklerini kısa sürede anlayabilmelerine yardımcı olduğundan önemlidir. İyi yazılan meta tanımlar tipik olarak daha iyi tıklama oranları sağlar. Metanı tanımların boş bırakılması bir optimizasyon fırsatının kaçırılması demektir. Bu aynı zamanda, meta tanımların sizin yerinize arama motorları tarafından yazılması anlamına gelir ve bunlar bazen çok kötü sonuçlar doğurabilmektedir. Sayfanızdaki içeriğe uygun güzel meta tanımlar oluşturun.',
      audit_wrong_meta_descr__fail_msg:
        'Üzgünüz, $$issuecount sayfada uygun olmayan meta tanımınız var. Meta tanımına, sayfa içeriğinin kısa bir özetini eklemeyi deneyin',
      audit_wrong_meta_descr__fail_name: 'Uygun olmayan META Tanımları',
      audit_wrong_meta_descr__pass_msg:
        'Harika bir iş! Meta tanımı, sayfa içeriğine uygun',
      audit_wrong_meta_descr__pass_name: 'Uygun olmayan META Tanımı Yok',
      audit_page_size__descr:
        "Sayfalarınızda bozuk HTML veya desteklenmeyen içerik var: Googlebot, sayfanızdaki içeriği çözümleyemezse sayfanızda dolaşması da mümkün olmaz. Googlebot'un sayfanızı nasıl gördüğünü incelemek için Fetch as Google komutunu kullanın.",
      audit_page_size__fail_msg:
        'Ah, hala $$issuecount sayfada geçersiz içerik boyutları var',
      audit_page_size__fail_name:
        'İnternet Sitesindeki Sayfalarda Geçersiz Boyutlar Var',
      audit_page_size__pass_msg: 'Harika, sayfa boyutlarınız iyi durumda',
      audit_page_size__pass_name:
        'İnternet Sitesindeki Sayfalardaki Boyutlar Geçerli',
      audit_flash__descr:
        'Arama motoru örümcekleri, alanda ne olduğunu anlamak için Flash içinde dolaşamaz. Google internet tasarımcıları destek forumunda şöyle yazmaktadır: "Genel olarak arama motorları metin tabanlıdır. Bu, içinde dolaşılabilmesi ve dizinlenebilmesi için içeriğinizin metin formatında olması gerektiği anlamına gelir. Bu, sitenize Flash, Silverlight veya videolar gibi zengin medya içerikleri ekleyemeyeceğiniz anlamına gelmez; yalnızca, bu dosyalara eklediğiniz içeriğin aynı zamanda metin formatında da bulunması gerektiği, aksi halde arama motorları tarafından erişilemeyeceği anlamına gelir."',
      audit_flash__fail_msg: 'Hala $$issuecount sayfada flash içerik var',
      audit_flash__fail_name: 'Hala Flash Kullanıyorsunuz',
      audit_flash__pass_msg:
        'Tebrikler, tüm medya içeriğiniz arama motoru dostu durumda',
      audit_flash__pass_name: 'Flash Kullanmıyorsunuz',
      audit_iframe__descr:
        "iFrame, SEO bağlantılıysa Google, internet sayfalarındaki iFrame içeriklerinde az da olsa dolaşma kapasitesine sahiptir. Google'ın iFrame'leri bağlantılar üzerinden tanıdığı bilinmektedir ancak internet sitenizi yine de iFrame kullanmadan geliştirmeniz daha iyi olur.",
      audit_iframe__fail_msg: 'Ah, hala $$issuecount sayfada iFrame var',
      audit_iframe__fail_name: 'İnternet Sitenizde iFrame Bulundu',
      audit_iframe__pass_msg: 'Güzel, internet sitenizde iFrame bulunmadı',
      audit_iframe__pass_name: 'İnternet Sitenizde iFrame Bulunmadı',
      audit_lorem__descr:
        'Geliştiriciler, Lorem ipsum metnini, internet sayfaları için ayrılmış bilgi alanı olarak kullanırlar. Şablonu ayarladıktan sonra bu metinlerin tamamı benzersiz ve internet sitesi temasıyla ilgili bir metinle değiştirilmelidir. Aksi halde arama motorları sayfayı ilgisiz veya tekrarlanan bir sayfa olarak değerlendirebilir. SEO puanınızı geliştirmek için bu metni ilgili bir içerikle değiştirin.',
      audit_lorem__fail_msg:
        'Hala $$issuecount sayfada ayrılmış bilgi alanı metni var. SEO puanınızı artırmak için ayrılmış bilgi alanı metnini, ilgili bir içerikle değiştirin',
      audit_lorem__fail_name: 'Lorem Ipsum Bulundu',
      audit_lorem__pass_msg:
        'Ana sayfanızda ayrılmış bilgi alanı metni yok, tebrikler!',
      audit_lorem__pass_name: 'Lorem Ipsum Yok',
      audit_400__descr:
        "4xx HTTP durum kodlu hatalar temel olarak istemci tarafı hatalardır. Bu hatalar, kimlik doğrulaması veya sunum formatı vb.'den kaynaklanabilir. Yani sorun, istemci tarafındadır. Hatanın tam açıklaması için konsolu kontrol edin. Buna, durum kodu vb. (400, 401 vs.) de dahildir. Her kod, farklı bir istemci tarafı soruna işaret eder. Sorunu anlayın ve soruna uygun çözümler uygulayın.",
      audit_400__fail_msg: 'Ah! Hala 4xx hatası var',
      audit_400__fail_name: 'Ana Sayfanızda 4xx Hataları Bulundu',
      audit_400__pass_msg: 'Ana sayfanızda 4xx hatası yok, tebrikler!',
      audit_400__pass_name: 'Ana Sayfanızda 4xx Hatası Yok',
      audit_500__descr:
        '5xx sunucu hatası, sunucu bir isteği yerine getirmediğinde meydana gelen bir hatadır. Bu hatanın 10 çeşidi mevcuttur. Bu hataları tespit etmek ve gidermek oldukça zordur ve arama motorları tarafından sevilmezler; özellikle 500 ve 503 kodlu hatalar hiç sevilmez.',
      audit_500__fail_msg: 'Ah! Hala 5xx hatası görünüyor',
      audit_500__fail_name: '5xx Hataları Bulundu',
      audit_500__pass_msg: '5xx hatası bulunmadı, tebrikler!',
      audit_500__pass_name: '5xx Hatası Bulunmadı',
      audit_seo_url__descr:
        "SEO dostu URL'ler, kullanıcıların ve arama yapacakların ihtiyaçlarını karşılamak için tasarlanan URL'lerdir. Özellikle SEO için optimize edilen URL'ler kısa olma ve anahtar kelimeler açısından zengin olma eğilimi gösterirler. URL'nizin kısa olduğundan ve ilgili bir anahtar kelime içerdiğinden emin olun.",
      audit_seo_url__fail_msg:
        "Hala $$issuecount sayfadaki URL SEO dostu değil. URL'nizin kısa olduğundan ve ilgili bir anahtar kelime içerdiğinden emin olun",
      audit_seo_url__fail_name: 'Bu, SEO Dostu Bir URL Değil',
      audit_seo_url__pass_msg: 'Tebrikler! Bu URL harika görünüyor!',
      audit_seo_url__pass_name: 'Bu, SEO Dostu Bir URL',
      audit_ssl__descr:
        'Sitenizde bir SSL sertifikasına sahip olmanın değerli olduğuna emin olabilirsiniz. Ziyaretçilere, sitenizin doğrulandığını ve hackerlara karşı güvende olduğunu gösterir. Aynı zamanda SEO sıralamanızı da geliştirir. SSL sertifikası alın.',
      audit_ssl__fail_msg:
        'Ah! Hala $$issuecount sayfanın güvenli olmadığı görülüyor',
      audit_ssl__fail_name: 'SSL Sertifikasına İhtiyacınız Var',
      audit_ssl__pass_msg:
        'İyi görünüyor! SSL sertifikasına sahipsiniz ve kullanıcılarınız sitenizin güvenli olduğunu bilecekler',
      audit_ssl__pass_name: 'Kurulu Bir SSL Sertifikasına Sahipsiniz',
      audit_ssl_valid__descr:
        'Sitenizde bir SSL sertifikasına sahip olmanın değerli olduğuna emin olabilirsiniz. Ziyaretçilere, sitenizin doğrulandığını ve hackerlara karşı güvende olduğunu gösterir. Aynı zamanda SEO sıralamanızı da geliştirir.',
      audit_ssl_valid__fail_msg:
        'Ah! $$issuecount sayfanın güvenli olmadığı görülüyor',
      audit_ssl_valid__fail_name: 'SSL Sertifikası Geçersiz',
      audit_ssl_valid__pass_msg:
        'İyi görünüyor! Kullanıcılarınız sitenizin güvenli olduğunu bilecekler',
      audit_ssl_valid__pass_name: 'SSL Sertifikası Geçerli',
      audit_test_server__descr:
        'Bir kullanıcı, internet sitenizin barındırıldığı sunucudaki internet sitelerinden bir URL talep ettiğinde, sunucudan başlık sunucu durum kodu gelir. İdeal koşullarda, sunucu "200 OK" durum kodu göndererek tarayıcıyı, sayfada her şeyin yolunda olduğu ve talep edilen konumda sayfanın bulunduğu konusunda bilgilendirecektir. Giderilmesi gereken sunucu hatalarınız var.',
      audit_test_server__fail_msg: 'Ah! Sitenizde hala sunucu hatası görünüyor',
      audit_test_server__fail_name: 'Sunucu Hataları Bulundu',
      audit_test_server__pass_msg: 'Sunucu hatası giderildi, tebrikler!',
      audit_test_server__pass_name: 'Sunucu Hatası Bulunmadı',
      audit_load_high__descr:
        'Yavaş bir internet sitesi, sıralamanıza ve kullanıcılarınız deneyimine zarar verir. Google, site hızının (dolayısıyla sayfa hızının) sayfa sıralama algoritmaları tarafından kullanılan sinyallerden biri olduğunu belirtmiştir. Ek olarak sayfa hızının yavaş olması, arama motorlarının kendilerine ayrılan dolaşma süresinde daha az sayfayı dolaşabilmeleri anlamına gelir ve bu da sayfanızın dizinlenmesini olumsuz etkileyebilir. Sayfa hızı, kullanıcı deneyimi için de önemlidir. Yüklenme süresi uzun sayfaların ayrılma oranları daha yüksek olma eğilimi gösterir. Kullanıcılar sayfada daha kısa süre harcar ve dönüştürmeler olumsuz etkilenir. İnternet sitenizi hızlandırmaya çalışın.',
      audit_load_high__fail_msg:
        'Ah! İnternet siteniz hala çok yavaş yükleniyor',
      audit_load_high__fail_name: 'İnternet Sitenizin Hızı Çok İyi Değil',
      audit_load_high__pass_msg:
        'Harika bir iş! İnternet siteniz çok hızlı yükleniyor!',
      audit_load_high__pass_name: 'İnternet Sitenizin Hızı Çok İyi',
      audit_dup_title__descr: `Bazen, aynı sayfanın internet sitenizde iki farklı yerde olmasını istersiniz. İşletme müşterilerinize ve özel müşterilerinize sunduğunuz bir ürün hayal edin. Bu durumda ilgili sayfayı, internet sitenizde iki farklı konuma/URL'ye koymak isteyebilirsiniz. Örneğin: www.example.com/business/myproduct.html (Sayfa Başlığı: "Ürünüm")\\nwww.example.com/private/myproduct.html (Sayfa Başlığı: "Ürünüm") İki sayfa da var olmayı hak eder ancak Google hangisinin daha güçlü olduğunu (orijinal olduğunu) bilemez ve bağlantı gücünü (sıralama gücünü) iki sayfa arasında iyi kötü ayırır. Bu durumda rel="canonical" etiketini kullanmalısınız. rel="canonical" etiketi, tekrarlanan sayfaya koyulmalı ve orijinal sayfayı göstermelidir. Yukarıdaki örneklerdeki orijinalin business klasöründe bulunması gerekiyorsa rel="canonical" etiketinin özel klasöre (tekrarlanan sayfaya) yerleştirilmesi gerekir ve şöyle görünmelidir: <link rel="canonical" href="www.example.com/business/myproduct.html" />`,
      audit_dup_title__fail_msg:
        'Ah! Hala $$issuecount sayfada tekrarlanan başlık etiketi var',
      audit_dup_title__fail_name: 'Tekrarlanan Başlık Etiketleriniz Var',
      audit_dup_title__pass_msg:
        'Harika görünüyor. İyi bir sıralama için başlık iki sayfadan da yeterince farklı görünüyor',
      audit_dup_title__pass_name: 'Tekrarlanan Başlık Etiketiniz Yok',
      audit_empty_title__descr:
        'Başlık etiketi gerek kullanıcıların gerekse arama motorlarının sayfanızda ne tür bir içerik bulmayı bekleyebileceklerini hemen anlamalarına yardımcı olması açısından önemlidir. Başlık etiketi, sayfa için SEO etkenleri arasında en önemlilerden biridir ve eksik olması halinde arama motorları sıralamalarını ve arama sonuçlarından gelen tıklama oranlarını etkileyebilir. SEO performansını artırmak için eklemeyi deneyin.',
      audit_empty_title__fail_msg:
        'Ah, hala $$issuecount sayfada etiket eksik görünüyor. SEO performansını artırmak için eklemeyi deneyin',
      audit_empty_title__fail_name:
        'İnternet Sitelerinizin Başlık Etiketleri Boş',
      audit_empty_title__pass_msg: 'Tebrikler, etiketler iyi görünüyor!',
      audit_empty_title__pass_name:
        'İnternet Sitelerinizin Başlık Etiketleri Boş Değil',
      audit_wrong_title__descr:
        'Başlıklarınızı daha konuyla ilgili hale getirin ve spam yapmaktan kaçının. Yalnızca anahtar kelimeleri sıralayıp bırakmayın ve başlığı yazarken öncelikle kullanıcıyı düşündüğünüzden emin olun. Açık, kısa olduklarından ve içeriğin değerini aktardıklarından emin olun.',
      audit_wrong_title__fail_msg:
        'Hala $$issuecount sayfada değişiklik yapmanız gerekiyor. Açık, kısa olduklarından ve içeriğin değerini aktardıklarından emin olun',
      audit_wrong_title__fail_name:
        'Ana Sayfanızda Uygun Olmayan Başlıklar Bulundu',
      audit_wrong_title__issue: 'Uygun olmayan başlıklar bulundu',
      audit_wrong_title__pass_msg: 'Tebrikler! Başlıklar harika',
      audit_wrong_title__pass_name: 'Ana Sayfanızda Uygun Olmayan Başlık Yok',
      audit_long_title__descr:
        "Google genel olarak başlık etiketindeki ilk 50-60 karakteri gösterir. Araştırmalarımız, başlıklarınızı 60 karakterden kısa tutarsanız başlıklarınızın yaklaşık %90'ının doğru görüntüleneceğini göstermektedir. İnternet sitesi başlıklarınız çok uzun. Daha kısa bir metin deneyin.",
      audit_long_title__fail_msg:
        'Üzgünüz, internet sitesi başlıklarınız hala çok uzun. Daha kısa bir metin deneyin',
      audit_long_title__fail_name: 'İnternet Sitenizde Çok Uzun Başlıklar Var',
      audit_long_title__issue: 'Uzun başlıklar bulundu',
      audit_long_title__pass_msg:
        'Güzel! İnternet sitenizdeki başlıkların uzunluğu harika.',
      audit_long_title__pass_name: 'İnternet Sitenizde Çok Uzun Başlık Yok',
      audit_short_title__descr:
        "Google genel olarak başlık etiketindeki ilk 50-60 karakteri gösterir. Araştırmalarımız, başlıklarınızı 60 karakterden kısa tutarsanız başlıklarınızın yaklaşık %90'ının doğru görüntüleneceğini göstermektedir. İnternet sitesi başlıklarınız çok kısa.",
      audit_short_title__fail_msg:
        'Üzgünüz, internet sitesi başlıklarınız hala çok kısa.',
      audit_short_title__fail_name: 'İnternet Sitenizde Çok Kısa Başlıklar Var',
      audit_short_title__issue: 'Kısa başlıklar bulundu',
      audit_short_title__pass_msg:
        'Güzel! İnternet sitenizdeki başlıkların uzunluğu harika.',
      audit_short_title__pass_name: 'İnternet Sitenizde Çok Kısa Başlık Yok',
      audit_label__easy: 'Kolay',
      audit_label__medium: 'Orta',
      audit_label__difficult: 'Zor',
      audit_title: 'Sitenizi Denetleyin',
      audit_continuous:
        'İnternet sitenizi sürekli olarak denetler ve düzeltilmesi gereken sorunlar ve bunun nasıl yapılabileceği hakkında sürekli olarak bilgi veririz.',
      audit_seo__short: 'SEO',
      audit_seo__description:
        'SEO optimizasyonu, sıralama potansiyelini maksimize edebilmeniz ve arama motorlarından internet sitenize trafik alabilmeniz için önemlidir. Daha gelişmiş SEO stratejilerine geçmeden önce sayfanızın burada ana hatlarıyla verilen ve önerilen yaygın iyileştirmeleri sağladığından emin olmalısınız.',
      audit_performance: 'Performans',
      audit_performance__descr:
        'İyi bir kullanıcı deneyimi ve ayrılma oranını azaltmak (bu arama motoru sıralamanızı da dolaylı olarak etkileyebilir) için performans önemlidir. Düzenli aralıklarla dalgalanma olmadığından emin olmak için zaman içindeki performansınızı izlemeye devam edin.',
      audit_security: 'Güvenlik',
      audit_security__descr:
        'İnternet sitenizin kullanıcı verilerini korumasını ve açık vermemesini sağlamak, arıza süreleri veya veri kaybı yaşamamak için güvenlik önemlidir. Sürekli yamalama ve sürekli koruyucu izleme araçları kullanımı tavsiye ederiz.',
      audit_mobile: 'Mobil',
      audit_mobile__descr:
        'İşletmelerin, mobil araçlarda kullanıma uygun bir internet sitesine sahip olmalarının önemi giderek artmaktadır. Bu, yalnızca müşterilerinizin hareket halindeyken size daha kolay ulaşmalarına yardımcı olmakla kalmaz aynı zamanda da arama motoru optimizasyonunu etkiler.',
      audit_fix_issue: 'Sorunu Giderin',
      savings_table__keyword: 'Anahtar Kelime',
      savings_table__keyword_explained:
        "Anahtar kelimenin etki alanında sıralandığı bir URL'miz olursa anahtar kelime bağlantıya dönüşür",
      savings_table__position: 'Pozisyon',
      savings_table__cpc: 'CPC',
      savings_table__cpc_explained: 'Tıklama Başına Maliyet',
      savings_table__monthly_search: 'Aylık Arama',
      savings_table__savings: 'Tasarruf',
      competitors_table__modal_title:
        'Rakip karşılaştırması için kullanılan anahtar kelimeler',
      competitors_table__table_header: 'Anahtar kelimeler',
      screenshot__not_available: 'Ekran görüntüsü bekleniyor',
      english: 'İngilizce',
      dutch: 'Felemenkçe',
      french: 'Fransızca',
      german: 'Almanca',
      espanol: 'İspanyolca',
      portuguese: 'Portekizce',
      italian: 'İtalyanca',
      turkish: 'Türkçe',
      wp_auto__header_1: 'Haberler iyi! Görünen o ki',
      wp_auto__header_2: 'WordPress kullanıyorsunuz',
      wp_auto__header_3:
        'Bu, pek çok sorunu sizin için otomatik olarak çözebileceğimiz anlamına geliyor.',
      wp_auto__header_4: 'Ancak bunu yapabilmemiz için sizi kullanıcı adınızı',
      wp_auto__header_5: 've',
      wp_auto__header_6: 'parolanızı',
      wp_auto__header_7: 'bilmemiz',
      wp_auto__header_8: "ve yönetici bölümü URL'nizin doğru olması gerekir.",
      wp_auto__form_1: 'URL',
      wp_auto__form_2:
        'Sitenizin yönetici bölümünün, WordPress tarafından varsayılan olarak belirlendiğini varsayarız. Durum bu değilse lütfen düzenleyin.',
      wp_auto__form_3: 'Kullanıcı adı',
      wp_auto__form_4: 'Parola',
      wp_auto__form_installed: 'kuruldu',
      wp_auto__form_success_header:
        'Eklentiler başarıyla kuruldu ve ayarlandı!',
      wp_auto__form_success_msg:
        'Sonraki denetimde iyileşmeleri görmeye başlamalısınız.',
      wp_auto__form_success_breakdown:
        'Sitenize kurulanların ve yapılandırılanların dökümü şöyledir:',
      wp_auto__request_error:
        'Sunucuya ulaşılamıyor lütfen daha sonra tekrar deneyin.',
      wp_auto__page_not_found: 'Sağlanan URL bulunamadı',
      wp_auto__login_not_found:
        "Oturum açma formu bulunamadı lütfen URL'yi kontrol edin",
      wp_auto__login_error: 'Geçersiz bir kullanıcı adı veya parola girdiniz',
      wp_auto__file_upload_error:
        'Eklentiler yüklenemedi. Bu işlemin yapılabilmesi için kullanıcının yönetici olması gerekir',
      wp_auto__loading_msg_1:
        'Oturum açma formu aranıyor ve kimlik bilgileri girilmeye çalışılıyor...',
      wp_auto__loading_msg_2: 'Eklentiler, WordPress sayfanıza yükleniyor...',
      wp_auto__loading_msg_3:
        'Eklentiler kuruluyor ve yapılandırılıyor. Bu işlem birkaç dakika sürebilir...',
      uptime__header_title: 'Hizmet Süresi Bilgisi',
      uptime__header_status: 'Geçerli Durum',
      uptime__table_date: 'Tarih',
      uptime__table_time: 'Saat (UTC)',
      uptime__table_status: 'Durum',
      uptime__table_no_data: 'Veri yok',
      all: 'tümü',
      online: 'çevrim içi',
      offline: 'çevrim dışı',
      request_help__cta: 'Yardım iste',
      request_help__success_toast: 'E-posta başarıyla gönderildi!',
      request_help__header_li: 'Sorun adı',
      request_help__header_li_2: 'Sorun Açıklaması',
      request_help__header_li_3: 'Sorunlu sayfalar',
      request_help__description_p:
        'Bu sorunun bir kopyasını internet sayfası geliştiricinize gönderin',
      request_help__error_bad_request:
        'Gönderilen veri hatalıydı. Lütfen destek ile iletişime geçin',
      request_help__error_unauthorized:
        'İzinler geçersiz. Lütfen yöneticinizle iletişime geçin',
      request_help__form_subject: 'Konu',
      request_help__form_subject_placeholder: 'e-postanın konusu',
      request_help__form_to: 'Alıcı',
      request_help__form_to_placeholder:
        'internet sitesi geliştiricinin e-posta adresi',
      request_help__form_cc: 'Cc',
      request_help__form_cc_placeholder: 'kendi e-posta adresiniz',
      request_help__form_name: 'Ad/Şirket',
      request_help__form_name_placeholder: 'sizin veya şirketinizin adı',
      request_help__form_comments: 'Yorumlar',
      request_help__form_comments_placeholder: 'isteğe bağlı yorumlar',
      request_help__form_required: 'gerekli',
      request_help__form_send: 'Gönder',
    },
  },
};
export default resources;
