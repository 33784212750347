import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import './ranking-chart.scss';

import theme from '../../assets/css/theme.module.scss';

const diff = (a, b) => Math.abs(a - b);

const getScoreDiffMessage = (lastScore, prevScore, t) => {
  if (lastScore !== null && prevScore !== null) {
    if (lastScore < prevScore) {
      return `${t('ranking_graph__up')} ${diff(lastScore, prevScore)} ${t(
        'ranking_graph__positions_last_week'
      )}`;
    }

    if (lastScore > prevScore) {
      return `${t('ranking_graph__down')} ${diff(lastScore, prevScore)} ${t(
        'ranking_graph__positions_last_week'
      )}`;
    }

    if (lastScore === prevScore) {
      return t('ranking_graph__rank_no_movement');
    }
  }

  return t('ranking_graph__rank_unknown');
};

const colorList = [theme.soCoGraph2, theme.soCoGraph4, theme.soCoGraph1];

const ScoreItem = ({ className, keyword, scores, loadGraph, index, t }) => {
  if (scores.last) {
    return (
      <li className="ranking-chart__score-item">
        <div className={classNames('ranking-chart__last-score', className)}>
          <span>{scores.last}</span>
        </div>
        <div className="ranking-chart__keyword-info">
          <p className="ranking-chart__keyword">{keyword}</p>
          <p className="ranking-chart__keyword-diff">
            {getScoreDiffMessage(scores.last, scores.prev, t)}
          </p>
        </div>
        <div className="ranking-chart__graph-cta">
          <button onClick={() => loadGraph(keyword, scores, colorList[index])}>
            {t('ranking_graph__open_graph')}
          </button>
        </div>
      </li>
    );
  }

  return (
    <li className="ranking-chart__score-item ranking-chart__score-item--unknown">
      <p>
        {t('ranking_graph__calculation_error')} <strong>{keyword}</strong>
      </p>
      <p>{t('ranking_graph__try_again_one_week')}</p>
    </li>
  );
};

const findColour = (index) => {
  switch (index) {
    case 0:
      return 'ranking-chart__last-score--blue';
    case 1:
      return 'ranking-chart__last-score--turquoise';
    default:
      return 'ranking-chart__last-score--purple';
  }
};

const RankingChart = ({ keywordScores: { scoresData, lastUpdate }, t }) => {
  const [showGraph, setShowGraph] = useState(false);
  const [graphData, setGraphData] = useState({
    name: undefined,
    data: [],
    color: '',
  });

  const loadKeywordGraph = (keyword, scores, color) => {
    setGraphData({ name: keyword, data: scores.all[0].data, color });
    setShowGraph(true);
  };

  const goBackToList = () => {
    setGraphData({ name: undefined, data: [], color: '' });
    setShowGraph(false);
  };

  if (showGraph) {
    return (
      <div className="ranking-chart__graph">
        <Chart
          options={{
            chart: {
              height: 400,
              type: 'line',
              zoom: {
                enabled: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth',
              width: 2,
            },
            title: {
              enabled: false,
            },
            grid: {
              row: {
                colors: ['transparent'],
              },
            },
            xaxis: {
              type: 'datetime',
              labels: {
                style: {
                  fontWeight: 300,
                },
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
            },
            yaxis: {
              reversed: true,
              labels: {
                style: {
                  fontWeight: 300,
                },
              },
            },
            legend: {
              showForSingleSeries: true,
              fontSize: '14px',
              fontWeight: 300,
              offsetY: 10,
              height: 50,
              formatter: function (seriesName) {
                return seriesName && seriesName.length > 30
                  ? `${seriesName.slice(0, 30)}...`
                  : seriesName;
              },
            },
          }}
          series={[graphData]}
        />
        <div className="ranking-chart__graph-cta">
          <button onClick={() => goBackToList()}>
            {t('ranking_graph__go_back')}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="ranking-chart">
      <p className="ranking-chart__last-update">
        {t('ranking_graph__last_update')} {lastUpdate}
      </p>
      <ul className="ranking-chart__scores-list">
        {scoresData.map(({ keyword, scores }, index) => (
          <ScoreItem
            key={keyword}
            index={index}
            keyword={keyword}
            scores={scores}
            className={findColour(index)}
            loadGraph={loadKeywordGraph}
            t={t}
          />
        ))}
      </ul>
    </div>
  );
};

export default withTranslation()(RankingChart);
