import React, { Component } from 'react';
import axios from 'axios';
import i18n from 'i18next';
import { serverUrl } from '../../helpers/utility';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';

import './request-update.scss';

const Description = () => (
  <h4 className="request-update__description">
    Ask your web agency or web developer for help
    {/* {i18n.t('request_help__description_p')} */}
  </h4>
);

const Header = ({ currentIssue, pages }) => (
  <ul className="request-update__header">
    <li>
      <span>{i18n.t('request_help__header_li')}:</span>{' '}
      {currentIssue && currentIssue.failName}
    </li>
    <li>
      <span>{i18n.t('request_help__header_li_2')}:</span>{' '}
      {currentIssue && currentIssue.description}
    </li>
    <li>
      <span>{i18n.t('request_help__header_li_3')}:</span>{' '}
      {pages.length > 0 ? pages.length : 'n/a'}
    </li>
  </ul>
);

const Error = ({ error }) => {
  switch (error.name) {
    case 'Bad Request':
      return (
        <p className="request-update__error-generic">
          {i18n.t('request_help__error_bad_request')}
        </p>
      );
    case 'Unauthorized':
      return (
        <p className="request-update__error-generic">
          {i18n.t('request_help__error_unauthorized')}
        </p>
      );
    case 'RequestError':
      return (
        <p className="request-update__error-generic">
          {i18n.t('wp_auto__request_error')}
        </p>
      );
    default:
      return null;
  }
};

const Form = ({
  handleInputChange,
  handleSubmit,
  name,
  subject,
  to,
  emailClasses,
  cc,
  comments,
  error,
}) => (
  <>
    <div className="formInput">
      <label>
        {i18n.t('request_help__form_subject')}{' '}
        <span className="input-required">*</span>
      </label>
      <input
        name="subject"
        className={emailClasses}
        placeholder={i18n.t('request_help__form_subject_placeholder')}
        type="text"
        autoFocus={true}
        value={subject}
        onChange={handleInputChange}
        maxLength="100"
        required={true}
      />
    </div>
    <div className="formInput">
      <label>
        {i18n.t('request_help__form_to')}{' '}
        <span className="input-required">*</span>
      </label>
      <input
        name="to"
        className={emailClasses}
        placeholder={i18n.t('request_help__form_to_placeholder')}
        type="email"
        value={to}
        onChange={handleInputChange}
        maxLength="100"
        required={true}
      />
    </div>
    <div className="formInput">
      <label>{i18n.t('request_help__form_cc')}</label>
      <input
        name="cc"
        className={emailClasses}
        placeholder={i18n.t('request_help__form_cc_placeholder')}
        type="email"
        value={cc}
        onChange={handleInputChange}
        maxLength="100"
      />
    </div>
    <div className="formInput">
      <label>
        {i18n.t('request_help__form_name')}{' '}
        <span className="input-required">*</span>
      </label>
      <input
        name="name"
        className={emailClasses}
        placeholder={i18n.t('request_help__form_name_placeholder')}
        type="text"
        value={name}
        onChange={handleInputChange}
        maxLength="100"
        required={true}
      />
    </div>
    <div className="formInput">
      <label>{i18n.t('request_help__form_comments')}</label>
      <textarea
        name="comments"
        onChange={handleInputChange}
        placeholder={i18n.t('request_help__form_comments_placeholder')}
        value={comments}
        maxLength="500"
        className="form-control"
      />
    </div>
    <Error error={error} />
    <div className="d-flex justify-content-between">
      <span className="form-asterisk">
        <span className="input-required">*</span>
        {i18n.t('request_help__form_required')}
      </span>
      <Button
        variant="success"
        type="submit"
        title="Send email"
        className="form-send"
        onClick={handleSubmit}
      >
        Send
      </Button>
    </div>
  </>
);

class RequestUpdate extends Component {
  constructor() {
    super();

    this.state = {
      name: '',
      subject: '',
      to: '',
      cc: '',
      comments: '',
      currentIssue: {},
      error: {
        name: '',
        message: '',
      },
      pages: [],
    };
  }

  componentDidMount() {
    this.setState({
      name: this.props.user.name || '',
      cc: this.props.user.email,
      currentIssue: {
        failName: 'WordPress version update',
        description: `${this.props.domain} is currently using an older version of worpdress. It is recommended to update to ensure smooth operation and benefit from the latest security updates and bug fixes.`,
      },
    });
  }

  handleSubmit = (e) => {
    this.setState({
      error: {
        name: '',
        message: '',
      },
    });

    const payload = {
      user: {
        _id: this.props.user._id,
        currentOrganisationId: this.props.user.currentOrganisationId,
        name: this.state.name,
        email: this.state.cc.toLowerCase(),
        permissions: this.props.user.permissions,
      },
      email: {
        subject: this.state.subject,
        domain: this.props.domain,
        recipient: this.state.to,
        issue: {
          name: this.state.currentIssue.failName,
          description: this.state.currentIssue.description,
          pages: this.state.pages,
        },
        comments: this.state.comments,
      },
    };

    axios
      .post(`${serverUrl}/email/issue`, payload)
      .then((response) => {
        if (response && response.data && response.data.success) {
          //  close modal and send success toast
          this.props.hideModal();
          toast.success(i18n.t('request_help__success_toast'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          if (err.response.data.error && err.response.data.message) {
            console.log(err.response.data);
            this.setState({
              error: {
                name: err.response.data.error,
                message: err.response.data.message,
              },
            });
          }
        } else if (err.request) {
          // axios generated error
          this.setState({
            error: {
              name: 'RequestError',
              message: 'wp_auto__request_error',
            },
          });
        }
      });
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { name, subject, to, cc, comments, currentIssue, error } = this.state;

    const emailClasses = classnames({
      'form-control': true,
    });

    return (
      <div className="request-update">
        <Description />
        <Header currentIssue={currentIssue} pages={[]} />
        <Form
          handleInputChange={this.handleInputChange}
          handleSubmit={this.handleSubmit}
          handleToChange={this.handleToChange}
          handleCCchange={this.handleCCchange}
          handleCommentsChange={this.handleCommentsChange}
          name={name}
          subject={subject}
          to={to}
          cc={cc}
          comments={comments}
          emailClasses={emailClasses}
          error={error}
        />
      </div>
    );
  }
}

export default RequestUpdate;
