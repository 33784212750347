import React from 'react';

import './navigation.scss';

const Navigation = ({
  basicSiteList,
  changeLanguage,
  changeSelectedSite,
  language,
  getLangFromLocale,
  organisation,
  siteId,
  t,
  toggleMenu,
  user,
}) => {
  return (
    <div className="row header-inner">
      <div className="col-9 col-sm-6 col-lg-3">
        {organisation &&
        organisation.organisationLogoDark &&
        organisation.organisationLogoDark.Url ? (
          <img
            src={organisation.organisationLogoDark.Url}
            className="organisation-logo"
            alt="Logo"
          />
        ) : null}
      </div>
      <div className="col-3 col-sm-6 d-lg-none text-right">
        <span className="mobile-menu-icon" onClick={toggleMenu}>
          <i className="fal fa-bars" />
        </span>
      </div>
      <div className="d-none d-lg-block col-6 col-lg-9 text-right">
        <div className="header-inner-links">
          <span className={`header-inner-link`}>
            <a href="#/site-summary" target="_self">
              {t('header__dashboard')}
            </a>
          </span>
          <span className={`header-inner-link`}>
            <a href="#/audit" target="_self">
              {t('header__audit')}
            </a>
          </span>
          <span className={`header-inner-link`}>
            <a href="#/alerts" target="_self">
              {t('header__alerts')}
            </a>
          </span>
          {user &&
          user.currentPermissions &&
          (user.currentPermissions.isOrganisationAdmin ||
            user.currentPermissions.isOrganisationOwner) ? (
            <span className={`header-inner-link`}>
              <a href="#/admin" target="_self">
                {t('header__admin')}
              </a>
            </span>
          ) : null}

          {basicSiteList && basicSiteList.length > 1 && siteId ? (
            <span className={'hideFromIframe'}>
              <select
                className="form-control rounded-border custom"
                name="siteId"
                value={siteId}
                onChange={changeSelectedSite}
              >
                {basicSiteList.map((site) => {
                  return (
                    <option key={site._id} value={site._id}>
                      {site.domainFriendly}
                    </option>
                  );
                })}
              </select>
            </span>
          ) : null}

          {user && user.selfSignUp && (
            <span className="hideFromIframe header-inner-link">
              <a href="#/account" target="_self">
                <i className="fal fa-user" />
              </a>
            </span>
          )}

          <span className="m-l--10" id="languageSelectContainer">
            <select
              className="form-control rounded-border custom"
              value={getLangFromLocale(language)}
              onChange={(event) => {
                changeLanguage(event.target.value);
              }}
            >
              <option value="nl">{t('dutch')}</option>
              <option value="en">{t('english')}</option>
              <option value="es">{t('espanol')}</option>

              <option value="fr" disabled>
                {t('french')}
              </option>
              <option value="de" disabled>
                {t('german')}
              </option>
              <option value="it" disabled>
                {t('italian')}
              </option>
              <option value="pt" disabled>
                {t('portuguese')}
              </option>
              <option value="tr">{t('turkish')}</option>
            </select>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
