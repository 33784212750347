import axios from 'axios';
import { find, filter, intersection, forEach, sortBy, last } from 'underscore';
import { setOrganisation, setSites, setSitesLoading } from '../actions';
import {
  addMinutes,
  isAfter,
  parseISO,
  differenceInDays,
  format,
} from 'date-fns';
import { setAttribute } from '../components/analytics-helper/analytics-helper';
import queryString from 'query-string';
import {
  CATEGORY_SEO,
  CATEGORY_PERFORMANCE,
  CATEGORY_SECURITY,
} from './constants';

axios.defaults.withCredentials = true;

export let serverUrl = `${window.location.protocol}//${window.location.host}`;

// DEV: Switch if running on localhost
if (window.location.host.indexOf('localhost') > -1) {
  serverUrl = 'http://localhost';
}

export function getOrdinalSuffix(i) {
  const j = i % 10,
    k = i % 100;

  if (j === 1 && k !== 11) {
    return i + 'st';
  }

  if (j === 2 && k !== 12) {
    return i + 'nd';
  }

  if (j === 3 && k !== 13) {
    return i + 'rd';
  }

  return i + 'th';
}

export function getNumberFormattingAndOrdinalSuffix(i) {
  if (typeof i === 'number') {
    const j = i % 10,
      k = i % 100,
      localeI = Number(i).toLocaleString();

    if (j === 1 && k !== 11) {
      return localeI + 'st';
    }

    if (j === 2 && k !== 12) {
      return localeI + 'nd';
    }

    if (j === 3 && k !== 13) {
      return localeI + 'rd';
    }

    return localeI + 'th';
  } else {
    return i;
  }
}

function addPartnerLogos({ organisationData, attempt = 0 }) {
  const favicon = document.getElementById('applicationFavicon');
  if (favicon && organisationData.organisationFavicon) {
    favicon.href = organisationData.organisationFavicon.Url;
  }

  const sidebarLogo = document.getElementById('sidebarLogo');
  if (sidebarLogo && organisationData.organisationLogo) {
    sidebarLogo.style.backgroundImage = `url(${organisationData.organisationLogo.Url})`;
  } else {
    // Avoid infinite loop, may not be possible as sidebar no visible (mobile or not authenticated yet etc)
    if (attempt < 3) {
      setTimeout(() => {
        console.log('--- Performing delayed logo injection');
        addPartnerLogos({ organisationData, attempt: attempt + 1 });
      }, 1000);
    }
  }
}

export function getListOfSites({ dispatch }) {
  // Get: All sites
  axios.get(`${serverUrl}/site/all/basic`).then((response) => {
    if (response && response.data && response.data.success === true) {
      const data = response.data.data;

      // Filter our sites without basic data including an audit ID (wait until audit has finished)
      const basicSiteList = filter(data, (site) => {
        if (
          site &&
          site.domain &&
          site.audit &&
          site.audit.data &&
          site.audit.data.auditId
        ) {
          return true;
        }
      });

      // List of sites that have a queued audit task
      const loadingSiteList = filter(data, (site) => {
        if (site && site.domain && site.audit && site.audit.taskId) {
          return true;
        }
      });

      dispatch(setSites(basicSiteList));
      dispatch(setSitesLoading(loadingSiteList));
    }
  });
}

export function userHasLoggedInActions({ dispatch, user }) {
  // Helper utility to connect Google state to Redux
  checkAndLoadGoogleAccounts({ dispatch });

  getListOfSites({ dispatch });

  if (user) {
    setAttribute({ userId: user._id });
  }

  // Load any pre-selected sites for the token based user
  const selectedSite = getParameterByName('site');
  const selectedPage = getParameterByName('page');
  const iframe = getParameterByName('iframe');

  // Store for future processing
  if (sessionStorage) {
    if (selectedSite) {
      sessionStorage.setItem('selectedSite', decodeURIComponent(selectedSite));
    }
    if (iframe) {
      sessionStorage.setItem('iframe', decodeURIComponent(iframe));
    }
  }

  if (!sessionStorage) {
    console.error(
      'Session storage is required for some functionality. Session storage was not detected and therefore performance/functionality may be degraded.'
    );
  }

  // Load any route requested by URL parameters (selectedPage)
  if (selectedPage) {
    window.location.href = decodeURIComponent(selectedPage);
  }
}

// Note: 10 minute session storage cache policy is in use
export function getOrganisation({ dispatch, cacheBust = false }) {
  const processOrganisation = ({ fulfil, organisationData }) => {
    dispatch(setOrganisation(organisationData));
    fulfil(true);

    // An built-in theme to use
    const themeOverride = getParameterByName('theme');
    // An actual CSS file to import
    const themeCSSOverride = getParameterByName('css');

    // 1) Override the theme based on param
    if (themeOverride) {
      injectOrganisationTheme(themeOverride);
    } else {
      // 2) Use a specific CSS file if specified by param
      if (themeCSSOverride) {
        injectCustomTheme(
          `https://gorank-themes-v3.s3-eu-west-1.amazonaws.com/${themeCSSOverride}.css`
        );
      } else {
        // 3) Use CSS file if available
        if (organisationData.customCSSURL) {
          let cssURL = organisationData.customCSSURL;

          if (cacheBust) {
            cssURL = `${cssURL}?timestamp=${new Date().getTime()}`;
          }

          injectCustomTheme(cssURL);
        } else {
          // 4) Else use 'old' theme if available
          if (organisationData.theme) {
            injectOrganisationTheme(organisationData.theme);
          } else {
            injectOrganisationTheme('gorank');
          }
        }
      }
    }

    // Check if iFrame mode based on URL parameter or session storage value
    const iframeMode =
      getParameterByName('iframe') === 'true' ||
      (getParameterByName('iframe') !== 'false' &&
        sessionStorage &&
        sessionStorage.getItem('iframe') === 'true');

    if (iframeMode === false) {
      // Dynamically swap the page title (may need to roll out to other pages, e.g. login)
      document.title = `${organisationData.name} Audit | Premium Search Engine Optimisation made affordable for everyone`;

      addPartnerLogos({ organisationData });

      sessionStorage.removeItem('iframe');
    } else {
      if (sessionStorage) {
        sessionStorage.setItem('iframe', 'true');
      }

      if (document.body.classList.contains('iframeMode') === false) {
        document.body.classList.add(`iframeMode`);
      }
    }
  };

  return new Promise(function (fulfil, reject) {
    if (
      cacheBust === false &&
      sessionStorage &&
      sessionStorage.getItem('organisationExpiry') &&
      isAfter(
        parseISO(sessionStorage.getItem('organisationExpiry')),
        new Date()
      ) &&
      sessionStorage.getItem('organisation')
    ) {
      try {
        const organisationData = JSON.parse(
          sessionStorage.getItem('organisation')
        );
        processOrganisation({ fulfil, organisationData });
      } catch (err) {
        console.error(err);
      }
    } else {
      // Get organisation info
      // Note this is called on load which is great, but must be called after login to fetch new data!
      axios
        .get(`${serverUrl}/organisation`)
        .then((response) => {
          if (response && response.data && response.data.success === true) {
            const organisationData = response.data.data;
            processOrganisation({ fulfil, organisationData });

            sessionStorage.setItem(
              'organisation',
              JSON.stringify(organisationData)
            );
            sessionStorage.setItem(
              'organisationExpiry',
              addMinutes(new Date(), 5)
            );
          }
        })
        .catch(function (error) {
          console.log('Organisation fetch failed.');
          injectOrganisationTheme('gorank');
          reject();
        });
    }
  });
}

export function handleCheckboxChange(event) {
  if (event.target && event.target.name) {
    const update = {};
    update[event.target.name] = event.target.checked;
    this.setState(update);
  }
}

export function handleInputChange(event) {
  if (event && event.target && event.target.name) {
    // Switch between top level state property change and a nested value update
    if (typeof event.target.dataset.stateType === 'undefined') {
      const update = {};
      update[event.target.name] = event.target.value;
      this.setState(update);
    } else {
      if (
        typeof event.target.dataset.stateType === 'string' &&
        this.state[event.target.dataset.stateType] !== 'undefined'
      ) {
        // stateType reflects the "parent" property of the value we are trying to update
        // e.g. this.state.PARENT.x
        const update = Object.assign(
          {},
          this.state[event.target.dataset.stateType]
        );
        update[event.target.name] = event.target.value;

        // We want to setState on the updated property, not replace the whole store with a nested value
        const updateWrapper = {};
        updateWrapper[event.target.dataset.stateType] = update;

        this.setState(updateWrapper);
      } else {
        console.error('ERR: Could not update nested state value #28444');
      }
    }
  }
}

export function getLocaleFromUrl() {
  const parsed = queryString.parse(window.location.search);

  const { lang } = parsed;

  if (lang) {
    return lang.replace('/', '');
  }

  return null;
}

export function getLangFromLocale(locale) {
  return locale.substring(0, 2);
}

// source: Google
// date: 25/11/2021
export const GBP_USD_EXCHANGE_RATE = 1.33;
export const EUR_USD_EXCHANGE_RATE = 1.12;
export const USD_USD_EXCHANGE_RATE = 1;
export const AUD_USD_EXCHANGE_RATE = 0.72;
export const CAD_USD_EXCHANGE_RATE = 0.79;

export function getUserCurrency() {
  let symbol = '£',
    currency = 'GBP',
    exchange_rate = GBP_USD_EXCHANGE_RATE;

  if (localStorage && localStorage.getItem('currency')) {
    const userCurrency = localStorage.getItem('currency');

    switch (userCurrency) {
      case 'GBP':
        symbol = '£';
        currency = 'GBP';
        exchange_rate = GBP_USD_EXCHANGE_RATE;
        break;
      case 'EUR':
        symbol = '€';
        currency = 'EUR';
        exchange_rate = EUR_USD_EXCHANGE_RATE;
        break;
      case 'USD':
        symbol = '$';
        currency = 'USD';
        exchange_rate = USD_USD_EXCHANGE_RATE;
        break;
      case 'AUD':
        symbol = '$';
        currency = 'AUD';
        exchange_rate = AUD_USD_EXCHANGE_RATE;
        break;
      case 'CAD':
        symbol = '$';
        currency = 'CAD';
        exchange_rate = CAD_USD_EXCHANGE_RATE;
        break;
      default:
        symbol = '£';
        currency = 'GBP';
        exchange_rate = GBP_USD_EXCHANGE_RATE;
        break;
    }
  }

  return {
    symbol,
    currency,
    exchange_rate,
  };
}

export function getUserLocale() {
  let userLocale,
    userCurrency,
    userCurrencyCode,
    ddMMYYYYLocale = 'DD/MM/YYYY',
    ddMMYYLocale = 'DD/MM/YY';

  // Intentionally localStorage, set by i18n library
  if (localStorage && localStorage.getItem('i18nextLng')) {
    userLocale = localStorage.getItem('i18nextLng');

    switch (userLocale) {
      case 'en-GB':
        userCurrency = '£';
        userCurrencyCode = 'GBP';
        ddMMYYYYLocale = 'DD/MM/YYYY';
        ddMMYYLocale = 'DD/MM/YY';
        break;
      case 'en-US':
        userCurrency = '$';
        userCurrencyCode = 'USD';
        ddMMYYYYLocale = 'MM/DD/YYYY';
        ddMMYYLocale = 'DD/MM/YY';
        break;
      case 'pl':
        userCurrency = 'zł';
        userCurrencyCode = 'PLN';
        break;
      case 'de':
        userCurrency = '€';
        userCurrencyCode = 'EUR';
        break;
      case 'fr':
        userCurrency = '€';
        userCurrencyCode = 'EUR';
        break;
      case 'es':
        userCurrency = '€';
        userCurrencyCode = 'EUR';
        break;
      case 'it':
        userCurrency = '€';
        userCurrencyCode = 'EUR';
        break;
      default:
        userCurrency = '£';
        userCurrencyCode = 'GBP';
        break;
    }
  }

  return {
    userLocale,
    userCurrency,
    userCurrencyCode,
    ddMMYYLocale,
    ddMMYYYYLocale,
  };
}

// Perform check if connected to Google and set state ((Redux)
// If connected, fetch the list of both Google Analytics and Google Webmaster accounts
export function checkAndLoadGoogleAccounts({ dispatch }) {
  if (typeof dispatch === 'undefined') {
    console.error('ERR: Cannot connect Google response to store');
  }
}

export function stripDomainProtocols(domain) {
  if (domain) {
    return domain.replace(/(http)*(s)*:\/\/+(www.)*/, '').replace(/\/$/, '');
  } else {
    return null;
  }
}

export function copyToClipboard(str) {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

export function getParameterByName(name, url) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function checkPermission(user, permissionsRequired) {
  if (user && user.currentPermissions && permissionsRequired) {
    const permissionList = user.currentPermissions;

    // Convert to array if just a string (support both formats automatically)
    if (typeof permissionsRequired === 'string') {
      permissionsRequired = [permissionsRequired];
    }

    if (permissionList) {
      const result = find(permissionsRequired, function (key) {
        return permissionList && permissionList[key] === true;
      });

      if (result) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function getFormattedNumber(numberAsString) {
  try {
    let value = parseInt(numberAsString);
    value = value.toLocaleString(navigator.language, {
      minimumFractionDigits: 0,
    });
    return value;
  } catch (e) {
    console.log('ERR: Could not parse number');
    return numberAsString;
  }
}

export function injectCustomTheme(customTheme) {
  if (document.body.classList.contains('custom') === false) {
    document.body.classList.add(`custom`);
  }

  const link = document.createElement('link');
  link.href = customTheme;
  link.type = 'text/css';
  link.rel = 'stylesheet';
  document.getElementsByTagName('head')[0].appendChild(link);
}

export function injectOrganisationTheme(organisationTheme) {
  // Get theme by checking 1) URL params, 2) Session 3) Organisation default and 4) Based on URL
  const theme =
    getParameterByName('theme') ||
    sessionStorage.getItem('theme') ||
    organisationTheme ||
    'gorank';

  // If we have a theme and has not yet been loaded (based on <body> class)
  if (theme && document.body.classList.contains(theme) === false) {
    document.body.classList.add(theme);
    sessionStorage.setItem('theme', theme);
  }
}

export function showModal(modalFlag) {
  if (typeof modalFlag !== 'undefined') {
    const update = {};
    update[modalFlag] = true;
    this.setState(update);
  }
}

export function hideModal(modalFlag) {
  if (typeof modalFlag !== 'undefined') {
    const update = {};
    update[modalFlag] = false;
    this.setState(update);
  }
}

export const validProtocol = new RegExp(/(http:\/\/)|(https:\/\/)+/i);

export function round(value, precision) {
  if (typeof value !== 'number') {
    return value;
  }
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

// 'Hello, World' => ['Hello', 'World']
export const splitAndTrim = (x) =>
  x && typeof x === 'string' ? x.split(',').map((y) => y.trim()) : x;

export const extractTimestamps = (series) => {
  const extractedTimestamps = [];

  forEach(series, ({ data }) => {
    extractedTimestamps.push(data.map((item) => item[0]));
  });

  return extractedTimestamps;
};

export const getSharedTimestamps = (list) => {
  const sharedTimestamps = intersection(...list);

  return sharedTimestamps;
};

export const normaliseSeriesDates = (series) => {
  // build shared timestamps array
  const sharedTimestamps = getSharedTimestamps(extractTimestamps(series));

  // remove data items if not found in shared timestamps
  const normalisedSeries = series.map((serie) => ({
    ...serie,
    data: serie.data.filter((item) => sharedTimestamps.includes(item[0])),
  }));

  // don't forget to sort data arrays at the end
  return normalisedSeries;
};

const removeDuplicateBy = (array, key) => [
  ...new Map(array.map((item) => [key(item), item])).values(),
];

export const buildKeywordScores = (data, limit = 3) => {
  const keywordScores = [];
  const lastEntriesByDate = [];

  if (data) {
    Object.entries(data).forEach(([key, value]) => {
      if (key && key !== '' && typeof value === 'object' && value.length > 0) {
        // Remove date duplicates from SERP object array data and sort by date
        const entriesByDate = sortBy(
          removeDuplicateBy(value, (item) => item.date),
          'date'
        );
        // Retrieve the most recent keyword SERP data point
        const lastEntryByDate = last(entriesByDate);
        // Retrieve the second most recent keyword SERP data point
        const prevEntryByDate =
          entriesByDate.length >= 2
            ? entriesByDate[entriesByDate.length - 2] &&
              entriesByDate[entriesByDate.length - 2].position
            : null;

        lastEntriesByDate.push(lastEntryByDate);

        keywordScores.push({
          keyword: key,
          scores: {
            last: lastEntryByDate.position,
            prev: prevEntryByDate,
            all: [
              {
                data: removeDuplicateBy(value, (item) => item.date) // remove duplicate date entries
                  .map((rank) => [new Date(rank.date).getTime(), rank.position])
                  .filter((item) => item[1] !== null), // remove entries where rank is null
              },
            ],
          },
        });
      }

      return null;
    });

    // find last update amongst all keywords
    const lastUpdate = last(sortBy(lastEntriesByDate, 'date'));

    return {
      lastUpdate: format(parseISO(lastUpdate.date), 'dd/MM/yy'),
      scoresData: keywordScores.slice(0, limit),
    };
  }

  return null;
};

export const getTopIssues = (auditSummary, metricInfo) => {
  // Priority list:
  // Security -> Performance -> SEO
  let SEO_list = [];
  let PERF_list = [];
  let SEC_list = [];

  for (const [key, value] of Object.entries(auditSummary)) {
    if (
      metricInfo[key] &&
      metricInfo[key].passName &&
      metricInfo[key].description &&
      // Check that issueValue is not null, which can occur when an audit crawl fails.
      value &&
      !!value.passed === false
    ) {
      if (metricInfo[key].category.includes(CATEGORY_SEO)) {
        SEO_list.push({
          ...metricInfo[key],
          key,
          show: false,
          showPages: false,
          passed: !!value.passed,
        });
      }
      if (metricInfo[key].category.includes(CATEGORY_PERFORMANCE)) {
        PERF_list.push({
          ...metricInfo[key],
          key,
          show: false,
          showPages: false,
          passed: !!value.passed,
        });
      }
      if (metricInfo[key].category.includes(CATEGORY_SECURITY)) {
        SEC_list.push({
          ...metricInfo[key],
          key,
          show: false,
          showPages: false,
          passed: !!value.passed,
        });
      }
    }
  }

  return [
    ...SEC_list.slice(0, 2),
    ...PERF_list.slice(0, 2),
    ...SEO_list.slice(0, 2),
  ];
};

// remove url trailing slashes from a string or string array
export const removeUrlTrailingSlash = (urls) => {
  if (typeof urls === 'string') {
    return urls[urls.length - 1] === '/'
      ? urls.substr(0, urls.length - 1)
      : urls;
  } else if (typeof urls === 'object' && !!urls.length) {
    return urls.map((url) =>
      url[url.length - 1] === '/' ? url.substr(0, url.length - 1) : url
    );
  } else {
    return urls;
  }
};
