import React from 'react';
import i18n from 'i18next';

const PluginsBreakdown = ({ data }) => (
  <div className="plugins-breakdown">
    {data.map(({ name, installed, actions }) => (
      <ul className="plugin" key={name}>
        <li className="plugin__name">{name}</li>
        <li
          className={`plugin__status ${
            installed ? 'plugin__status--active' : ''
          }`}
        >
          {i18n.t('wp_auto__form_installed')}{' '}
          {installed ? (
            <i className="fal fa-check" />
          ) : (
            <i className="fal fa-times" />
          )}
        </li>
        {actions &&
          actions.map(({ name, enabled }) => (
            <li
              key={name}
              className={`plugin__action ${
                enabled ? 'plugin__action--active' : ''
              }`}
            >
              {name}{' '}
              {enabled ? (
                <i className="fal fa-check" />
              ) : (
                <i className="fal fa-times" />
              )}
            </li>
          ))}
      </ul>
    ))}
  </div>
);

const SuccessMessage = ({ data }) => (
  <div className="wp-automation--success">
    <section className="success__message">
      <h3>{i18n.t('wp_auto__form_success_header')}</h3>
      <p>{i18n.t('wp_auto__form_success_msg')}</p>
    </section>
    <p className="success__breakdown">
      {i18n.t('wp_auto__form_success_breakdown')}
    </p>
    <PluginsBreakdown data={data} />
  </div>
);

export default SuccessMessage;
