import React from 'react';
import { getParameterByName } from '../../helpers/utility';
import './login.scss';
import { withTranslation } from 'react-i18next';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { username: '', password: '' };

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  handleUsernameChange(event) {
    this.setState({ username: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  attemptAutoLogin() {
    if (this.props.loggedIn) {
      console.log('already logged in');
      // redirect user to homepage
      window.location.href = '#/';
    } else {
      // Auto submit if username/password
      if (
        this.state.username &&
        this.state.username.length > 3 &&
        this.state.password &&
        this.state.password.length > 3
      ) {
        console.log(`--- Attempting auto login`);

        if (this.props.organisation) {
          if (this.form) {
            this.form.dispatchEvent(new Event('submit'));
          } else {
            console.log(
              `WARN: Cannot auto login, form either has not been rendered or does not exist`
            );
          }
        } else {
          console.log(`WARN: Cannot auto login, no organisation ID found`);
        }
      }
    }
  }

  componentDidMount() {
    const username = getParameterByName('username');
    const password = getParameterByName('password');

    if (username || password) {
      this.setState({ username, password }, () => {
        this.attemptAutoLogin();
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      typeof prevProps.organisation === 'undefined' &&
      this.props.organisation
    ) {
      setTimeout(() => {
        this.attemptAutoLogin();
      }, 1000);
    }
  }

  render() {
    return this.props.loggedIn !== true ? (
      <div className="container-fluid" id="login">
        <div className="d-flex flex-wrap login-container">
          <div className="col-12 text-center col-lg-6 offset-lg-3">
            {this.props.organisation &&
            this.props.organisation.organisationLogoDark &&
            this.props.organisation.organisationLogoDark.Url ? (
              <div className="login-logo-container">
                <img
                  src={this.props.organisation.organisationLogoDark.Url}
                  alt="Logo"
                  className="login-logo"
                />
              </div>
            ) : null}

            <form
              className="small-form-container"
              ref={(form) => (this.form = form)}
              onSubmit={(event) => {
                const organisationId = this.props.organisation
                  ? this.props.organisation._id
                  : null;

                if (organisationId === null) {
                  console.error('ERR: No organisation ID to login with');
                }

                this.props.attemptLogin(
                  event,
                  this.state.username,
                  this.state.password,
                  organisationId
                );
              }}
            >
              <div className="m-b--10">
                <h1 className="m-b--30">{this.props.t('login__sign_in')}</h1>

                <div className="formInput">
                  <div className="text-left">
                    {this.props.t('login__email')}
                  </div>
                  <input
                    autoFocus={true}
                    type="text"
                    className="form-control"
                    value={this.state.username}
                    id="username"
                    onChange={this.handleUsernameChange}
                  />
                </div>

                <div className="formInput">
                  <div className="row">
                    <div className="col-12">
                      <span className="float-left">
                        {this.props.t('account__password')}
                      </span>

                      <span className="float-right">
                        <a
                          href={`#/forgot-password?username=${encodeURIComponent(
                            this.state.username
                          )}`}
                          className="link"
                        >
                          {this.props.t('login__forgot_pwd')}
                        </a>
                      </span>
                    </div>
                  </div>

                  <input
                    type="password"
                    className="form-control"
                    value={this.state.password}
                    id="password"
                    onChange={this.handlePasswordChange}
                  />
                </div>
              </div>

              <div className="row">
                <span className="col-12">
                  <input
                    type="submit"
                    value={this.props.t('login__sign_in')}
                    className="btn wide btn-success m-b--10"
                    id="signIn"
                  />
                </span>
              </div>

              {this.props.loginFailure ? (
                <div className="m-t--15 error">
                  {this.props.t('login__error')}
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default withTranslation()(Login);
