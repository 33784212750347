import React, { useEffect, useState } from 'react';
import LoadingCircle from '../loading-circle/loading-circle';

const WPloader = ({ show, messages }) => {
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    let timeout;
    if (messageIndex < messages.length - 1 && show === true) {
      timeout = setTimeout(() => setMessageIndex(messageIndex + 1), 10000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [messages, messageIndex]);

  useEffect(() => setMessageIndex(0), [show]);

  return (
    show && (
      <div className="wp-automation__loading-box">
        <p>{messages[messageIndex]}</p>
        <LoadingCircle />
      </div>
    )
  );
};

export default WPloader;
