import React from 'react';
import connect from 'react-redux/es/connect/connect';
import i18n from 'i18next';
import './admin-import.scss';
import { handleInputChange, serverUrl } from '../../helpers/utility';
import axios from 'axios';
import ImportWizard from '../../components/import-wizard/import-wizard';
import LoadingCircle from '../../components/loading-circle/loading-circle';

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    userType: state.authentication.userType,
    organisation: state.organisation.organisation,
    organisationTeam: state.organisation.team,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

class AdminImport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleInputChange = handleInputChange.bind(this);
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="main-content-container" id="admin-import">
          <div className="row">
            <div className="col-12 m-t--30">
              <div className="m-b--10">
                <a href={`/#admin`}>
                  <span className={`m-r--5`}>
                    <i className={`fal fa-chevron-left`} />
                  </span>
                  <span>{i18n.t('admin_import__back')}</span>
                </a>
              </div>

              {this.state.importInProgress !== true ? (
                <ImportWizard
                  show={this.state.showSiteImportModal}
                  hideModal={() => {
                    this.setState({ showSiteImportModal: false });
                  }}
                  importAction={({ sites, columns }) => {
                    this.setState({
                      importInProgress: true,
                    });

                    axios
                      .post(`${serverUrl}/site/import/bulk`, { sites, columns })
                      .then((response) => {
                        if (
                          response &&
                          response.data &&
                          response.data.success
                        ) {
                          console.log('Site import completed.');
                          window.location.href = '#/admin-clients';
                        } else {
                          console.error(`ERR: Import failed`);

                          this.setState({
                            importInProgress: false,
                            importError: true,
                          });
                        }
                      })
                      .catch((e) => {
                        // handle error
                        console.error(`Error with bulk import. ${e}`);

                        this.setState({
                          importInProgress: false,
                          importError: true,
                        });
                      });
                  }}
                  downloadLink={'/downloads/template.xls'}
                  previewNotes={'N/A'}
                  previewEndpoint={`${serverUrl}/importCSV/preview`}
                  title={i18n.t('admin_import__sites')}
                />
              ) : (
                <div className="large-loading-box">
                  <LoadingCircle />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminImport);
