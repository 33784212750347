import { connect } from 'react-redux';
import { serverUrl } from '../../helpers/utility';
import axios from 'axios';
import UserAccount from './user-account';
import { loginSuccess } from '../../actions';

// dispatch logout with logout button

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  organisation: state.organisation.organisation,
});

const mapDispatchToProps = (dispatch) => ({
  updateAccount: (event, data, cb) => {
    if (event) {
      event.preventDefault();
    }

    axios
      .put(`${serverUrl}/user`, data)
      .then((response) => {
        if (response && response.data && response.data.success) {
          console.log('User updated');

          if (cb) {
            cb(true);
          }
        }
      })
      .catch((error) => {
        // use cb if present to notify of error
        console.log('Error updating user data');
      });
  },
  reloadUserData: () => {
    // refetch user data, as it is only fetched when login in
    axios
      .get(`${serverUrl}/user`)
      .then((response) => {
        if (response && response.data && response.data.success) {
          dispatch(loginSuccess(response.data.user));
        }
      })
      .catch((error) => {
        console.log('Authentication error');
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAccount);
