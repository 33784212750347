import React, { Component } from 'react';
import classnames from 'classnames';
import { sortBy } from 'underscore';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import UptimeTable from './uptime-table';
import WidgetContainer from '../widgetContainer/widgetContainer';
import { ALL, FORWARD, BACKWARD, ONLINE, OFFLINE, LIMIT } from './constants';

import './uptime-history.scss';

class UptimeHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      historyStartIndex: 0,
      history: [],
      offlineHistory: [],
      onlineHistory: [],
      filter: ALL,
    };

    this.scrollHistory = this.scrollHistory.bind(this);
    this.setFilter = this.setFilter.bind(this);
  }

  componentDidMount() {
    const { uptime: { history = [] } = {} } = this.props;

    this.setState({
      history: sortBy(history, 'created').reverse(),
      offlineHistory: sortBy(history, 'created')
        .reverse()
        .filter(({ websiteOK }) => websiteOK === false),
      onlineHistory: sortBy(history, 'created')
        .reverse()
        .filter(({ websiteOK }) => websiteOK === true),
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.uptime !== prevProps.uptime) {
      const { uptime: { history = [] } = {} } = this.props;

      this.setState({
        history: sortBy(history, 'created').reverse(),
        offlineHistory: sortBy(history, 'created')
          .reverse()
          .filter(({ websiteOK }) => websiteOK === false),
        onlineHistory: sortBy(history, 'created')
          .reverse()
          .filter(({ websiteOK }) => websiteOK === true),
        historyStartIndex: 0,
        filter: ALL,
      });
    }
  }

  scrollHistory({ direction }) {
    if (this.props.uptime && this.props.uptime.history) {
      if (direction === FORWARD) {
        this.setState({
          historyStartIndex: Math.min(
            this.state.historyStartIndex + LIMIT,
            this.props.uptime.history.length - LIMIT
          ),
        });
      }

      if (direction === BACKWARD) {
        this.setState({
          historyStartIndex: Math.max(this.state.historyStartIndex - LIMIT, 0),
        });
      }
    }
  }

  setFilter({ filter }) {
    switch (filter) {
      case ONLINE:
        this.setState({
          filter: ONLINE,
          historyStartIndex: 0,
        });
        break;
      case OFFLINE:
        this.setState({
          filter: OFFLINE,
          historyStartIndex: 0,
        });
        break;
      case ALL:
      default:
        this.setState({
          filter: ALL,
          historyStartIndex: 0,
        });
        break;
    }
  }

  renderTable() {
    const {
      filter,
      history,
      historyStartIndex,
      offlineHistory,
      onlineHistory,
    } = this.state;
    switch (filter) {
      case ONLINE:
        return (
          <UptimeTable
            list={onlineHistory}
            index={historyStartIndex}
            allHistory={history}
            onlineHistory={onlineHistory}
            offlineHistory={offlineHistory}
            setFilter={this.setFilter}
            scrollHistory={this.scrollHistory}
          />
        );
      case OFFLINE:
        return (
          <UptimeTable
            list={offlineHistory}
            index={historyStartIndex}
            allHistory={history}
            onlineHistory={onlineHistory}
            offlineHistory={offlineHistory}
            setFilter={this.setFilter}
            scrollHistory={this.scrollHistory}
          />
        );
      case ALL:
      default:
        return (
          <UptimeTable
            list={history}
            index={historyStartIndex}
            allHistory={history}
            onlineHistory={onlineHistory}
            offlineHistory={offlineHistory}
            setFilter={this.setFilter}
            scrollHistory={this.scrollHistory}
          />
        );
    }
  }

  render() {
    const { uptime } = this.props;

    const CurrentStatusClasses = classnames({
      uptime_status: true,
      'uptime_status--up': uptime && uptime.currentlyDown === false,
      'uptime_status--down': uptime && uptime.currentlyDown === true,
    });

    return (
      <div className="col-12 dashboard-box dashboard-box--uptime">
        <WidgetContainer>
          <div className="dashboard-box-header dashboard-box-header__bg-grey justify-content-between d-flex">
            <div className="dashboard-box-header__wrapper col-6 text-left">
              {i18n.t('uptime__header_title')}
            </div>
            <div className="dashboard-box-header__wrapper col-6 text-right">
              {i18n.t('uptime__header_status')}:{' '}
              <span className={CurrentStatusClasses}>
                {uptime && uptime.currentlyDown
                  ? i18n.t(OFFLINE)
                  : i18n.t(ONLINE)}
              </span>
            </div>
          </div>
          {this.renderTable()}
        </WidgetContainer>
      </div>
    );
  }
}

UptimeHistory.propTypes = {
  uptime: PropTypes.object.isRequired,
};

export default UptimeHistory;
