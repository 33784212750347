import { connect } from 'react-redux';
import axios from 'axios';
import i18n from 'i18next';
import { loginSuccess, loginFailure, logout } from '../../actions/index';
import Login from './login';
import {
  serverUrl,
  getOrganisation,
  userHasLoggedInActions,
  getParameterByName,
} from '../../helpers/utility';

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    organisation: state.organisation.organisation,
    loggedIn: !!state.authentication.user,
    loginFailure: state.authentication.loginFailure === true,
    googleConnected: state.authentication.googleConnected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    attemptLogin: (event, username, password, organisationId) => {
      if (event) {
        event.preventDefault();
      }

      axios
        .get(
          `${serverUrl}/login?username=${encodeURIComponent(
            username
          )}&password=${encodeURIComponent(
            password
          )}&organisationId=${organisationId}`
        )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.data
          ) {
            dispatch(loginSuccess(response.data.data.user));
            const user = response.data.data.user;

            // Post user login helper function (Zendesk, Team info, Google accounts)
            userHasLoggedInActions({ dispatch, user });

            getOrganisation({ dispatch, cacheBust: true });

            // 1) Redirect to URL from parameter
            // 2) Show Setup if a new Account Owner
            // 3) Fallback to loading home page
            if (getParameterByName('redirect')) {
              window.location.href = decodeURIComponent(
                getParameterByName('redirect')
              );
            } else {
              // Check if new account owner and we need to redirect
              if (
                user &&
                user.currentPermissions &&
                user.currentPermissions.isOrganisationOwner &&
                user.loginCount === 0
              ) {
                window.location.href = '#/admin-setup';
              } else {
                // No other redirects to fallback to homepage
                window.location.href = `#/${
                  window.location.hash && window.location.hash.indexOf('?') > -1
                    ? `?${window.location.hash.split('?')[1]}`
                    : ''
                }`;
              }
            }
          } else {
            // handle failure/error
            dispatch(loginFailure({ message: i18n.t('login__failed') }));
          }
        })
        .catch(function (error) {
          console.error('Unable to login, server error.');
        });
    },

    logout: (event) => {
      if (event) {
        event.preventDefault();
      }
      dispatch(logout());
    },
  };
};

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default LoginContainer;
