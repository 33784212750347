import React from "react";
import i18n from "i18next";
import axios from "axios";
import { getParameterByName, serverUrl } from "../../helpers/utility";
import "./forgot.scss";
import { event } from "../../helpers/analytics-helper";
import { connect } from "react-redux";
import Login from "../login/login";

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    organisation: state.organisation.organisation,
  };
};

class Forgot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      reminderSent: false,
    };

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
  }

  componentDidMount() {
    const username = getParameterByName("username");
    if (username) {
      this.setState({ username: username });
    }
  }

  handleUsernameChange(event) {
    this.setState({ username: event.target.value });
  }

  forgottenPassword(username) {
    axios
      .get(
        `${serverUrl}/login/forgotten?organisationId=${
          this.props.organisation ? this.props.organisation._id : ""
        }&username=${encodeURIComponent(username)}`
      )
      .then((response) => {
        if (response && response.data && response.data.success) {
          event({
            category: "ForgotPassword",
            action: "ResetPassword",
          });

          this.setState({
            reminderSent: true,
          });
        } else {
          // handle failure/error
          console.error("Unable to reset password.");
          this.setState({
            forgotError: i18n.t(
              "Sorry, we could not find an account with that email address"
            ),
          });
        }
      })
      .catch(function (error) {
        console.error("Unable to reset password.");
      });
  }

  render() {
    return (
      <div className="container-fluid" id="login">
        <div className="d-flex flex-wrap align-items-center login-container">
          <div className="col-12 text-center col-lg-6 offset-lg-3">
            {this.state.reminderSent !== true ? (
              <form
                className="small-form-container"
                onSubmit={(event) => {
                  if (event) {
                    event.preventDefault();
                  }

                  this.forgottenPassword(this.state.username);
                }}
              >
                <div>
                  <h1 className="h2 m-b--30">{i18n.t("Forgotten Password")}</h1>

                  <div className="formInput">
                    <div className="text-left">{i18n.t("Email Address")}</div>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.username}
                      onChange={this.handleUsernameChange}
                    />
                  </div>
                </div>

                {this.state.forgotError ? (
                  <div className="alert alert-danger">
                    {this.state.forgotError}
                  </div>
                ) : null}

                <input
                  type="submit"
                  value="Reset Password"
                  className="btn btn-success wider"
                />

                <div className="m-t--30">
                  <div className="m-b--5">
                    <a href="#/register">Sign up for a free account</a>
                  </div>
                  <div className="">
                    <a href="#/login">
                      {i18n.t("Already know your password? Login now")}
                    </a>
                  </div>
                </div>
              </form>
            ) : (
              <div className="small-form-container">
                <h1 className="m-b--30">{i18n.t("Password Reset")}</h1>

                <div className="light">
                  <span className="bold">
                    We have emailed a new password to you.
                  </span>
                  <br />
                  <br />
                  Please check your email ('{this.state.username}').
                  <br />
                  <br />
                  Tip: Change your password after logging in via the 'Account'
                  page
                </div>

                <div className="m-t--30">
                  <div>
                    <a href="#/login">
                      <i>{i18n.t("Return to login page?")}</i>
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(Forgot);
