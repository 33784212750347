import ReactGA from "react-ga";
let initiated = false;

export function pageView() {
  ReactGA.pageview(window.location.hash + window.location.search);
}

export function listen() {
  if (initiated === false) {
    console.log("--- Analytics is listening");
    window.addEventListener("hashchange", pageView, false);
  }
}

export function setAttribute(object) {
  ReactGA.set(object);
}

export function event({ category, action }) {
  ReactGA.event({
    category: category,
    action: action,
  });
}
