import React from "react";
import * as PropTypes from "prop-types";
import "./gr-table.scss";

class GRTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    return (
      <div className={`gr-table ${this.props.customClass || ""}`}>
        <div className="flex-row table-row">
          {this.props.columns.map((column, columnIndex) => {
            return (
              <div
                key={`gr-table-header-${columnIndex}`}
                className={`row-header row-item row-item-${
                  column.size || "md"
                } ${column.customClass ? column.customClass : ""}`}
                style={column.percent ? { flexBasis: column.percent } : {}}
              >
                {column.label}
              </div>
            );
          })}
        </div>

        {this.props.data.map((row, rowIndex) => {
          return (
            <div
              className="flex-row table-row"
              key={row._id || rowIndex}
              onClick={() => {
                if (this.props.rows && this.props.rows.clickEvent) {
                  this.props.rows.clickEvent(row);
                }
              }}
            >
              {this.props.columns.map((item, columnIndex) => {
                return (
                  <div
                    className={`row-item row-item-${item.size || "md"} ${
                      item.customClass
                    }`}
                    style={item.percent ? { flexBasis: item.percent } : {}}
                    key={`${row._id || rowIndex}-${columnIndex}`}
                    onClick={() => {
                      if (item && item.clickEvent) {
                        item.clickEvent(item);
                      }
                    }}
                    title={item.getTitle ? item.getTitle(row) : ""}
                  >
                    {item.getValue(row)}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

GRTable.propTypes = {
  data: PropTypes.array.isRequired,
  rows: PropTypes.shape({
    clickEvent: PropTypes.func,
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      customClass: PropTypes.string,
      size: PropTypes.string,
      percent: PropTypes.string,
      clickEvent: PropTypes.func,
      getValue: PropTypes.func.isRequired,
      getTitle: PropTypes.func,
    })
  ).isRequired,
  customClass: PropTypes.string,
};

/*
Example

<GRTable
    data={this.state.organisations}
    rows={{
        clickEvent: () => {
            console.log("Hello");
        },
    }}
    columns={[
        {
            label: "Test",
            customClass: "custom",
            size: "lg",
            expand: false,
            clickEvent: () => {
                console.log("Hello");
            },
            getValue: (item) => {
                return item.name
            },
            getTitle: (item) => {
                return `URL: ${item.SourceURL}`
            },
        }
    ]}
    customClass="gr-table-padded"
/>
 */

export default GRTable;
