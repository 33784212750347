import React from "react";
import * as PropTypes from "prop-types";
import "./gr-card.scss";

class GRCard extends React.Component {
  render() {
    return (
      <div
        className={`${this.props.customOuterClass || "bg-gradient-dark"} card`}
      >
        <div className={`${this.props.customClass || ""} card-body`}>
          <div className="row">
            <div className="col">
              <div
                className="text-uppercase mb-0 text-white card-title"
                title={this.props.title}
              >
                {this.props.label}
              </div>
              <span className="h2 font-weight-bold mb-0 text-white card-title-value">
                {this.props.value}
              </span>
            </div>

            {this.props.iconClass ? (
              <div className="col-auto col-right col">
                <div className="card-icon">
                  <i className={this.props.iconClass}></i>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

GRCard.propTypes = {
  customOuterClass: PropTypes.string,
  customClass: PropTypes.string,
  iconClass: PropTypes.string,
  label: PropTypes.string.isRequired,
  title: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default GRCard;
