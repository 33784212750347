import React from 'react';
import Navigation from '../navigation/navigation';
import NavigationAlt from '../navigationAlt/navigationAlt';
import NavigationMobile from '../navigationMobile/navigationMobile';
import { withTranslation } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import { getParameterByName, serverUrl } from '../../helpers/utility';
import compose from 'ramda/src/compose';
import { setSelectedSiteData, setSelectedSiteAuditData } from '../../actions';
import { findIndex } from 'underscore';
import axios from 'axios';
import { getLangFromLocale } from '../../helpers/utility';
import { removeUrlTrailingSlash } from '../../helpers/utility';

import './header.scss';
import { setAuditFailMsg } from '../../actions/notifications';

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    userType: state.authentication.userType,
    organisation: state.organisation.organisation,
    locale: state.locale.userLocaleModel,
    basicSiteList: state.sites.basicSiteList,
    selectedSiteData: state.sites.selectedSiteData,
    selectedSiteAuditData: state.sites.selectedSiteAuditData,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setSelectedSiteData: (site) => {
      dispatch(setSelectedSiteData(site));
      if (site && site.audit.didLastAuditFail) {
        dispatch(setAuditFailMsg(true));
      } else {
        dispatch(setAuditFailMsg(false));
      }
    },
    setSelectedSiteAuditData: (auditData) => {
      dispatch(setSelectedSiteAuditData(auditData));
    },
  };
}

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      siteId: null,
      menuVisible: false,
    };

    this.changeSelectedSite = this.changeSelectedSite.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    const newVisibility = !this.state.menuVisible;

    this.setState({
      menuVisible: newVisibility,
    });

    if (newVisibility === true) {
      document.getElementById('inner-content').classList.add('fixedPosition');
    } else {
      document
        .getElementById('inner-content')
        .classList.remove('fixedPosition');
    }
  }

  changeLanguage(lng) {
    this.props.i18n.changeLanguage(lng);
  }

  getSelectedSite() {
    return sessionStorage && sessionStorage.getItem('selectedSite')
      ? removeUrlTrailingSlash(sessionStorage.getItem('selectedSite'))
      : '';
  }

  showSpecifiedSiteElseFirstOne() {
    // Check if a site has been pre-selected based on session data (this is used for iFrame integrations to select a site)
    if (this.props.basicSiteList && this.getSelectedSite()) {
      const selectedSite = this.getSelectedSite();

      const selectedSiteIndex = findIndex(this.props.basicSiteList, (site) => {
        if (
          typeof selectedSite === 'string' &&
          site &&
          typeof site.domain === 'string' &&
          (site.domain.toLowerCase() === selectedSite.toLowerCase() ||
            site.domainFriendly === selectedSite.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });

      if (selectedSiteIndex > -1) {
        console.log(`----- Detected pre-selected site`);
        // If last audit failed and there is a lastSuccessfulAudit, set the auditId to lastSuccessfulAudit
        // Else set the auditId to to the last audit (audit.data.auditId)
        if (
          this.props.basicSiteList[selectedSiteIndex].audit.didLastAuditFail &&
          this.props.basicSiteList[selectedSiteIndex].audit
            .lastSuccessfulAuditId
        ) {
          this.auditId =
            this.props.basicSiteList[
              selectedSiteIndex
            ].audit.lastSuccessfulAuditId;
        } else {
          this.auditId =
            this.props.basicSiteList[selectedSiteIndex].audit.data.auditId;
        }

        this.siteId = this.props.basicSiteList[selectedSiteIndex]._id;
        this.loadSiteAndAuditData();
      }
    } else {
      // Default to the first site in the list
      if (
        this.props.basicSiteList &&
        this.props.basicSiteList.length > 0 &&
        typeof this.props.basicSiteList[0] === 'object'
      ) {
        // If last audit failed and there is a lastSuccessfulAudit, set the auditId to lastSuccessfulAudit
        // Else set the auditId to to the last audit (audit.data.auditId)
        if (
          this.props.basicSiteList[0].audit.didLastAuditFail &&
          this.props.basicSiteList[0].audit.lastSuccessfulAuditId
        ) {
          this.auditId =
            this.props.basicSiteList[0].audit.lastSuccessfulAuditId;
        } else {
          this.auditId = this.props.basicSiteList[0].audit.data.auditId;
        }

        this.siteId = this.props.basicSiteList[0]._id;
        this.loadSiteAndAuditData();
      }
    }
  }

  getAuditData() {
    if (this.auditId) {
      // Get an existing audit
      axios
        .get(`${serverUrl}/audit/id/${this.auditId}?backlinks=true&pages=true`)
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success === true &&
            response.data.data
          ) {
            const auditData = response.data.data;
            this.props.setSelectedSiteAuditData(auditData);
          } else {
            this.setState({
              auditFetchError: this.props.t('header__error'),
              loadingAudit: false,
            });
          }
        })
        .catch((err) => {
          console.error(`ERR: Could not fetch audit data: ${err}`);

          this.setState({
            auditFetchError: this.props.t('header__error'),
            loadingAudit: false,
          });
        });
    }
  }

  getSite() {
    // Get an existing site
    axios
      .get(`${serverUrl}/site/id/${this.siteId}`)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.success === true &&
          response.data.data
        ) {
          const siteData = response.data.data;
          this.props.setSelectedSiteData(siteData);
        }
      })
      .catch((err) => {
        console.error(`ERR: Could not fetch site data: ${err}`);
        this.setState({
          loadingSite: false,
        });
      });
  }

  loadSiteAndAuditData() {
    this.setState({
      loadingAudit: true,
      loadingSite: true,
      auditId: this.auditId,
      siteId: this.siteId,
    });
    this.getAuditData();
    this.getSite();
  }

  changeSelectedSite(event) {
    if (event && event.target && event.target.value) {
      const siteIndex = findIndex(this.props.basicSiteList, (site) => {
        return site._id === event.target.value;
      });

      const newSite = this.props.basicSiteList[siteIndex];

      if (newSite) {
        // this.X must be set as they are used by the audit and site loading functions [getAuditData / getSite]
        // If last audit failed and there is a lastSuccessfulAudit, set the auditId to lastSuccessfulAudit
        // Else set the auditId to to the last audit (audit.data.auditId)
        if (
          newSite.audit.didLastAuditFail &&
          newSite.audit.lastSuccessfulAuditId
        ) {
          this.auditId = newSite.audit.lastSuccessfulAuditId;
        } else {
          this.auditId = newSite.audit.data.auditId;
        }

        this.siteId = newSite._id;
        this.loadSiteAndAuditData();
      }
    }
  }

  componentDidMount() {
    this.auditId = getParameterByName('auditId');
    this.siteId = getParameterByName('siteId');

    if (this.auditId === null || this.siteId === null) {
      // Fallback to default site if not specified by parameters (getParameterByName returns NULL if no match)
      this.showSpecifiedSiteElseFirstOne();
    } else {
      // Load site now we know the audit/site ID's
      this.loadSiteAndAuditData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.auditId === null || this.siteId === null) {
      if (
        typeof prevProps.basicSiteList === 'undefined' &&
        this.props.basicSiteList
      ) {
        // Now site list has been loaded and no site has yet been selected..
        // let's show the first site in the list by default
        this.showSpecifiedSiteElseFirstOne();
      }
      // Populate selectedSiteData and selectedSiteAuditData if undefined, but
      // basicSiteList is populated
      if (
        this.props.basicSiteList &&
        this.props.basicSiteList.length &&
        !this.props.selectedSiteData &&
        !this.props.selectedSiteAuditData
      ) {
        this.showSpecifiedSiteElseFirstOne();
      }
    }
  }

  render() {
    const { organisation: { ui: { alternateNav } = {} } = {} } = this.props;

    // Hide nav if basicSiteList is unpopulated
    if (
      !this.props.basicSiteList ||
      (this.props.basicSiteList && this.props.basicSiteList.length < 1)
    ) {
      return null;
    } else if (
      // Hide nav if selectedSite in sessionStorge can't be found in basicSiteList
      this.getSelectedSite() &&
      !this.props.basicSiteList.find(
        ({ domain }) => domain === this.getSelectedSite()
      )
    ) {
      return null;
    }

    if (alternateNav) {
      return (
        <div id="headerAlt">
          <NavigationAlt
            basicSiteList={this.props.basicSiteList}
            selectedSiteData={this.props.selectedSiteData}
            changeSelectedSite={this.changeSelectedSite}
            siteId={this.state.siteId}
            t={this.props.t}
            user={this.props.user}
          />
        </div>
      );
    }

    return (
      <div id="header">
        <div className="container-fluid">
          <Navigation
            basicSiteList={this.props.basicSiteList}
            changeLanguage={this.changeLanguage}
            changeSelectedSite={this.changeSelectedSite}
            language={this.props.i18n.language}
            getLangFromLocale={getLangFromLocale}
            organisation={this.props.organisation}
            siteId={this.state.siteId}
            t={this.props.t}
            toggleMenu={this.toggleMenu}
            user={this.props.user}
          />
        </div>

        <NavigationMobile
          basicSiteList={this.props.basicSiteList}
          changeLanguage={this.changeLanguage}
          changeSelectedSite={this.changeSelectedSite}
          language={this.props.i18n.language}
          getLangFromLocale={getLangFromLocale}
          menuVisible={this.state.menuVisible}
          siteId={this.state.siteId}
          t={this.props.t}
          toggleMenu={this.toggleMenu}
          user={this.props.user}
        />
      </div>
    );
  }
}

Header.propTypes = {};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
