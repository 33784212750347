import React, { Component, Suspense } from 'react';
import ReactGA from 'react-ga';
import './App.scss';
import AppRouter from './AppRouter';
import { listen as listenForAnalytics } from './helpers/analytics-helper';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import connect from 'react-redux/es/connect/connect';
import { getLocaleFromUrl } from './helpers/utility';
import { setLocale } from './actions';
import {
  SUPPORT_EMAIL,
  SUPPORT_TITTLE,
  SUPPORT_SUBJECT,
} from './helpers/constants';
import i18n from 'i18next';

// react-toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    userType: state.authentication.userType,
    organisation: state.organisation.organisation,
    organisationTeam: state.organisation.team,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setLocale: (locale) => {
    dispatch(setLocale(locale));
  },
});

class App extends Component {
  constructor(props) {
    super(props);

    this.initializeReactGA();
  }

  initializeReactGA() {
    // Switch between DEV and PROD analytics tracking to improve data quality
    const testAnalyticsId = 'UA-144560377-6';
    const prodAnalyticsId = 'UA-144560377-5';

    let analyticsId =
      window.location.host.indexOf('localhost') > -1
        ? testAnalyticsId
        : prodAnalyticsId;
    ReactGA.initialize(analyticsId);

    listenForAnalytics();
  }

  componentDidMount() {
    // get locale from query string if present
    const locale = getLocaleFromUrl();

    if (locale && localStorage) {
      i18n.changeLanguage(locale);
      // update redux store with new locale
      setLocale(locale);
    }
  }

  render() {
    return (
      <Suspense fallback="loading">
        <div className="App" id="app">
          <ToastContainer />

          <div id="rightHandSide">
            <AppRouter>
              {this.props.user && <Header />}

              <div id="inner-content"></div>
            </AppRouter>

            {this.props.user && this.props.user.selfSignUp && (
              <Footer
                email={SUPPORT_EMAIL}
                title={SUPPORT_TITTLE}
                subject={SUPPORT_SUBJECT}
              />
            )}
          </div>
        </div>
      </Suspense>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
