import i18n from "i18next";
// import Backend from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translations from "./i18n-translations";

i18n
  .use(LanguageDetector)
  // bind react-i18next to the instance
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    resources: translations,
  });
