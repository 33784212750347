import React from "react";
import axios from "axios";
import { serverUrl } from "../../helpers/utility";
import connect from "react-redux/es/connect/connect";
import "./gorank-logs.scss";
import { socket } from "../../assets/scripts/socket";
import GRTable from "../../components/gr-table/gr-table";
import { format, parseISO } from "date-fns";

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    organisation: state.organisation.organisation,
  };
};

class GorankLogs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logs: [],
    };
  }

  componentDidMount() {
    // Get logs
    axios
      .get(`${serverUrl}/gorank/application/logs`)
      .then((response) => {
        if (response && response.data && response.data.success === true) {
          const data = response.data.data;

          if (data && data.logs) {
            data.logs = data.logs.reverse();

            this.setState({
              logs: data.logs,
            });
          }
        }
      })
      .catch(function (err) {
        console.error(`ERR: Could not fetch logs. ${err}`);
      });

    // Get logs
    axios
      .get(`${serverUrl}/user/socketCode`)
      .then((response) => {
        if (response && response.data && response.data.success === true) {
          const data = response.data.data;

          if (data && data.code) {
            const roomName = encodeURIComponent(`private-gorank-logs`);
            socket.emit("joinRoom", { roomName, authModel: data.code });
          }
        }
      })
      .catch(function (err) {
        console.error(`ERR: Could not fetch logs. ${err}`);
      });

    // Logging events
    socket.on("NEW_MESSAGE", (messageModel) => {
      if (messageModel && typeof messageModel.message === "string") {
        const newLogs = [messageModel, ...this.state.logs];
        this.setState({
          logs: newLogs,
        });
      }
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="main-content-container" id="gorankLogs">
          {this.state.logs && this.state.logs.length > 0 ? (
            <div className="row">
              <div className="col-12 m-t--20 m-b--45">
                <GRTable
                  data={this.state.logs}
                  rows={{}}
                  columns={[
                    {
                      label: "Timestamp",
                      size: "md",
                      getValue: (item) => {
                        return item.date;
                      },
                    },
                    {
                      label: "Type",
                      size: "sm",
                      getValue: (item) => {
                        return <div className={item.type}>{item.type}</div>;
                      },
                    },
                    {
                      label: "Message",
                      size: "lg",
                      getValue: (item) => {
                        return item.message;
                      },
                      customClass: "wrap",
                    },
                  ]}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

GorankLogs.propTypes = {};

export default connect(mapStateToProps)(GorankLogs);
