import React from 'react';
import * as PropTypes from 'prop-types';
import './backlinks-preview.scss';
const FORWARD = 'forward',
  BACKWARD = 'backward';

class BacklinksPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backlinksStartIndex: 0,
      backlinks: [],
    };

    this.handleImageError = this.handleImageError.bind(this);
  }

  componentDidMount() {
    this.setState({
      backlinks: this.props.backlinks,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.backlinks !== prevProps.backlinks) {
      this.setState({
        backlinks: this.props.backlinks,
        backlinksStartIndex: 0,
      });
    }
  }

  // Scroll the backlink preview forwards or backwards
  scrollBacklinkPreview({ direction }) {
    if (this.props.backlinks) {
      if (direction === FORWARD) {
        this.setState({
          backlinksStartIndex: Math.min(
            this.state.backlinksStartIndex + 4,
            this.props.backlinks.length - 4
          ),
        });
      }

      if (direction === BACKWARD) {
        this.setState({
          backlinksStartIndex: Math.max(this.state.backlinksStartIndex - 4, 0),
        });
      }
    } else {
      console.error(`ERR: Invalid props. backlinks required`);
    }
  }

  // Remove domains when the preview screenshot fails to load
  handleImageError(URL) {
    this.setState({
      backlinks: this.state.backlinks.filter((x) => x.urlFrom !== URL),
    });
  }

  render() {
    return (
      <div className="backlink-screenshot-container" id="backlinksPreview">
        {this.state.backlinks
          .slice(
            this.state.backlinksStartIndex,
            this.state.backlinksStartIndex + 4
          )
          .map((link, index) => {
            return (
              <div
                title={`Select to open ${link.urlFrom}`}
                key={index}
                className="backlink-screenshot"
              >
                <a
                  href={link.urlFrom}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`https://image.thum.io/get/width/300/crop/880/allowJPG/auth/39628-a5ca44fbcb595a46c39704fc9bf250ba/${link.urlFrom}`}
                    alt={`${link.urlFrom} website screenshot`}
                    onError={() => this.handleImageError(link.urlFrom)}
                  />
                </a>
              </div>
            );
          })}

        <div className="controls bold">
          <span
            className="m-r--10 pointer"
            onClick={() => this.scrollBacklinkPreview({ direction: BACKWARD })}
          >
            <i className="fal fa-chevron-left" />
          </span>
          {Math.max(Math.round(this.state.backlinksStartIndex / 4) + 1, 1)} /{' '}
          {Math.ceil(this.state.backlinks.length / 4)}
          <span
            className="m-l--10 pointer"
            onClick={() => this.scrollBacklinkPreview({ direction: FORWARD })}
          >
            <i className="fal fa-chevron-right" />
          </span>
        </div>
      </div>
    );
  }
}

BacklinksPreview.propTypes = {
  backlinks: PropTypes.array.isRequired,
};

export default BacklinksPreview;
