import React from 'react';
import i18n from 'i18next';
import './gr-radial-chart.scss';
import Chart from 'react-apexcharts';
import InfoIcon from '../info-icon/info-icon';
import useWindowSize from '../../hooks/useWindowSize';

import theme from '../../assets/css/theme.module.scss';

const getColor = (score) => {
  if (score > 80) {
    return theme.soCoTertiary;
  } else {
    if (score > 50) {
      return '#f59e4d';
    } else {
      return theme.soCoSecondary;
    }
  }
};

const valueFontSize = ({ width }) => {
  if (320 < width) {
    return '60px';
  }
  if (width < 321) {
    return '40px';
  }
};

const GRRadialChart = (props) => {
  const windowSize = useWindowSize();

  return (
    <div className={`radial-chart-container ${props.className}`}>
      <Chart
        options={{
          chart: {
            fontFamily: 'Open Sans, Roboto, Arial, sans-serif',
          },
          states: {
            normal: {
              filter: {
                type: 'none',
                value: 0,
              },
            },
            hover: {
              filter: {
                type: 'none',
                value: 0,
              },
            },
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: '79%',
                ...props.hollowOptions,
              },
              startAngle: -135,
              endAngle: 135,
              track: {
                background: '#E7E7E7',
                opacity: 0.2,
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: 0,
                  fontSize: valueFontSize(windowSize),
                  fontWeight: 'bold',
                  color: '#243D57',
                  formatter: function (val) {
                    return val;
                  },
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
          fill: {
            colors: props.graded ? getColor(props.value) : theme.soCoTertiary,
            opacity: 1,
            type: 'solid',
          },
        }}
        series={[props.value]}
        type="radialBar"
        width="100%"
        className="chart-central"
      />

      <div className="radial-chart-score">
        <div>{props.titlePrefix}</div>

        <div>
          <span className="radial-chart-score-label">
            {i18n.t('radial_chart__score')}
          </span>

          {!!props.infoIcon && (
            <span className="m-l--5">
              <InfoIcon
                id={'searchInfo'}
                placement={'right'}
                text={props.infoIcon}
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default GRRadialChart;
