import React, { Fragment } from 'react';
import i18n from 'i18next';
import * as PropTypes from 'prop-types';

class RegisterAgencyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      organisationName: '',
      agreeToTerms: false,
    };
  }

  handleInputChange = (event) => {
    if (event.target && event.target.name) {
      const update = {};
      update[event.target.name] = event.target.value;
      this.setState(update);
    }
  };

  handleCheckboxChange = (event) => {
    if (event.target && event.target.name) {
      const update = {};
      update[event.target.name] = event.target.checked;
      this.setState(update);
    }
  };

  render() {
    return (
      <Fragment>
        <h1 className="m-b--30">{i18n.t('register_form__partner_account')}</h1>

        <form
          onSubmit={(event) => {
            this.props.attemptRegisterAgency(event, this.state);
          }}
          autoComplete="off"
        >
          <div>
            <div className="formInput">
              <div className="text-left">{i18n.t('register_form__name')}*</div>
              <input
                autoFocus={true}
                type="text"
                className="form-control"
                value={this.state.name}
                autoComplete="name"
                name="name"
                required
                minLength="3"
                onChange={this.handleInputChange}
              />
            </div>

            <div className="formInput">
              <div className="text-left">{i18n.t('register_form__email')}*</div>
              <input
                type="email"
                className="form-control"
                value={this.state.email}
                name="email"
                autoComplete="email"
                required
                onChange={this.handleInputChange}
              />
            </div>

            <div className="formInput">
              <div className="text-left">
                {i18n.t('register_form__password')}*
              </div>
              <input
                type="password"
                className="form-control"
                value={this.state.password}
                name="password"
                required
                minLength="5"
                onChange={this.handleInputChange}
              />
            </div>

            <hr />

            <div className="formInput">
              <div className="text-left">
                {i18n.t('register_form__agency_name')}*
              </div>
              <input
                type="text"
                className="form-control"
                value={this.state.organisationName}
                name="organisationName"
                autoComplete="organisationName"
                required
                minLength="3"
                onChange={this.handleInputChange}
              />
            </div>

            <div className="formInput text-left">
              <div>{i18n.t('register_form__domain')}*</div>

              <div className="input-group input-group-sm mb-3">
                <input
                  type="text"
                  className={`subdomain-input form-control ${
                    this.state.isSubdomainAvailable === false
                      ? 'unavailable'
                      : ''
                  }`}
                  value={this.state.internalSubdomain}
                  name="internalSubdomain"
                  autoComplete="internalSubdomain"
                  required
                  minLength="2"
                  maxLength="15"
                  aria-describedby="subdomain-group"
                  onChange={this.handleInputChange}
                  onBlur={this.checkSubdomainAvailability}
                />
                <div className="input-group-append">
                  <span className="input-group-text" id="subdomain-group">
                    .automate-seo.com
                  </span>
                </div>
              </div>

              {this.state.isSubdomainAvailable === false ? (
                <div className="error">
                  {i18n.t('register_form__domain_error')}
                </div>
              ) : null}

              {this.state.isSubdomainAvailable === true ? (
                <div className="green">
                  {i18n.t('register_form__domain_success')}
                </div>
              ) : null}
            </div>

            <div className="formInput text-left">
              <label className={`checkbox-container`}>
                {i18n.t('register_agency__terms_1')}{' '}
                <a
                  href="https://seo.gorank.app/terms.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {i18n.t('register_agency__terms_2')}
                </a>
                .
                <input
                  type="checkbox"
                  value={this.state.agreeToTerms}
                  autoComplete="agreeToTerms"
                  name="agreeToTerms"
                  onChange={this.handleCheckboxChange}
                />
                <span className="checkmark" />
              </label>
            </div>

            {this.props.registerErrorMessage ? (
              <div className="error m-t--10 m-b--20">
                {this.props.registerErrorMessage}
              </div>
            ) : null}

            <input
              type="submit"
              value="Create"
              className="btn btn-success wide"
            />
          </div>
        </form>
      </Fragment>
    );
  }
}

RegisterAgencyForm.propTypes = {
  attemptRegisterAgency: PropTypes.func.isRequired,
  registerErrorMessage: PropTypes.string,
};

export default RegisterAgencyForm;
