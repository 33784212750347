import { combineReducers } from 'redux';
import register from './register';
import authentication from './authentication';
import organisation from './organisation';
import locale from './locale';
import sites from './sites';
import notifications from './notifications';

export default combineReducers({
  authentication,
  register,
  organisation,
  locale,
  sites,
  notifications,
});
