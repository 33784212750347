import React from 'react'
import "./error-boundary-fallback.scss"
/**
 * Errors are still shown in development however the ErrorBoundaryFallback allows
 * the error to be closed by clicking the cross in top right of viewport.
 */

class ErrorBoundaryFallback extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      console.log(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // Renders fallback component
        return (
            <p className="error-boundary-fallback text-center">{this.props.text}</p>
        )
      }
      return this.props.children; 
    }
  }

  ErrorBoundaryFallback.defaultProps = {
    text: "Something went wrong while trying to load a component"
  }

export default ErrorBoundaryFallback