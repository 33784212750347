import React, { useEffect, useState } from 'react';
import { indexOf, find, sortBy } from 'underscore';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import './navigationAlt.scss';

const navItems = [
  {
    name: 'header__dashboard',
    hash: ['#/site-summary', '#/'],
  },
  {
    name: 'header__audit',
    hash: ['#/audit'],
  },
  {
    name: 'header__alerts',
    hash: ['#/alerts'],
  },
  {
    name: 'header__admin',
    hash: ['#/admin'],
    adminOnly: true,
  },
];

const isAdmin = (user) =>
  user &&
  user.currentPermissions &&
  (user.currentPermissions.isOrganisationAdmin ||
    user.currentPermissions.isOrganisationOwner);

const NavItem = ({ t, name, hash, adminOnly, user }) => {
  if (adminOnly) {
    if (isAdmin(user)) {
      return (
        <li
          className={classNames('navigationAlt__nav-item', {
            'navigationAlt__nav-item--active':
              hash.length > 1
                ? window.location.hash === hash[0] ||
                  window.location.hash === hash[1]
                : window.location.hash === hash[0],
          })}
        >
          <a href={hash[0]} target="_self">
            {t(name)}
          </a>
        </li>
      );
    } else {
      return null;
    }
  } else {
    return (
      <li
        className={classNames('navigationAlt__nav-item', {
          'navigationAlt__nav-item--active':
            hash.length > 1
              ? window.location.hash === hash[0] ||
                window.location.hash === hash[1]
              : window.location.hash === hash[0],
        })}
      >
        <a href={hash[0]} target="_self">
          {t(name)}
        </a>
      </li>
    );
  }
};

const NavigationAlt = ({
  basicSiteList,
  changeSelectedSite,
  siteId,
  selectedSiteData,
  t,
  user,
}) => {
  const location = useLocation();
  const [upSince, setUpSince] = useState('');
  const [selectdWidth, setSelectWidth] = useState('auto');

  useEffect(() => {
    console.log('route changed');
  }, [location]);

  useEffect(() => {
    if (
      selectedSiteData &&
      selectedSiteData.uptime &&
      selectedSiteData.uptime.history
    ) {
      const sortedList = sortBy(
        selectedSiteData.uptime.history,
        'created'
      ).reverse();

      const findLastDownEntry = find(
        sortedList,
        ({ websiteOK }) => websiteOK === false
      );

      const findEntryIndex = indexOf(sortedList, findLastDownEntry);
      const findUpSince =
        typeof sortedList[findEntryIndex - 1] === 'undefined'
          ? sortedList[sortedList.length - 1]
          : sortedList[findEntryIndex - 1];

      if (findUpSince) {
        setUpSince(format(new Date(findUpSince.created), 'dd MMMM - kk:mm'));
      }
      setSelectWidth(
        `${selectedSiteData['domainFriendly'].length * 15 + 36}px`
      );
    }
  }, [selectedSiteData]);

  const iconStatusClasses = classNames({
    'navigationAlt__icon-status': true,
    'navigationAlt__icon-status--online':
      selectedSiteData &&
      selectedSiteData.uptime &&
      selectedSiteData.uptime.currentlyDown === false,
    'navigationAlt__icon-status--offline':
      selectedSiteData &&
      selectedSiteData.uptime &&
      selectedSiteData.uptime.currentlyDown === true,
  });

  return (
    <div className="navigationAlt col-12 page-padding">
      <div className="navigationAlt__site-selector-container">
        <div className="navigationAlt__icon">
          <i className="fal fa-globe" />
          <span className={iconStatusClasses}></span>
        </div>
        <div className="navigationAlt__site-selector-controls">
          {selectedSiteData && basicSiteList && siteId && (
            <>
              <select
                className="form-control"
                name="siteId"
                value={siteId}
                onChange={changeSelectedSite}
                style={{
                  width: selectdWidth,
                }}
              >
                {basicSiteList.map((site) => {
                  return (
                    <option key={site._id} value={site._id}>
                      {site.domainFriendly}
                    </option>
                  );
                })}
              </select>
              <div className="navigationAlt__domain-info">
                <a
                  className="navigationAlt__domain"
                  href={selectedSiteData && selectedSiteData['domain']}
                  target="_blank"
                  rel="noreferrer"
                >
                  {selectedSiteData && selectedSiteData['domain']}
                </a>
                <span className="navigationAlt__domain-uptime">
                  {selectedSiteData &&
                  selectedSiteData.uptime &&
                  selectedSiteData.uptime.currentlyDown
                    ? 'currently down'
                    : `/ up since: ${upSince}`}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
      <nav className="navigationAlt__nav">
        <ul>
          {navItems.map(({ name, hash, adminOnly }) => (
            <NavItem
              key={hash}
              t={t}
              name={name}
              hash={hash}
              adminOnly={adminOnly}
              user={user}
            />
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default NavigationAlt;
