const organisation = (state, action) => {
  switch (action.type) {
    case "SET_ORGANISATION":
      return {
        ...state,
        organisation: action.organisation,
      };
    case "SET_TEAM_USERS":
      return {
        ...state,
        team: action.team,
      };
    case "CLEAR_ORGANISATION":
      return {
        ...state,
        organisation: null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default organisation;
