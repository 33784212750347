import React from "react";
import "./audit-passed.scss";
import { ReactComponent as Tick } from "../../assets/images/tick.svg";
import { ReactComponent as Cross } from "../../assets/images/cross.svg";

const AuditPassed = ({ result, pass, units, passed }) => {
  const checkPassed = typeof passed === "boolean" ? passed : result <= pass;
  return (
    <div className="audit-passed">
      {checkPassed ? (
        <Tick className="audit-passed-icon" />
      ) : (
        <Cross className="audit-passed-icon" />
      )}
      <div className={`${checkPassed && `audit-passed-passed`}`}>
        {result}
        {units && <span className="audit-passed-units">{units}</span>}
      </div>
    </div>
  );
};

export default AuditPassed;
