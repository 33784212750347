import React from 'react';
import WidgetContainer from '../widgetContainer/widgetContainer';
import i18n from 'i18next';

import './top-issues.scss';

import RenderIssues from '../render-issues/render-issues';

const TopIssues = ({
  issues = [],
  toggleIssueVisibility,
  togglePagesVisibility,
  renderDifficulty,
  onAutoFixClick,
  onLearnFixClick,
  onRequestHelpClick,
  audit,
}) => {
  const issuesFailed = issues.filter((issue) => !issue.passed);

  return (
    <div className="top-issues col-12 dashboard-box">
      <WidgetContainer>
        <div className="dashboard-box-header dashboard-box-header__bg-grey">
          {i18n.t('top_issues__title')}
        </div>
        <div className="standard-box-content standard-box-content-no-padding standard-box-auto-height">
          {issuesFailed.length ? (
            <RenderIssues
              issues={issuesFailed}
              toggleIssueVisibility={toggleIssueVisibility}
              togglePagesVisibility={togglePagesVisibility}
              renderDifficulty={renderDifficulty}
              onAutoFixClick={onAutoFixClick}
              onLearnFixClick={onLearnFixClick}
              onRequestHelpClick={onRequestHelpClick}
              audit={audit}
              showMoreVisible={false}
              maxIssuesToShow={6}
            />
          ) : (
            <p className="standard-box-content no-margin standard-box-auto-height standard-box-content-no-border">
              {i18n.t('audit_section__no_issues')}
            </p>
          )}
        </div>
      </WidgetContainer>
    </div>
  );
};

export default TopIssues;
