import React from 'react';
import { format } from 'date-fns';
import { Badge } from 'react-bootstrap';
import i18n from 'i18next';

import { LIMIT, FORWARD, BACKWARD, ALL, ONLINE, OFFLINE } from './constants';

const UptimeFilters = ({
  list,
  setFilter,
  allHistory,
  onlineHistory,
  offlineHistory,
}) => (
  <ul className="uptime_filters">
    <li className="uptime_filter">
      <button onClick={() => setFilter({ filter: ALL })}>{i18n.t(ALL)}</button>
      <Badge variant="secondary">{allHistory && allHistory.length}</Badge>
    </li>
    <li className="uptime_filter">
      <button onClick={() => setFilter({ filter: ONLINE })}>
        {i18n.t(ONLINE)}
      </button>
      <Badge variant="secondary">{onlineHistory && onlineHistory.length}</Badge>
    </li>
    <li className="uptime_filter">
      <button onClick={() => setFilter({ filter: OFFLINE })}>
        {i18n.t(OFFLINE)}
      </button>
      <Badge variant="secondary">
        {offlineHistory && offlineHistory.length}
      </Badge>
    </li>
  </ul>
);

const UptimeTable = ({
  list,
  index,
  allHistory,
  onlineHistory,
  offlineHistory,
  scrollHistory,
  setFilter,
}) => (
  <div className="uptime-table dashboard-box-content">
    <UptimeFilters
      list={list}
      setFilter={setFilter}
      allHistory={allHistory}
      onlineHistory={onlineHistory}
      offlineHistory={offlineHistory}
    />
    {list && list.length ? (
      <div className="table">
        <div className="tr th">
          <div className="td">{i18n.t('uptime__table_date')}</div>
          <div className="td">{i18n.t('uptime__table_time')}</div>
          <div className="td">{i18n.t('uptime__table_status')}</div>
        </div>
        {list.slice(index, index + LIMIT).map(({ created, websiteOK }) => (
          <div className="tr" key={created}>
            <div className="td">{format(new Date(created), 'dd/MM/yyyy')}</div>
            <div className="td">{format(new Date(created), 'kk:mm')}</div>
            <div
              className={`td uptime_status ${
                websiteOK ? 'uptime_status--up' : 'uptime_status--down'
              }`}
            >
              {websiteOK ? i18n.t(ONLINE) : i18n.t(OFFLINE)}
            </div>
          </div>
        ))}
      </div>
    ) : (
      <p className="uptime-table--no-data">{i18n.t('uptime__table_no_data')}</p>
    )}
    {list && list.length ? (
      <div className="controls bold">
        <span
          className="m-r--10 pointer"
          onClick={() => scrollHistory({ direction: BACKWARD })}
        >
          <i className="fal fa-chevron-left" />
        </span>
        {Math.max(Math.round(index / LIMIT) + 1, 1)} /{' '}
        {Math.round(list.length / LIMIT)}
        <span
          className="m-l--10 pointer"
          onClick={() => scrollHistory({ direction: FORWARD })}
        >
          <i className="fal fa-chevron-right" />
        </span>
      </div>
    ) : null}
  </div>
);

export default UptimeTable;
