// Keep this simple, as don't want to have if gorank, else if team else if ...
function getUserType(permissions) {
  if (permissions) {
    if (permissions.isOrganisationStaff || permissions.isOrganisationAdmin) {
      return "team";
    } else {
      return "user";
    }
  } else {
    console.error("ERR: No permissions found");
    return "user";
  }
}

function isGoRankTeam(permissions) {
  if (permissions) {
    if (
      permissions.isApplicationAdmin ||
      permissions.isApplicationStaff ||
      permissions.isApplicationSupport
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

const authentication = (state, action) => {
  switch (action.type) {
    case "LOGOUT":
      return {
        ...state,
        user: null,
        loggedIn: false,
        googleConnected: false,
        googleAnalyticsAccounts: {},
        googleWebmasterAccounts: [],
      };
    case "NOT_LOGGED_IN":
      return {
        ...state,
        user: null,
        loginFailure: false,
        loggedIn: false,
        googleConnected: false,
        googleAnalyticsAccounts: {},
        googleWebmasterAccounts: [],
      };
    case "LOGIN_FAILED":
      return {
        ...state,
        user: null,
        loginFailure: true,
        loggedIn: false,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        user: action.user,
        userType:
          action.user && action.user.currentPermissions
            ? getUserType(action.user.currentPermissions)
            : "user",
        goRankTeam:
          action.user && action.user.currentPermissions
            ? isGoRankTeam(action.user.currentPermissions)
            : false,
        loginFailure: false,
        loggedIn: true,
      };
    case "USER_UPDATE":
      return {
        ...state,
        user: action.user,
      };
    case "GOOGLE_CONNECTED":
      return {
        ...state,
        googleConnected: true,
      };
    case "GOOGLE_DISCONNECTED":
      return {
        ...state,
        googleConnected: false,
      };
    case "GOOGLE_ANALYTICS_ACCOUNTS":
      return {
        ...state,
        googleAnalyticsAccounts: action.accounts,
      };
    case "GOOGLE_WEBMASTER_ACCOUNTS":
      return {
        ...state,
        googleWebmasterAccounts: action.accounts,
      };
    default:
      return {
        ...state,
      };
  }
};

export default authentication;
