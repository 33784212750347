import React, {Fragment} from 'react';
import i18n from 'i18next';
import * as PropTypes from 'prop-types';
import axios from 'axios';
import {findIndex} from 'underscore';
import GRTable from '../gr-table/gr-table';
import Button from 'react-bootstrap/Button';

class ImportWizard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 'start',
      sitesPreview: [],
      preview: [],
      errorMessage: null,
    };

    this.handleSelectedFile = this.handleSelectedFile.bind(this);
  }

  handleInputChange = (event) => {
    if (event.target && event.target.name) {
      // Switch between top level state property change and a nested value update
      if (typeof event.target.dataset.stateType === 'undefined') {
        const update = {};
        update[event.target.name] = event.target.value;
        this.setState(update);
      } else {
        if (
          typeof event.target.dataset.stateType === 'string' &&
          this.state[event.target.dataset.stateType] !== 'undefined'
        ) {
          // stateType reflects the "parent" property of the value we are trying to update
          // e.g. this.state.PARENT.x
          const update = Object.assign(
            {},
            this.state[event.target.dataset.stateType]
          );
          update[event.target.name] = event.target.value;

          // We want to setState on the updated property, not replace the whole store with a nested value
          const updateWrapper = {};
          updateWrapper[event.target.dataset.stateType] = update;

          this.setState(updateWrapper);
        } else {
          console.error('ERR: Could not update nested state value #28444');
        }
      }
    }
  };

  resetState() {
    // Reset site state
    this.setState({
      preview: [],
      headerRow: [],
      step: 'start',
      errorMessage: null,
    });
  }

  scanHeaders({ headers = [] }) {
    const name = findIndex(headers, (header) => {
      return (
        typeof header === 'string' &&
        ((header.toLowerCase().includes('name') &&
          !(
            header.toLowerCase().includes('surname') ||
            header.toLowerCase().includes('lastname')
          )) ||
          header.toLowerCase().includes('firstname'))
      );
    });
    const lastname = findIndex(headers, (header) => {
      return (
        typeof header === 'string' &&
        (header.toLowerCase().includes('surname') ||
          header.toLowerCase().includes('lastname'))
      );
    });
    const email = findIndex(headers, (header) => {
      return (
        typeof header === 'string' && header.toLowerCase().includes('email')
      );
    });
    const emailInvite = findIndex(headers, (header) => {
      return (
        typeof header === 'string' && header.toLowerCase().includes('invite')
      );
    });
    const domain = findIndex(headers, (header) => {
      return (
        typeof header === 'string' &&
        (header.toLowerCase().includes('url') ||
          header.toLowerCase().includes('website'))
      );
    });
    const userExternalId = findIndex(headers, (header) => {
      return (
        typeof header === 'string' &&
        (header.toLowerCase().includes('reference') ||
          header.toLowerCase().includes('client id') ||
          header.toLowerCase().includes('external id'))
      );
    });
    const industry = findIndex(headers, (header) => {
      return (
        typeof header === 'string' && header.toLowerCase().includes('industry')
      );
    });
    const tag = findIndex(headers, (header) => {
      return (
        typeof header === 'string' &&
        (header.toLowerCase().includes('tag') ||
          header.toLowerCase().includes('note'))
      );
    });
    const searchLanguage = findIndex(headers, (header) => {
      return (
        typeof header === 'string' &&
        header.toLowerCase().includes('search language')
      );
    });
    const searchLocationCanonical = findIndex(headers, (header) => {
      return (
        typeof header === 'string' &&
        header.toLowerCase().includes('search location canonical')
      );
    });

    return {
      name,
      lastname,
      email,
      emailInvite,
      domain,
      userExternalId,
      industry,
      tag,
      searchLanguage,
      searchLocationCanonical,
    };
  }

  handleSelectedFile(event) {
    let data = new FormData();
    data.append('importData', event.target.files[0]);

    axios
      .post(this.props.previewEndpoint, data)
      .then((response) => {
        if (response && response.data && response.data.success) {
          console.log('Preview fetched.');
          const previewData = response.data.data.splice(
            response.data.startRow || 0
          );

          if (
            response.data &&
            response.data.data &&
            response.data.data.length > 0 &&
            response.data.data[response.data.headerRow]
          ) {
            const mapColumnsToData = this.scanHeaders({
              headers: response.data.data[response.data.headerRow],
            });

            // Validate required columns exist (domain, email)
            if (
              mapColumnsToData &&
              mapColumnsToData.domain > -1 &&
              mapColumnsToData.email > -1
            ) {
              this.setState({
                preview: previewData,
                headerRow: response.data.data[response.data.headerRow],
                step: 'preview',
                mapColumnsToData,
              });
            } else {
              this.setState({
                step: 'start',
                sitesPreview: [],
                preview: [],
                errorMessage: i18n.t('import_wizard__template_error'),
              });
            }
          }
        }
      })
      .catch(function (e) {
        // handle error
        console.error(`Error uploading content preview! ${e}`);
      });
  }

  render() {
    return (
      <form
        onSubmit={(event) => {
          if (event) {
            event.preventDefault();
          }

          // If on final step (e.g. wizard completed), then call the submit action
          if (
            this.state.step === 'preview' &&
            this.state.preview &&
            this.state.mapColumnsToData
          ) {
            this.props.importAction({
              sites: this.state.preview,
              columns: this.state.mapColumnsToData,
            });

            setTimeout(() => {
              this.resetState();
            }, 500);
          }
        }}
      >
        <div className="">
          {this.state.errorMessage ? (
            <div>
              <div className="alert alert-danger text-center m-b--30">
                {this.state.errorMessage}
              </div>
            </div>
          ) : null}

          <div>
            <Fragment>
              {this.state.step === 'start' ? (
                <div>
                  <h1>{i18n.t('import_wizard__import_clients')}</h1>

                  <div className="formInput">
                    <div className="m-t--15">
                      <div className="text-left small-text">
                        {i18n.t('import_modal__step_1')}
                      </div>
                      <div className="m-t--10">
                        {this.props.downloadLink ? (
                          <a href={this.props.downloadLink} download>
                            <div
                              className="btn btn-outline-dark"
                              style={{ minWidth: '150px' }}
                            >
                              {i18n.t('import_modal__download_template')}
                            </div>
                          </a>
                        ) : null}
                      </div>
                    </div>

                    <div className="m-t--30">
                      <div className="text-left small-text">
                        {i18n.t('import_modal__step_2')}
                        <br />
                        {i18n.t('import_modal__step_2_def')}
                      </div>
                      <div className="m-t--10">
                        <input
                          type="file"
                          name="contentToImport"
                          id="file"
                          className="uploadFileCustom"
                          accept={'*'}
                          onChange={this.handleSelectedFile}
                        />
                        <label htmlFor="file" style={{ minWidth: '150px' }}>
                          {i18n.t('import_modal__upload')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {this.state.step === 'preview' && this.state.preview ? (
                <Fragment>
                  <div>
                    <GRTable
                      data={this.state.preview}
                      rows={{}}
                      columns={
                        this.state.headerRow
                          ? this.state.headerRow.map((header, headerIndex) => {
                              return {
                                label: header,
                                size: 'lg',
                                getValue: (item) => {
                                  return item &&
                                    item.length > headerIndex &&
                                    item[headerIndex]
                                    ? item[headerIndex]
                                    : '';
                                },
                              };
                            })
                          : []
                      }
                    />
                  </div>

                  <div className="m-t--20">
                    <Button variant="success" type="submit">
                      {i18n.t('import_wizard__confirm')}
                    </Button>
                  </div>
                </Fragment>
              ) : null}
            </Fragment>
            <br />
          </div>
        </div>
      </form>
    );
  }
}

ImportWizard.propTypes = {
  importAction: PropTypes.func.isRequired,
  downloadLink: PropTypes.string.isRequired,
  previewNotes: PropTypes.string.isRequired,
  previewEndpoint: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default ImportWizard;
