import React, { Fragment } from 'react';
import i18n from 'i18next';
import axios from 'axios';
import {
  serverUrl,
  getOrganisation,
  splitAndTrim,
} from '../../helpers/utility';
import connect from 'react-redux/es/connect/connect';
import './admin-setup.scss';
import vaultImg from '../../assets/images/undraw_vault_9cmw.svg';
import alternateNavImg from '../../assets/images/alternate-nav.png';
import regularNavImg from '../../assets/images/regular-nav.png';
import Dropzone from 'react-dropzone';
import Button from 'react-bootstrap/Button';
import AdminSetupWebhooks from '../../components/admin-setup-webhooks/admin-setup-webhooks';

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    organisation: state.organisation.organisation,
    loginFailure: state.authentication.loginFailure,
    googleConnected: state.authentication.googleConnected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrganisation: () => {
      getOrganisation({ dispatch, cacheBust: true });
    },
  };
};

class AdminSetup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Note: Expect a maximum of 10 customAppURL
      customAppURL: [],
      customCSSURL: '',
      termsUrl: '',
      exportConfig: {
        callToActionTitle: '',
        callToActionText: '',
      },
      name: '',
      sendFromEmail: '',
      sharedSecret: '',
      alternateNav: '',
      webhookSubscribers: [],
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.updateOrganisationSettings =
      this.updateOrganisationSettings.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.viewPage = this.viewPage.bind(this);
    this.handleURLInputChange = this.handleURLInputChange.bind(this);
  }

  viewPage(url) {
    window.location.href = url;
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    // If value is 'true' or 'false' convert string to boolean
    value = value === 'true' ? true : value === 'false' ? false : value;

    this.setState({
      [name]: value,
    });
  }

  // Custom update logic for the domains array (customAppURL)
  handleURLInputChange(event) {
    if (event && event.target && event.target.name) {
      const update = [...this.state.customAppURL];

      // Note: event.target.name is the index we wish to update
      update[Number(event.target.name)] = event.target.value;

      this.setState({
        customAppURL: update,
      });
    }
  }

  componentDidMount() {
    if (this.props.organisation) {
      let customAppURL = this.props.organisation.customAppURL;

      // Legacy compatibility, remove me once database migration has converted strings to array
      if (typeof customAppURL === 'string') {
        customAppURL = [customAppURL];
      }

      this.setState({
        theme: this.props.organisation.theme,
        customAppURL,
        customCSSURL: this.props.organisation.customCSSURL,
        name: this.props.organisation.name,
        termsUrl: this.props.organisation.termsUrl,
        exportConfig: {
          callToActionTitle: '',
          callToActionText: '',
        },
        sendFromEmail: this.props.organisation.sendFromEmail,
        sharedSecret: this.props.organisation.sharedSecret,
        alternateNav: this.props.organisation.ui.alternateNav,
        webhookSubscribers: this.props.organisation.webhookSubscribers,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (typeof prevProps.organisation === 'undefined') {
      if (this.props.organisation) {
        let customAppURL = this.props.organisation.customAppURL;

        // Legacy compatibility, remove me once database migration has converted strings to array
        if (typeof customAppURL === 'string') {
          customAppURL = [customAppURL];
        }

        this.setState({
          theme: this.props.organisation.theme,
          customAppURL,
          customCSSURL: this.props.organisation.customCSSURL,
          name: this.props.organisation.name,
          termsUrl: this.props.organisation.termsUrl,
          exportConfig: {
            callToActionTitle: '',
            callToActionText: '',
          },
          sendFromEmail: this.props.organisation.sendFromEmail,
          sharedSecret: this.props.organisation.sharedSecret,
          alternateNav: this.props.organisation.ui.alternateNav,
          webhookSubscribers: this.props.organisation.webhookSubscribers,
        });
      }
    }
  }

  uploadFile(files) {
    if (files && files.length > 0) {
      let data = new FormData();
      data.append('upload', files[0]);
      // Optional parent name, if updating a grandchild value
      if (this.parentDocumentField) {
        data.append('parentDocumentField', this.parentDocumentField);
      }
      data.append('documentField', this.documentField);

      axios
        .put(`${serverUrl}/organisation/upload`, data)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.props.getOrganisation();
          }
        })
        .catch(function (err) {
          // handle error
          console.error(`ERR: Could not update site details: ${err}`);
        });
    }
  }

  updateOrganisationSettings() {
    const organisationUpdateModel = {
      // Filter out all empty (falsey) urls
      customAppURL: this.state.customAppURL.filter(Boolean),
      customCSSURL: this.state.customCSSURL,
      name: this.state.name,
      termsUrl: this.state.termsUrl,
      exportConfig: {
        callToActionTitle: '',
        callToActionText: '',
      },
      sendFromEmail: this.state.sendFromEmail,
      sharedSecret: this.state.sharedSecret,
      ui: {
        alternateNav: this.state.alternateNav,
      },
      webhookSubscribers: Array.isArray(this.state.webhookSubscribers)
        ? // Do not send empty arrays
          this.state.webhookSubscribers.length
          ? // Remove falsey values
            this.state.webhookSubscribers.filter(Boolean)
          : null
        : // If string split into array and remove falsey values
          splitAndTrim(this.state.webhookSubscribers),
    };

    axios
      .put(`${serverUrl}/organisation`, organisationUpdateModel)
      .then((response) => {
        if (response && response.data && response.data.success) {
          console.log('Organisation updated.');
          this.setState({ updateSuccess: true });
          this.props.getOrganisation();

          if (sessionStorage && sessionStorage.getItem('organisation')) {
            sessionStorage.removeItem('organisation');
          }

          setTimeout(() => {
            this.setState({ updateSuccess: false });
          }, 4000);
        }
      })
      .catch(function (error) {
        // handle error
        console.error('Error updating organisation data!');
      });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="main-content-container" id="admin-setup">
          <div className="row gr-section">
            <div className="col-12 standard-box desktop-margin-top">
              <div className="m-b--10">
                <a href={`/#admin`}>
                  <span className={`m-r--5`}>
                    <i className={`fal fa-chevron-left`} />
                  </span>
                  <span>{i18n.t('admin_setup__back')}</span>
                </a>
              </div>

              <div className={`box full-height`}>
                {this.props.user &&
                this.props.user.currentPermissions &&
                (this.props.user.currentPermissions.isOrganisationOwner ||
                  this.props.user.currentPermissions.isApplicationAdmin) ? (
                  <Fragment>
                    <div className={`standard-box-header`}>
                      {i18n.t('admin_setup__settings')}
                    </div>
                    <div className={`standard-box-content`}>
                      <div className="gr-section m-b--20">
                        <div className="formInput">
                          <p className="m-b--10">
                            {i18n.t('admin_setup__business_name')}
                          </p>
                          <input
                            type="text"
                            className="form-control sm"
                            value={this.state.name}
                            autoComplete="organisationName"
                            name="name"
                            required
                            onChange={this.handleInputChange}
                          />
                        </div>

                        <div className="formInput">
                          <div>{i18n.t('admin_setup__domain')}</div>
                          <p className="light m-b--10">
                            <small>
                              {i18n.t('admin_setup__domain_description')}
                            </small>
                          </p>

                          {this.props.user &&
                          this.props.user.currentPermissions &&
                          this.props.user.currentPermissions
                            .isApplicationAdmin ? (
                            <div>
                              {this.state.customAppURL &&
                              this.state.customAppURL.length > 0 ? (
                                <div className="m-b--10">
                                  {this.state.customAppURL.map(
                                    (url, urlIndex) => {
                                      return (
                                        <div key={urlIndex}>
                                          <input
                                            type="text"
                                            className="form-control sm m-b--10"
                                            value={
                                              this.state.customAppURL[urlIndex]
                                            }
                                            name={urlIndex}
                                            onChange={this.handleURLInputChange}
                                          />
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              ) : null}

                              <div>
                                <Button
                                  variant="outline-dark"
                                  onClick={() => {
                                    if (
                                      this.state.customAppURL &&
                                      this.state.customAppURL.length < 10
                                    ) {
                                      this.setState({
                                        customAppURL: [
                                          ...this.state.customAppURL,
                                          '',
                                        ],
                                      });
                                    }
                                  }}
                                  disabled={
                                    this.state.customAppURL &&
                                    this.state.customAppURL.length > 9
                                  }
                                  title={i18n.t('add_domain__up_to_10')}
                                >
                                  {i18n.t('add_domain')}
                                </Button>
                                <Button
                                  variant="outline-dark"
                                  onClick={() => {
                                    if (
                                      this.state.customAppURL &&
                                      this.state.customAppURL.length > 1
                                    ) {
                                      this.setState({
                                        customAppURL:
                                          this.state.customAppURL.slice(0, -1),
                                      });
                                    }
                                  }}
                                  disabled={
                                    this.state.customAppURL &&
                                    this.state.customAppURL.length === 1
                                  }
                                  title={i18n.t('add_domain__remove_last')}
                                >
                                  {i18n.t('add_domain__remove')}
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <label
                              className={`form-control`}
                              style={{
                                cursor: 'not-allowed',
                              }}
                            >
                              {this.state.customAppURL &&
                              typeof this.state.customAppURL === 'object'
                                ? this.state.customAppURL.map(
                                    (url, urlIndex) => {
                                      return (
                                        <span key={urlIndex}>
                                          {url}
                                          {urlIndex !==
                                          this.state.customAppURL.length - 1
                                            ? ', '
                                            : ''}
                                        </span>
                                      );
                                    }
                                  )
                                : null}
                            </label>
                          )}
                        </div>

                        <div className="formInput">
                          <p className="light m-b--0">
                            {i18n.t('admin_setup__send_from')}
                          </p>
                          <p className="light m-b--10">
                            <small>
                              {i18n.t('admin_setup__send_from_description')}
                            </small>
                          </p>

                          {this.props.user &&
                          this.props.user.currentPermissions &&
                          this.props.user.currentPermissions
                            .isApplicationAdmin ? (
                            <input
                              type="text"
                              className="form-control sm"
                              value={this.state.sendFromEmail}
                              autoComplete="sendFromEmail"
                              name="sendFromEmail"
                              required
                              onChange={this.handleInputChange}
                            />
                          ) : (
                            <label
                              className={`form-control`}
                              style={{
                                cursor: 'not-allowed',
                              }}
                            >
                              {this.state.sendFromEmail}
                            </label>
                          )}
                        </div>

                        <div className="formInput">
                          <p className="m-b--10">
                            {i18n.t('admin_setup__shared_secret')}
                          </p>
                          <input
                            type="text"
                            className="form-control sm"
                            value={this.state.sharedSecret}
                            name="sharedSecret"
                            required
                            onChange={this.handleInputChange}
                          />
                        </div>

                        <div className="formInput">
                          <p className="m-b--0">
                            {i18n.t('admin_setup__custom_css')}
                          </p>
                          <p className="light m-b--10">
                            <small>
                              {i18n.t('admin_setup__advanced')}
                              {': '}
                              {i18n.t('admin_setup__css_page')}
                            </small>
                          </p>
                          <input
                            type="text"
                            className="form-control sm"
                            value={this.state.customCSSURL}
                            name="customCSSURL"
                            required
                            onChange={this.handleInputChange}
                          />
                        </div>

                        <div className="formInput">
                          <p className="m-b--0">
                            {i18n.t('admin_setup__webhook_endpoints')}
                          </p>
                          <p className="light m-b--10">
                            <small>
                              {i18n.t('admin_setup__advanced')}
                              {': '}
                              {i18n.t('admin_setup__webhooks_description')}
                            </small>
                          </p>
                          <input
                            type="text"
                            className="form-control sm"
                            value={this.state.webhookSubscribers}
                            name="webhookSubscribers"
                            required
                            onChange={this.handleInputChange}
                          />
                        </div>

                        <AdminSetupWebhooks
                          customAppURL={this.state.customAppURL}
                        />

                        <div
                          className="formInput"
                          onClick={this.updateOrganisationSettings}
                        >
                          <div className="btn btn-success">
                            {this.state.updateSuccess
                              ? i18n.t('admin_setup__saved')
                              : i18n.t('admin_setup__update')}
                          </div>
                        </div>
                      </div>

                      <div className={`m-b--30`}>
                        <hr />
                      </div>

                      <div className="gr-section m-b--20">
                        <div className="row">
                          <div className="col-12 m-b--15">
                            <div>{i18n.t('admin_setup__navbar_styling')}</div>
                            <label
                              className="d-flex align-items-center"
                              htmlFor="regular-nav"
                            >
                              <input
                                type="radio"
                                name="alternateNav"
                                value={false}
                                checked={!this.state.alternateNav}
                                onChange={this.handleInputChange}
                              />
                              <img
                                src={regularNavImg}
                                alt="regular-nav"
                                className="pl-1"
                              />
                            </label>
                            <label
                              className="d-flex align-items-center"
                              htmlFor="alternate-nav"
                            >
                              <input
                                type="radio"
                                name="alternateNav"
                                value="true"
                                checked={this.state.alternateNav}
                                onChange={this.handleInputChange}
                              />
                              <img
                                src={alternateNavImg}
                                alt="alternate-nav"
                                className="pl-1"
                              />
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 m-b--15">
                            <div>{i18n.t('admin_setup__logo_dark')}</div>

                            <p className="light small">
                              {i18n.t('admin_setup__logo_dark_description')}
                            </p>
                          </div>

                          <div className="col-6 col-md-4 col-lg-2">
                            {this.props.organisation &&
                            this.props.organisation.organisationLogoDark &&
                            this.props.organisation.organisationLogoDark.Url ? (
                              <div>
                                <img
                                  src={
                                    this.props.organisation.organisationLogoDark
                                      .Url
                                  }
                                  className="img-preview"
                                  alt={`${this.props.organisation.name} 'logo'`}
                                />
                              </div>
                            ) : (
                              <div>
                                <span
                                  className="img-missing"
                                  title={i18n.t('admin_setup__no_image')}
                                >
                                  <span className="fal fa-image" />
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="col-6">
                            <Dropzone
                              onDrop={(files) => {
                                this.documentField = 'organisationLogoDark';
                                this.uploadFile(files);
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div>
                                  <div
                                    {...getRootProps()}
                                    className="dropzone m-b--15"
                                  >
                                    <input {...getInputProps()} />
                                    <span>
                                      {i18n.t('admin_setup__drag_and_drop')}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 m-b--15">
                            {i18n.t('admin_setup__favicon')}
                          </div>

                          <div className="col-6 col-md-4 col-lg-2">
                            {this.props.organisation &&
                            this.props.organisation.organisationFavicon &&
                            this.props.organisation.organisationFavicon.Url ? (
                              <div>
                                <img
                                  src={
                                    this.props.organisation.organisationFavicon
                                      .Url
                                  }
                                  className="img-preview"
                                  alt={`${this.props.organisation.name} 'favicon'`}
                                />
                              </div>
                            ) : (
                              <div>
                                <span
                                  className="img-missing"
                                  title={i18n.t('admin_setup__no_image')}
                                >
                                  <span className="fal fa-image" />
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="col-6">
                            <Dropzone
                              onDrop={(files) => {
                                this.documentField = 'organisationFavicon';
                                this.uploadFile(files);
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div>
                                  <div
                                    {...getRootProps()}
                                    className="dropzone m-b--15"
                                  >
                                    <input {...getInputProps()} />
                                    <span>
                                      {i18n.t('admin_setup__drag_and_drop')}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <div className="col-12">
                    <div className="gr-section full-height text-center">
                      <div className="">
                        <h2 className="h4 m-t--45">
                          <span className="icon-container m-r--10">
                            <i className={`fal fa-info-circle`} />
                          </span>
                          {i18n.t('admin_setup__not_allowed')}
                        </h2>

                        <div className="col-12 m-t--20 m-b--30">
                          {i18n.t('admin_setup__no_permission')}
                          <br />
                          {i18n.t('admin_setup__account_owner_only')}

                          <br />
                          <br />
                        </div>

                        <div className="m-t--45">
                          <img
                            src={vaultImg}
                            className="img-300"
                            alt="Locked"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AdminSetup.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSetup);
