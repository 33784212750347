import React from "react";
import * as PropTypes from "prop-types";
import ipadTransparent from "../../assets/images/browser-trans-800.png";

class IpadScreenshot extends React.Component {
  renderScreenshot({ clear = false }) {
    const ipad = new Image();
    const screenshot = new Image();
    const canvasId = this.props.canvasId;
    const canvas = document.getElementById(this.props.canvasId);
    const screenshotSrc = this.props.screenshotSrc;
    let loaded = 0,
      context,
      imgSize = {};

    function tryToDraw() {
      if (loaded === 2) {
        //the total amount of images
        canvas.width = 700;
        canvas.height = 504;
        context.drawImage(
          screenshot,
          0,
          0,
          imgSize.screenshot.width,
          imgSize.screenshot.height,
          2,
          55,
          696,
          imgSize.screenshot.height * (652 / imgSize.screenshot.width)
        ); //and then draw that!
        context.drawImage(
          ipad,
          0,
          0,
          imgSize.ipad.width,
          imgSize.ipad.height,
          0,
          0,
          700,
          504
        ); //draw it first

        const screenshotEl = document.getElementById(canvasId);

        if (screenshotEl) {
          screenshotEl.style.opacity = 1;
        }
      }
    }

    if (canvas && screenshotSrc) {
      context = canvas.getContext("2d");

      if (clear === true) {
        context.clearRect(0, 0, canvas.width, canvas.height);
      }

      ipad.onload = (img) => {
        imgSize["ipad"] = {
          width: img.target.width,
          height: img.target.height,
        };

        loaded++;
        tryToDraw();
      };

      screenshot.onload = (img) => {
        imgSize["screenshot"] = {
          width: img.target.width,
          height: img.target.height,
        };

        loaded++;
        tryToDraw();
      };

      ipad.src = ipadTransparent;
      screenshot.src = screenshotSrc;
    } else {
      console.error("ERR: Invalid params");
    }
  }

  componentDidUpdate(prevProps) {
    // If src exists and has changed, we need to re-render
    if (
      this.props.screenshotSrc &&
      prevProps.screenshotSrc !== this.props.screenshotSrc
    ) {
      this.renderScreenshot({ clear: true });
    }
  }

  componentDidMount() {
    this.renderScreenshot({ clear: false });
  }

  render() {
    return (
      <canvas
        id={this.props.canvasId}
        style={{
          width: this.props.displayWidth || "700px",
          maxWidth: "92%",
          opacity: 0,
        }}
      />
    );
  }
}

IpadScreenshot.propTypes = {
  canvasId: PropTypes.string.isRequired,
  screenshotSrc: PropTypes.string.isRequired,
  displayWidth: PropTypes.number,
};

export default IpadScreenshot;
