import React from 'react';
import './footer.scss';

const Footer = ({ email, title, subject }) => (
  <footer className="footer">
    <a href={`mailto:${email}?subject=${subject}`}>{title}</a>
  </footer>
);

export default Footer;
