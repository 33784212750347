import React from 'react';
import i18n from 'i18next';
import axios from 'axios';
import { serverUrl, getOrganisation } from '../../helpers/utility';
import connect from 'react-redux/es/connect/connect';
import './theme-generator.scss';
import { findIndex } from 'underscore';

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    organisation: state.organisation.organisation,
    locale: state.locale.userLocaleModel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrganisation: async () => {
      await getOrganisation({ dispatch, cacheBust: true });
    },
  };
};

class ThemeGenerator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarColor: '',
      pageTextHeaderColor: '',
      pageTextColor: '',
      primaryButtonColor: '',
      fontUrl: '',
      fontFamily: '',
      fontFamilyIndex: 0,
      themeResult: null,
    };

    this.fonts = [
      {
        name: 'Lato',
        url: 'https://fonts.googleapis.com/css?family=Lato&display=swap',
      },
      {
        name: 'Nunito Sans',
        url: 'https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap',
      },
      {
        name: 'Oxygen',
        url: 'https://fonts.googleapis.com/css?family=Oxygen&display=swap',
      },
      {
        name: 'Open Sans',
        url: 'https://fonts.googleapis.com/css?family=Open+Sans&display=swap',
      },
      {
        name: 'Poppins',
        url: 'https://fonts.googleapis.com/css?family=Poppins&display=swap',
      },
      {
        name: 'Roboto',
        url: 'https://fonts.googleapis.com/css?family=Roboto&display=swap',
      },
      {
        name: 'Source Sans Pro',
        url:
          'https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap',
      },
      {
        name: 'Ubuntu',
        url: 'https://fonts.googleapis.com/css?family=Ubuntu&display=swap',
      },
    ];

    this.updatePartnerTheme = this.updatePartnerTheme.bind(this);
    this.handleFontChange = this.handleFontChange.bind(this);
  }

  findFontIndex(url) {
    let fontIndex = findIndex(this.fonts, (font) => {
      return font && font.url === url;
    });

    return fontIndex || 0;
  }

  componentDidMount() {
    if (sessionStorage.getItem('themeGenerator')) {
      const themeState = JSON.parse(sessionStorage.getItem('themeGenerator'));
      this.setState({ ...themeState });
    }

    if (this.props.organisation && this.props.organisation.branding) {
      this.setState({
        sidebarColor: this.props.organisation.branding.sidebarColor,
        pageTextHeaderColor: this.props.organisation.branding
          .pageTextHeaderColor,
        pageTextColor: this.props.organisation.branding.pageTextColor,
        primaryButtonColor: this.props.organisation.branding.primaryButtonColor,
        fontUrl: this.props.organisation.branding.fontUrl,
        fontFamily: this.props.organisation.branding.fontFamily,
        fontFamilyIndex: this.findFontIndex(
          this.props.organisation.branding.fontUrl
        ),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (typeof prevProps.organisation === 'undefined' ||
        typeof prevProps.organisation.branding === 'undefined') &&
      typeof this.props.organisation === 'object' &&
      this.props.organisation.branding
    ) {
      this.setState({
        sidebarColor: this.props.organisation.branding.sidebarColor || '',
        pageTextHeaderColor:
          this.props.organisation.branding.pageTextHeaderColor || '',
        pageTextColor: this.props.organisation.branding.pageTextColor || '',
        primaryButtonColor:
          this.props.organisation.branding.primaryButtonColor || '',
        fontUrl: this.props.organisation.branding.fontUrl || '',
        fontFamily: this.props.organisation.branding.fontFamily || '',
        fontFamilyIndex: this.findFontIndex(
          this.props.organisation.branding.fontUrl
        ),
      });
    }
  }

  reloadOrganisationTheme() {
    if (
      typeof this.props.organisation === 'object' &&
      this.props.organisation.branding
    ) {
      this.setState({
        sidebarColor: this.props.organisation.branding.sidebarColor || '',
        pageTextHeaderColor:
          this.props.organisation.branding.pageTextHeaderColor || '',
        pageTextColor: this.props.organisation.branding.pageTextColor || '',
        primaryButtonColor:
          this.props.organisation.branding.primaryButtonColor || '',
        fontUrl: this.props.organisation.branding.fontUrl || '',
        fontFamily: this.props.organisation.branding.fontFamily || '',
      });
    }
  }

  handleCheckboxChange = (event) => {
    if (event.target && event.target.name) {
      const update = {};
      update[event.target.name] = event.target.checked;
      this.setState(update);
    }
  };

  updatePartnerTheme() {
    if (this.state.sidebarColor && this.state.primaryButtonColor) {
      axios
        .put(`${serverUrl}/organisation/theme`, {
          organisationId: this.props.organisation._id,
          fontUrl: this.state.fontUrl,
          fontFamily: this.state.fontFamily,
          primaryButtonColor: this.state.primaryButtonColor,
          pageTextColor: this.state.pageTextColor,
          pageTextHeaderColor: this.state.pageTextHeaderColor,
          sidebarColor: this.state.sidebarColor,
        })
        .then(async (response) => {
          if (response && response.data && response.data.success) {
            console.log('Theme updated.');

            await this.props.getOrganisation();
            this.reloadOrganisationTheme();

            this.setState({
              themeResult: response.data.data,
              requiredFields: null,
              updateSuccess: true,
            });

            setTimeout(() => {
              this.setState({ updateSuccess: false });
            }, 4000);
          }
        })
        .catch(function (error) {
          // handle error
          console.error('Error updating theme!');
        });
    } else {
      this.setState({
        requiredFields: i18n.t('theme__all_fields_required'),
      });
    }
  }

  addStyleString(str) {
    const node = document.createElement('style');
    node.innerHTML = str;
    document.body.appendChild(node);
  }

  handleFontChange = (event) => {
    if (
      event.target &&
      typeof event.target.value === 'string' &&
      this.fonts &&
      this.fonts.length > Number(event.target.value)
    ) {
      const fontFamilyIndex = Number(event.target.value);
      const font = this.fonts[fontFamilyIndex];

      if (font) {
        this.setState({
          fontFamily: font.name,
          fontUrl: font.url,
          fontFamilyIndex: fontFamilyIndex,
        });

        if (font.loaded !== true) {
          this.addStyleString(`@import url('${font.url}')`);
          font.loaded = true;
        }
      }
    }
  };

  handleInputChange = (event) => {
    if (event.target && event.target.name) {
      // Switch between top level state property change and a nested value update
      if (typeof event.target.dataset.stateType === 'undefined') {
        const update = {};
        update[event.target.name] = event.target.value;
        this.setState(update);
      } else {
        if (
          typeof event.target.dataset.stateType === 'string' &&
          this.state[event.target.dataset.stateType] !== 'undefined'
        ) {
          // stateType reflects the "parent" property of the value we are trying to update
          // e.g. this.state.PARENT.x
          const update = Object.assign(
            {},
            this.state[event.target.dataset.stateType]
          );
          update[event.target.name] = event.target.value;

          // We want to setState on the updated property, not replace the whole store with a nested value
          const updateWrapper = {};
          updateWrapper[event.target.dataset.stateType] = update;

          this.setState(updateWrapper);
        } else {
          console.error('ERR: Could not update nested state value #28774');
        }
      }
    }
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="unaligned-content-container" id="themeGenerator">
          <div className="row">
            <div className="col-12">
              <div className="row navigation-row">
                <div className="col-12">
                  {/*eslint-disable-next-line*/}
                  <a href="javascript:history.back()">
                    <span className="small">
                      <i className="fal fa-chevron-left" />
                    </span>{' '}
                    {i18n.t('theme__back')}
                  </a>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-7 col-xl-8">
              <div className="box">
                <h3>{i18n.t('theme')}</h3>

                <p className="light">{i18n.t('theme__customise')}</p>

                {this.state.requiredFields ? (
                  <p className="light error">{this.state.requiredFields}</p>
                ) : null}

                <div className="formInput">
                  <div>{i18n.t('theme__sidebar_bg')}</div>
                  <div className="row">
                    <div className="col-4">
                      <input
                        type="color"
                        className="form-control sm"
                        value={this.state.sidebarColor}
                        name="sidebarColor"
                        required
                        onChange={this.handleInputChange}
                      />
                    </div>

                    <div className="col-4">
                      <input
                        type="text"
                        className="form-control sm"
                        value={this.state.sidebarColor}
                        name="sidebarColor"
                        required
                        placeholder={i18n.t('theme__hex')}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="formInput">
                  <div>{i18n.t('theme__header_colour')}</div>
                  <div className="row">
                    <div className="col-4">
                      <input
                        type="color"
                        className="form-control sm"
                        value={this.state.pageTextHeaderColor}
                        name="pageTextHeaderColor"
                        required
                        onChange={this.handleInputChange}
                      />
                    </div>

                    <div className="col-4">
                      <input
                        type="text"
                        className="form-control sm"
                        value={this.state.pageTextHeaderColor}
                        name="pageTextHeaderColor"
                        required
                        placeholder={i18n.t('theme__hex')}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="formInput">
                  <div>{i18n.t('theme__primary_colour')}</div>
                  <div className="row">
                    <div className="col-4">
                      <input
                        type="color"
                        className="form-control sm"
                        value={this.state.pageTextColor}
                        name="pageTextColor"
                        required
                        onChange={this.handleInputChange}
                      />
                    </div>

                    <div className="col-4">
                      <input
                        type="text"
                        className="form-control sm"
                        value={this.state.pageTextColor}
                        name="pageTextColor"
                        required
                        placeholder={i18n.t('theme__hex')}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="formInput">
                  <div>{i18n.t('theme__primary_button_colour')}</div>
                  <div className="row">
                    <div className="col-4">
                      <input
                        type="color"
                        className="form-control sm"
                        value={this.state.primaryButtonColor}
                        name="primaryButtonColor"
                        required
                        onChange={this.handleInputChange}
                      />
                    </div>

                    <div className="col-4">
                      <input
                        type="text"
                        className="form-control sm"
                        value={this.state.primaryButtonColor}
                        name="primaryButtonColor"
                        required
                        placeholder={i18n.t('theme__hex')}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="formInput">
                  <div>{i18n.t('theme__custom_font')}</div>

                  <div className="row">
                    <div className="col-4">
                      <select
                        className="form-control"
                        value={this.state.fontFamilyIndex}
                        required
                        name="dashboardView"
                        onChange={this.handleFontChange}
                      >
                        <option disabled defaultValue value={{ name: '' }}>
                          {i18n.t('theme__select')}
                        </option>

                        {this.fonts && this.fonts.length > 0
                          ? this.fonts.map((font, fontIndex) => {
                              return (
                                <option key={font.name} value={fontIndex}>
                                  {font.name}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                  </div>
                </div>

                <p className="light">
                  {i18n.t('theme__disclaimer_1')}{' '}
                  <span className="underline">
                    {i18n.t('theme__disclaimer_2')}
                  </span>{' '}
                  {i18n.t('theme__disclaimer_3')}
                </p>

                <div className="formInput" onClick={this.updatePartnerTheme}>
                  <div className="btn btn-success">
                    {this.state.updateSuccess
                      ? i18n.t('theme__saved')
                      : i18n.t('theme__save')}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-5 col-xl-4">
              <div className="box">
                <h2 className="h3">{i18n.t('theme__preview')}</h2>

                <div id="theme-preview">
                  <div
                    className="theme-preview-sidebar"
                    style={{ backgroundColor: this.state.sidebarColor }}
                  />
                  <div className="theme-preview-inner">
                    <h1
                      className="h4 theme-preview-title"
                      style={{
                        color: this.state.pageTextHeaderColor,
                        fontFamily: this.state.fontFamily,
                      }}
                    >
                      {i18n.t('theme__title')}
                    </h1>
                    <p
                      className="theme-preview-text"
                      style={{
                        color: this.state.pageTextColor,
                        fontFamily: this.state.fontFamily,
                      }}
                    >
                      {i18n.t('theme__font_preview')}
                    </p>
                    <div
                      className="theme-preview-button btn btn-success"
                      style={{
                        backgroundColor: this.state.primaryButtonColor,
                        fontFamily: this.state.fontFamily,
                      }}
                    >
                      {i18n.t('theme__ok')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeGenerator);
