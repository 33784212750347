import React from 'react';
import i18n from 'i18next';
import Modal from 'react-bootstrap/Modal';
import * as PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import './permissions-modal.scss';

class PermissionsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      newRole: '',
    };
  }

  handleInputChange = (event) => {
    if (event.target && event.target.name) {
      // Switch between top level state property change and a nested value update
      if (typeof event.target.dataset.stateType === 'undefined') {
        const update = {};
        update[event.target.name] = event.target.value;
        this.setState(update);
      } else {
        if (
          typeof event.target.dataset.stateType === 'string' &&
          this.state[event.target.dataset.stateType] !== 'undefined'
        ) {
          // stateType reflects the "parent" property of the value we are trying to update
          // e.g. this.state.PARENT.x
          const update = Object.assign(
            {},
            this.state[event.target.dataset.stateType]
          );
          update[event.target.name] = event.target.value;

          // We want to setState on the updated property, not replace the whole store with a nested value
          const updateWrapper = {};
          updateWrapper[event.target.dataset.stateType] = update;

          this.setState(updateWrapper);
        } else {
          console.error('ERR: Could not update nested state value #28444');
        }
      }
    }
  };

  render() {
    return (
      <Modal
        size="md"
        centered={true}
        show={this.props.show}
        className="editUserModal"
        onHide={() => {
          this.props.hideModal();
        }}
      >
        <form
          onSubmit={(event) => {
            if (event) {
              event.preventDefault();
            }

            this.props.onSubmit(this.state.newRole);
            this.props.hideModal();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="col-12">{i18n.t('permissions_modal__edit')}</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-12 left">
              <div>
                <p className="small">{i18n.t('permissions_modal__tip')}</p>

                <div>
                  <select
                    className="form-control"
                    name="newRole"
                    value={this.state.newRole}
                    onChange={this.handleInputChange}
                  >
                    <option defaultValue value="">
                      {i18n.t('permissions_modal__select')}
                    </option>
                    <option value="user">
                      {i18n.t('permissions_modal__user')}
                    </option>
                    <option value="admin">
                      {i18n.t('permissions_modal__admin')}
                    </option>
                    {this.props.organisationOwner ? (
                      <option value="owner">
                        {i18n.t('permissions_modal__acc_owner')}
                      </option>
                    ) : null}
                  </select>
                </div>

                {this.props.applicationAdmin &&
                this.props.user &&
                this.props.user.currentPermissions &&
                this.props.user.currentPermissions.isApplicationFulfilment ===
                  true ? (
                  <div className="m-t--15">
                    <span className="m-r--5 green">
                      <span className="fal fa-check-circle" />
                    </span>
                    {i18n.t('permissions_modal__fulfilment')}
                  </div>
                ) : null}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-dark"
              onClick={() => {
                this.props.hideModal();

                // Reset state
                this.setState({
                  newRole: '',
                });
              }}
            >
              {i18n.t('permissions_modal__close')}
            </Button>

            <Button variant="success" type="submit">
              {i18n.t('permissions_modal__update')}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

PermissionsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  applicationAdmin: PropTypes.bool.isRequired,
  organisationOwner: PropTypes.bool.isRequired,
};

export default PermissionsModal;
