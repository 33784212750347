import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { serverUrl } from '../../helpers/utility';
import { toast } from 'react-toastify';
import './admin-setup-webhooks.scss';
// 1 input
// 1 send button per item
// global error reporting

const Webhooks = ({
  name,
  event,
  description,
  requestComplete,
  endpoint,
  action,
}) => {
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [requestComplete]);

  return (
    <div className="webhook-item" key={name}>
      <p className="webhook-item__name">
        <small>
          EVENT: <strong>{name}</strong>
        </small>
      </p>
      <p className="webhook-item__description">{description}</p>
      <button
        className="btn btn-outline-dark"
        onClick={() => {
          setIsLoading(true);
          action(endpoint, event);
        }}
        disabled={loading}
      >
        {loading ? (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        ) : (
          'TEST'
        )}
      </button>
    </div>
  );
};

const Error = ({ error }) => {
  switch (error.name) {
    case 'No URL':
      return <p className="admin-setup-webhooks__error">{error.message}</p>;
    case 'Bad Request':
      return <p className="admin-setup-webhooks__error">{error.message}</p>;
    case 'RequestError':
      return (
        <p className="admin-setup-webhooks__error">
          Something went wrong, please check the URL
        </p>
      );
    default:
      return null;
  }
};

const AdminSetupWebhooks = ({ customAppURL }) => {
  const [testURL, setTestURL] = useState('');
  const [error, setError] = useState({});
  const [requestComplete, setRequestComplete] = useState(false);

  const handleURLChange = (e) => {
    setTestURL(e.target.value);
  };

  const sendWebhook = (endpoint, eventType) => {
    setRequestComplete(false);

    if (testURL.length > 0) {
      const payload = {
        eventType,
        subscriber: [testURL],
        customAppURL: customAppURL[0],
      };

      axios
        .post(`${serverUrl}${endpoint}`, payload)
        .then((res) => {
          setError({});
          setRequestComplete(true);
          toast.success(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          setRequestComplete(true);
          if (err.response) {
            console.log(err.response);
            if (err.response.data.error && err.response.data.message) {
              setError({
                name: err.response.data.error,
                message: err.response.data.message,
              });
            }
          } else if (err.request) {
            // axios generated error
            setError({
              name: 'RequestError',
              message: 'wp_auto__request_error',
            });
          }
        });
    } else {
      setRequestComplete(true);
      setError({ name: 'No URL', message: 'Please enter a Test URL' });
    }
  };

  const webhookList_1 = [
    {
      name: 'SITE DOWN',
      event: 'SITE_DOWN',
      description:
        'This event is sent when a website goes offline. Those checks are performed every 15 min.',
      endpoint: '/webhook/testing/siteDown',
      action: sendWebhook,
      requestComplete,
    },
    {
      name: 'SITE BACK UP',
      event: 'SITE_BACK_UP',
      description: 'This event is sent when a website goes back online.',
      endpoint: '/webhook/testing/siteBackUp',
      action: sendWebhook,
      requestComplete,
    },
    {
      name: 'SITE COMPETITORS RANKING',
      event: 'SITE_COMPETITORS_RANKING',
      description: 'This event is sent when the competitors rankings changes.',
      endpoint: '/webhook/testing/siteCompetitorsRanking',
      action: sendWebhook,
      requestComplete,
    },
  ];

  const webhookList_2 = [
    {
      name: 'SITE SSL',
      event: 'SITE_SSL',
      description:
        "This event is sent when an issue with a website's SSL certificate has been detected.",
      endpoint: '/webhook/testing/siteSSL',
      action: sendWebhook,
      requestComplete,
    },
    {
      name: 'SITE CRAWL FAILED',
      event: 'SITE_CRAWL_FAILED',
      description: 'This event is sent when a website cannot be crawled.',
      endpoint: '/webhook/testing/siteCrawlFailed',
      action: sendWebhook,
      requestComplete,
    },
    {
      name: 'SITE KEYWORD RANKING',
      event: 'SITE_KEYWORD_RANKING',
      description:
        "This event is sent when a website's keyword ranking has changed.",
      endpoint: '/webhook/testing/siteKeywordRanking',
      action: sendWebhook,
      requestComplete,
    },
  ];

  return (
    <section className="admin-setup-webhooks">
      <p className="admin-setup-webhooks__title m-b--10">
        Test each webhook event with dummy data:
      </p>
      <p className="m-b--0">Test URL</p>
      <p className="light m-b--10">
        <small>
          We recommend using{' '}
          <a href="https://webhook.site/" target="_blank" rel="noreferrer">
            https://webhook.site
          </a>
        </small>
      </p>
      <input
        type="url"
        className="form-control sm"
        value={testURL}
        onChange={handleURLChange}
      />
      <Error error={error} />
      <div className="webhooks-items__wrapper">
        <ul className="webhooks-items">{webhookList_1.map(Webhooks)}</ul>
        <ul className="webhooks-items">{webhookList_2.map(Webhooks)}</ul>
      </div>
    </section>
  );
};

export default AdminSetupWebhooks;
