import React, { Fragment } from 'react';
import i18n from 'i18next';

import {
  CATEGORY_SEO,
  CATEGORY_MOBILE,
  CATEGORY_PERFORMANCE,
  CATEGORY_SECURITY,
} from '../../helpers/constants';
import AuditPassed from '../audit-passed/audit-passed';
import './audit-section.scss';
import { round } from '../../helpers/utility';
import AuditSpeedChart from '../audit-speed-chart/audit-speed-chart';
import InfoIcon from '../info-icon/info-icon';
import phoneMockupSrc from '../../assets/images/screen-mock-mobile.png';
import GRRadialChart from '../gr-radial-chart/gr-radial-chart';
import NoScreenshot from '../no-screenshot/no-screenshot';
import RenderIssues from '../../components/render-issues/render-issues';
import WidgetContainer from '../widgetContainer/widgetContainer';

const yesNo = (requiredMetrics, issues) => {
  // Filter issues based on required names
  const requiredIssues = issues.filter((x) => requiredMetrics.includes(x.key));
  // If a single failure, fail test
  const passed = requiredIssues.reduce(
    (acc, curr) => (!curr.passed ? false : acc),
    true
  );

  return {
    result: passed ? i18n.t('audit_section__yes') : i18n.t('audit_section__no'),
    passed,
  };
};

const siteSecure = (issues) => {
  // List of required security metric names
  const requiredMetrics = ['ssl', 'ssl_certificate_valid'];
  return yesNo(requiredMetrics, issues);
};

const mobileReady = (issues) => {
  // List of required mobile metric names
  const requiredMetrics = [
    'pages_with_frame',
    'pages_with_flash',
    'time_load_high',
    'time_waiting_high',
  ];
  return yesNo(requiredMetrics, issues);
};

const AuditSection = ({
  renderDifficulty,
  toggleIssueVisibility,
  togglePagesVisibility,
  onAutoFixClick,
  onLearnFixClick,
  onRequestHelpClick,
  issues,
  title,
  shortTitle,
  description,
  site,
  category,
  pagesTotal,
  audit,
  id,
  score,
}) => {
  const issuesFailed = issues.filter((x) => !x.passed);
  const issuesPassed = issues.filter((x) => x.passed);

  return (
    <div className="audit-section" id={id}>
      <WidgetContainer>
        <div className="audit-section-header row">
          {audit.scores && audit.scores[score] && (
            <div className="col-12 col-md-6 col-lg-4 offset-lg-2">
              <GRRadialChart
                value={audit.scores[score]}
                titlePrefix={shortTitle || title}
                graded
              />
            </div>
          )}
          <div className="audit-section-header-text col-12 col-md-6 col-lg-4">
            <h2 className="audit-section-title">{title}</h2>
            {description && (
              <p className="audit-section-description">{description}</p>
            )}
          </div>
        </div>
      </WidgetContainer>

      <div className="standard-box box-shadow full-height gr-section">
        <h3 className="standard-box-header">
          {i18n.t('audit_section__what_we_found')}
        </h3>

        <div className="standard-box-content standard-box-auto-height">
          <div className="row">
            {category.includes(CATEGORY_PERFORMANCE) && (
              <div className="col-12 col-md-6 audit-section-box">
                <div className="flex align-items-center">
                  <h4 className="audit-section-box-title">
                    {i18n.t('audit_section__average_speed')}
                  </h4>
                  <div className="audit-section-box-title-icon">
                    <InfoIcon
                      id={'averageSpeedInfo'}
                      placement={'right'}
                      text={
                        <div>
                          <p>
                            {i18n.t('audit_section__average_speed_description')}
                          </p>
                        </div>
                      }
                    />
                  </div>
                </div>
                <p>{i18n.t('audit_section__is_site_fast')}</p>
                {site &&
                site.metrics &&
                site.metrics.metrics &&
                site.metrics.metrics.totalLoad &&
                0 < site.metrics.metrics.totalLoad ? (
                  <AuditSpeedChart
                    value={round(site.metrics.metrics.totalLoad, 1)}
                    timeLimit={10}
                    bounds={[2, 6]}
                    units={'s'}
                  />
                ) : (
                  <p className="audit-section-no-data">
                    {i18n.t('audit_section__try_again_later')}
                  </p>
                )}

                <div className="m-b--15"></div>
              </div>
            )}

            {category.includes(CATEGORY_SECURITY) && (
              <div className="col-12 col-md-6 audit-section-box">
                <h4 className="audit-section-box-title">
                  {i18n.t('audit_section__security')}
                </h4>
                <p>{i18n.t('audit_section__is_site_secure')}</p>
                <AuditPassed {...siteSecure(issues)} />
              </div>
            )}

            {category.includes(CATEGORY_SEO) && (
              <>
                {/*@TODO: content freshness*/}
                {/*<div className="col-12 col-md-6 audit-section-box">*/}
                {/*  <h4 className="audit-section-box-title">*/}
                {/*    {i18n.t('Content freshness')}*/}
                {/*  </h4>*/}
                {/*  <p>*/}
                {/*    {i18n.t("Days since you have updated your site's content")}*/}
                {/*  </p>*/}
                {/*  <AuditPassed result="3" passed={true} />*/}
                {/*</div>*/}
                <div className="col-12 col-md-6 audit-section-box">
                  <h4 className="audit-section-box-title">
                    {i18n.t('audit_section__pages_analyzed')}
                  </h4>
                  <p>{i18n.t('audit_section__pages_on_site')}</p>
                  <AuditPassed result={pagesTotal} passed={true} />
                </div>
              </>
            )}

            {category.includes(CATEGORY_MOBILE) && (
              <>
                <div className="col-12 col-md-4 audit-section-box">
                  <h4 className="audit-section-box-title">
                    {i18n.t('audit_section__mobile_speed')}
                  </h4>
                  <p>{i18n.t('audit_section__mobile_loading_time')}</p>
                  {site &&
                  site.metrics &&
                  site.metrics.metricsMobile &&
                  site.metrics.metricsMobile.totalLoad &&
                  0 < site.metrics.metricsMobile.totalLoad ? (
                    <AuditSpeedChart
                      value={round(site.metrics.metricsMobile.totalLoad, 1)}
                      timeLimit={20}
                      bounds={[6, 12]}
                      units={'s'}
                    />
                  ) : (
                    <p className="audit-section-no-data">
                      {i18n.t('audit_section__try_again_later')}
                    </p>
                  )}
                </div>
                <div className="col-12 col-md-4 audit-section-box audit-section-screenshot-container">
                  <div className="audit-section-screenshot-text">
                    <h4 className="audit-section-box-title">
                      {i18n.t('audit_section__mobile_ready')}
                    </h4>
                    <p>{i18n.t('audit_section__mobile_site_works')}</p>
                    <AuditPassed {...mobileReady(issues)} />
                  </div>
                </div>
                <div className="d-none d-md-flex col-md-4">
                  <div className="audit-section-screenshot">
                    {audit.screenshotUrlMobile &&
                    audit.screenshotUrlMobile !== null ? (
                      <Fragment>
                        <img
                          src={phoneMockupSrc}
                          className="audit-section-screenshot-wrapper"
                          alt=""
                          aria-hidden
                        />
                        <img
                          src={audit.screenshotUrlMobile}
                          alt={`${audit.domain} screenshot`}
                          className="audit-section-screenshot-image"
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <img
                          src={phoneMockupSrc}
                          className="audit-section-screenshot-wrapper"
                          alt=""
                          aria-hidden
                        />
                        <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            maxWidth: '160px',
                          }}
                          className="screenshot-unavailable"
                        >
                          <NoScreenshot
                            orientation="portrait"
                            text={i18n.t('screenshot__not_available')}
                          />
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {!!issues.length && (
        <>
          <div className="standard-box box-shadow full-height gr-section">
            <h3 className="standard-box-header">
              {i18n.t('audit_section__what_to_look_at')}
            </h3>

            <div className="standard-box-content standard-box-content-no-padding standard-box-auto-height">
              <div className="row">
                <div className="col-12">
                  {issuesFailed.length ? (
                    <RenderIssues
                      issues={issuesFailed}
                      toggleIssueVisibility={toggleIssueVisibility}
                      togglePagesVisibility={togglePagesVisibility}
                      renderDifficulty={renderDifficulty}
                      onAutoFixClick={onAutoFixClick}
                      onLearnFixClick={onLearnFixClick}
                      onRequestHelpClick={onRequestHelpClick}
                      audit={audit}
                    />
                  ) : (
                    <p className="standard-box-content no-margin standard-box-auto-height standard-box-content-no-border">
                      {i18n.t('audit_section__no_issues')}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="standard-box box-shadow full-height gr-section">
            <h3 className="standard-box-header">
              {i18n.t('audit_section__what_done_well')}
            </h3>

            <div className="standard-box-content standard-box-content-no-padding standard-box-auto-height">
              <div className="row">
                <div className="col-12">
                  {issuesPassed.length ? (
                    <RenderIssues
                      issues={issuesPassed}
                      toggleIssueVisibility={toggleIssueVisibility}
                      togglePagesVisibility={togglePagesVisibility}
                      renderDifficulty={renderDifficulty}
                      onAutoFixClick={onAutoFixClick}
                      onLearnFixClick={onLearnFixClick}
                      onRequestHelpClick={onRequestHelpClick}
                      audit={audit}
                    />
                  ) : (
                    <p className="standard-box-content no-margin standard-box-auto-height standard-box-content-no-border">
                      {i18n.t('audit_section__no_issues')}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AuditSection;
