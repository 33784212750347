import React from 'react';
import { withTranslation } from 'react-i18next';
import { serverUrl } from '../../helpers/utility';

const Sitemap = ({ t, site }) => {
  return (
    <div className="search-engine-title-item">
      <div className="search-engine-title-item-subheader">
        {t('sitemap')}
        <div className="search-engine-title-item-subheader-number">
          {site.sitemap && site.sitemap.sitemapPageCount
            ? site.sitemap.sitemapPageCount
            : 0}
        </div>
        {t('sitemap__2')}
      </div>
      <div className="search-engine-title-item-text">
        {site.sitemap && site.sitemap.sitemapUrl ? (
          <a
            href={`${serverUrl}/site/sitemap/${site._id}`}
            download={`${site.domainFriendly}-sitemap.xml`}
          >
            {t('sitemap__3')}
            <i className="m-l--5 fal fa-download pointer" />
          </a>
        ) : (
          t('sitemap__3')
        )}
      </div>
    </div>
  );
};

export default withTranslation()(Sitemap);
