import React from 'react';
import i18n from 'i18next';

const SitemapFix = (props) => {
  return (
    <div>
      {props.site.sitemap && props.site.sitemap.sitemapUrl ? (
        <div>
          <div>
            {i18n.t('sitemap_fix__1')} {props.site.sitemap.sitemapPageCount}{' '}
            {i18n.t('sitemap_fix__2')}
          </div>
          <div>
            <a href={props.site.sitemap.sitemapUrl} download>
              <div className="btn btn-primary m-t--10">
                {i18n.t('sitemap_fix__3')}
              </div>
            </a>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SitemapFix;
