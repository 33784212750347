// This must be the first line in src/index.js
import "react-app-polyfill/ie9";
// Needed also! Since the ie9 ones are just the basic missing functions
import "react-app-polyfill/stable";

// Additional IE polyfills not picked up by react-app-polyfill, required.
import "classlist-polyfill";
import "es7-object-polyfill";

import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/bootstrap.css";
import App from "./App";
import "./i18n";
// Connect to socket/init
import "./assets/scripts/socket";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducer from "./reducers";

const store = createStore(rootReducer);

let errored = false;

try {
  const storage = window.sessionStorage;
} catch (error) {
  if (error) {
    errored = true;
    ReactDOM.render(
      <p style={{ padding: "20px" }}>
        It looks like 3rd-party site data may be disabled in your browser. This
        feature is required for this application to work. Please enable
        3rd-party site data for your browser and try again. This may be caused
        by viewing this site in incognito mode.
      </p>,
      document.getElementById("root")
    );
  }
}

if (!errored) {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
