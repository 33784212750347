import React from 'react';
import classnames from 'classnames';

import './no-screenshot.scss';

const NoScreenshot = ({ text, orientation = 'landscape' }) => {
  const iconClasses = classnames({
    noScreenshot__icon: true,
    fal: true,
    'fa-10x': orientation === 'landscape',
    'fa-8x': orientation === 'portrait',
    'fa-image': orientation === 'landscape',
    'fa-file-image': orientation === 'portrait',
  });

  const textClasses = classnames({
    noScreenshot__text: true,
    'noScreenshot__text--portrait': orientation === 'portrait',
  });

  return (
    <div className="noScreenshot">
      <i className={iconClasses}></i>
      <h3 className={textClasses}>{text}</h3>
    </div>
  );
};

export default NoScreenshot;
