import React from 'react';

import WPautomation from '../../components/wp-automation/wp-automation';
import SitemapFix from '../../pages/audit/sitemapFix';
import SpeedFix from '../../pages/audit/speedFix';
import { WPfixList } from '../../helpers/constants';

const AutoFix = ({ currentIssue, site, CMS, user, hideModal }) => {
  if (!currentIssue || !currentIssue.key) {
    return null;
  }

  if (CMS === 'WordPress' && WPfixList.includes(currentIssue.key)) {
    return (
      <WPautomation domain={site.domain} user={user} hideModal={hideModal} />
    );
  }

  if (currentIssue.key === 'have_sitemap' && CMS !== 'WordPress') {
    return <SitemapFix site={site} />;
  }

  if (currentIssue.key === 'time_load_high' && CMS !== 'WordPress') {
    return <SpeedFix />;
  }

  return null;
};

export default AutoFix;
