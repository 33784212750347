export const WPfixList = [
  // 'have_sitemap',
  // 'time_load_high',
  // 'duplicate_meta_descriptions',
  // 'meta_description_empty',
  // 'meta_description_inappropriate',
  // 'duplicate_meta_tags',
  // 'duplicate_titles',
  // 'title_empty',
  // 'title_duplicate_tag',
  // 'have_robots',
  // 'compression_disabled',
];

export const SUPPORT_EMAIL = 'support@gorank.app';
export const SUPPORT_TITTLE = 'Customer support';
export const SUPPORT_SUBJECT = 'Support enquiry';

// audit issues
export const EASY = 1;
export const MEDIUM = 2;
export const HARD = 3;

export const CATEGORY_SEO = 'CATEGORY_SEO';
export const CATEGORY_PERFORMANCE = 'CATEGORY_PERFORMANCE';
export const CATEGORY_SECURITY = 'CATEGORY_SECURITY';
export const CATEGORY_MOBILE = 'CATEGORY_MOBILE';
export const SEO_ID = 'seo_section';
export const PERFORMANCE_ID = 'performance_section';
export const SECURITY_ID = 'security_section';
export const MOBILE_ID = 'mobile_section';
